import { fromEvent as observableFromEvent, Observable, interval } from 'rxjs';
import { Directive, ElementRef, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
    selector: '[appScroll]',
})
export class ScrollDirective {
    private didScroll;
    private lastScrollTop = 0;
    private delta = 5;
    private navbarHeight;
    constructor(
        public element: ElementRef,
        public renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.navbarHeight = this.element.nativeElement.clientHeight;
        observableFromEvent(window, 'scroll').subscribe((e: any) => {
            this.didScroll = true;
        });
        const source = interval(300);
        const subscribe = source.subscribe((val) => {
            if (this.didScroll) {
                this.hasScrolled();
                this.didScroll = false;
            }
        });
    }
    public hasScrolled() {
        const scrollTop = document.documentElement.scrollTop;

        if (Math.abs(this.lastScrollTop - scrollTop) <= this.delta) {
            return;
        }
        if (scrollTop > this.lastScrollTop && scrollTop > this.navbarHeight) {
            // Scroll Down
            this.renderer.addClass(this.element.nativeElement, 'nav-up');
            this.renderer.removeClass(this.element.nativeElement, 'nav-down');
        } else {
            // Scroll UP
            this.renderer.addClass(this.element.nativeElement, 'nav-down');
            this.renderer.removeClass(this.element.nativeElement, 'nav-up');
        }
        this.lastScrollTop = scrollTop;
    }
}
