export class PayrollLimits {
    totalPayroll: number;
    maxPayrolls: MaxValues[];
}

export class MaxValues {
    id: string;
    value: number;
    type?: string;
}

export class EmpLimits {
    totalEmployees: number;
    limitConsumed: number;
    consumption: MaxValues[];
}
