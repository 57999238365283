import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { EmailFormModule } from '../email-form/email-form.module';
import { MatDialogModule } from '@angular/material/dialog';
import { AnalyticsModule } from '@crux/services';

@NgModule({
    declarations: [DialogComponent],
    imports: [CommonModule, EmailFormModule, MatDialogModule, AnalyticsModule],
})
export class DialogModule {}
