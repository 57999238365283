import {
    sciRateQuoteActionTypes,
    sciRateQuoteActionUnion,
} from '../actions/sci-rate-quote.action';

export let sciRateQuoteInitialState: any;

export function sciRateQuoteReducer(
    sciRateQuoteapiData = sciRateQuoteInitialState,
    action: sciRateQuoteActionUnion
) {
    switch (action.type) {
        case sciRateQuoteActionTypes.sciRateQuoteData: {
            return action.payload;
        }
        default: {
            return sciRateQuoteapiData;
        }
    }
}
