import { Component, OnInit } from '@angular/core';
import { CommonCommunicationService } from './../../../services/common-communication.service';
import { DictionaryService } from './../../../services/dictionary.service';

import { ProductService } from '../../../services/product.service';
import { Router } from '@angular/router';
import { findIndex } from 'lodash-es';
import { AnalyticsService } from '@crux/services';

/**
 * NGRX store
 */
import { Store, select } from '@ngrx/store';
import { Classification } from '../../../model/classification';
import { ZipcodeModel } from '../../../model/zipcode';
import { StartQuoteAction } from '../../../sales-store/appetite-store/actions';
import { ProductsRequest } from './../../../model/products-request';
/**
 *  Partner Customization
 */
import { PartnerCustomization } from '../../../partner-customization/partner-customization.helper';

@Component({
    selector: 'app-step-one',
    templateUrl: './step-one.component.html',
    styleUrls: ['./step-one.component.scss'],
})
export class StepOneComponent implements OnInit {
    showNote = true;
    public showError: boolean;
    public enableNext = false;
    public hideQuoteAll = false;
    public selected = {};
    labelDictionary: any;
    enableStartFlag: false;
    classification: Classification;
    zipcodeData: ZipcodeModel[];
    revenue: number;

    public commonCommunicationService: CommonCommunicationService;
    constructor(
        public dictionaryService: DictionaryService,
        private pcommonCommunicationService: CommonCommunicationService,
        private productService: ProductService,
        private router: Router,
        private _analytics: AnalyticsService,
        private _store: Store<{
            classification: Classification;
            zipcode: ZipcodeModel[];
            revenue: number;
        }>,
        public _partnerCustomization: PartnerCustomization
    ) {
        this.commonCommunicationService = pcommonCommunicationService;
        this._store.pipe(select('classification')).subscribe((classData) => {
            this.classification = classData;
        });
        this._store.pipe(select('zipcode')).subscribe((zipData) => {
            this.zipcodeData = zipData;
        });
        this._store.pipe(select('revenue')).subscribe((amt) => {
            this.revenue = amt;
        });
    }

    ngOnInit() {
        this.labelDictionary = this.dictionaryService.labelDict;
        this.enableNext =
            findIndex(
                this.commonCommunicationService.quoteRequestData.products,
                { selected: true }
            ) > -1;
    }

    goNext(e) {
        /**
         * Track Click of `NEW QUOTE` after user
         * chooses Classification and Postal Code.
         */
        this._analytics.eventTrack.next({
            category: 'ProductSelection',
            action: 'BUTTON_CLICK',
            label: 'NEW QUOTE',
        });

        this._store.dispatch(new StartQuoteAction(this.buildProductsRequest()));

        // show eligibility question irrespective of revenue
        // do not show eligibility for 3.0
        this._partnerCustomization.goToNextScreen('/');
    }

    buildProductsRequest() {
        const productsRequest = new ProductsRequest();
        productsRequest.digitalClassCode = this.classification.digitalClassCode;
        productsRequest.stateId = this.zipcodeData[0].StateProvince;

        productsRequest.annualRevenue = this.revenue.toString();
        productsRequest.isSciExcluded = this._partnerCustomization.isSciExcluded;
        productsRequest.wsgClassId = this.classification.wsgClassId;
        return productsRequest;
    }

    continueToEdit() {
        this.showNote = false;
    }
    closeEditPanel() {
        this.commonCommunicationService.isBusinessClassEditMode = false;
        this.commonCommunicationService.stepNumber = 4;
    }
}
