import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { DictionaryService } from '../../../services/dictionary.service';
import { Store, select } from '@ngrx/store';
import { QuoteStep } from '../../../model/quote-step';
import {
    QuoteStepAction,
    QuestionDataAction,
    StartQuoteAction,
    QuestionInsuranceAction,
    QuestionWebsiteAction,
    QuestionRevenueAction,
    QuestionBusinessAction,
    QuestionZipCodeAction,
    QuestionEmailAction,
    QuestionResetDataAction,
    ResetClassificationQuoteAction,
    ResetZipcodeDataQuoteAction,
    ResetRevenueDataQuoteAction,
} from '../../../sales-store/appetite-store/actions';
import {
    QuestionType,
    DataType,
} from '../../../csb-quote/csb-question/model/question-type';
import { LeftPanel } from './csb-left-panel/model/left-panel';
import { environment } from '../../../../environments/environment';
import { CruxHttpClientService, AnalyticsService } from '@crux/services';
import { ProductService } from '../../../services/product.service';
import { EmailJourney } from './../../../../assets/json/start-email-journey-payload';
import { SaveEmailService } from '../../../services/save-email.service';
import { SetProductsAction } from '../../../sales-store/products-store/actions';
import { Product } from '../../../model/product';
import { ProductsRequest } from '../../../model/products-request';
import { PartnerCustomization } from '../../../partner-customization/partner-customization.helper';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { Classification } from '../../../model/classification';
import { SetCustomerInfoDataAction } from '../../../sales-store/questions-store/actions';
import { QuestionsEffects } from '../../../sales-store/questions-store/effects';
import { GAEvent } from '../../../csb-core/ga-event';
import { SCIRateService } from '../../../services/sci-rate.service';
import { AffinityPartnerFlags } from '../../../model/affinity-partner';
import { isEmpty, isNil } from 'lodash-es';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-quote-step',
    templateUrl: './quote-step.component.html',
    styleUrls: ['./quote-step.component.scss'],
})
export class QuoteStepComponent implements OnInit {
    showQuestions: boolean;

    showContinue: boolean;

    showEmail: boolean;

    emailValid = false;

    questions: Array<QuestionType>;

    leftPanel: LeftPanel;

    homeQuestion: any;

    quoteStep: QuoteStep = new QuoteStep();

    timerReference: any;

    products: any;

    showError: boolean;

    showSpinner: boolean;

    private blobURL = environment.blobStorageBaseURL;

    hasLeftPanelLoaded = false;

    hasQuestionLoaded = false;

    firstTimeHide = false;

    saveQuote: EmailJourney;

    @Output() showProducts: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('quickQuote') quickQuote: ElementRef;

    advance: boolean;

    private customerInfoData: any;

    AffinityPartnerFlags: AffinityPartnerFlags;
    showEmailComponent = true;
    autocomptypefield = false;

    constructor(
        private http: CruxHttpClientService,
        private dictionaryService: DictionaryService,
        private _ccs: CommonCommunicationService,
        private _questionsEffects: QuestionsEffects,
        public productService: ProductService,
        private saveEmailService: SaveEmailService,
        public _partnerCustomization: PartnerCustomization,
        private _analytics: AnalyticsService,
        private _store: Store<{
            quoteStep: QuoteStep;
            products: Product[];
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>,
        private sciRateService: SCIRateService,
        private _router: Router
    ) {
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.AffinityPartnerFlags = AffinityPartnerFlags;
                        if (
                            this.AffinityPartnerFlags.utm_source ===
                                'walmart' ||
                            this.AffinityPartnerFlags.utm_source === 'ISBA' ||
                            this.AffinityPartnerFlags.utm_source === 'RSA' ||
                            this.AffinityPartnerFlags.utm_source === 'amazon' ||
                            this.AffinityPartnerFlags.utm_source === 'briza'
                        ) {
                            this.autocomptypefield = true;
                        }
                    }
                }
            });
    }

    ngOnInit() {
        const ts = new Date().getTime();
        this.advance = false;
        this._ccs.scrollToQuestions = this.quickQuote;
        this.http
            .get(this.blobURL + '/data/home-quote-left-panel.json?ts=' + ts, {
                responseType: 'json',
            })
            .subscribe((res) => {
                this.leftPanel = res;
                this.hasLeftPanelLoaded = true;
            });

        this.http
            .get(this.blobURL + '/data/home-quote-question.json?ts=' + ts, {
                responseType: 'json',
            })
            .subscribe((res) => {
                if (
                    this.AffinityPartnerFlags &&
                    this.AffinityPartnerFlags.partnercode &&
                    this.AffinityPartnerFlags.partnercode === 'CISA'
                ) {
                    this.showEmailComponent = false;
                }
                this.homeQuestion = res;
                this.hasQuestionLoaded = true;
                this.updateHomeQuestion();
            });
        this._store.pipe(select('quoteStep')).subscribe((quoteStep) => {
            if (!this.showQuestions) {
                this.quoteStep.stepsComplete = quoteStep.stepsComplete;
                this.quoteStep.insurance = quoteStep.insurance;
                this.quoteStep.website = quoteStep.website;
                this.quoteStep.business = quoteStep.business;
                this.quoteStep.revenue = quoteStep.revenue;
                this.quoteStep.zipcode = quoteStep.zipcode;
                this.quoteStep.email = quoteStep.email;
                this.showQuestions = true;
                this.updateHomeQuestion();
            }
        });

        this._questionsEffects
            .readCustomerInfoDataFromStore$()
            .subscribe((customerInfoData) => {
                this.customerInfoData = customerInfoData;
            });

        this._store.pipe(select('products')).subscribe((products: any) => {
            this.showSpinner = false;
            if (products && products.length > 0 && this.advance) {
                products = products.filter(function (product) {
                    return (
                        product.productId !== 'MICROCYBER' &&
                        product.productId !== 'MICROBOP' &&
                        product.productId !== 'MICROGL'
                    );
                });
            }
            if (products && products.length > 0 && this.advance) {
                this.showProducts.emit(true);
                this.showError = false;
            } else if (products && products.length === 0 && this.advance) {
                this.resetQuoteStepData();
                this.showError = true;
            }
            this.products = products;
        });
    }

    resetQuoteStepData() {
        GAEvent.CapturEvent(
            this._analytics,
            GAEvent.ACTION.VALID_DETAILS,
            GAEvent.CATEGORY.SUBMIT,
            GAEvent.LABEL.No_Products_Offered
        );

        this._store.dispatch(
            new ResetClassificationQuoteAction(new Classification())
        );
        this._store.dispatch(new ResetZipcodeDataQuoteAction([]));
        this._store.dispatch(new ResetRevenueDataQuoteAction(undefined));
        this.customerInfoData.emailAddress = '';
        this._store.dispatch(
            new SetCustomerInfoDataAction(this.customerInfoData)
        );
        this._store.dispatch(new QuestionResetDataAction(new QuoteStep()));
    }

    updateHomeQuestion() {
        if (this.hasQuestionLoaded && this.showQuestions) {
            this.homeQuestion.forEach((item) => {
                const name = item.data.name;
                if (this.quoteStep[name]) {
                    item.data.valid = this.quoteStep[name].valid;
                    item.data.value = this.quoteStep[name].value;
                }
            });
            this.quoteStep.questionData = [...this.homeQuestion];
            this.checkAllValidQuestion();
        }
    }

    buildProductsRequest() {
        const productsRequest = new ProductsRequest();
        const quoteStep = this.quoteStep;
        productsRequest.digitalClassCode = quoteStep.business
            ? quoteStep.business.value.tts_code
            : '';
        productsRequest.stateId = quoteStep.zipcode
            ? quoteStep.zipcode.value[0].StateProvince
            : '';

        if (this._partnerCustomization.appVersion === 1) {
            productsRequest.annualRevenue = quoteStep.revenue
                ? quoteStep.revenue.value
                : '';
        }

        productsRequest.isSciExcluded = this._partnerCustomization.isSciExcluded;

        productsRequest.wsgClassId = quoteStep.business
            ? quoteStep.business.value.wsgClassId
            : '';
        if (
            this.AffinityPartnerFlags &&
            this.AffinityPartnerFlags.partnercode &&
            !isNil(this.AffinityPartnerFlags.partnercode) &&
            !isEmpty(this.AffinityPartnerFlags.partnercode) &&
            this.AffinityPartnerFlags.partnercode === 'CISA'
        ) {
            productsRequest.isInsideSales = true;
        }
        return productsRequest;
    }

    continue(el) {
        el.scrollIntoView();
        this.showSpinner = true;
        this.saveQuoteAPI();

        // Email Event

        GAEvent.CapturEvent(
            this._analytics,
            GAEvent.ACTION.USER_INPUT,
            GAEvent.CATEGORY.Question_5,
            'Email - ' + 'xxxx'
        );

        GAEvent.CapturEvent(
            this._analytics,
            GAEvent.ACTION.VALID_DETAILS,
            GAEvent.CATEGORY.SUBMIT,
            GAEvent.LABEL.Details_Submitted
        );

        if (!this.autocomptypefield) {
            this._router.navigate(['/businesstype']);
        } else {
            this._store.dispatch(
                new StartQuoteAction(this.buildProductsRequest())
            );
        }

        this.advance = true;
    }

    saveQuoteAPI() {
        this.saveQuote = new EmailJourney();

        let saveQuotePayloadFormat: any = this.saveQuote
            .captureSaveQuotePayload;

        saveQuotePayloadFormat = this.saveQuote.buildSaveQuoteReq(
            saveQuotePayloadFormat,
            this.quoteStep.email.value.toLowerCase(),
            this._ccs.PartnerId,
            this.quoteStep.insurance.value,
            this.quoteStep.revenue.value,
            this.quoteStep.zipcode.value,
            this.quoteStep.business.value,
            this.AffinityPartnerFlags
        );
        this.saveEmailService
            .startEmailJourney(saveQuotePayloadFormat)
            .subscribe(
                (data) => {
                    //  do nothing
                },
                (error) => {
                    // do nothing
                }
            );
    }

    handleStore(data: DataType) {
        if (data && this.quoteStep.questionData[data.id] != null) {
            this.quoteStep.questionData[data.id].data = { ...data };
            this.dispatchStoreDataEvent(data);
            this._store.dispatch(new QuestionDataAction(this.quoteStep));
        }
        if (this.timerReference) {
            clearTimeout(this.timerReference);
        }
        this.timerReference = setTimeout(this.checkAllValidQuestion, 200);
    }

    dispatchStoreDataEvent(data: DataType) {
        if (data && data.valid) {
            switch (data.name) {
                case 'insurance':
                    this.quoteStep.insurance = data;
                    this._store.dispatch(
                        new QuestionInsuranceAction(this.quoteStep)
                    );
                    break;

                case 'revenue':
                    this.quoteStep.revenue = data;
                    this._store.dispatch(
                        new QuestionRevenueAction(this.quoteStep)
                    );
                    break;

                case 'zipcode':
                    this.quoteStep.zipcode = data;
                    this._store.dispatch(
                        new QuestionZipCodeAction(this.quoteStep)
                    );
                    break;

                case 'business':
                    this.quoteStep.business = data;
                    this._store.dispatch(
                        new QuestionBusinessAction(this.quoteStep)
                    );
                    break;

                case 'email':
                    this.quoteStep.email = data;
                    this._store.dispatch(
                        new QuestionEmailAction(this.quoteStep)
                    );
                    break;

                case 'website':
                    this.quoteStep.website = data;
                    this._store.dispatch(
                        new QuestionWebsiteAction(this.quoteStep)
                    );
                    break;

                default:
                    break;
            }
        }
    }

    checkAllValidQuestion = () => {
        let flag = true;
        let count = 0;
        this.quoteStep.questionData.forEach((item, index) => {
            if (item.data.valid) {
                count++;
            }

            if (!item.data.valid) {
                flag = false;
            }
        });

        if (!this.firstTimeHide && count > 0) {
            this.firstTimeHide = true;
        }

        this.showContinue = flag;

        if (this.showContinue && !this.quoteStep.stepsComplete) {
            this.quoteStep.stepsComplete = true;
            this._store.dispatch(new QuoteStepAction(this.quoteStep));
        }
    };

    emailHandler(data) {
        if (data) {
            this.emailValid = data.valid;
            this.quoteStep.email = data;
            this._store.dispatch(new QuestionEmailAction(this.quoteStep));
        }
    }

    enableSubmit() {
        return !(this.showContinue && this.emailValid);
    }
}
