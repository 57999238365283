import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '@crux/services';
import { Router } from '@angular/router';
import { CommonCommunicationService } from '../../services/common-communication.service';
import { AffinityPartnerFlags } from '../../model/affinity-partner';
import { Store, select } from '@ngrx/store';
import { reject, find, filter, isNil, isEmpty } from 'lodash-es';

@Component({
    selector: 'app-marketing-banner',
    templateUrl: './marketing-banner.component.html',
    styleUrls: ['./marketing-banner.component.scss'],
})
export class MarketingBannerComponent implements OnInit {
    @Input() imagePath: any;
    @Input() coverageadviserimg: any;
    @Input() coverageadviserlink: any;
    @Input() bannerbgColor: any;
    @Input() policyName: string;
    @Input() policyTitle: string;
    @Input() footerTitle: string;
    @Input() policyInfo: string;
    @Input() showCp1Banner = false;
    @Input() cp1RouterUrl: string;
    @Input() buttoninfo: any;

    imageBasePath: any;
    AffinityPartnerFlags: AffinityPartnerFlags;
    constructor(
        private _analytics: AnalyticsService,
        private _router: Router,
        public _ccs: CommonCommunicationService,
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>
    ) {
        this.imageBasePath = environment.blobStorageBaseURL;
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.AffinityPartnerFlags = AffinityPartnerFlags;
                    }
                }
            });
    }

    ngOnInit() {}

    navigate(url, clickon) {
        this._analytics.eventTrack.next({
            category: 'NAVBAR',
            action: 'NAVBAR_LINK_CLICK',
            label: clickon,
        });
        this._router.navigate([url]);
    }
}
