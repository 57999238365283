import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './products.component';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FloatingLeftColumnModule } from '../floating-left-column/floating-left-column.module';
import {
    InputModule,
    CruxAccordionModule,
    SpinnerModule,
} from '@crux/components';
import { StepOneModule } from '../step-one/step-one.module';
import { CoverhoundModule } from './../../../components/coverhound/coverhound.module';
import { RatingBannerModule } from '../../form/rating-banner/rating-banner.module';
import { ProgressBarModule } from '../../../progress-bar/progress-bar.module';
import { ErrorBoxModule } from './../../../components/error-box/error-box.module';
import { AnalyticsModule } from '@crux/services';
import { ProductsTileModule } from './products-tile/products-tile.module';
import { ReferralMessageModule } from '../referral-message/referral-message.module';

const route: Routes = [
    {
        path: '',
        component: ProductsComponent,
    },
];

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        FloatingLeftColumnModule,
        RouterModule.forChild(route),
        InputModule,
        CruxAccordionModule,
        CoverhoundModule,
        StepOneModule,
        RatingBannerModule,
        SpinnerModule,
        ProgressBarModule,
        ErrorBoxModule,
        AnalyticsModule,
        ProductsTileModule,
        ReferralMessageModule,
    ],
    declarations: [ProductsComponent],
    exports: [ProductsComponent],
})
export class ProductsModule {}
