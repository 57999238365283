import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsbDropdownListComponent } from './csb-dropdown-list.component';

@NgModule({
    declarations: [CsbDropdownListComponent],
    imports: [CommonModule],
    exports: [CsbDropdownListComponent],
    entryComponents: [CsbDropdownListComponent],
})
export class CsbDropdownListModule {}
