import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModalComponent } from './loading-modal.component';
import { EmailFormModule } from '../email-form/email-form.module';
import { MatDialogModule } from '@angular/material/dialog';
import { AnalyticsModule } from '@crux/services';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CarouselModule } from '@crux/components';

@NgModule({
    declarations: [LoadingModalComponent],
    imports: [CommonModule, EmailFormModule, MatDialogModule, AnalyticsModule, MatProgressBarModule, CarouselModule],
    exports: [LoadingModalComponent] 
})
export class LoadingModalModule {}
