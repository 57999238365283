import { Action } from '@ngrx/store';

export enum RetrievedBusinessProfileActionTypes {
    businessProfileData = '[businessprofiledata] setbusinessprofiledata',
}

export class SetRetrievedBusinessProfileInfoAction implements Action {
    readonly type = RetrievedBusinessProfileActionTypes.businessProfileData;

    constructor(public payload: any) {}
}

export type RetrievedBusinessProfileActionUnion = SetRetrievedBusinessProfileInfoAction;
