import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageProductSelectionComponent } from './home-page-product-selection.component';
import { ProductCardModule } from '../product-card/product-card.module';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AnalyticsModule } from '@crux/services';

@NgModule({
    declarations: [HomePageProductSelectionComponent],
    imports: [
        CommonModule,
        ProductCardModule,
        RouterModule,
        CarouselModule,
        AnalyticsModule,
    ],
    exports: [HomePageProductSelectionComponent],
})
export class HomePageProductSelectionModule {}
