import {
    PartnerOffersActionUnion,
    PartnerOffersActionTypes,
} from '../../partners-redirection-store/actions/partner-offers.actions';

export let PartnerOffersData: any;

export function PartnerOffersReducer(
    PartnerOffersInfo = PartnerOffersData,
    action: PartnerOffersActionUnion
) {
    switch (action.type) {
        // Flag Setter
        case PartnerOffersActionTypes.PartnerOffers: {
            PartnerOffersInfo = action.payload;
            return PartnerOffersInfo;
        }
        default: {
            return PartnerOffersInfo;
        }
    }
}
