export class Questions {
    Type: string;
    group_name: string;
    questions: Question[];
}

export class Question {
    iso_code?: any[];
    length?: number;
    maxlength?: number;
    minlength?: number;
    parent_question_id?: string;
    parent_trigger_value?: string;
    question_id: any;
    required?: boolean;
    text: string;
    endText?: string;
    tooltip?: string;
    type: any;
    value: any;
    disqualifyingValue: any;
    disqualifyingOperator: any;
    list_of_values: any[];
    second_list_of_values?: any[];
    group_name?: string;
    rawValue?: any;
}

export class SimpleQuestions {
    [key: string]: Question;
}
