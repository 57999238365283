import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';

@Injectable()
export class QuoteEffects {
    sciOptionalCoverages: Observable<any>;
    constructor(
        private _action: Actions,
        private _store: Store<{ sciOptionalCoverages: any }>
    ) {
        this.sciOptionalCoverages = this._store.pipe(
            select('sciOptionalCoverages')
        );
    }

    /**
     * Read the Sci Optional Coverages
     */
    readSciOptionalCoveragesFromStore$(): Observable<any> {
        return this.sciOptionalCoverages;
    }
}
