export class UserProfileRequest {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    adminUserId: string;
    admin: string;
    userType: string;
    phone: string;
    roleInfoRequired: boolean;
    basicInfoRequired: boolean;
    profileLinkingSearchRequired: boolean;
    pasCodeRequired: boolean;
    stepValueRequired: boolean;
    pasCodeList: [''];
    agencyName: string;
    basicAceProfileRequired: string;
    entitlementOnly: boolean;
}
