import { CruxHttpClientService } from '@crux/services';
import { Injectable } from '@angular/core';
import { FraudDisclosure } from '../model/fraud-disclosure';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class FraudDisclosureService {
    public url: string = environment.blobStorageBaseURL;

    constructor(private _http: CruxHttpClientService) {}

    getFraudDisclosure(): Observable<FraudDisclosure[]> {
        const ts = new Date().getTime();
        return this._http.get(
            this.url + '/data/fraudDisclosures.json?ts=' + ts
        );
    }
}
