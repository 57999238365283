import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RetrieveQuoteButtonComponent } from './retrieve-quote-button.component';
import { RouterModule } from '@angular/router';
import { AnalyticsModule } from '@crux/services';

@NgModule({
    imports: [CommonModule, RouterModule, AnalyticsModule],
    declarations: [RetrieveQuoteButtonComponent],
    exports: [RetrieveQuoteButtonComponent],
})
export class RetrieveQuoteButtonModule {}
