import { Injectable } from '@angular/core';

import messageDict from 'assets/json/app-messages.json';
import apiMessages from 'assets/json/api-error-messages.json';
import states from 'assets/json/states.json';
@Injectable()
export class ApplicationMessagesService {
    //Stores all the json request formats based on product id as key

    messageDictionary: any;
    apiMessageDictionary: any;
    postSalesValidationMessages: any;
    states: any;

    constructor() {}

    load() {
        this.messageDictionary = messageDict;
        this.apiMessageDictionary = apiMessages;
        this.states = states;
    }
}
