import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-marketing-tabs',
    templateUrl: './marketing-tabs.component.html',
    styleUrls: ['./marketing-tabs.component.scss'],
})
export class MarketingTabsComponent implements OnInit {
    @Input() tabTitle: string;
    @Input() tabs: any;
    @Input() ProductName: String;
    @Input() disclaimer: string;
    public lastActiveTab = 0;

    // @Output() tabBtnClicked = new EventEmitter();
    imageBasePath: any;
    constructor() {
        this.imageBasePath = environment.blobStorageBaseURL;
    }

    ngOnInit() {
        this.tabs[0].active = true;
    }

    showTabs(item, index) {
        this.tabs[this.lastActiveTab].active = false;
        item.active = !item.active;
        this.lastActiveTab = index;
    }
}
