import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import {
    FormGroup,
    FormControl,
    FormBuilder,
    Validators,
    ValidatorFn,
    AbstractControl,
} from '@angular/forms';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { EmailJourney } from './../../../../assets/json/start-email-journey-payload';
import { SaveEmailService } from '../../../services/save-email.service';
import { PartnerCustomization } from '../../../partner-customization/partner-customization.helper';
import { ApplicationMessagesService } from '../../../services/application-messages.service';
import { DictionaryService } from '../../../services/dictionary.service';
import { Store } from '@ngrx/store';
import { SetCustomerInfoDataAction } from '../../../sales-store/questions-store/actions';
import { CustomerInfo } from '../../../model/customer-info';
import { QuestionsEffects } from '../../../sales-store/questions-store/effects';
import { QuestionBase } from '../../../csb-quote/csb-question/csb-question-base.component';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
} from 'rxjs/operators';
import { GAEvent } from '../../../csb-core/ga-event';
import { AnalyticsService } from '@crux/services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-email',
    templateUrl: './email.component.html',
    styleUrls: ['./email.component.scss'],
})
export class EmailComponent extends QuestionBase implements OnInit {
    @Input() showLabel = true;
    @Output() next: EventEmitter<any> = new EventEmitter();

    emailForm: FormGroup;
    emailField: FormControl;
    emailJourney: EmailJourney;
    errorMessages: any;
    labelsDictionary: any;
    partnerId: any;
    customerInfoData: CustomerInfo;
    isEmailInvalid = false;
    errorText = '';
    componentClass: Array<string>;
    showinfoIcon: boolean = false;
    offSet: number = -17;

    constructor(
        private _fb: FormBuilder,
        private _ccs: CommonCommunicationService,
        private _saveEmailService: SaveEmailService,
        private _partnerCustomization: PartnerCustomization,
        private applicationMessagesService: ApplicationMessagesService,
        private dictionaryService: DictionaryService,
        private _questionsEffects: QuestionsEffects,
        private _analytics: AnalyticsService,
        private _store: Store<{}>,
        private router: Router
    ) {
        super();
        this._questionsEffects
            .readCustomerInfoDataFromStore$()
            .subscribe((customerInfoData) => {
                this.customerInfoData = customerInfoData;
            });
        this.buildFormValidators();
    }

    ngOnInit() {
        this.componentClass = this.questionNo
            ? ['col-5', 'col-md-5', 'col-sm-12']
            : ['col-5', 'col-md-5', 'col-sm-6'];

        this.buildEmailForm();
        this.errorMessages = this.applicationMessagesService.messageDictionary;
        this.labelsDictionary = this.dictionaryService.labelDict;
        this.partnerId = this._ccs.PartnerId;
        if (this.customerInfoData.emailAddress) {
            this.emailField.setValue(this.customerInfoData.emailAddress);
        }
        if (this.router.url.indexOf('/get-a-quote') > -1) {
            this.showinfoIcon = true;
        }
    }

    buildEmailForm(): void {
        this.emailForm = this._fb.group({
            emailField: this.emailField,
        });

        this.emailField.valueChanges
            .pipe(
                filter((val) => val.length >= 2 || val.length === 0),
                debounceTime(500),
                distinctUntilChanged(),
                map((res) => res)
            )
            .subscribe((val) => {
                this.continueToProducts();
            });
    }

    buildFormValidators() {
        /**
         * build validation for email
         */
        this.emailField = new FormControl('', [
            Validators.required,
            Validators.pattern(
                '/^[a-zA-Z0-9!._]*@([a-zA-Z0-9-]*[.]{1})[a-zA-Z0-9-]*$/gm'
            ),
        ]);
    }

    emailValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            const regExp: RegExp = new RegExp(
                '^[a-zA-Z0-9!._]*@([a-zA-Z0-9-]*[.]{1})[a-zA-Z0-9-]*$',
                'gm'
            );
            const invalidState = regExp.test(control.value);
            return !invalidState
                ? {
                      invalid: { value: control.value },
                  }
                : null;
        };
    }

    continueToProducts(): void {
        if (this.emailForm.valid) {
            /**
             * Make call to save email
             */
            this.customerInfoData.emailAddress = this.emailField.value;
            this._store.dispatch(
                new SetCustomerInfoDataAction(this.customerInfoData)
            );
            this.isEmailInvalid = false;
            this.navigateToNextPage();
        } else {
            this.emailForm.get('emailField').markAsTouched({ onlySelf: true });
            this.emailForm
                .get('emailField')
                .updateValueAndValidity({ onlySelf: true });
            this.isEmailInvalid = true;
            this.next.emit({
                valid: false,
                value: this.emailField.value,
            });
            this.valid(false, this.emailField.value);
        }
    }

    /* Set the navigation after parent component is setup */
    navigateToNextPage() {
        this.next.emit({
            valid: true,
            value: this.emailField.value,
        });
        this.valid(true, this.emailField.value);
    }

    checkDirtyAndEmpty() {
        let flag = false;
        if (
            this.emailForm.invalid &&
            this.emailField.value.length === 0 &&
            (this.emailForm.touched || this.emailForm.dirty)
        ) {
            this.errorText = this.errorMessages.email.required;
            flag = true;
        } else if (
            this.emailForm.invalid &&
            (this.emailForm.touched || this.emailForm.dirty)
        ) {
            this.errorText = this.errorMessages.email.invalid;
            flag = true;
        }

        return flag;
    }
}
