import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

/**
 * Store imports
 */
import { Store, select } from '@ngrx/store';

/**
 * Partner Customization
 */
import { PartnerCustomization } from '../../../partner-customization/partner-customization.helper';
import { Product } from '../../../model/product';

@Injectable({
    providedIn: 'root',
})
export class EligibilityGuard implements CanActivate {
    startQuoteFlag: boolean;
    productFlag: boolean;

    constructor(
        private _store: Store<{ startQuote: boolean; products: Product }>,
        private _partnerCustomization: PartnerCustomization
    ) {
        this._store.pipe(select('startQuote')).subscribe((flag) => {
            this.startQuoteFlag = flag;
        });
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        /**
         *Only load the route if startQuote action is triggered
         *
         */
        if (this.startQuoteFlag) {
            return true;
        }

        /**
         * Navigate to home when the above condition does not match
         */
        this._partnerCustomization.goToHome();
    }
}
