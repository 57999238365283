export const Routes = [
    {
        normal: [
            '/home',
            '/products',
            '/business-details',
            '/additional-info',
            '/custom-packages',
            '/review-application',
            '/payment',
            '/payment-confirmation',
        ],
        retrieveQuoteByNumber: [
            '/home',
            '/retrieve-quote',
            '/package-selection',
            '/business-details',
        ],
        retrieveQuoteByEmail: [
            '/home',
            '/retrieve-quote',
            '/quote-list',
            '/package-selection',
            '/business-details',
        ],
        skipPolicy: ['/additional-info', '/review-application'],
        error: '/generic-error',
    },
    {
        normal: [
            '/home',
            '/eligibility-questions',
            '/products',
            '/business-details',
            '/additional-info',
            '/custom-packages',
            '/review-application',
            '/payment',
            '/payment-confirmation',
        ],
        retrieveQuoteByNumber: [
            '/home',
            '/retrieve-quote',
            '/package-selection',
            '/business-details',
        ],
        retrieveQuoteByEmail: [
            '/home',
            '/retrieve-quote',
            '/quote-list',
            '/package-selection',
            '/business-details',
        ],
        skipPolicy: ['/additional-info', '/review-application'],
        error: '/generic-error',
    },
];
