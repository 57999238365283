import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';

/**
 * Store imports
 */
import { Store, select } from '@ngrx/store';

/**
 * Partner Customization
 */
import { PartnerCustomization } from '../../../partner-customization/partner-customization.helper';
@Injectable()
export class AdditionalInfoGuard implements CanActivate {
    isBizDetailsAnswered = false;
    constructor(
        private _store: Store<{ isBizDetails: boolean }>,
        private _partnerCustomization: PartnerCustomization
    ) {
        this._store.pipe(select('isBizDetails')).subscribe((isBizDetails) => {
            this.isBizDetailsAnswered = isBizDetails;
        });
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        /**
         * Only load the route when BUSINESS DETAILS ARE ANSWERED
         * ehich means store returns true for bizDetailsReducer
         * This is temporary and needs to be changed.
         *
         * @todo only route when the path has business type and postal code.
         */
        if (this.isBizDetailsAnswered) {
            return true;
        } else {
            this._partnerCustomization.goToHome();
        }
    }
}
