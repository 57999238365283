import { Component, OnInit, Input } from '@angular/core';
import { AnalyticsService } from '@crux/services';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-marketing-coverage',
    templateUrl: './marketing-coverage.component.html',
    styleUrls: ['./marketing-coverage.component.scss'],
})
export class MarketingCoverageComponent implements OnInit {
    @Input() coverageTitle: string;
    @Input() coverageDescription: string;
    @Input() coveredBtn: string;
    @Input() notCoveredBtn: string;
    @Input() coveredItems: any[];
    @Input() notCoveredItems: string[];
    @Input() endorsementItems: any[];
    @Input() linkText: string;
    @Input() linkURL: string;
    @Input() ProductName: String;

    public covered: boolean;
    public notCovered: boolean;
    public endorsement: boolean;

    // ! capture remove items;
    removedCoverageItems: any;
    removedNotCoverageItems: any;
    removedEndorsementItems: any;
    imageBasePath: any;
    constructor(private _router: Router, private _analytics: AnalyticsService) {
        this.imageBasePath = environment.blobStorageBaseURL;
    }

    ngOnInit() {
        this.removedCoverageItems = this.coveredItems.splice(3);
        this.removedNotCoverageItems = this.notCoveredItems.splice(3);
        this.removedEndorsementItems = this.endorsementItems.splice(3);
    }

    hideShowCovered() {
        this.covered = !this.covered;
        this.loadMoreCoveredItems();
        this.removedCoverageItems = this.coveredItems.splice(3);
        this.notCovered = false;
        this.endorsement = false;
    }

    hideShowNotCovered() {
        this.covered = false;
        this.endorsement = false;
        this.loadMoreNotCoveredItems();
        this.removedNotCoverageItems = this.notCoveredItems.splice(3);
        this.notCovered = !this.notCovered;
    }

    hideShowendorsement() {
        this.covered = false;
        this.notCovered = false;
        this.loadMoreEndorsementItems();
        this.removedEndorsementItems = this.endorsementItems.splice(3);
        this.endorsement = !this.endorsement;
    }

    loadMoreCoveredItems() {
        if (this.removedCoverageItems && this.removedCoverageItems.length) {
            this.coveredItems.splice(3, 0, ...this.removedCoverageItems);
            this.removedCoverageItems = null;
        }
    }

    loadMoreNotCoveredItems() {
        if (
            this.removedNotCoverageItems &&
            this.removedNotCoverageItems.length
        ) {
            this.notCoveredItems.splice(3, 0, ...this.removedNotCoverageItems);
            this.removedNotCoverageItems = null;
        }
    }

    loadMoreEndorsementItems() {
        if (
            this.removedEndorsementItems &&
            this.removedEndorsementItems.length
        ) {
            this.endorsementItems.splice(3, 0, ...this.removedEndorsementItems);
            this.removedEndorsementItems = null;
        }
    }

    hideCoverage() {
        if (this.covered) {
            this.loadMoreCoveredItems();
            this.removedCoverageItems = this.coveredItems.splice(3);
            this.covered = false;
        }
        if (this.notCovered) {
            this.loadMoreNotCoveredItems();
            this.removedNotCoverageItems = this.notCoveredItems.splice(3);
            this.notCovered = false;
        }
        if (this.endorsement) {
            this.loadMoreEndorsementItems();
            this.removedEndorsementItems = this.endorsementItems.splice(3);
            this.endorsement = false;
        }
    }

    navigateRoutes(url) {
        this._analytics.eventTrack.next({
            category: 'NAVBAR',
            action: 'NAVBAR_EXPLORE_COVERAGE_CLICK',
            label: this.ProductName + " - (What's Not Covered) - " + url,
        });
        this._router.navigate(['/' + url]);
    }

    navigateTo() {
        if (this.endorsementItems && this.endorsementItems.length > 0) {
            this.endorsementItems.forEach((item) => {
                if (item.incidentName === 'Endorsement' && item.linkUrl) {
                    window.open(item.linkUrl, '_blank');
                }
            });
        }
    }
}
