import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '@crux/services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-marketing-product-steps',
    templateUrl: './marketing-product-steps.component.html',
    styleUrls: ['./marketing-product-steps.component.scss'],
})
export class MarketingProductStepsComponent implements OnInit {
    @Input() stepTitle: string;
    @Input() stepMessage: string;
    @Input() steps: string[];
    imageBasePath: any;
    constructor(private _analytics: AnalyticsService, private _router: Router) {
        this.imageBasePath = environment.blobStorageBaseURL;
    }

    ngOnInit() {}

    navigate(url, clickon) {
        this._analytics.eventTrack.next({
            category: 'NAVBAR',
            action: 'NAVBAR_LINK_CLICK',
            label: clickon,
        });
        this._router.navigate([url]);
    }
}
