import { Action } from '@ngrx/store';
import { PartnersRedirectionFlags } from './../../../model/partners-redirection';

export enum PartnersredirectionActionTypes {
    PartnerFlag = '[ set flag ] Set flag from Partner redirection ',
}

export class SetFlagAction implements Action {
    readonly type = PartnersredirectionActionTypes.PartnerFlag;

    constructor(public payload: PartnersRedirectionFlags) {}
}

export type PartnerredirectionActionUnion = SetFlagAction;
