import { Component, OnInit } from '@angular/core';
import { CommonCommunicationService } from '../../services/common-communication.service';
@Component({
    selector: 'app-thirdparty-disclosures',
    templateUrl: './thirdparty-disclosures.component.html',
    styleUrls: ['./thirdparty-disclosures.component.scss'],
})
export class ThirdpartyDisclosuresComponent implements OnInit {
    public commonCommunicationService: CommonCommunicationService;

    constructor(
        private pcommonCommunicationService: CommonCommunicationService,
    ) {
        this.commonCommunicationService = pcommonCommunicationService;
    }

    ngOnInit() {}
}
