import { Component, OnInit } from '@angular/core';
import { CommonCommunicationService } from '../../services/common-communication.service';
import { format } from 'date-fns';
import { filter } from 'lodash-es';
import { coerceNumberProperty } from '@angular/cdk/coercion';

/**
 * NGRX store imports
 */
import { ProductEffects } from './../../sales-store/products-store/effects';
import { Product } from '../../model/product';
import { QuestionsEffects } from './../../sales-store/questions-store/effects';
import { CustomerInfo } from '../../model/customer-info';
import { DownloadQuoteService } from '../../services/download-quote.service';
import { AnalyticsService } from '@crux/services';
import { ActivatedRoute } from '@angular/router';
import { CallUsNumberModel } from '../../model/callUsNumber';

@Component({
    selector: 'app-download-receipt',
    templateUrl: './download-receipt.component.html',
    styleUrls: ['./download-receipt.component.scss'],
})
export class DownloadReceiptComponent implements OnInit {
    receiptId: string;
    callUsNumber: CallUsNumberModel;
    constructor(
        public commonCommunicationService: CommonCommunicationService,
        private _productEffects: ProductEffects,
        private _questionsEffects: QuestionsEffects,
        private downloadQuoteService: DownloadQuoteService,
        private _analytics: AnalyticsService,
        private _route: ActivatedRoute
    ) {
        // this._productEffects.readProductsFromStore$().subscribe((products) => {
        //     this.products = products;
        //     this.products.forEach((product) => {
        //         if (product.policyIssued) {
        //             this.makePaymentOptions = product.makePaymentOptions;
        //         }
        //     });
        // });
        // this._questionsEffects
        //     .readCustomerInfoDataFromStore$()
        //     .subscribe((customerInfoData) => {
        //         this.customerInfoData = customerInfoData;
        //     });
    }

    dateOfReceipt = '';
    totalPayment = 0;
    paymentMade = 0;
    dueAmount: number;
    policyList: any[];
    isReceiptShown = true;
    effectiveDate: string;
    expirationDate: string;
    productsWithIssuedPolicies: any;
    totalCoverages: any;
    coveragesOptional = {};
    coveragesNotOptional = {};
    sciCoveragesNotOptional = {};
    makePaymentOptions: any;
    products: Product[];
    customerInfoData: CustomerInfo;
    ngOnInit() {
        this._route.paramMap.subscribe((params) => {
            this.receiptId = params.get('quoteId');
        });
        this.downloadQuoteService
            .getPdfData(this.receiptId)
            .subscribe((response) => {
                this.products = response.data.products;
                this.customerInfoData = response.data.customerInfoData;
                this.makePaymentOptions = response.data.paymentOptions;
                this.setPayments();
                this.setCoveragesPremium();
                this.setReceiptDate();
                this.setPayments();
                this.setCoveragesPremium();
            });

            this.callUsNumber = this.commonCommunicationService.callUsNumber();
    }

    setCoveragesPremium() {
        this.totalCoverages = filter(this.products, (product) => {
            return product['coverageAndLimits'] ? product : null;
        });
        // getting coverages premiums for micro products
        this.coveragesOptional['values'] = [];
        this.coveragesNotOptional['values'] = [];
        this.totalCoverages.forEach((product) => {
            for (let i = 0; i < product.coverageAndLimits.length; i++) {
                if (
                    product.coverageAndLimits[i].isOptional === false &&
                    (product.productSourceSystem === 'Micro' &&
                        product.selected === true)
                ) {
                    let coverageNotOptional = {};
                    coverageNotOptional['premium'] =
                        product.coverageAndLimits[i].group_premium;
                    coverageNotOptional['title'] =
                        product.coverageAndLimits[i].coverage;
                    coverageNotOptional['coverage'] =
                        product.coverageAndLimits[i].coverageCd;
                    coverageNotOptional['limit'] =
                        product.coverageAndLimits[i].defaultValue;
                    coverageNotOptional['displayValues'] =
                        product.coverageAndLimits[i].displayValues;
                    this.coveragesNotOptional['values'].push(
                        coverageNotOptional
                    );
                } else if (
                    product.coverageAndLimits[i].isChecked === true &&
                    (product.productSourceSystem === 'Micro' &&
                        product.selected === true)
                ) {
                    let coverageOptional = {};
                    coverageOptional['premium'] =
                        product.coverageAndLimits[i].premium;
                    coverageOptional['title'] =
                        product.coverageAndLimits[i].coverage;
                    coverageOptional['limit'] =
                        product.coverageAndLimits[i].defaultValue;
                    this.coveragesOptional['values'].push(coverageOptional);
                    this.coveragesOptional['optionalCoveragePremium'] =
                        product.selectedpackage.optionalCoveragePremium;
                }
            }
        });
        // getting coverages premiums for SCI
        this.sciCoveragesNotOptional['values'] = [];

        this.totalCoverages.forEach((product) => {
            for (let i = 0; i < product.coverageAndLimits.length; i++) {
                if (
                    product.productSourceSystem === 'SCI' &&
                    product.selected === true
                ) {
                    let sciCoverageNotOptional = {};
                    sciCoverageNotOptional['premium'] =
                        product.coverageAndLimits[i].group_premium;
                    sciCoverageNotOptional['title'] =
                        product.coverageAndLimits[i].coverage;
                    sciCoverageNotOptional['coverage'] =
                        product.coverageAndLimits[i].coverageCd;
                    sciCoverageNotOptional['limit'] =
                        product.coverageAndLimits[i].defaultValue;
                    sciCoverageNotOptional['displayValues'] =
                        product.coverageAndLimits[i].displayValues;
                    this.sciCoveragesNotOptional['values'].push(
                        sciCoverageNotOptional
                    );
                }
            }
        });
    }

    setPayments() {
        // filter products only with policies issued
        this.productsWithIssuedPolicies = filter(this.products, (product) => {
            return product['policyIssuedData'] ? product : null;
        });

        this.productsWithIssuedPolicies.forEach((c) => {
            if (c.selected) {
                this.effectiveDate = format(c.effective_date, 'MM/dd/yyyy');
                this.expirationDate = format(c.expiration_date, 'MM/dd/yyyy');
            }
        });

        this.productsWithIssuedPolicies.forEach((product) => {
            if (product.policyIssuedData.PolicyResponse.PolicyNumber) {
                this.totalPayment =
                    this.totalPayment +
                    coerceNumberProperty(
                        product.policyIssuedData.PolicyResponse.Premium
                    );
            }
        });

        if (this.makePaymentOptions.payInFull.selected == true) {
            this.paymentMade =
                coerceNumberProperty(this.makePaymentOptions.payInFull.amount) /
                100;
            this.dueAmount = 0;
        } else {
            this.paymentMade =
                coerceNumberProperty(
                    this.makePaymentOptions.payInInstallments.firstInstallment
                ) / 100;
            this.dueAmount = this.totalPayment - this.paymentMade;
        }
    }
    setReceiptDate(): void {
        const today = new Date();
        const dateValue =
            today.getDate().toString().length === 1
                ? '0' + today.getDate().toString()
                : today.getDate().toString();
        this.dateOfReceipt = format(today, 'MMMM D, yyyy');
    }
}
