import { Action } from '@ngrx/store';
import { AgentInformation } from '../../../model/agent-info';

export enum AgentInfoActionTypes {
    AgentInfoSaved = '[ set agent information ] Set information from agent',
}

export class SetAgentInfoAction implements Action {
    readonly type = AgentInfoActionTypes.AgentInfoSaved;

    constructor(public payload: AgentInformation) {}
}

export type AgentInfoActionUnion = SetAgentInfoAction;
