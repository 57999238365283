import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-csb-dropdown-list',
    templateUrl: './csb-dropdown-list.component.html',
    styleUrls: ['./csb-dropdown-list.component.scss'],
})
export class CsbDropdownListComponent implements OnInit {
    @Input() options: Array<any> = [];

    @Input() labelKey: string;

    @Input() selected: any;

    @Input() placeHolder: string;

    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    @Input() dropDownError: boolean;

    constructor() {}

    ngOnInit() {}

    itemClickHandler(value) {
        this.valueChange.emit([value]);
    }
}
