import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../../../services/product.service';
import { DictionaryService } from '../../../services/dictionary.service';
import { Classification } from './../../../model/classification';
import { Store, select } from '@ngrx/store';
import { AppetiteEffects } from './../../../sales-store/appetite-store/effects/appetite.effect.service';
import { ZipcodeModel } from '../../../model/zipcode';
import {
    SetRevenueAction,
    SetLocationAction,
    StartQuoteAction,
    SetClassificationAction,
} from '../../../sales-store/appetite-store/actions';
import { SetProductsStatusAction } from '../../../../app/sales-store/products-store/actions';
import { PartnerCustomization } from '../../../partner-customization/partner-customization.helper';
import questionFromJson from './../../../../assets/json/rating-questions-data.json';
import { LocationService } from '../../../services/location.service';
import { AnalyticsService } from '@crux/services';
import { ProductsRequest } from './../../../model/products-request';
import { FormControl } from '@angular/forms';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { Product } from '../../../model/product';
import { ClassificationService } from '../../../services/classification.service';
import { filter } from 'lodash-es';
import { Subject } from 'rxjs';
import { PartnersRedirectionFlags } from '../../../model/partners-redirection';

@Component({
    selector: 'app-appetite-check',
    templateUrl: './appetite-check.component.html',
    styleUrls: ['./appetite-check.component.scss'],
})
export class AppetiteCheckComponent implements OnInit, OnDestroy {
    classificationInputCtrl: FormControl = new FormControl();
    revenueInputCtrl: FormControl = new FormControl();
    zipCodeFormControl: FormControl = new FormControl();

    @Input() isShowAll = false;
    @Input() isWorkersComp = false;
    @Input() partnerId = this.ccs.PartnerId;
    @Input() classificationPrefill: string;
    @Input() prevRoute: string;
    public showNote = true;
    public enableNext = false;
    public selected = {};
    labelDictionary: any;
    public isValidZipCode = false;
    public selectedCity: any;
    public products = [];

    /**
     * Location Service
     * ! Location Service
     */
    public locationService: LocationService;

    /**
     * Store variables
     */
    public classification: Classification;
    public zipcodeData: ZipcodeModel[] = [];
    public zipcode: any = null;
    public revenue: number;
    private _routingHelper: any;
    /**
     * ! Initial questions depending on environment
     */
    public initialQuestions: any;

    cityList: any[] = [];
    public errorMessage = '';
    public error = false;
    public productRequest: ProductsRequest;
    public enableStartFlag = false;
    public showCruxZipcode = false;
    public isCitySelected = false;
    numberOfRecords = '10';
    classificationURL: string;
    invalidPartnersField: any;
    public enableQuoteBtn = false;
    public zipCodeValue$ = new Subject<string>();
    updatedFromStore = false;
    showZipInfo = false;

    constructor(
        public dictionaryService: DictionaryService,
        /**
         * ! Short form of commonCommunicationService === ccs
         */
        public ccs: CommonCommunicationService,
        private classificationData: ClassificationService,
        private productService: ProductService,
        private router: Router,
        private _appetiteEffects: AppetiteEffects,
        private _store: Store<{
            zipcode: ZipcodeModel[];
            revenue: number;
            products: Product[];
            classification: Classification;
            partnersRedirectionFlag: PartnersRedirectionFlags;
        }>,
        private _locationService: LocationService,
        private _analytics: AnalyticsService,
        private _partnerCustomization: PartnerCustomization
    ) {
        this.locationService = this._locationService;
        // this.ccs = ccs;
        if (this.ccs.quoteRequestData.businessLocation) {
            this.zipcode = this.ccs.quoteRequestData.businessLocation.PostalCode;
        }
        this.classificationURL = this.classificationData.classificationServiceUrl.replace(
            'numberOfRecords',
            this.numberOfRecords
        );

        this.readDataFromStore();
    }

    readDataFromStore() {
        this._store.pipe(select('classification')).subscribe((classData) => {
            this.classification = classData;
            this.classificationInputCtrl.setValue(this.classification.name);
            this.checkPartnerFields();
        });
        this._store.pipe(select('zipcode')).subscribe((zipdata) => {
            this.zipcodeData = zipdata;
            if (this.zipcodeData.length === 1) {
                this.isCitySelected = true;
            } else {
                this.cityList = this.zipcodeData;
            }
            this.displayCityState();
            // this.setZipcodeSubject();
        });

        this._store.pipe(select('revenue')).subscribe((revenue) => {
            this.revenue = revenue;
            this.revenueInputCtrl.setValue(this.revenue);
        });

        this.classificationURL = this.classificationData.classificationServiceUrl.replace(
            'numberOfRecords',
            '5'
        );
    }

    ngOnInit() {
        this.labelDictionary = this.dictionaryService.labelDict;
        this.initialQuestions = this._partnerCustomization.getInitialQuestions();
        this.quotebtncondition();
        this.classificationInputCtrl.valueChanges.subscribe(
            (classificationData) => {
                if (
                    classificationData &&
                    classificationData.nomatch === undefined &&
                    typeof classificationData !== 'string'
                ) {
                    this._analytics.eventTrack.next({
                        category: 'ProductSelection',
                        action: 'CLASSIFICATION_CHOICE',
                        label: classificationData.name,
                    });
                    // this.modalSelected.emit(true);
                    // Dispatch the store with selected classification details recieved from typeahead
                    this._store.dispatch(
                        new SetClassificationAction(classificationData)
                    );
                    this.classification = classificationData;
                    this.checkPartnerFields();
                }
            }
        );

        this.zipCodeFormControl.valueChanges.subscribe((resp) => {
            this.zipCodeDetails(resp);
        });

        this._store
            .pipe(select('partnersRedirectionFlag'))
            .subscribe((error) => {
                this.invalidPartnersField = error;
            });
    }

    findProduct(e) {
        this._store.pipe(select('products')).subscribe((products) => {
            this.products = products;
        });

        this._store.dispatch(new SetLocationAction(this.zipcodeData));

        /**
         * @todo change the CCS flag to store
         */
        if (this.ccs.userIsComingFromPartnersRedirection) {
            /**
             * Check if products available only in case of partner redirection
             */
            if (!this.products) {
                this._store.dispatch(
                    new StartQuoteAction(this.buildProductsRequest())
                );
            }
        } else {
            /**
             * Reset eligibility questions in case of start quote action
             */
            const eligibilityFromJson = filter(
                questionFromJson,
                (questionGroup) => {
                    return questionGroup.group_name === 'Eligibility Questions';
                }
            );

            eligibilityFromJson[0].questions.forEach((question) => {
                question.value = '';
            });
            this._store.dispatch(new SetProductsStatusAction(undefined));

            this._store.dispatch(
                new StartQuoteAction(this.buildProductsRequest())
            );
        }
        /**
         * ! Track star quote button.
         */
        this._analytics.eventTrack.next({
            category: 'ProductSelection',
            action: 'BUTTON_CLICK',
            label: 'NEW QUOTE',
        });

        // Other view , v=3 or combination of any, consider current screen as home
        this._partnerCustomization.goToNextScreen('/');
    }

    setEnableFlag(revenue) {
        revenue = revenue.toString().replace(/\D+/g, '');
        if (revenue > 0) {
            this.enableQuoteBtn = true;
            revenue = parseInt(revenue, 10);
        } else {
            this.enableQuoteBtn = false;
            revenue = '';
        }

        this._store.dispatch(new SetRevenueAction(revenue));
        this.checkPartnerFields();
        this.quotebtncondition();
    }

    zipCodeDetails(validateResult) {
        if (
            this.isWorkersComp ||
            (this._partnerCustomization.appVersion === 0 &&
                this.classification.name)
        ) {
            this.enableStartFlag = true;
        }
        // this.isCitySelected = false;
        if (validateResult && validateResult.data && !validateResult.error) {
            this.showZipInfo = false;
            if (validateResult.data.length === 1) {
                this.zipcodeData = validateResult.data;
                this.cityList = [];
                this.isCitySelected = true;
                this.displayCityState();
            } else {
                if (this.isCitySelected) {
                    this.zipcodeData = validateResult.data.filter((results) => {
                        return results.City === this.zipcodeData[0].City;
                    });
                    this.isCitySelected = this.zipcodeData.length > 0;
                } else {
                    this.zipcodeData = validateResult.data;
                }
                this.cityList = validateResult.data;
                this.displayCityState();
            }
            this.quotebtncondition();

            if (this.zipcodeData.length === 0 && validateResult.error) {
                if (validateResult.data[0].Status === 'F') {
                    this.error = true;
                    this.isValidZipCode = false;
                    this.errorMessage = 'Please enter a valid zip code.';
                    this.showZipInfo = true;
                }
                /**
                 * ! Track Failure Postal Code Lookup.
                 */
                this._analytics.eventTrack.next({
                    category: 'ProductSelection',
                    action: 'POSTAL_CODE_LOOKUP_FAILURE',
                    label: validateResult.data[0].PostalCode,
                });
            } else {
                // if (validateResult.data[0].ProcessedBy) {
                //     this.isValidZipCode = true;
                //     this.error = false;
                // }
                /**
                 * ! Track successful Postal Code Lookup.
                 * A combination of City, State and Postal Code
                 * for the postal code entered by the user is
                 * tracked as the label for this event.
                 */
                this._analytics.eventTrack.next({
                    category: 'ProductSelection',
                    action: 'POSTAL_CODE_LOOKUP_SUCCESS',
                    label: `${validateResult.data[0].City}, ${validateResult.data[0].StateProvince}, ${validateResult.data[0].PostalCode}`,
                });
            }
        } else if (validateResult === null) {
            this.isValidZipCode = false;
            this.isCitySelected = false;
        }
    }

    setZipcodeSubject(): void {
        if (
            this.zipcodeData &&
            this.zipcodeData[0] &&
            this.zipcodeData[0].Status !== 'F'
        ) {
            this.isValidZipCode = true;
            this.zipCodeValue$.next(this.zipcodeData[0].PostalCode);
            this.zipCodeFormControl.setValue(this.zipcodeData[0].PostalCode);
        }
    }

    displayCityState(): void {
        this.checkPartnerFields();
        if (this.zipcodeData.length > 0) {
            if (this.zipcodeData[0].City && this.zipcodeData[0].StateProvince) {
                this._store.dispatch(new SetLocationAction(this.zipcodeData));
                this.isValidZipCode = true;
            } else {
                this.isValidZipCode = false;
                this.isCitySelected = false;
            }
        }
    }

    buildProductsRequest() {
        const productsRequest = new ProductsRequest();
        productsRequest.digitalClassCode = this.classification.digitalClassCode;
        productsRequest.stateId = this.zipcodeData[0].StateProvince;

        if (this._partnerCustomization.appVersion === 1) {
            productsRequest.annualRevenue = this.revenue.toString();
        }
        productsRequest.isSciExcluded = this._partnerCustomization.isSciExcluded;
        productsRequest.wsgClassId = this.classification.wsgClassId;
        return productsRequest;
    }

    quotebtncondition() {
        if (
            this.classification.name &&
            this.isValidZipCode &&
            this.isCitySelected &&
            this.revenue > 0
        ) {
            this.enableStartFlag = true;
        } else {
            this.enableStartFlag = false;
        }
    }

    disableBtn() {
        if (this._partnerCustomization.appVersion === 0) {
            if (
                this.classification.name &&
                this.isValidZipCode &&
                this.isCitySelected
            ) {
                return 'active';
            } else {
                return 'disable';
            }
        } else {
            if (
                this.classification.name &&
                this.isValidZipCode &&
                this.revenue > 0 &&
                this.isCitySelected
            ) {
                return 'active';
            } else {
                return 'disable';
            }
        }
    }
    disableBtnEbay() {
        if (
            this.classification.name &&
            this.isValidZipCode &&
            this.revenue > 0 &&
            this.zipcodeData.length &&
            this.isCitySelected
        ) {
            return 'active';
        } else {
            return 'disable';
        }
    }
    setMulticityData(citySelection) {
        if (citySelection.length > 0) {
            this._store.dispatch(new SetLocationAction(citySelection));
            this.isCitySelected = true;
            this.quotebtncondition();
        } else {
            this.isCitySelected = false;
            this.quotebtncondition();
        }
    }

    closeInfoBlock(): void {
        this.showZipInfo = false;
    }

    getTitle() {
        const label = this.labelDictionary.banner[this.partnerId];
        return label.quoteTitle;
    }

    setAnalytics(revenue) {
        /**
         * ! Track revenue.
         */
        this._analytics.eventTrack.next({
            category: 'ProductSelection',
            action: 'USER_INPUT',
            label: revenue,
        });
    }

    checkPartnerFields() {
        // hide partner redirection error
        if (
            this.invalidPartnersField &&
            this.invalidPartnersField.invalidParams &&
            this.invalidPartnersField.invalidParams.length > 0
        ) {
            this.invalidPartnersField.invalidParams.forEach((errorlist) => {
                if (
                    this.classification.name &&
                    errorlist.errorFieldType === 'business type'
                ) {
                    errorlist.showError = false;
                }
                if (
                    // this.isValidZipCode &&
                    this.isCitySelected &&
                    errorlist.errorFieldType === 'zip code'
                ) {
                    errorlist.showError = false;
                }
                if (
                    this.revenue > 0 &&
                    errorlist.errorFieldType === 'revenue'
                ) {
                    errorlist.showError = false;
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.invalidPartnersField = [];
    }
}
