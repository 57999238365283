import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsbYesNoComponent } from './csb-yes-no.component';
import { FormsModule } from '@angular/forms';
import { ButtonToggleModule, ButtonModule } from '@crux/components';
import { CSBCoreModule } from '../../csb-core/csb-core.module';
import { QuoteImageModule } from '../quote-image/quote-image.module';

@NgModule({
    declarations: [CsbYesNoComponent],
    imports: [
        CommonModule,
        FormsModule,
        ButtonToggleModule,
        ButtonModule,
        CSBCoreModule,
        QuoteImageModule,
    ],
    exports: [CsbYesNoComponent],
    entryComponents: [CsbYesNoComponent],
})
export class CsbYesNoModule {}
