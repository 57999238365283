import { Component, Input, OnInit } from '@angular/core';
import productsData from '../../../../assets/json/home-page-content.json';
import { Router } from '@angular/router';
import { AnalyticsService } from '@crux/services';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-products-offered',
    templateUrl: './products-offered.component.html',
    styleUrls: ['./products-offered.component.scss'],
})
export class ProductsOfferedComponent implements OnInit {
    blobURL = environment.blobStorageBaseURL;
    @Input() productsContent: any;

    constructor(private router: Router, private _analytics: AnalyticsService) {}

    ngOnInit(): void {}

    navigateTo(action) {
        this._analytics.eventTrack.next({
            category: 'HomePage',
            action: 'BUTTON_CLICK',
            label: `${action.text}`,
        });
        this.router.navigate([action.url]);
    }
}
