import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    OnDestroy,
} from '@angular/core';
import { JobtypeViewBlock, JobtypeTile } from '../../../../model/jobtype-tile';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonCommunicationService } from '../../../../services/common-communication.service';
import { PayrollLimits, EmpLimits } from '../../../../model/payrollLimits';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { MaskGapii } from '../../../../issuance/helper/ga-mask-pii';
import { AnalyticsService } from '@crux/services';
import { find, isEmpty, isNil } from 'lodash';
import { select, Store } from '@ngrx/store';
import { AffinityPartnerFlags } from '../../../../model/affinity-partner';
import { ZipcodeModel } from '../../../../model/zipcode';

@Component({
    selector: 'app-emp-tile',
    templateUrl: './emp-tile.component.html',
    styleUrls: ['./emp-tile.component.scss'],
})
export class EmpTileComponent implements OnInit, OnChanges, OnDestroy {
    /**
     * Inputs
     */

    @Input() blockDetails: JobtypeTile;
    @Input() type: string;
    @Input() companyStructure: any;
    @Input() state: string;
    @Input() errorMessages: any;
    @Input() payrollMax: number;
    @Input() id: string;
    @Input() viewIndex: number;
    @Input() isIncludeAdded: boolean;
    @Input() jobTypeArray = [];
    @Input() jobtypes;
    @Input() validateErrors: boolean;
    @Input() length: number;

    /**
     * Outputs
     */
    @Output() addBlockEmiiter = new EventEmitter();
    @Output() formValues = new EventEmitter();
    @Output() invalidForm = new EventEmitter();
    @Output() close = new EventEmitter();
    @Output() jobTypeValid = new EventEmitter();
    @Output() officerValid = new EventEmitter();

    @Output() checkFormviewState = new EventEmitter();
    /**
     * Form controls
     */

    tileFormGroup: FormGroup;
    nameControl: FormControl;
    jobTitleControl: FormControl;
    jobTypeControl: FormControl;
    ftEmpControl: FormControl;
    ptEmpControl: FormControl;
    payrollControl: FormControl;

    /**
     * Form Elements
     */
    name: string;
    jobTitle: string;
    jobType: any;
    ftEmp: number;
    ptEmp: number;
    ftSliderValue: number;
    ptSliderValue: number;
    payroll: number;
    stateSelected: string;

    /**
     * Other variables
     */
    titeTitle: string;
    titleSubtitle: string;
    tileDescription: string;
    titleSubtitle2: string;
    tileDescription2: string;
    showView = false;
    sliderError = false;
    isAddLink = false;

    entityType: string;

    officerDropdownValues = [
        {
            text: '0',
            value: '0',
        },
        {
            text: '1',
            value: '1',
        },
    ];
    payrollSliderValue: any;
    payrollLimits: PayrollLimits;
    empLimits: EmpLimits;
    ftSliderError = false;
    ptSliderError = false;
    consumedJobtypes = [];
    duplicateJobTypeError = false;
    entityDisabled: boolean;
    showEmpRequiredError = false;
    public gaMask: MaskGapii;
    AffinityPartnerFlags: AffinityPartnerFlags;
    defaultJobCodes = [];

    constructor(
        public ccs: CommonCommunicationService,
        public _analytics: AnalyticsService,
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
            producerCodesInfo: any;
            zipcode: ZipcodeModel;
        }>
    ) {
        this._store.pipe(select('zipcode')).subscribe((zipData) => {
            this.stateSelected = zipData[0].StateProvince;
        });

        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.AffinityPartnerFlags = AffinityPartnerFlags;
                    }
                }
            });

        this._store
            .pipe(select('producerCodesInfo'))
            .subscribe((producerCodesInfo) => {
                if (!isNil(producerCodesInfo) && !isEmpty(producerCodesInfo)) {
                    producerCodesInfo.forEach((partner) => {
                        if (
                            this.AffinityPartnerFlags &&
                            this.AffinityPartnerFlags.partnercode &&
                            partner.partnerId ===
                                this.AffinityPartnerFlags.partnercode
                        ) {
                            if (
                                this.stateSelected &&
                                !isNil(this.stateSelected) &&
                                !isEmpty(this.stateSelected) &&
                                partner.defaultWcJobTypeCodes &&
                                partner.defaultWcJobTypeCodes.length &&
                                partner.defaultWcJobTypeCodes.length > 0
                            ) {
                                if (
                                    find(partner.defaultWcJobTypeCodes, [
                                        'state',
                                        this.stateSelected,
                                    ])
                                ) {
                                    this.defaultJobCodes = partner.defaultWcJobTypeCodes.filter(
                                        (code) => {
                                            return (
                                                code.state ===
                                                this.stateSelected
                                            );
                                        }
                                    );
                                } else {
                                    this.defaultJobCodes = partner.defaultWcJobTypeCodes.filter(
                                        (code) => {
                                            return code.state === 'All';
                                        }
                                    );
                                }
                            }
                        }
                    });
                }
            });
    }

    ngOnInit() {
        this.buildForm();
        this.setAvailableValues();
        this.formValues.emit(this.blockDetails);
        this.gaMask = new MaskGapii();
        this.jobTypeArray.sort(function(a,b){
            return a.classCodeDescription > b.classCodeDescription? 1 : a.classCodeDescription < b.classCodeDescription ? -1 : 0
           });
        if (
            this.AffinityPartnerFlags && //Default Job Type For RSA partner
            this.AffinityPartnerFlags.partnercode &&
            this.AffinityPartnerFlags.partnercode === 'RSA10'
        ) {
            if (this.defaultJobCodes && this.defaultJobCodes.length > 0) {
                const wcJobCode = this.jobTypeArray.filter((code) => {
                    return (
                        code.classCode &&
                        code.classCode ===
                            this.defaultJobCodes[0].wcClassCode &&
                        code.descriptionId &&
                        code.descriptionId === this.defaultJobCodes[0].descId
                    );
                });
                if (wcJobCode && wcJobCode.length > 0) {
                    this.tileFormGroup.controls['jobTypeControl'].setValue(
                        wcJobCode[0]
                    );
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.checkFormviewState.emit();
    }

    ngOnChanges() {
        // reset
        this.consumedJobtypes = [];
        if (this.jobtypes && this.jobtypes.tiles) {
            this.jobtypes.tiles.forEach((jobtype, i) => {
                if (jobtype.formValues && jobtype.formValues.jobTypeControl) {
                    this.consumedJobtypes[i] =
                        jobtype.formValues.jobTypeControl;
                }
            });
        }
    }

    buildForm(): void {
        /**
         * Add Validators based on type
         */
        if (this.type === 'include') {
            // Add include validators
            this.tileFormGroup = new FormGroup({
                nameControl: new FormControl(this.name, Validators.required),
                jobTitleControl: new FormControl(
                    this.jobTitle,
                    Validators.required
                ),
                entityTypeControl: new FormControl(
                    this.entityType,
                    Validators.required
                ),
                ftEmpControl: new FormControl(this.ftEmp, Validators.required),
                ptEmpControl: new FormControl(this.ptEmp, Validators.required),
            });
        } else if (this.type === 'exclude') {
            this.tileFormGroup = new FormGroup({
                nameControl: new FormControl(this.name, Validators.required),
                jobTitleControl: new FormControl(
                    this.jobTitle,
                    Validators.required
                ),
                entityTypeControl: new FormControl(
                    this.entityType,
                    Validators.required
                ),
                ftEmpControl: new FormControl(this.ftEmp, Validators.required),
                ptEmpControl: new FormControl(this.ptEmp, Validators.required),
            });
        } else if (this.type === 'jobtype') {
            this.jobTypeControl = new FormControl(
                this.jobType,
                Validators.required
            );
            this.ftEmpControl = new FormControl(
                this.ftEmp,
                Validators.required
            );
            this.ptEmpControl = new FormControl(
                this.ptEmp,
                Validators.required
            );
            this.payrollControl = new FormControl(
                this.payroll,
                Validators.required
            );
            this.tileFormGroup = new FormGroup({
                jobTypeControl: this.jobTypeControl,
                ftEmpControl: this.ftEmpControl,
                ptEmpControl: this.ptEmpControl,
                payrollControl: this.payrollControl,
            });
        }

        this.showView = true;

        if (this.tileFormGroup.controls.jobTypeControl) {
            this.tileFormGroup.controls.jobTypeControl.valueChanges.subscribe(
                (newJobtype) => {
                    this.checkDuplicateJobtype(newJobtype);
                }
            );
        }

        this.tileFormGroup.valueChanges.subscribe((newValue) => {
            const isFormValid = this.isFormValid();
            if (isFormValid && !this.duplicateJobTypeError) {
                this.formValues.emit(newValue);
            } else {
                this.invalidForm.emit();
            }
        });

        this.ccs.recheckWcForms.subscribe((flag) => {
            if (flag) {
                const controls = this.tileFormGroup.controls;
                Object.keys(controls).forEach((controlName) => {
                    if (
                        controls[controlName] === this.payrollControl &&
                        this.payrollControl.value === '0'
                    ) {
                        this.payrollControl.setValue(null);
                    }
                    controls[controlName].markAsTouched();
                    controls[controlName].updateValueAndValidity();
                });
                if (this.type === 'jobtype') {
                    const ftEmp = coerceNumberProperty(this.ftEmpControl.value);
                    const ptEmp = coerceNumberProperty(this.ptEmpControl.value);
                    if (ftEmp + ptEmp <= 0) {
                        this.showEmpRequiredError = true;
                    } else {
                        this.showEmpRequiredError = false;
                    }
                }
            }
        });
    }

    getValue(ev, label) {
        let gaLabel: string;
        if (label === 'Job type') {
            if (!isNil(ev) && !isEmpty(ev) && typeof ev === 'object') {
                gaLabel = 'Job Type :' + ev.classCodeDescription;
            } else if (typeof ev === 'string' && ev !== '') {
                this.tileFormGroup.controls['jobTypeControl'].setErrors({
                    noMatch: true,
                });
            } else if (typeof ev === 'string' && ev === '') {
                this.tileFormGroup.controls['jobTypeControl'].setErrors({
                    required: true,
                });
            }
        } else {
            if (label === 'Title') {
                gaLabel = 'Title :' + ev[0].wcOfficerTitleName;
            } else if (label === 'Company structure') {
                gaLabel = 'Company structure :' + ev[0].wcEntityName;
            }
        }
        this._analytics.eventTrack.next({
            category: 'JobType',
            action: 'USER_INPUT',
            label: gaLabel,
        });
    }

    checkDuplicateJobtype(newValue) {
        if (newValue && this.consumedJobtypes.length > 0) {
            // reset everytime
            this.duplicateJobTypeError = false;
            this.consumedJobtypes.forEach((jobtype, i) => {
                // Change below condition as we have move from typeahead to drop down
                //// newValue.wcClassCode &&
                // jobtype.wcClassCode === newValue.wcClassCode

                if (
                    jobtype.classCode === newValue.classCode &&
                    jobtype.descriptionId === newValue.descriptionId &&
                    i !== this.viewIndex
                ) {
                    this.duplicateJobTypeError = true;
                }
            });
        }
    }

    setAvailableValues() {
        const formValues = this.blockDetails.formValues;
        if (this.blockDetails.type !== 'jobtype' && formValues) {
            this.tileFormGroup.controls.nameControl.setValue(
                formValues.nameControl
            );
            this.tileFormGroup.controls.jobTitleControl.setValue(
                formValues.jobTitleControl
            );
            this.tileFormGroup.controls.entityTypeControl.setValue(
                formValues.entityTypeControl
            );
            this.tileFormGroup.controls.nameControl.updateValueAndValidity();
            this.tileFormGroup.controls.jobTitleControl.updateValueAndValidity();
            this.tileFormGroup.controls.entityTypeControl.updateValueAndValidity();
        } else if (this.blockDetails.type === 'jobtype' && formValues) {
            let wcClassCode = {};
            wcClassCode = formValues.jobTypeValues
                ? formValues.jobTypeValues
                : '';
            this.tileFormGroup.controls.jobTypeControl.setValue(wcClassCode);
            this.tileFormGroup.controls.ftEmpControl.setValue(
                formValues.ftEmpControl
            );
            this.tileFormGroup.controls.ptEmpControl.setValue(
                formValues.ptEmpControl
            );
            this.tileFormGroup.controls.payrollControl.setValue(
                formValues.payrollControl
            );
            this.tileFormGroup.controls.jobTypeControl.updateValueAndValidity();
            this.tileFormGroup.controls.ftEmpControl.updateValueAndValidity();
            this.tileFormGroup.controls.ptEmpControl.updateValueAndValidity();
            this.tileFormGroup.controls.payrollControl.updateValueAndValidity();
        }
    }

    checkPayrollLimits(value: any, ccsPayrollLimits?: PayrollLimits) {
        if (!ccsPayrollLimits) {
            // set
            ccsPayrollLimits = new PayrollLimits();
            ccsPayrollLimits.totalPayroll = this.blockDetails.totalPayroll;

            ccsPayrollLimits.maxPayrolls = [];
            let jobtypeLimit;
            if (this.type === 'jobtype') {
                jobtypeLimit = {
                    id: 'jobtype' + this.viewIndex,
                    value: value,
                };
            }

            ccsPayrollLimits.maxPayrolls.push(jobtypeLimit);

            this.ccs.payrollLimits = ccsPayrollLimits;
        } else {
            // let newTotal = 0;
            // ccsPayrollLimits.maxPayrolls.forEach((ele) => {
            //     if (ele.id !== this.id) {
            //         newTotal = newTotal + ele.value;
            //     }
            // });
            // if (newTotal + value <= ccsPayrollLimits.totalPayroll) {
            //     this.sliderError = false;
            //     ccsPayrollLimits.maxPayrolls.forEach((ele) => {
            //         if (ele.id === this.id) {
            //             ele.value = value;
            //         }
            //     });
            // } else {
            //     this.sliderError = true;
            //     // Reset the total
            //     this.tileFormGroup.controls.payrollControl.setValue(0);
            //     ccsPayrollLimits.maxPayrolls.forEach((ele) => {
            //         if (ele.id === this.id) {
            //             ele.value = 0;
            //         }
            //     });
            //     value = 0;
            // }
            // this.ccs.payrollLimits = ccsPayrollLimits;
        }
        this.tileFormGroup.controls.payrollControl.setValue(value);
    }

    checkEmployeeLimits(
        valueFromUser: any,
        type: string,
        ccsEmpLimits: EmpLimits
    ): void {
        let otherSliderValue = 0;
        if (type === 'ft') {
            otherSliderValue = this.tileFormGroup.controls.ptEmpControl.value
                ? coerceNumberProperty(
                      this.tileFormGroup.controls.ptEmpControl.value
                  )
                : 0;
        } else {
            otherSliderValue = this.tileFormGroup.controls.ftEmpControl.value
                ? coerceNumberProperty(
                      this.tileFormGroup.controls.ftEmpControl.value
                  )
                : 0;
        }

        let usedConsumption = 0;
        ccsEmpLimits.consumption.forEach((limit) => {
            if (limit.id !== this.id) {
                usedConsumption = usedConsumption + limit.value;
            }
        });

        if (otherSliderValue) {
            usedConsumption = usedConsumption + otherSliderValue;
        }

        let consumptionWithoutCurrentTile = 0;
        // we can set
        ccsEmpLimits.consumption.forEach((limit) => {
            if (limit.id === this.id) {
                limit.value = valueFromUser + otherSliderValue;
            } else {
                consumptionWithoutCurrentTile =
                    consumptionWithoutCurrentTile + limit.value;
            }
        });
        ccsEmpLimits.limitConsumed =
            consumptionWithoutCurrentTile + valueFromUser + otherSliderValue;
        if (type === 'ft') {
            this.tileFormGroup.controls.ftEmpControl.setValue(valueFromUser);
            this.ftSliderError = false;
        } else {
            this.tileFormGroup.controls.ptEmpControl.setValue(valueFromUser);
            this.ptSliderError = false;
        }
    }

    getSelectedItem(): any {
        if (this.companyStructure.length === 1) {
            this.entityDisabled = true;
            return this.companyStructure[0].wcEntityCode;
        } else if (this.entityType) {
            this.entityDisabled = false;
            return this.entityType;
        } else {
            this.entityDisabled = false;
            return '';
        }
    }

    setSlider(type: string, ccsEmpLimits?: EmpLimits) {
        if (type === 'ftEmp') {
            const ftEmpCount = coerceNumberProperty(
                this.tileFormGroup.controls.ftEmpControl.value
            );
            this.checkEmployeeLimits(ftEmpCount, 'ft', ccsEmpLimits);
        } else if (type === 'ptEmp') {
            const ptEmpCount = coerceNumberProperty(
                this.tileFormGroup.controls.ptEmpControl.value
            );
            this.checkEmployeeLimits(ptEmpCount, 'pt', ccsEmpLimits);
        }
    }

    setPayrollSlider(ccsPayrollLimits: PayrollLimits) {
        let payroll = this.tileFormGroup.controls.payrollControl.value
            .toString()
            .replace(/\D+/g, '');
        payroll = coerceNumberProperty(payroll);
        this.checkPayrollLimits(payroll, ccsPayrollLimits);
    }

    ifAddLink(ccsEmpLimits: EmpLimits) {
        const validFlag = this.isFormValid();
        if (!validFlag) {
            return false;
        }
        if (
            this.type !== 'jobtype' ||
            (ccsEmpLimits.limitConsumed < ccsEmpLimits.totalEmployees &&
                this.type === 'jobtype')
        ) {
            return true;
        }
        return false;
    }

    isFormValid() {
        const formControls = this.tileFormGroup.controls;
        let validFlag = false;
        let includeOfficerValid = false;
        let excludeOfficerValid = false;
        let jobTypeValid = false;
        // Changed from validFlag only to multiple flag scenario
        // so this would help with Continue button validation
        if (this.type === 'include') {
            validFlag = includeOfficerValid =
                !!formControls.nameControl.value &&
                !!formControls.jobTitleControl.value &&
                !!formControls.entityTypeControl.value;
            // validFlag = includeOfficerValid;
        } else if (this.type === 'exclude') {
            validFlag = excludeOfficerValid =
                !!formControls.nameControl.value &&
                !!formControls.jobTitleControl.value &&
                !!formControls.entityTypeControl.value;
            // validFlag = excludeOfficerValid;
        } else {
            validFlag = jobTypeValid =
                !!formControls.jobTypeControl.value &&
                !!formControls.payrollControl.value &&
                formControls.payrollControl.value !== '0' &&
                (formControls.ftEmpControl.value > 0 ||
                    formControls.ptEmpControl.value > 0);
            // validFlag = jobTypeValid;
            // Send jobType validity for Continue Button
            this.jobTypeValid.emit(jobTypeValid);
        }
        // Then send validity for officers
        if (this.type === 'include' || this.type === 'exclude') {
            // if () {
            this.officerValid.emit(includeOfficerValid || excludeOfficerValid);
            // }
        }
        this.checkFormviewState.emit();

        return validFlag;
    }

    emitAddTile() {
        this.addBlockEmiiter.emit();
    }

    restOtherValue(value, otherField: string): void {
        /**
         * If Value has not been chosen consider it as 0
         */
        if (value.length === 0) {
            value = [
                {
                    text: '0',
                    value: '0',
                },
            ];
        }
        if (otherField === 'pt') {
            if (value[0].value === '1') {
                this.tileFormGroup.controls.ptEmpControl.setValue('0');
            } else if (value[0].value === '0') {
                this.tileFormGroup.controls.ptEmpControl.setValue('1');
                this.tileFormGroup.controls.ftEmpControl.setValue('0');
            }
        }
        if (otherField === 'ft') {
            if (value[0].value === '1') {
                this.tileFormGroup.controls.ftEmpControl.setValue('0');
            } else if (value[0].value === '0') {
                this.tileFormGroup.controls.ftEmpControl.setValue('1');
                this.tileFormGroup.controls.ptEmpControl.setValue('0');
            }
        }
    }

    removeTile(ev) {
        ev.stopPropagation();
        this.close.emit();
    }
}
