import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import {
    ProductSelectedPipe,
    NoReferralProductsPipe,
} from '../../pipes/productSelected.pipe';
import { CommonCommunicationService } from './../../services/common-communication.service';
import { environment } from '../../../environments/environment';
import { DictionaryService } from './../../services/dictionary.service';
import { CallUsNumberModel } from '../../model/callUsNumber';
import { Store, select } from '@ngrx/store';
import { AffinityPartnerFlags } from '../../model/affinity-partner';
import { find, isEmpty, isNil } from 'lodash-es';
import errorMessagesData from '../../../assets/json/error-screens-content.json';
import { CruxHttpClientService } from '@crux/services';
import { AnalyticsService } from '@crux/services';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { QuestionsEffects } from '../../sales-store/questions-store/effects';
import { CustomerInfo } from '../../model/customer-info';
import { CisareferModelComponent } from '../../components/cisarefer-model/cisarefer-model.component';
import { referralResponseComponent } from '../../components/referral-response/referral-response.component';
import { AgentInformation } from '../../model/agent-info';
import { QuoteStep } from '../../model/quote-step';
import { SaveEmailService } from '../../services/save-email.service';
import { Question, Questions, SimpleQuestions } from '../../model/questions';
import {
    EmailJourney,
    ReviewPageInfo,
} from '../../../assets/json/start-email-journey-payload';
import { Router } from '@angular/router';

@Component({
    selector: 'app-coverhound',
    templateUrl: './coverhound.component.html',
    styleUrls: ['./coverhound.component.scss'],
})
export class CoverhoundComponent implements OnInit, OnChanges {
    public labelDictionary: any;

    public coverHoundBtnURL = '';

    @Input() referralType: string;
    @Input() partnerId: number;
    @Input() showContinue: boolean;
    @Input() currentIndex: number;
    @Input() selectedProducts: Array<any>;
    @Input() productLength: number;
    @Input() coverHoundImg;
    @Input() postQuoteErr: any;
    @Input() preQuoteError: any;

    @Output() continue: EventEmitter<boolean> = new EventEmitter<boolean>();

    haveSkipAddCartFlag: boolean;

    content: string;
    content2: string;
    content3: string;
    content4: string;
    errorCode: string;
    errorCodeText: string;
    subcontent1: string;
    subcontent2: string;
    emailContactContent: string;
    description: string;
    description2: string;
    wcdescription2: string;
    title: string;
    chloe: any;
    subtitle: string;
    products = [];
    phoneNumber: string;
    phoneNumberLink: string;
    callPhoneNumber: string;
    callEnd: string;
    initialize = false;
    callUsNumber: CallUsNumberModel;
    affinityPartnerFlags: AffinityPartnerFlags;
    errorContent: any;
    errorMessages: any;
    enableEmailContact: boolean;
    public currentProduct: any;
    blobURL = environment.blobStorageBaseURL;
    customerInfoData: CustomerInfo;
    prefilledEmail: boolean;
    quoteSubmissionInformation: any;
    enableQuoteSubmission: boolean;
    agentInfo: AgentInformation;
    emailJourney: EmailJourney = new EmailJourney();
    reviewPageInfo: ReviewPageInfo;
    public quote: QuoteStep = new QuoteStep();
    public questions: Question[];
    partneraffcode: any = '';
    addFontBold: boolean;
    failureReasons = [];
    isCisaUser: boolean;
    @Output() btn: EventEmitter<any> = new EventEmitter<any>();
    @Output() link: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private http: CruxHttpClientService,
        public ccs: CommonCommunicationService,
        public dictionaryService: DictionaryService,
        private _analytics: AnalyticsService,
        public dialog: MatDialog,
        private _questionsEffects: QuestionsEffects,
        public saveEmailService: SaveEmailService,
        public router: Router,
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
            producerCodesInfo: any;
            customerInfoData: CustomerInfo;
            agentInfoSaved: AgentInformation;
            questions: Question[];
            quoteStep: QuoteStep;
        }>
    ) {
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.affinityPartnerFlags = AffinityPartnerFlags;
                        this.partneraffcode = this.affinityPartnerFlags.partnercode;
                        if (
                            this.affinityPartnerFlags.partnercode.toLowerCase() ===
                            'cisa'
                        ) {
                            this.isCisaUser = true;
                        }
                    }
                }
            });

        this._store.pipe(select('questions')).subscribe((questions) => {
            this.questions = questions;
        });
        this._store
            .pipe(select('quoteStep'))
            .subscribe((quoteStep: QuoteStep) => {
                this.quote = quoteStep;
            });

        this._questionsEffects
            .readCustomerInfoDataFromStore$()
            .subscribe((customerInfoData) => {
                this.customerInfoData = customerInfoData;
            });

        this._store
            .pipe(select('customerInfoData'))
            .subscribe((customerInfoData) => {
                this.customerInfoData = customerInfoData;
            });

        this._store
            .pipe(select('producerCodesInfo'))
            .subscribe((producerCodesInfo) => {
                if (!isNil(producerCodesInfo) && !isEmpty(producerCodesInfo)) {
                    producerCodesInfo.forEach((partner) => {
                        if (
                            this.affinityPartnerFlags &&
                            this.affinityPartnerFlags.partnercode &&
                            partner.partnerId ===
                                this.affinityPartnerFlags.partnercode
                        ) {
                            if (partner.eligibleForQuoteSubmission) {
                                this.enableQuoteSubmission =
                                    partner.eligibleForQuoteSubmission;
                                this.quoteSubmissionInformation =
                                    partner.quoteSubmissionInfo;
                            }
                        } else if (partner.partnerId === this.ccs.PartnerId) {
                            if (partner.eligibleForQuoteSubmission) {
                                this.enableQuoteSubmission =
                                    partner.eligibleForQuoteSubmission;
                                this.quoteSubmissionInformation =
                                    partner.quoteSubmissionInfo;
                            }
                        }
                    });
                }
            });

        this._store.pipe(select('agentInfoSaved')).subscribe((res) => {
            this.agentInfo = new AgentInformation();
            if (
                res &&
                res.agencyName &&
                !isNil(res.agencyName) &&
                !isEmpty(res.agencyName)
            ) {
                this.agentInfo = res;
            }
        });
    }

    ngOnInit() {
        this.currentProduct = this.selectedProducts[this.currentIndex];
        if (
            this.ccs.errorScreenContent &&
            !isNil(this.ccs.errorScreenContent) &&
            !isEmpty(this.ccs.errorScreenContent)
        ) {
            this.errorMessages = this.ccs.errorScreenContent;
            this.getErrorContent();
        } else {
            this.errorMessages = errorMessagesData;
            this.getErrorContent();
        }
        this.initialize = true;
        this.labelDictionary = this.dictionaryService.labelDict;
        this.products = this.labelDictionary.coverHound.referral.products;

        if (this.ccs.PartnerId === 1) {
            this.coverHoundBtnURL = environment.coverhoundAmex;
            this.phoneNumber = '844-319-5978';
            this.phoneNumberLink = 'tel:+18443195978';
        } else {
            this.coverHoundBtnURL = environment.coverhoundCSB;
            this.callUsNumber = this.ccs.callUsNumber();
            this.phoneNumber = this.callUsNumber.phoneNumber;
            this.phoneNumberLink = this.callUsNumber.phoneNumberLink;
        }

        this.updateContent();
    }

    getErrorContent() {
        if (
            this.affinityPartnerFlags &&
            this.affinityPartnerFlags.partnercode &&
            !isNil(this.affinityPartnerFlags.partnercode) &&
            !isEmpty(this.affinityPartnerFlags.partnercode)
        ) {
            if (
                find(this.errorMessages, [
                    'partner',
                    this.affinityPartnerFlags.partnercode,
                ])
            ) {
                this.errorContent = this.errorMessages.filter((data) => {
                    return (
                        data.partner &&
                        !isNil(data.partner) &&
                        !isEmpty(data.partner) &&
                        data.partner === this.affinityPartnerFlags.partnercode
                    );
                });
            } else {
                this.errorContent = this.errorMessages.filter((data) => {
                    return (
                        data.partner &&
                        !isNil(data.partner) &&
                        !isEmpty(data.partner) &&
                        data.partner === 'default'
                    );
                });
            }
        } else {
            this.errorContent = this.errorMessages.filter((data) => {
                return (
                    data.partner &&
                    !isNil(data.partner) &&
                    !isEmpty(data.partner) &&
                    data.partner === 'default'
                );
            });
        }
        this.errorContent = this.errorContent[0];
        this.enableEmailContact = this.errorContent.defaultEmailAsContact;
        this.updateContent();
    }

    updateContent() {
        if (
            this.errorContent &&
            this.errorContent.errors &&
            this.errorContent.errors.length > 1
        ) {
            this.errorContent.errors.forEach((error) => {
                if (this.postQuoteErr) {
                    if (
                        this.currentProduct &&
                        this.currentProduct.quoteStatus &&
                        (this.currentProduct.quoteStatus === 'Referral' ||
                            this.currentProduct.errorMessage === 'Referral' ||
                            this.currentProduct.quoteStatus ===
                                'SubjectToReferral')
                    ) {
                        if (
                            error.type &&
                            error.type === 'postReferralQuoteError'
                        ) {
                            this.addFontBold = true;
                            this.title = error.tag;
                            this.subtitle = error.title;
                            this.content = error.message1;
                            this.content2 = error.message2;
                            this.content3 = error.message3;
                            this.content4 = error.message4.replace(
                                '0000',
                                this.currentProduct.quote_number
                            );
                            this.emailContactContent = error.emailContact;
                        }
                    } else {
                        if (error.type && error.type === 'postQuoteError') {
                            this.addFontBold = true;
                            this.title = error.tag;
                            this.subtitle = error.title;
                            this.content = error.message1;
                            this.content2 = error.message2;
                            this.content3 = error.message3;
                            this.content4 = error.message4.replace(
                                '0000',
                                this.currentProduct.quote_number
                            );
                            this.errorCode = error.code;
                            this.errorCodeText = error.codeText;
                        }
                    }
                    if (
                        this.currentProduct &&
                        this.currentProduct.quoteStatus &&
                        (this.currentProduct.quoteStatus === 'Decline' ||
                            this.currentProduct.errorMessage === 'Declined')
                    ) {
                        this.errorCode = '600';
                        this.errorCodeText = 'Error Code: ';
                        this.failureReasons = this.currentProduct.failureReason;
                    }
                } else if (this.preQuoteError) {
                    if (error.type && error.type === 'preQuoteError') {
                        this.title = error.tag;
                        this.subtitle = error.title;
                        this.content = error.message1;
                        this.errorCode = error.code;
                        this.errorCodeText = error.codeText;
                    }
                    if (
                        this.currentProduct &&
                        this.currentProduct.quoteStatus &&
                        (this.currentProduct.quoteStatus === 'Decline' ||
                            this.currentProduct.errorMessage === 'Declined')
                    ) {
                        this.errorCode = '600';
                        this.errorCodeText = 'Error Code: ';
                        this.failureReasons = this.currentProduct.failureReason;
                    }
                } else {
                    if (error.type && error.type === 'sciError') {
                        this.title = error.tag;
                        this.subtitle = error.title;
                        this.chloe = error.chloe;
                        this.errorCode = error.code;
                        this.errorCodeText = error.codeText;
                        if (this.checkCurrentIndex()) {
                            // Multiple Policy
                            this.content = error.multipleProductContent.subTitle.replace(
                                '#productname#',
                                this.currentProduct.name
                            );
                            this.failureReasons = this.currentProduct.failureReason;
                            this.content2 =
                                error.multipleProductContent.subTitle2;
                            this.subcontent1 =
                                error.multipleProductContent.message1;
                            this.subcontent2 =
                                error.multipleProductContent.message2;
                            this.emailContactContent =
                                error.multipleProductContent.emailContact;
                        } else {
                            // Single Policy
                            this.content = error.singleProductContent.subTitle.replace(
                                '#productname#',
                                this.currentProduct.name
                            );
                            this.failureReasons = this.currentProduct.failureReason;
                            this.subcontent1 =
                                error.singleProductContent.message1;
                            this.subcontent2 =
                                error.singleProductContent.message2;
                            this.emailContactContent =
                                error.singleProductContent.emailContact;
                        }
                    }
                    if (
                        this.currentProduct &&
                        this.currentProduct.quoteStatus &&
                        (this.currentProduct.quoteStatus === 'Decline' ||
                            this.currentProduct.quoteStatus === 'Declined' ||
                            this.currentProduct.errorMessage === 'Declined')
                    ) {
                        this.errorCode = '600';
                        this.errorCodeText = 'Error Code: ';
                        this.failureReasons = this.currentProduct.failureReason;
                    }
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes['productLength'] ||
            changes['currentIndex'] ||
            changes['selectedProducts']
        ) {
            if (this.initialize) {
                this.updateContent();
            }
        }
    }

    checkCurrentIndex() {
        if (
            this.selectedProducts.length == 2 &&
            (this.selectedProducts[0].productId == 'BOP' ||
                this.selectedProducts[0].productId == 'GL') &&
            this.selectedProducts[1].productId == 'UMBRELLA'
        ) {
            if (
                (this.selectedProducts[Number(this.currentIndex)].productId ===
                    'UMBRELLA' &&
                    !this.selectedProducts[0].errorMessage) ||
                (this.selectedProducts[0].errorMessage &&
                    (isNil(this.selectedProducts[0].errorMessage) ||
                        isEmpty(this.selectedProducts[0].errorMessage)))
            ) {
                this.showContinue = true;
            }
            return false;
        }
        if (this.selectedProducts) {
            const cartProduct = new ProductSelectedPipe().transform(
                this.selectedProducts,
                true
            );
            this.selectedProducts.forEach((prod) => {
                if (!!prod.skipFlag && prod.errorMessage === '') {
                    this.haveSkipAddCartFlag = true;
                } else if (cartProduct.length > 0) {
                    this.haveSkipAddCartFlag = true;
                }
            });
        }

        if (
            Number(this.currentIndex) > -1 &&
            this.productLength > 0 &&
            Number(this.currentIndex) === this.productLength - 1
        ) {
            if (this.haveSkipAddCartFlag) {
                return true;
            } else {
                this.showContinue = false;
                return false;
            }
        }
        return true;
    }

    pickLabel(): string {
        const type =
            this.ccs.PartnerId === 1 ? 'Call Coverhound' : 'Call Teamfocus';

        return type + '(from description) :' + this.referralType;
    }

    continueHandle() {
        this.continue.emit(true);
    }

    tryAgainHandle() {
        this.router.navigate(['/get-a-quote']);
    }

    showEmail() {
        this._analytics.eventTrack.next({
            category: 'ProductsInCart',
            action: 'EMAIL_QUOTE',
            label: 'Email the Quote',
        });
        this.customerInfoData.emailAddress || this.ccs.savedQuoteEmail
            ? (this.prefilledEmail = true)
            : (this.prefilledEmail = false);

        this.dialog.open(DialogComponent, {
            width: '665px',
            data: {
                tag: '',
                title: 'Email your quote',
                content: '',
                showEmail: true,
            },
        });
    }
    closeEmail() {
        this._analytics.eventTrack.next({
            category: 'ProductsInCart',
            action: 'BUTTON_CLICK',
            label: 'Close',
        });
        this.ccs.isShowIdleEmailSuccess = false;
    }

    referToAgent() {
        let dialogRef = this.dialog.open(CisareferModelComponent, {
            width: '765px',
            data: {
                title: this.quoteSubmissionInformation.referModalTitle,
                telNumber: this.customerInfoData.phoneNumber
                    ? this.customerInfoData.phoneNumber
                    : '',
                email: this.customerInfoData.emailAddress
                    ? this.customerInfoData.emailAddress
                    : '',
                firstName: this.customerInfoData.firstName
                    ? this.customerInfoData.firstName
                    : '',
                lastName: this.customerInfoData.lastName
                    ? this.customerInfoData.lastName
                    : '',
                agentEmail: this.quoteSubmissionInformation.email,
            },
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (res && !isNil(res) && !isEmpty(res) && res === 'navigate') {
                let agentInfo: any;
                if (
                    this.affinityPartnerFlags &&
                    this.affinityPartnerFlags.partnercode &&
                    !isNil(this.affinityPartnerFlags.partnercode) &&
                    !isEmpty(this.affinityPartnerFlags.partnercode)
                ) {
                    if (this.affinityPartnerFlags.partnercode === 'CISA') {
                        agentInfo = {
                            agencyName: this.agentInfo.agencyName,
                            agentProducerCode: this.agentInfo.producerCode,
                            agentLegacyCode: this.agentInfo.LegacyChubbCode,
                            agentFirstName: this.customerInfoData.firstName
                                ? this.customerInfoData.firstName
                                : '',
                            agentLastName: this.customerInfoData.lastName
                                ? this.customerInfoData.lastName
                                : '',
                            agentPhoneNumber: this.customerInfoData.phoneNumber
                                ? this.customerInfoData.phoneNumber
                                : '',
                            agentEmail: this.customerInfoData.emailAddress
                                ? this.customerInfoData.emailAddress
                                : '',
                        };
                    } else {
                        agentInfo = null;
                    }
                } else {
                    agentInfo = null;
                }

                if (this.customerInfoData.emailAddress) {
                    let startEmailPayloadFormat: any = this.emailJourney
                        .emailJourneyPayload;
                    startEmailPayloadFormat = this.emailJourney.buildEmailJourneyQuoteRequest(
                        startEmailPayloadFormat,
                        this.questions,
                        new NoReferralProductsPipe().transform(
                            this.selectedProducts
                        ),
                        this.customerInfoData.emailAddress.toLowerCase(),
                        false,
                        this.ccs.PartnerId,
                        this.quote,
                        this.affinityPartnerFlags,
                        this.reviewPageInfo,
                        agentInfo
                    );
                    this.ccs.emailJourneyStarted = false;
                    this.saveEmailService
                        .startEmailJourney(startEmailPayloadFormat)
                        .subscribe((data) => {}),
                        (error: Response) => {};
                }
            }
        });
    }
}
