import { Component, OnInit, Input } from '@angular/core';
import { CommonCommunicationService } from './../../../services/common-communication.service';
import { LocationService } from '../../../services/location.service';
import { AnalyticsService } from '@crux/services';
import { map } from 'lodash-es';
import { DictionaryService } from '../../../services/dictionary.service';
import { Store } from '@ngrx/store';
import { ZipcodeModel } from '../../../model/zipcode';
import { SetLocationAction } from '../../../sales-store/appetite-store/actions';

@Component({
    selector: 'app-zip-code',
    templateUrl: './zip-code.component.html',
    styleUrls: ['./zip-code.component.scss'],
})
export class ZipCodeComponent implements OnInit {
    public locationService: LocationService;
    public labelDictionary: any;
    public errorMessage = '';
    public error: boolean;
    public allZipCodes: any[] = [];
    public zipCodeShown = false;
    public cityList: any[] = [];
    public rawZipResponse: any;
    public stateId = '';

    @Input() theme = '';

    public commonCommunicationService: CommonCommunicationService;
    constructor(
        private pcommonCommunicationService: CommonCommunicationService,
        private plocationService: LocationService,
        private _analytics: AnalyticsService,
        private dictionaryService: DictionaryService,
        private _store: Store<{ zipcode: ZipcodeModel }>
    ) {
        this.commonCommunicationService = this.pcommonCommunicationService;
        this.locationService = this.plocationService;
    }

    ngOnInit() {
        this.labelDictionary = this.dictionaryService.labelDict;
    }

    _zipCodeDetails(validateResult) {
        this._store.dispatch(new SetLocationAction(validateResult.data));

        // reset
        this.cityList = [];
        this.commonCommunicationService.quoteRequestData.stateId = null;

        // build citylist
        validateResult.data.forEach((zipData) => {
            const cityObj: { [k: string]: any } = {};
            cityObj.value = zipData.City;
            this.cityList.push(cityObj);
        });

        if (this.cityList.length === 1) {
            this.commonCommunicationService.quoteRequestData.businessLocation =
                validateResult.data[0];
            this.commonCommunicationService.quoteRequestData.stateId =
                validateResult.data[0].StateProvince;
        } else {
            // wait for user action to select city
            this.rawZipResponse = validateResult.data;
        }
        this.stateId = validateResult.data[0].StateProvince;

        if (validateResult.error) {
            this.error = true;
            this.errorMessage = 'Zip code not found.';

            /**
             * Track Postal Code Lookup.
             *
             * The Action being tracked is a failure of Postal Code Lookup.
             */
            this._analytics.eventTrack.next({
                category: 'ProductSelection',
                action: 'POSTAL_CODE_LOOKUP_FAILURE',
                label: validateResult.data[0].PostalCode,
            });
        } else {
            this.error = false;
            /**
             * Track Postal Code Lookup.
             *
             * The Action being tracked is a successful
             * lookup of Postal Code entered by the user.
             *
             * A combination of City, State and Postal Code
             * for the postal code entered by the user is
             * tracked as the label for this event.
             */
            this._analytics.eventTrack.next({
                category: 'ProductSelection',
                action: 'POSTAL_CODE_LOOKUP_SUCCESS',
                label: `${validateResult.data[0].City}, ${
                    validateResult.data[0].StateProvince
                }, ${validateResult.data[0].PostalCode}`,
            });
        }
    }

    _setMulticityData(citySelection) {
        if (citySelection.length === 0) {
            return;
        }
        map(this.rawZipResponse, (value) => {
            if (value.City === citySelection[0].value) {
                this.commonCommunicationService.quoteRequestData.businessLocation = value;
            }
            this.commonCommunicationService.quoteRequestData.stateId =
                value.StateProvince;
        });
    }

    _resetPevValue() {
        this.cityList = [];
        this.commonCommunicationService.quoteRequestData.businessLocation = '';
    }
}
