import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatingLeftColumnComponent } from './floating-left-column.component';
import { CruxAccordionModule } from '@crux/components';

@NgModule({
    imports: [CommonModule, CruxAccordionModule],
    declarations: [FloatingLeftColumnComponent],
    exports: [FloatingLeftColumnComponent],
})
export class FloatingLeftColumnModule {}
