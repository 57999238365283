import { Injectable } from '@angular/core';
import { CruxHttpClientService } from '@crux/services';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QuoteSubmissionService {
    constructor(private _http: CruxHttpClientService) {}

    quoteSubmissionUrl: string =
        environment.backend + environment.uri.cisaQuoteSubmission;

    cisaQuoteSubmission(quotePayload: any) {
        return this._http
            .post(this.quoteSubmissionUrl, { body: quotePayload })
            .pipe(
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
    }
}
