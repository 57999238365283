import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
/**
 * Store imports
 */
import { Store, select } from '@ngrx/store';

/**
 * Partner Customization
 */
import { PartnerCustomization } from './../../partner-customization/partner-customization.helper';

@Injectable()
export class ReviewApplicationGuard implements CanActivate {
    isPremiumMoreThanZero: boolean;
    constructor(
        private _store: Store<{ totalPremium: number }>,
        private _partnerCustomization: PartnerCustomization
    ) {
        this._store.pipe(select('totalPremium')).subscribe((amt) => {
            if (amt > 0) {
                this.isPremiumMoreThanZero = true;
            }
        });
    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        /**
         * Only load the route when the user has selected any package.
         * This is temporary and needs to be changed.
         *
         * @todo only route when the path has business type and postal code.
         */
        if (this.isPremiumMoreThanZero) {
            return true;
        }

        // /**
        //  * Navigate to home when the above condition does not match
        //  */
        this._partnerCustomization.goToHome();
    }
}
