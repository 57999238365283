import { Action } from '@ngrx/store';
import { ReferInformation } from '../../../model/refer-info';

export enum ReferInfoActionTypes {
    ReferInfoSaved = '[ set refer information ] Set information from refer',
}

export class SetReferInfoAction implements Action {
    readonly type = ReferInfoActionTypes.ReferInfoSaved;

    constructor(public payload: ReferInformation) {}
}

export type ReferInfoActionUnion = SetReferInfoAction;
