import { Action } from '@ngrx/store';
import { AffinityPartnerFlags } from '../../../model/affinity-partner';

export enum AffinityPartnerInfoActionTypes {
    AffinityPartnerFlag = '[ set affinity partner flag ] Set flag from affinity partner',
}

export class SetAffinityPartnerInfoAction implements Action {
    readonly type = AffinityPartnerInfoActionTypes.AffinityPartnerFlag;

    constructor(public payload: AffinityPartnerFlags) {}
}

export type AffinityPartnerInfoActionUnion = SetAffinityPartnerInfoAction;
