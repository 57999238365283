import { AffinityPartnerFlags } from '../../../model/affinity-partner';
import {
    AffinityPartnerInfoActionUnion,
    AffinityPartnerInfoActionTypes,
} from '../actions';

export let FlagInitialStateC: AffinityPartnerFlags;

export function affinityPartnerInfoFlagReducer(
    FlagState = FlagInitialStateC,
    action: AffinityPartnerInfoActionUnion
) {
    switch (action.type) {
        // Flag Setter
        case AffinityPartnerInfoActionTypes.AffinityPartnerFlag: {
            FlagState = action.payload;
            return FlagState;
        }

        default: {
            return FlagState;
        }
    }
}
