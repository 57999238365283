import { ReferInformation } from '../../../model/refer-info';
import { ReferInfoActionUnion, ReferInfoActionTypes } from '../actions';

export let ReferInfoSavedState: ReferInformation;

export function referInfoFlagReducer(
    InfoState = ReferInfoSavedState,
    action: ReferInfoActionUnion
) {
    switch (action.type) {
        // Flag Setter
        case ReferInfoActionTypes.ReferInfoSaved: {
            InfoState = action.payload;
            return InfoState;
        }
        default: {
            return InfoState;
        }
    }
}
