import {
    RetrievedComProducerCodesActionUnion,
    RetrievedComProducerCodesActionTypes,
} from './../actions';

export let ComProducerCodesInitial: any;

export function RetrievedComProducerCodesReducer(
    ComProducerCodesInfo = ComProducerCodesInitial,
    action: RetrievedComProducerCodesActionUnion
) {
    switch (action.type) {
        // Flag Setter
        case RetrievedComProducerCodesActionTypes.ComProducerCodes: {
            ComProducerCodesInfo = action.payload;
            return ComProducerCodesInfo;
        }

        default: {
            return ComProducerCodesInfo;
        }
    }
}
