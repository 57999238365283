import {
    RetrieveQuoteActionTypes,
    RetrieveQuoteActionUnion,
} from '../actions/retrievequote.action';

export let retrieveQuoteInitialState: any;

export function retrieveQuoteReducer(
    retrieveQuoteData = retrieveQuoteInitialState,
    action: RetrieveQuoteActionUnion
) {
    switch (action.type) {
        // retrieveQuoteData Setter
        case RetrieveQuoteActionTypes.setRetrieveQuoteData: {
            const newRetrieveQuoteData = action.payload;
            return newRetrieveQuoteData;
        }

        default: {
            return retrieveQuoteData;
        }
    }
}
