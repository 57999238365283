import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    TypeaheadModule,
    ZipCodeModule,
    InputModule,
    CruxDropdownModule,
    AutocompleteModule,
    ButtonToggleModule,
    ButtonModule,
    SpinnerModule,
} from '@crux/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsModule } from '@crux/services';
import { QuoteStepComponent } from './quote-step.component';
import { CSBCoreModule } from '../../../csb-core/csb-core.module';
import { CsbBusinessTypeComponent } from './csb-business-type/csb-business-type.component';
import { CsbRevenueComponent } from './csb-revenue/csb-revenue.component';
import { CsbZipcodeComponent } from './csb-zipcode/csb-zipcode.component';
import { EmailModule } from '../email/email.module';
import { CsbYesNoModule } from '../../../csb-quote/csb-yes-no/csb-yes-no.module';
import { CsbWebsiteModule } from '../../../csb-quote/csb-website/csb-website.module';
import { CsbQuestionModule } from '../../../csb-quote/csb-question/csb-question.module';
import { QuoteImageModule } from '../../../csb-quote/quote-image/quote-image.module';
import { CsbDropdownListModule } from '../../../csb-quote/csb-dropdown-list/csb-dropdown-list.module';
import { CsbLeftPanelComponent } from './csb-left-panel/csb-left-panel.component';
import { ReferralMessageModule } from '../referral-message/referral-message.module';
import { CsbCoreZipcodeModule } from '../../../csb-quote/csb-zipcode/csb-core-zipcode.module';

@NgModule({
    declarations: [
        QuoteStepComponent,
        CsbBusinessTypeComponent,
        CsbRevenueComponent,
        CsbZipcodeComponent,
        CsbLeftPanelComponent,
    ],
    imports: [
        CommonModule,
        TypeaheadModule,
        ZipCodeModule,
        InputModule,
        CruxDropdownModule,
        FormsModule,
        ReactiveFormsModule,
        AnalyticsModule,
        AutocompleteModule,
        ButtonToggleModule,
        ButtonModule,
        CSBCoreModule,
        CsbYesNoModule,
        EmailModule,
        CsbQuestionModule,
        QuoteImageModule,
        ReferralMessageModule,
        EmailModule,
        SpinnerModule,
        CsbDropdownListModule,
        CsbCoreZipcodeModule,
        CsbWebsiteModule,
    ],
    exports: [QuoteStepComponent],
    entryComponents: [
        CsbBusinessTypeComponent,
        CsbRevenueComponent,
        CsbZipcodeComponent,
        CsbLeftPanelComponent,
    ],
})
export class QuoteStepModule {}
