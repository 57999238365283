import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { environment } from '../../../../environments/environment';
import { AnalyticsService } from '@crux/services';
@Component({
    selector: 'app-content-section-tile',
    templateUrl: './content-section-tile.component.html',
    styleUrls: ['./content-section-tile.component.scss'],
})
export class ContentSectionTileComponent implements OnInit {
    @Input() itemToDisplay: any;
    public imgURL: any;

    constructor(
        private _router: Router,
        private _ccs: CommonCommunicationService,
        private _analytics: AnalyticsService
    ) {}

    ngOnInit() {
        this.imgURL = environment.blobStorageBaseURL;
    }

    navigateTo(url) {
        this._analytics.eventTrack.next({
            category: 'RELATED ARTICLES',
            action: 'RELATED_ARTICLES_ITEM_CLICK',
            label: 'Navigate To' + ' ' + url,
        });
        window.open(url);
    }
}
