import {
    AppetiteActionUnion,
    AppetiteActionTypes,
} from './../actions/appetite.action';

export const startQuoteInitialState = false;

export function startQuoteReducer(
    startQuoteState = startQuoteInitialState,
    action: AppetiteActionUnion
) {
    switch (action.type) {
        case AppetiteActionTypes.StartQuote: {
            startQuoteState = true;
            return startQuoteState;
        }
        default: {
            return startQuoteState;
        }
    }
}
