import { Injectable } from '@angular/core';
import { CruxHttpClientService } from '@crux/services';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { PartnerClassificationReq } from '../model/classification';

@Injectable()
export class PartnerClassificationService {
    public partnerclassificationURL: string =
        environment.backend + environment.uri.partnerClassifications;
    constructor(private _http: CruxHttpClientService) {}

    getPartnerClassification(
        classificationRequestBody: PartnerClassificationReq
    ): Observable<any> {
        return this._http.post(this.partnerclassificationURL, {
            body: classificationRequestBody,
        });
    }
}
