import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepOneComponent } from './step-one.component';
import { ClassificationModule } from '../classification/classification.module';
import { ZipCodeModule } from '../zip-code/zip-code.module';
import { GrossSalesModule } from '../gross-sales/gross-sales.module';
import { AppetiteCheckModule } from '../appetite-check/appetite-check.module';

@NgModule({
    imports: [
        CommonModule,
        ClassificationModule,
        ZipCodeModule,
        GrossSalesModule,
        AppetiteCheckModule,
    ],
    declarations: [StepOneComponent],
    exports: [StepOneComponent],
})
export class StepOneModule {}
