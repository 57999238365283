import { ProductActionUnion, ProductActionTypes } from '../actions';

export const initialSelectedPackage = 0;

export function setSelectedPackageCountReducer(
    totalSelectedPackage = initialSelectedPackage,
    action: ProductActionUnion
) {
    switch (action.type) {
        case ProductActionTypes.setPackageCount: {
            totalSelectedPackage = action.payload;
            return totalSelectedPackage;
        }
        default: {
            return totalSelectedPackage;
        }
    }
}
