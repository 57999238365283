import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageBlogSectionComponent } from './homepage-blog-section.component';

@NgModule({
    imports: [CommonModule],
    declarations: [HomepageBlogSectionComponent],
    exports: [HomepageBlogSectionComponent],
})
export class HomepageBlogSectionModule {}
