import { Injectable } from '@angular/core';

import { CruxHttpClientService } from '@crux/services';
import { environment } from '../../environments/environment';
import { CommonCommunicationService } from './common-communication.service';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RatingQuestionsService {
    questionsApi: string = environment.backend + environment.uri.questions;

    eligibilityApi: string = '../../assets/json/rating-questions-data.json';

    constructor(
        private _http: CruxHttpClientService,
        private _ccs: CommonCommunicationService
    ) {}

    getRatingsData(classification_id, productId) {
        return this._http.get(
            this.questionsApi
                .replace('#PARTNER#', this._ccs.PartnerId)
                .replace('$classid$', classification_id)
                .replace('$productid$', productId)
        );
    }

    getEligibilityQuestions() {
        return this._http.get(this.eligibilityApi).pipe(
            catchError((error: Response) => {
                return observableThrowError(error);
            })
        );
    }
}
