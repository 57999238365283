import {
    Component,
    OnInit,
    ViewChild,
    ComponentFactoryResolver,
    Input,
    EventEmitter,
    Output,
} from '@angular/core';
import { HostDirective } from '../../csb-core/directive/host.directive';
import { CsbBusinessTypeComponent } from '../../quote/home-page/quote-step/csb-business-type/csb-business-type.component';
import { CsbZipcodeComponent } from '../../quote/home-page/quote-step/csb-zipcode/csb-zipcode.component';
import { CsbRevenueComponent } from '../../quote/home-page/quote-step/csb-revenue/csb-revenue.component';
import { CsbYesNoComponent } from '../csb-yes-no/csb-yes-no.component';
import { CsbWebsiteComponent } from '../csb-website/csb-website.component';
import { QuestionType, DataType } from './model/question-type';
import { QuestionBase } from './csb-question-base.component';
import { EmailComponent } from '../../quote/home-page/email/email.component';
import { select, Store } from '@ngrx/store';
import { AffinityPartnerFlags } from '../../model/affinity-partner';
import { isEmpty, isNil } from 'lodash';

@Component({
    selector: 'app-csb-question',
    templateUrl: './csb-question.component.html',
    styleUrls: ['./csb-question.component.scss'],
})
export class CsbQuestionComponent implements OnInit {
    AffinityPartnerFlags: AffinityPartnerFlags;
    @Input() question: Array<QuestionType> = [];

    @Output()
    updateStore: EventEmitter<DataType> = new EventEmitter<DataType>();

    @ViewChild(HostDirective, { static: true }) hostDirective: HostDirective;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>
    ) {
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.AffinityPartnerFlags = AffinityPartnerFlags;
                    }
                }
            });
    }

    ngOnInit() {
        this.initializeSteps();
    }

    /**
     * Initial the default step and enable the page which were navigated.
     */
    initializeSteps() {
        this.loadComponent();
    }

    /**
     * It will load the component directly and will be added to the view.
     */
    loadComponent() {
        const viewContainerRef = this.hostDirective.viewContainerRef;
        viewContainerRef.clear();

        this.question.forEach((item: QuestionType, index: number) => {
            const comp: any = this.getComponent(item.type);

            if (comp) {
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
                    comp
                );

                const componentRef = viewContainerRef.createComponent(
                    componentFactory,
                    index
                );
                if (
                    this.AffinityPartnerFlags &&
                    this.AffinityPartnerFlags.partnercode &&
                    (this.AffinityPartnerFlags.partnercode === 'walmart' ||
                        this.AffinityPartnerFlags.partnercode === 'amazon10') &&
                    item.id === 3
                ) {
                    item.text =
                        'Please select the category that best reflects the primary products you sell';
                    item.placeHolder = 'Retail category';
                }

                if (this.AffinityPartnerFlags && this.AffinityPartnerFlags.partnercode && this.AffinityPartnerFlags.partnercode === 'CISA') {
                    item.text = item.agencytext;
                }

                if (componentRef.instance instanceof QuestionBase) {
                    const ref = <QuestionBase>componentRef.instance;
                    ref.question = item.text;
                    ref.subquestiontext = item.subquestiontext;
                    ref.questionNo = item.questionNo;
                    ref.showImage = item.showImage;
                    ref.activeImage = item.activeImage;
                    ref.inactiveImage = item.inactiveImage;
                    ref.rowHeight = item.height;
                    ref.defaultColor = item.defaultColor;
                    ref.highlightColor = item.highlightColor;
                    ref.data = item.data;
                    ref.placeHolder = item.placeHolder;
                    ref.placeHolderSubText = item.placeHolderSubText;
                    ref.gaevent = item.gaevent;

                    ref.validEvent.subscribe((data: DataType) => {
                        if (data && this.question[data.id] !== null) {
                            this.question[data.id].data = {
                                ...data,
                            };
                        }
                        this.updateStore.emit(data);
                    });
                }
            }
        });
    }

    getComponent(value: string) {
        let comp: any;
        switch (value) {
            case 'YesNo':
                comp = CsbYesNoComponent;
                break;

            case 'BusinessType':
                comp = CsbBusinessTypeComponent;
                break;

            case 'ZipCode':
                comp = CsbZipcodeComponent;
                break;

            case 'Revenue':
                comp = CsbRevenueComponent;
                break;

            case 'Website':
                comp = CsbWebsiteComponent;
                break;    

            case 'Email':
                comp = EmailComponent;
                break;
        }
        return comp;
    }
}
