import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule, CruxDropdownModule } from '@crux/components';
import { CisaHomepageComponent } from './cisa-homepage.component';
import { AnalyticsModule } from '@crux/services';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, InputModule } from '@crux/components';
import { CSBCoreModule } from '../../../csb-core/csb-core.module';

@NgModule({
    declarations: [CisaHomepageComponent],
    imports: [
        CommonModule,
        CarouselModule,
        AnalyticsModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        InputModule,
        CSBCoreModule,
        CruxDropdownModule,
    ],
    exports: [CisaHomepageComponent],
})
export class CisaHomepageModule {}
