import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-carousel-navigation',
    templateUrl: './carousel-navigation.component.html',
    styleUrls: ['./carousel-navigation.component.scss'],
})
export class CarouselNavigationComponent implements OnInit {
    @Input() index: number;

    @Input() total: number;

    @Output() moveTo: EventEmitter<number> = new EventEmitter<number>();

    constructor() {}

    ngOnInit() {}

    handleNext(e) {
        let i: number;
        if (this.index === this.total - 1) {
            i = 0;
        } else {
            i = this.index + 1;
        }
        this.moveTo.emit(i);
    }

    handlePrevious(e) {
        let i: number;
        if (this.index === 0) {
            i = this.total - 1;
        } else {
            i = this.index - 1;
        }
        this.moveTo.emit(i);
    }
}
