import { QuoteActionTypes, QuoteActionUnion } from '../actions/quote.action';

export const initialSelectedPacks = 0;
export function numberOfSelectedPackages(
    numberOfSelectedPacks = initialSelectedPacks,
    action: QuoteActionUnion
) {
    switch (action.type) {
        case QuoteActionTypes.setNumberOfSelectedPackages: {
            numberOfSelectedPacks = action.payload;
            return numberOfSelectedPacks;
        }
        default: {
            return numberOfSelectedPacks;
        }
    }
}
