import { AgentInformation } from '../../../model/agent-info';
import { AgentInfoActionUnion, AgentInfoActionTypes } from '../actions';

export let AgentInfoSavedState: AgentInformation;

export function agentInfoFlagReducer(
    InfoState = AgentInfoSavedState,
    action: AgentInfoActionUnion
) {
    switch (action.type) {
        // Flag Setter
        case AgentInfoActionTypes.AgentInfoSaved: {
            InfoState = action.payload;
            return InfoState;
        }
        default: {
            return InfoState;
        }
    }
}
