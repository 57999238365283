import { map } from 'rxjs/operators';
import { CruxHttpClientService } from '@crux/services';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class DownloadQuoteService {
    public pdfurl: string = environment.backend + environment.uri.createpdf;
    public pdfDataUrl: string =
        environment.backend + environment.uri.getPdfData;
    public pdfStoreDataUrl: string =
        environment.backend + environment.uri.storePdfData;
    public baseUrl: string = environment.baseUrl;

    constructor(private http: CruxHttpClientService) {}

    getPdfData(arg1: string): Observable<any> {
        const pdfSourceDataUri = this.pdfDataUrl.replace(':quoteId', arg1);
        return this.http.get(pdfSourceDataUri, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        });
    }

    storePdfData(quoteData: object): any {
        return this.http.post(this.pdfStoreDataUrl, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: { data: quoteData },
        });
    }

    createPdf(quoteId: string, routeName: string): Observable<any> {
        return this.http.post(this.pdfurl, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                quoteId: quoteId,
                baseUrl: this.baseUrl + '/' + routeName + '/',
            },
            responseType: 'blob',
        });
    }
}
