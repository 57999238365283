import { MajescoActionTypes, MajescotActionUnion } from '../actions';

export let majescoStatus: boolean = false;

export function majescoReducer(
    majescoState = majescoStatus,
    action: MajescotActionUnion
) {
    switch (action.type) {
        // majesco Setter
        case MajescoActionTypes.setMajescoStatus: {
            majescoState = action.payload;
            return majescoState;
        }

        default: {
            return majescoState;
        }
    }
}
