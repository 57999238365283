import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'lodash-es';
import { environment } from '../../environments/environment';
import { CruxHttpClientService } from '@crux/services';

@Component({
    selector: 'app-blog-article',
    templateUrl: './blog-article.component.html',
    styleUrls: ['./blog-article.component.scss'],
})
export class BlogArticleComponent implements OnInit {
    extentContainer = false;
    showReadMorebtn = true;
    blogData: any;
    selectedBlogName: string;
    customData: any;
    blobStorage: any;
    readBlogData: any;
    displayBlogArticle = false;
    public contentItems: any;
    constructor(
        private _activatedRoute: ActivatedRoute,
        private http: CruxHttpClientService
    ) {
        this.blobStorage = environment.blobStorageBaseURL;
        const ts = new Date().getTime();
        const blogDataURL =
            this.blobStorage + '/data/blog-article.json?ts=' + ts;
        this.http
            .get(blogDataURL, { responseType: 'json' })
            .subscribe((res) => {
                this.readBlogData = res;
                if (this.readBlogData) {
                    this.displayBlogArticle = true;
                    this.setBlogData();
                }
            });
    }

    ngOnInit() {
        this.getBlogContentName();
    }
    setBlogData() {
        const contentfilter = filter(this.readBlogData, (blogname) => {
            return (
                blogname.banner_title.toUpperCase() ===
                this.selectedBlogName.toUpperCase()
            );
        });
        this.blogData = contentfilter[0];
        this.contentItems = contentfilter[0].Related_articles;
    }

    getBlogContentName() {
        this._activatedRoute.params.subscribe((param) => {
            if (param && param['blogContentName']) {
                this.selectedBlogName = param['blogContentName'].replace(
                    /-/g,
                    ' '
                );
            }
        });
    }
    loadMore() {
        this.extentContainer = true;
        this.showReadMorebtn = false;
    }
}
