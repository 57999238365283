import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsbMarketingpagesComponent } from './csb-marketingpages.component';
import { MarketingBannerModule } from '../marketing-banner/marketing-banner.module';
import { MarketingProductInfoModule } from '../marketing-product-info/marketing-product-info.module';
import { MarketingTabsModule } from '../marketing-tabs/marketing-tabs.module';
import { MarketingCoverageModule } from '../marketing-coverage/marketing-coverage.module';
import { MarketingProductStepsModule } from '../marketing-product-steps/marketing-product-steps.module';
import { SpinnerModule } from '@crux/components';

@NgModule({
    declarations: [CsbMarketingpagesComponent],
    imports: [
        CommonModule,
        MarketingBannerModule,
        MarketingProductInfoModule,
        MarketingTabsModule,
        MarketingCoverageModule,
        MarketingProductStepsModule,
        SpinnerModule,
    ],
})
export class CsbMarketingpagesModule {}
