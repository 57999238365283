import { BusinessClass } from '../../../model/p360-business-class';
import {
    P360ClassActionTypes,
    P360ClassActionUnion,
} from '../actions/p360-class.action';

export let p360ClassInitialState: BusinessClass[];

export function p360ClassReducer(
    p360ClassState = p360ClassInitialState,
    action: P360ClassActionUnion
) {
    switch (action.type) {
        // Set P360 Classes
        case P360ClassActionTypes.setP360Classes: {
            p360ClassState = action.payload;
            return p360ClassState;
        }
        default: {
            return p360ClassState;
        }
    }
}
