export class JobtypeTile {
    type: string;
    totalPayroll?: number;
    titleList?: any[];
    jobtypeListUrl?: string;
    entityTypeList?: any[];
    totalEmployees?: number;
    formValues?: any;
    isInvalid?: boolean;
}

export class FormViewState {
    includeBlock: JobtypeViewBlock;
    excludeBlock: JobtypeViewBlock;
    jobTypeBlock: JobtypeViewBlock;
}

export class JobtypeViewBlock {
    isAdded: boolean;
    tiles: JobtypeTile[];
}
