import { Classification } from '../../../model/classification';

import {
    AppetiteActionUnion,
    AppetiteActionTypes,
} from './../actions/appetite.action';
import { stat } from 'fs';

export const classificationInitialState: Classification = new Classification();
export const revenueInitialState = '';

export function classificationReducer(
    classificationState = classificationInitialState,
    action: AppetiteActionUnion
) {
    switch (action.type) {
        // Classification Setter
        case AppetiteActionTypes.SetClassificationCode: {
            classificationState = {
                digitalClassCode: action.payload.digitalClassCode,
                classId: action.payload.classId,
                name: action.payload.name,
                description: action.payload.description,
                tags: action.payload.tags,
                premiumBasis: action.payload.premiumBasis,
                classesByProduct: action.payload.classesByProduct,
                wsgClassId: action.payload.wsgClassId,
                keyWord: action.payload.keyWord,
            };
            return classificationState;
        }

        case AppetiteActionTypes.ResetClassificationCode: {
            classificationState = action.payload;

            return classificationState;
        }

        default: {
            return classificationState;
        }
    }
}
