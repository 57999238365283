import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '@crux/services';
import {
    FormControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {
    SetP360ClassAction,
    SetRevenueAction,
} from '../../../../sales-store/appetite-store/actions';
import { QuestionBase } from '../../../../csb-quote/csb-question/csb-question-base.component';
import { DictionaryService } from '../../../../services/dictionary.service';
import { ApplicationMessagesService } from '../../../../services/application-messages.service';
import { GAEvent } from '../../../../csb-core/ga-event';
import { isEmpty, isNil } from 'lodash';

@Component({
    selector: 'app-csb-revenue',
    templateUrl: './csb-revenue.component.html',
    styleUrls: ['./csb-revenue.component.scss'],
})
export class CsbRevenueComponent extends QuestionBase implements OnInit {
    revenueForm: FormGroup;
    revenueInputCtrl: FormControl;
    isrevenueValid = false;
    revenue: number;
    question: string;
    errorMessages: any;
    cssClass: Array<string>;
    componentClass: Array<string>;
    constructor(
        private dictionaryService: DictionaryService,
        private _fb: FormBuilder,
        private _analytics: AnalyticsService,
        private applicationMessagesService: ApplicationMessagesService,
        private _router: Router,
        private _store: Store<{
            revenue: number;
        }>
    ) {
        super();
        this.question = this.dictionaryService.labelDict.banner.quoteStep.annualRevenue;
    }

    ngOnInit() {
        this.cssClass = this.showImage
            ? ['col-4', 'col-md-4', 'col-sm-6']
            : ['col-7', 'col-md-7', 'col-sm-6'];

        this.componentClass = this.showImage
            ? ['col-5', 'col-md-5', 'col-sm-12']
            : ['col-5', 'col-md-5', 'col-sm-6'];
        this.errorMessages = this.applicationMessagesService.messageDictionary.homePageQuestions;
        this.revenueInputCtrl = new FormControl(Validators.required);
        this.revenueForm = this._fb.group({
            revenueInputCtrl: this.revenueInputCtrl,
        });
        this._store.pipe(select('revenue')).subscribe((revenue) => {
            if (revenue > 0) {
                this.revenue = revenue;
                this.revenueInputCtrl.setValue(this.revenue);
            } else {
                // this.revenueInputCtrl.setValue('');
            }
            this.enableNext();
        });
    }

    setRevenue(revenue) {
        if (
            this.revenueInputCtrl.value ||
            (!isNil(revenue) && !isEmpty(revenue))
        ) {
            let newrevenue: any;
            if (
                !isNil(this.revenueInputCtrl.value) &&
                !isEmpty(this.revenueInputCtrl.value)
            ) {
                newrevenue = this.revenueInputCtrl.value
                    .toString()
                    .replace(/\D+/g, '');
            } else {
                newrevenue = revenue;
            }
            if (newrevenue > 0) {
                this.isrevenueValid = false;
                /**
                 * Track Selection of revenue.
                 */
                // this._analytics.eventTrack.next({
                //     category: 'ProductSelection',
                //     action: 'USER_INPUT',
                //     label: this.revenueInputCtrl.value,
                // });
                // Dispatch the store when user enter value and click next button
                this._store.dispatch(new SetRevenueAction(newrevenue));
                this._store.dispatch(new SetP360ClassAction([]));
                // add action after click next button
            } else {
                this.isrevenueValid = true;
            }
        } else {
            this.isrevenueValid = true;
        }
        this.enableNext();
    }
    setEnableFlag(revenue) {
        revenue = revenue.toString().replace(/\D+/g, '');
        if (revenue > 0) {
            revenue = parseInt(revenue, 10);
            this.isrevenueValid = false;
        } else {
            revenue = '';
            this.revenueInputCtrl.setValue(revenue);
            this.isrevenueValid = true;
        }
        this.setRevenue(revenue);
    }

    handleFocusOut() {
        if (this.gaevent) {
            GAEvent.CapturEvent(
                this._analytics,
                this.gaevent.action,
                this.gaevent.category,
                '$' + this.revenueInputCtrl.value
            );
        }
    }

    enableNext() {
        if (!this.revenue || this.isrevenueValid) {
            this.valid(false, this.revenue);
        } else {
            this.valid(true, this.revenue);
        }
    }
}
