import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CommonCommunicationService } from '../../../services/common-communication.service';

/**
 * Store imports
 */
import { Store, select } from '@ngrx/store';

/**
 * Partner Customization
 */
import { PartnerCustomization } from '../../../partner-customization/partner-customization.helper';
import { Question } from '../../../model/questions';

@Injectable()
export class BusinessDetailsGuard implements CanActivate {
    getQuestions = false;
    constructor(
        private _communication: CommonCommunicationService,
        private _store: Store<{
            getQuestions: boolean;
            setQuestions: Question[];
            questions: Question[];
        }>,
        private _partnerCustomization: PartnerCustomization
    ) {
        this._store
            .pipe(select('getQuestions'))
            .subscribe((getQuestionsFlag) => {
                this.getQuestions = getQuestionsFlag;
            });
        this._store.pipe(select('questions')).subscribe((questions) => {
            if (questions.length > 0) {
                this.getQuestions = true;
            }
        });
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        /**
         * Allow to load this route only if questions API call has been initiated
         * means getQuestions state from store is true
         */

        if (this.getQuestions) {
            return true;
        }

        /**
         * Navigate to home if above condition fails
         */
        this._partnerCustomization.goToHome();
    }
}
