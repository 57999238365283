import { Action } from '@ngrx/store';

export enum PartnerOffersActionTypes {
    PartnerOffers = '[ get partner offers ] Get partner offers from blob storage ',
}

export class SetPartnerOffersAction implements Action {
    readonly type = PartnerOffersActionTypes.PartnerOffers;

    constructor(public payload: any) {}
}

export type PartnerOffersActionUnion = SetPartnerOffersAction;
