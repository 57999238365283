import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { CruxHttpClientService } from '@crux/services';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-csb-marketingpages',
    templateUrl: './csb-marketingpages.component.html',
    styleUrls: ['./csb-marketingpages.component.scss'],
})
export class CsbMarketingpagesComponent implements OnInit {
    pageData: any;
    productName: any;
    blobStorage: any;
    marketingPagesData: any;
    loadview = false;
    showSpinner = true;
    constructor(
        private router: Router,
        private titleService: Title,
        private metaService: Meta,
        private _activatedRoute: ActivatedRoute,
        private http: CruxHttpClientService
    ) {
        const ts = new Date().getTime();
        this.blobStorage = environment.blobStorageBaseURL;
        const dataURL =
            this.blobStorage + '/data/marketingpage-data.json?ts=' + ts;
        this.http.get(dataURL, { responseType: 'json' }).subscribe((res) => {
            this.marketingPagesData = res;
            if (this.marketingPagesData) {
                this.setProductName();
            }
        });
    }

    ngOnInit() {}
    setProductName() {
        this._activatedRoute.params.subscribe((param) => {
            if (param && param['pageName']) {
                this.productName = param['pageName'];
            }
            if (this.productName && this.marketingPagesData[this.productName]) {
                this.pageData = this.marketingPagesData[this.productName];
                this.setMetadata();
                this.showSpinner = false;
                this.loadview = true;
                return true;
            }
            // temporary digitech logic, cyber erm content will appear:
            if (
                this.productName === 'digitech' &&
                !this.marketingPagesData[this.productName]
            ) {
                this.pageData = this.marketingPagesData['cyber-erm'];
                this.setMetadata();
                this.showSpinner = false;
                this.loadview = true;
                return true;
            } else {
                this.router.navigate(['/generic-error']);
                return false;
            }
        });
    }
    setMetadata() {
        this.titleService.setTitle(this.pageData.metadata.title);
        const descriptionAttributeSelector = 'name="description"';
        const keywordAttributeSelector = 'name="keywords"';
        this.metaService.updateTag(
            { content: this.pageData.metadata.description },
            descriptionAttributeSelector
        );
        this.metaService.updateTag(
            { content: this.pageData.metadata.keyword },
            keywordAttributeSelector
        );
    }
}
