import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkersCompJobTypeComponent } from './workers-comp-job-type.component';
import { RatingBannerModule } from '../rating-banner/rating-banner.module';
import { EmpTileModule } from './emp-tile/emp-tile.module';
import {
    SpinnerModule,
    CardModule,
    AutocompleteModule,
    InputModule,
    CruxAccordionModule,
    ButtonModule,
    FormBuilderModule,
    CruxDropdownModule,
    CruxCheckboxModule,
    TypeaheadModule,
} from '@crux/components';
import { ProgressBarModule } from '../../../progress-bar/progress-bar.module';
import { ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { DialogModule } from '../../../components/dialog/dialog.module';
import { AnalyticsModule } from '@crux/services';

@NgModule({
    declarations: [WorkersCompJobTypeComponent],
    imports: [
        CommonModule,
        SpinnerModule,
        ProgressBarModule,
        RatingBannerModule,
        CardModule,
        AutocompleteModule,
        AnalyticsModule,
        ReactiveFormsModule,
        InputModule,
        CruxAccordionModule,
        ButtonModule,
        MatExpansionModule,
        MatIconModule,
        MatButtonModule,
        FormBuilderModule,
        CruxDropdownModule,
        MatCheckboxModule,
        CruxCheckboxModule,
        TypeaheadModule,
        EmpTileModule,
        DialogModule,
    ],
    exports: [WorkersCompJobTypeComponent],
})
export class WorkersCompJobTypeModule {}
