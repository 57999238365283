import { Input, EventEmitter, Output, Directive } from '@angular/core';
import { DataType } from './model/question-type';

@Directive()
export class QuestionBase {
    @Input() questionNo: string;

    @Input() question: string;

    @Input() subquestiontext: string;

    @Input() showImage: boolean;

    @Input() activeImage: string;

    @Input() inactiveImage: string;

    @Input() rowHeight: string;

    @Input() defaultColor: string;

    @Input() highlightColor: string;

    @Input() data: DataType;

    @Input() placeHolder: string;

    @Input() placeHolderSubText: string;

    @Input() gaevent: any;

    @Output() validEvent: EventEmitter<DataType> = new EventEmitter<DataType>();

    changeImage: boolean;

    handleMouseEvent(value) {
        this.changeImage = value;
    }

    valid(valid: boolean, value: any) {
        if (this.data) {
            this.data.valid = valid;
            this.data.value = value;
            this.validEvent.emit(this.data);
        }
    }
}
