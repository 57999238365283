import { Action } from '@ngrx/store';
import { Classification } from '../../../model/classification';
import { ZipcodeModel } from '../../../model/zipcode';
import { ProductsRequest } from './../../../model/products-request';

export enum AppetiteActionTypes {
    SetClassificationCode = '[Classification] SetClassification',
    SetZipcodeData = '[Zipcode] SetZipcode',
    SetRevenue = '[Revenue] SetRevenue',
    StartQuote = '[Start Quote] StartQuote',
    GetProductsSuccess = '[Get Products] Get products success',

    ResetClassificationCode = '[Classification] ResetClassification',
    ResetZipcodeData = '[Zipcode] ResetZipcode',
    ResetRevenue = '[Revenue] ResetRevenue',
}

export class SetClassificationAction implements Action {
    readonly type = AppetiteActionTypes.SetClassificationCode;

    constructor(public payload: Classification) {}
}

export class SetLocationAction implements Action {
    readonly type = AppetiteActionTypes.SetZipcodeData;

    constructor(public payload: ZipcodeModel[]) {}
}

export class SetRevenueAction implements Action {
    readonly type = AppetiteActionTypes.SetRevenue;

    constructor(public payload: number) {}
}

export class StartQuoteAction implements Action {
    readonly type = AppetiteActionTypes.StartQuote;

    constructor(
        public payload: ProductsRequest,
        public partner: string = '0'
    ) {}
}

export class ResetClassificationQuoteAction implements Action {
    readonly type = AppetiteActionTypes.ResetClassificationCode;

    constructor(public payload: Classification) {}
}

export class ResetZipcodeDataQuoteAction implements Action {
    readonly type = AppetiteActionTypes.ResetZipcodeData;

    constructor(public payload: ZipcodeModel[]) {}
}

export class ResetRevenueDataQuoteAction implements Action {
    readonly type = AppetiteActionTypes.ResetRevenue;

    constructor(public payload: number) {}
}

export type AppetiteActionUnion =
    | SetClassificationAction
    | SetLocationAction
    | SetRevenueAction
    | StartQuoteAction
    | ResetClassificationQuoteAction
    | ResetZipcodeDataQuoteAction
    | ResetRevenueDataQuoteAction;
