import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardComponent } from './product-card.component';
import { TooltipModule } from '@crux/components';

@NgModule({
    declarations: [ProductCardComponent],
    imports: [CommonModule, TooltipModule],
    exports: [ProductCardComponent, TooltipModule],
})
export class ProductCardModule {}
