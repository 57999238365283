import {
    Component,
    OnInit,
    ElementRef,
    ViewChild,
    OnDestroy,
} from '@angular/core';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { WcJobTypeService } from '../../../services/wc-job-type.service';
import { DictionaryService } from '../../../services/dictionary.service';
import {
    FormGroup,
    FormControl,
    FormArray,
    FormBuilder,
    NgControlStatus,
    AbstractControl,
    Validators,
} from '@angular/forms';
import { AnalyticsService } from '@crux/services';
import { PartnerCustomization } from '../../../partner-customization/partner-customization.helper';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Questions, SimpleQuestions } from '../../../model/questions';
import { CustomerInfo } from '../../../model/customer-info';
import { Product } from '../../../model/product';
import { Classification } from '../../../model/classification';
import { ZipcodeModel } from '../../../model/zipcode';
import { ProductSelectedPipe } from '../../../pipes/productSelected.pipe';
import {
    OfficersCoverage,
    JobType,
    WorkCompRateClass,
} from '../../../model/officers-coverage';
import { SetProductsAction } from '../../../sales-store/products-store/actions';
import {
    SetQuestionsAction,
    SetSimpleQuestionsObjAction,
} from '../../../sales-store/questions-store/actions';
import { ApplicationMessagesService } from '../../../services/application-messages.service';

import { environment } from '../../../../environments/environment';
import { CruxHttpClientService } from '@crux/services';
import { saveAs } from 'file-saver/FileSaver';
import { HttpHeaders } from '@angular/common/http';
import { filter, isEmpty, isNil } from 'lodash-es';
import { ReusableMethods } from '../../../issuance/helper/reusable-methods';
import { MatDialog } from '@angular/material/dialog';

import {
    JobtypeTile,
    FormViewState,
    JobtypeViewBlock,
} from '../../../model/jobtype-tile';
import { PayrollLimits, EmpLimits } from '../../../model/payrollLimits';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { DeviceDetectorService } from 'ngx-device-detector';
import { forkJoin } from 'rxjs';
import { ProgressBarService } from '../../../services/progress-bar.service';
import { DialogComponent } from '../../../components/dialog/dialog.component';
import { CallUsNumberModel } from '../../../model/callUsNumber';
import { AffinityPartnerFlags } from '../../../model/affinity-partner';
@Component({
    selector: 'app-workers-comp-job-type',
    templateUrl: './workers-comp-job-type.component.html',
    styleUrls: ['./workers-comp-job-type.component.scss'],
})
export class WorkersCompJobTypeComponent implements OnInit, OnDestroy {
    static STATE_CA = 'CA';

    @ViewChild('includeExec') includeExec: ElementRef;
    @ViewChild('excludeExec') excludeExec: ElementRef;

    @ViewChild('jobTypeBlock') jobTypeBlock: ElementRef;

    jobTypeValues = [
        {
            text: '0',
            value: '0',
        },
        {
            text: '1',
            value: '1',
        },
    ];

    public formviewState: FormViewState = new FormViewState();
    public blobURL = environment.blobStorageBaseURL;

    public totalPayroll = new FormControl(0, Validators.required);
    public totalEmployees: number;
    public labelDictionary: any;
    public partTimeEmployees = new FormControl('0');
    public fullTimeEmployees = new FormControl(0);
    public shortForm;
    public companyStructure: any;

    public jobTypes = {
        include: [],
        exclude: [],
        jobType: [],
    };
    // The groupings of Jobs and Execs to Send to the next page
    jobTypeFormGroup = new FormGroup({});
    includeExecFormGroup = new FormGroup({});
    excludeExecFormGroup = new FormGroup({});

    employeeMax: any;
    payrollMax: any;
    disabled = true;
    expanded = true;
    showSpinner: boolean;
    revenue: any;
    state: any;
    allProducts: any;
    selectedProducts: any;
    questions: any;
    productIndex: any;
    customerInfo: any;
    typecount = 0;
    businessName: any;
    showJobType = false;
    currentProduct: any;
    // entityType = new FormControl('', Validators.required);
    entityTypeValues: any;
    addLink = true;
    officersEntityType: any;
    showContinue = false;
    addOwnerLink = false;
    addjobTypeLink = false;
    showCloseLink = false;
    selectedClass: any;

    public jobFormGroup: FormGroup;
    selectFormGroup = new FormGroup({});
    includeOfficers = new FormControl();
    excludeOfficers = new FormControl();
    showError: boolean;
    payrollAllowed: number;
    execTitles: any;
    listOfJobTypeUrl: any;
    includedFormGroup: FormGroup;
    excludedFormGroup: FormGroup;
    commonJobTypeFormGroup: FormGroup;
    showIncludeExec = false;
    showExcludeExec = false;
    errorMessages: any;
    sliderValue: any;
    environment = environment;
    payrollLimits: PayrollLimits = new PayrollLimits();
    ifShowContinue = false;
    isEmpLimitConsumed = false;
    retrievedQuoteObj: any;
    ifShowEmpAlert = false;
    showCAInfo = false;
    simpleQuestionsObj: any;
    CAWaiverChecked = false;
    jobTypeArray: any;
    isMobiledevice = false;
    isDesktopDevice = false;
    isTabletDevice = false;
    showSkipPolicyLink = false;
    totalPremium: number;
    empLimitError = false;
    selectedEntityType: any;
    includeCheckError = false;
    excludeCheckError = false;
    jobTypeValid = false;
    officerValid = false;
    showOfficersCheckBox = true;
    caWaiverError = false;
    contents: any;
    callUsNumber: CallUsNumberModel;
    wcJobCodes = [];
    affinityPartnerFlags: AffinityPartnerFlags;
    isCisaUser: boolean;

    constructor(
        public commonCommunicationService: CommonCommunicationService,
        public dialog: MatDialog,
        private applicationMessagesService: ApplicationMessagesService,
        public _analytics: AnalyticsService,
        public dictionaryService: DictionaryService,
        public deviceService: DeviceDetectorService,
        public progressBarService: ProgressBarService,
        public _partnerCustomization: PartnerCustomization,
        public _router: Router,
        private _activatedRoute: ActivatedRoute,
        public _wcJobTypeService: WcJobTypeService,
        private http: CruxHttpClientService,
        private _store: Store<{
            questions: Questions[];
            simpleQuestionsObj: SimpleQuestions;
            customerInfoData: CustomerInfo;
            products: Product[];
            classification: Classification;
            zipcode: ZipcodeModel;
            revenue: number;
            customerInfo: CustomerInfo;
            retrieveQuoteData: any;
            totalPremium: number;
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>
    ) {
        this._router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.showSpinner = true;
        this.setProductIndex();

        this.buildInitialFormState();

        this.readDataFromStore();

        this.buildJobTypeUrl();
    }

    buildJobTypeUrl(): void {
        this.listOfJobTypeUrl = this._wcJobTypeService.wcJobTypeUrl
            .replace('$digitalCode', this.selectedClass.digitalClassCode)
            .replace('$state', this.state);
    }

    getCompanyJobData() {
        const officerTitles = this._wcJobTypeService.getOfficerTitle(
            this.officersEntityType
        );
        const companyStructure = this._wcJobTypeService.getCompanyStructure(
            this.officersEntityType,
            this.state
        );
        return forkJoin([officerTitles, companyStructure]);
    }

    buildInitialFormState(): void {
        this.formviewState.includeBlock = new JobtypeViewBlock();
        this.formviewState.includeBlock.isAdded = false;
        this.formviewState.includeBlock.tiles = [];
        this.formviewState.excludeBlock = new JobtypeViewBlock();
        this.formviewState.excludeBlock.isAdded = false;
        this.formviewState.excludeBlock.tiles = [];
        this.formviewState.jobTypeBlock = new JobtypeViewBlock();
        this.formviewState.jobTypeBlock.isAdded = false;
        this.formviewState.jobTypeBlock.tiles = [];
    }

    readDataFromStore(): void {
        this._store.pipe(select('products')).subscribe((products) => {
            this.allProducts = products;
            this.selectedProducts = new ProductSelectedPipe().transform(
                products
            );
            this.currentProduct = this.selectedProducts[this.productIndex];
        });
        this._store.pipe(select('zipcode')).subscribe((zipData) => {
            this.state = zipData[0].StateProvince;
        });

        this._store
            .pipe(select('questions'))
            .subscribe((questionsFromStore) => {
                this.questions = questionsFromStore;
            });

        this._store
            .pipe(select('customerInfoData'))
            .subscribe((customerInfoDataFromStore) => {
                this.customerInfo = customerInfoDataFromStore;
            });
        this._store
            .pipe(select('simpleQuestionsObj'))
            .subscribe((simpleQuestionObj) => {
                if (simpleQuestionObj.qid_9 && simpleQuestionObj.qid_9.value) {
                    this.totalEmployees = simpleQuestionObj.qid_9.value;
                    this.employeeMax = simpleQuestionObj.qid_9.value;
                    this.simpleQuestionsObj = simpleQuestionObj;
                }

                if (simpleQuestionObj.qid_8 && simpleQuestionObj.qid_8.value) {
                    this.totalPayroll.setValue(simpleQuestionObj.qid_8.value);
                    this.totalPayroll.disable();
                }
                if (
                    simpleQuestionObj.qid_21 &&
                    simpleQuestionObj.qid_21.value
                ) {
                    this.officersEntityType = simpleQuestionObj.qid_21.value.WC;
                    this.selectedEntityType = simpleQuestionObj.qid_21.value.WC;
                }
            });
        this.questions.forEach((questionGroup) => {
            if (questionGroup.group_name === 'Universal Questions') {
                questionGroup.questions.forEach((question) => {
                    if (question.text === 'Legal business name') {
                        this.businessName = question.value;
                    }
                });
            }
        });

        this._store.pipe(select('classification')).subscribe((classData) => {
            this.selectedClass = classData;
        });
        this._store.pipe(select('retrieveQuoteData')).subscribe((data) => {
            this.retrievedQuoteObj = data;
        });
        this._store.pipe(select('totalPremium')).subscribe((totalPremium) => {
            this.totalPremium = totalPremium;
        });

        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.affinityPartnerFlags = AffinityPartnerFlags;
                        if (
                            this.affinityPartnerFlags.partnercode.toLowerCase() ===
                            'cisa'
                        ) {
                            this.isCisaUser = true;
                        }
                    }
                }
            });
    }

    checkForJobTypeData() {
        let addJobTypeData = true;
        this.currentProduct.jobType.workCompRateClasses.forEach(
            (ProductJobType) => {
                if (ProductJobType.jobTypeData) {
                    addJobTypeData = false;
                }
            }
        );
        return addJobTypeData;
    }

    ngOnInit() {
        const ts = new Date().getTime();
        this.http
            .get(
                this.blobURL +
                    '/data/wc_state_statutory_guidelines.json?ts=' +
                    ts,
                {
                    responseType: 'json',
                }
            )
            .subscribe((res) => {
                this.contents = res[this.state];
            });
        if (
            this.commonCommunicationService.currentCompanyStructure !== null &&
            this.commonCommunicationService.currentCompanyStructure !==
                this.commonCommunicationService.previousCompanyStructure
        ) {
            this.currentProduct['officersCoverage'] = [];
            this.currentProduct['jobType'] = {};
            this.currentProduct['jobType']['workCompRateClasses'] = [];
            this.commonCommunicationService.previousCompanyStructure = this.commonCommunicationService.currentCompanyStructure;
            this.commonCommunicationService.caWaiverSectionChecked = false;
        }
        const payload = {};
        payload['stateCode'] = this.state;
        payload['partnerName'] = 'csb.com';
        this._wcJobTypeService.getMarketPlaceJobCodes(payload).subscribe(
            (data) => {
                if (
                    data.workerCompClassCodeResponse &&
                    data.workerCompClassCodeResponse.status &&
                    data.workerCompClassCodeResponse.status === 'Success'
                ) {
                    if (
                        data.workerCompClassCodeResponse.classList &&
                        data.workerCompClassCodeResponse.classList.length &&
                        data.workerCompClassCodeResponse.classList.length > 0
                    ) {
                        this.wcJobCodes =
                            data.workerCompClassCodeResponse.classList;
                        this.initializeData();
                    } else {
                        this.initializeData();
                    }
                }
            },
            (res: Response) => {
                if (res && res.status && res.status !== 200) {
                    this.initializeData();
                }
            }
        );
        this.errorMessages = this.applicationMessagesService.messageDictionary;
        this.labelDictionary = this.dictionaryService.labelDict;
        this.progressBarService.progress(
            this.selectedProducts[this.productIndex].percentage
        );
        this.setInitialFormSubscriptions();
        this.buildInitialEmpLimits();

        this.isMobiledevice = this.deviceService.isMobile();
        this.isTabletDevice = this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();

        if (this.currentProduct.skipFlag) {
            this.currentProduct.skipFlag = false;
            this._store.dispatch(new SetProductsAction(this.allProducts));
        }

        this.checkIfSkipAvailable();
        this.callUsNumber = this.commonCommunicationService.callUsNumber();
        this.commonCommunicationService.routeBeforeSCIUW =
            'workers-comp-job-type';
    }

    checkIfSkipAvailable() {
        /**
         * Make 'showSkipPolicyLink' flag true if
         * 1. next product is available
         */
        const currentProductIndex = coerceNumberProperty(this.productIndex);
        let currentProdPremium = 0;
        if (this.currentProduct.premium_amount) {
            currentProdPremium = this.currentProduct.premium_amount.Premium;
        }

        if (
            this.selectedProducts.length - 1 !== currentProductIndex ||
            this.totalPremium - currentProdPremium > 0
        ) {
            this.showSkipPolicyLink = true;
        }
    }

    initializeData() {
        this.getCompanyJobData().subscribe((responses) => {
            // Sort out the multiple responses from ForkJoin
            const titles = responses[0];
            const companyStructure = responses[1];
            const jobs = this.wcJobCodes;
            this.execTitles = titles;
            this.currentProduct.execTitles = titles;

            this.entityTypeValues = companyStructure;
            this.companyStructure = this.officersEntityType;

            this.jobTypeArray = jobs;

            this.showSpinner = false;
            if (
                this.retrievedQuoteObj &&
                this.execTitles &&
                this.checkForJobTypeData()
            ) {
                this.buildJobTypeData();
            } else if (this.retrievedQuoteObj && !this.checkForJobTypeData()) {
                this.buildJobTypeData();
            } else if (!this.retrievedQuoteObj) {
                this.checkForBuildDirectView();
            } else {
                this.checkForBuildDirectView();
            }
        });
    }

    showHideOfficerCheckbox(showBlock: boolean) {
        const allowInclude: boolean = this.entityTypeValues.allowInclude;
        const allowExclude: boolean = this.entityTypeValues.allowExclude;

        if (this.state !== WorkersCompJobTypeComponent.STATE_CA) {
            if (allowInclude && !allowExclude) {
                this.showOfficersCheckBox = false;
                this.includeOfficers.setValue(allowInclude);
                if (
                    showBlock ||
                    (!showBlock &&
                        allowInclude &&
                        !this.formviewState.includeBlock.isAdded)
                ) {
                    this.addBlock(
                        'include',
                        this.commonCommunicationService.empLimits
                    );
                }
            } else if (!allowInclude && allowExclude) {
                this.showOfficersCheckBox = false;
                this.excludeOfficers.setValue(allowExclude);
                if (
                    showBlock ||
                    (!showBlock &&
                        allowExclude &&
                        !this.formviewState.excludeBlock.isAdded)
                ) {
                    this.addBlock(
                        'exclude',
                        this.commonCommunicationService.empLimits
                    );
                }
            }
        }
    }

    handleSkipPolicy() {
        this.selectedProducts[this.productIndex].skipFlag = true;
        this._store.dispatch(new SetProductsAction(this.allProducts));
        this._analytics.eventTrack.next({
            category: 'SkipPolicy',
            action: 'SKIP_POLICY',
            label: 'Skip Policy - ' + this.currentProduct.name,
        });
        // navigate to questions of next
        const manipulatedUrl =
            '/employer-liability-limits/' + this.productIndex;
        this._partnerCustomization.goToNextScreenUsingProgressBarRoutes(
            manipulatedUrl,
            this.selectedProducts,
            this.productIndex
        );
    }

    checkForBuildDirectView() {
        /**
         * If product already has jobtype data and officers data, build the view
         */
        if (this.currentProduct.officersCoverage) {
            const prodOfficersCoverages = this.currentProduct.officersCoverage;
            prodOfficersCoverages.forEach((officer, index) => {
                if (
                    officer.incExcCode === 'I' &&
                    this.entityTypeValues &&
                    this.entityTypeValues.allowInclude
                ) {
                    this.formviewState.includeBlock.tiles.push({
                        type: 'include',
                        totalPayroll: this.totalPayroll.value,
                        entityTypeList: this.companyStructure,
                        jobtypeListUrl: this.listOfJobTypeUrl,
                        titleList: this.execTitles,
                        totalEmployees: this.totalEmployees,
                        formValues: this.setformValue('I', index),
                    });
                    this.formviewState.includeBlock.isAdded = true;
                    this.includeOfficers.setValue(true);
                    this.commonCommunicationService.empLimits.consumption.push({
                        type: 'include',
                        value: 0,
                        id: 'include' + index,
                    });
                } else if (
                    officer.incExcCode === 'E' &&
                    this.entityTypeValues &&
                    this.entityTypeValues.allowExclude
                ) {
                    this.formviewState.excludeBlock.tiles.push({
                        type: 'exclude',
                        totalPayroll: this.totalPayroll.value,
                        entityTypeList: this.companyStructure,
                        jobtypeListUrl: this.listOfJobTypeUrl,
                        titleList: this.execTitles,
                        totalEmployees: this.totalEmployees,
                        formValues: this.setformValue('E', index),
                    });
                    this.formviewState.excludeBlock.isAdded = true;
                    this.excludeOfficers.setValue(true);
                    this.commonCommunicationService.empLimits.consumption.push({
                        type: 'exclude',
                        value: 0,
                        id: 'exclude' + index,
                    });
                }
            });
        }

        if (this.currentProduct.jobType) {
            const prodJobtype = this.currentProduct.jobType;
            prodJobtype.workCompRateClasses.forEach((jobtype, index) => {
                if (jobtype.category === 'commonJobType') {
                    this.formviewState.jobTypeBlock.tiles.push({
                        type: 'jobtype',
                        totalPayroll: this.totalPayroll.value,
                        titleList: this.entityTypeValues,
                        jobtypeListUrl: this.listOfJobTypeUrl,
                        totalEmployees: this.totalEmployees,
                        formValues: this.setformValue('jobtype', index),
                    });
                    this.formviewState.jobTypeBlock.isAdded = true;
                    this.commonCommunicationService.empLimits.consumption.push({
                        type: 'jobtype',
                        value:
                            coerceNumberProperty(
                                jobtype.fullTimeEmployeeCount
                            ) +
                            coerceNumberProperty(jobtype.partTimeEmployeeCount),
                        id: 'jobtype' + index,
                    });
                }
            });

            if (
                this.state === WorkersCompJobTypeComponent.STATE_CA &&
                this.commonCommunicationService.caWaiverSectionChecked
            ) {
                this.excludeOfficers.setValue(true);
                this.showCAInfo = true;
                this.CAWaiverChecked = true;
                this.setUserInputs();
                // this.addBlock(
                //     'exclude',
                //     this.commonCommunicationService.empLimits
                // );
            }

            let newLimit = 0;
            this.commonCommunicationService.empLimits.consumption.forEach(
                (cons) => {
                    newLimit = newLimit + cons.value;
                }
            );
            this.commonCommunicationService.empLimits.limitConsumed = newLimit;
            this.showHideOfficerCheckbox(false);
        }

        if (
            this.state === WorkersCompJobTypeComponent.STATE_CA &&
            this.commonCommunicationService.caWaiverSectionChecked
        ) {
            this.excludeOfficers.setValue(true);
            this.showCAInfo = true;
            this.CAWaiverChecked = true;
            this.setUserInputs();
            // this.addBlock(
            //     'exclude',
            //     this.commonCommunicationService.empLimits
            // );
        }

        if (
            this.formviewState.jobTypeBlock.tiles.length === 0 &&
            !this.retrievedQuoteObj
        ) {
            this.addBlock('jobType', this.commonCommunicationService.empLimits);
            // Check for show hide Include/Exclude
            this.showHideOfficerCheckbox(true);
        }

        if (
            this.formviewState.jobTypeBlock.tiles.length === 0 &&
            this.retrievedQuoteObj &&
            this.currentProduct.jobType &&
            this.currentProduct.jobType.workCompRateClasses.length === 0 &&
            this.currentProduct.officersCoverage &&
            this.currentProduct.officersCoverage.length === 0
        ) {
            this.addBlock('jobType', this.commonCommunicationService.empLimits);
        }
    }

    checkJobtypeArray(): boolean {
        return this.jobTypeArray && this.jobTypeArray.length > 0;
    }

    setformValue(type, index) {
        if (type !== 'jobtype') {
            const officersData = {};
            this.currentProduct.officersCoverage.forEach(
                (officers, officerIndex) => {
                    if (
                        officerIndex === index &&
                        officers.incExcCode === type
                    ) {
                        officersData['nameControl'] = officers.name;
                        officersData['jobTitleControl'] = officers.title;
                        officersData['entityTypeControl'] =
                            officers.entityTypeCode;
                    }
                }
            );
            return officersData;
        } else {
            const jobTypeValues = {};
            this.currentProduct.jobType.workCompRateClasses.forEach(
                (ProductJobType, jobTypeindex) => {
                    if (jobTypeindex === index) {
                        jobTypeValues['jobTypeValues'] =
                            ProductJobType.jobTypeData;
                        jobTypeValues['ftEmpControl'] =
                            ProductJobType.fullTimeEmployeeCount;
                        jobTypeValues['ftEmpSliderControl'] =
                            ProductJobType.fullTimeEmployeeCount;
                        jobTypeValues['payrollControl'] =
                            ProductJobType.exposure;
                        jobTypeValues['ptEmpControl'] =
                            ProductJobType.partTimeEmployeeCount || 0;
                        jobTypeValues['ptEmpSliderControl'] =
                            ProductJobType.partTimeEmployeeCount;
                    }
                }
            );
            return jobTypeValues;
        }
    }

    buildInitialEmpLimits() {
        this.commonCommunicationService.empLimits = {
            totalEmployees: this.totalEmployees,
            limitConsumed: 0,
            consumption: [],
        };
    }

    showBlock(ev, formCategory, empLimits: EmpLimits) {
        if (formCategory === 'include') {
            this._analytics.eventTrack.next({
                category: 'JobType',
                action: 'USER_INPUT',
                label: ev.value
                    ? 'Include Owners/Officers - Checked'
                    : 'Include Owners/Officers - UnChecked',
            });
        } else {
            this._analytics.eventTrack.next({
                category: 'JobType',
                action: 'USER_INPUT',
                label: ev.value
                    ? 'Exclude Officers/Workers - Checked'
                    : 'Exclude Officers/Workers - UnChecked',
            });
        }

        if (ev.value) {
            // include checked
            this.includeCheckError = false;
            this.excludeCheckError = false;
            this.addBlock(formCategory, empLimits, ev);
            this.ifShowContinue = false;
        } else {
            // Include unckecked
            this.removeBlock(formCategory, empLimits);
            if (
                this.formviewState.excludeBlock.isAdded ||
                this.formviewState.includeBlock.isAdded
            ) {
                this.setFormValid();
            } else {
                if (
                    this.state === 'CA' &&
                    this.showCAInfo &&
                    this.CAWaiverChecked &&
                    this.commonCommunicationService.caWaiverSectionChecked &&
                    this.checkFormviewState()
                ) {
                    this.ifShowContinue = true;
                } else {
                    this.ifShowContinue = false;
                }
            }
        }
    }

    addBlock(
        context: string,
        empLimits: EmpLimits,
        control?: FormControl
    ): void {
        // Check for CA
        if (context === 'exclude' && this.state === 'CA') {
            this.showCAInfo = true;
        }

        if (!(context === 'exclude' && this.state === 'CA')) {
            const contextBlock = this.getContextBlock(context);
            const newTile = {
                type: context,
                totalPayroll: this.totalPayroll.value,
                entityTypeList: this.entityTypeValues
                    ? this.entityTypeValues[context + 'Entities']
                    : [],
                jobtypeListUrl: this.listOfJobTypeUrl,
                titleList: this.execTitles,
                totalEmployees: this.totalEmployees,
            };
            contextBlock.tiles.push(newTile);
            contextBlock.isAdded = true;
        }

        if (!this.formviewState.jobTypeBlock.isAdded) {
            const newjobTypeTile = {
                type: 'jobtype',
                totalPayroll: this.totalPayroll.value,
                titleList: this.entityTypeValues,
                jobtypeListUrl: this.listOfJobTypeUrl,
                totalEmployees: this.totalEmployees,
            };
            this.formviewState.jobTypeBlock.tiles.push(newjobTypeTile);
            this.formviewState.jobTypeBlock.isAdded = true;
        }

        const ccsEmpLimits = empLimits;
        // if consumption.length === 0, its new scenario
        if (ccsEmpLimits.consumption.length === 0) {
            ccsEmpLimits.limitConsumed = 0;
            ccsEmpLimits.consumption.push({
                type: 'jobtype',
                value: 0,
                id: 'jobtype0',
            });
        }
    }

    removeBlock(context: string, empLimits: EmpLimits): void {
        if (context === 'exclude') {
            this.showCAInfo = false;
            this.CAWaiverChecked = false;
            this.commonCommunicationService.caWaiverSectionChecked = false;
        }
        const contextBlock = this.getContextBlock(context);
        contextBlock.tiles = [];
        contextBlock.isAdded = false;
        const ccsEmpLimits = this.commonCommunicationService.empLimits;
        const removeIndex = [];
        ccsEmpLimits.consumption.forEach((ele, index) => {
            if (ele.type === context) {
                removeIndex.push(index);
            }
        });
        for (let i = ccsEmpLimits.consumption.length - 1; i >= 0; i--) {
            removeIndex.forEach((index) => {
                if (i === index) {
                    ccsEmpLimits.limitConsumed =
                        ccsEmpLimits.limitConsumed -
                        ccsEmpLimits.consumption[i].value;
                    ccsEmpLimits.consumption.splice(i, 1);
                }
            });
        }
        // Check if all include or exclude removed, if yes empty the consumption Array
        let isNonJBPresent = false;
        ccsEmpLimits.consumption.forEach((limit) => {
            if (limit.type !== 'jobtype') {
                isNonJBPresent = true;
            }
        });
        if (!isNonJBPresent) {
            ccsEmpLimits.consumption = [];
            ccsEmpLimits.limitConsumed = 0;
        }
        this.checkFormviewState();
    }

    addNewBlock(
        type: string,
        context: string,
        ccsEmpLimits: EmpLimits,
        ccsPayrollLimits: PayrollLimits,
        index: number
    ): void {
        const currentBlock = this.formviewState[type];
        const newTile = {
            type: context,
            totalPayroll: this.totalPayroll.value,
            titleList: this.execTitles,
            jobtypeListUrl: this.listOfJobTypeUrl,
            totalEmployees: this.totalEmployees,
            entityTypeList: this.companyStructure,
        };
        currentBlock.tiles.push(newTile);
        /**
         * Recalculate emp limits
         */

        const currentBlockLimit = 0;

        ccsEmpLimits.consumption.push({
            type: context,
            value: currentBlockLimit,
            id: context + (currentBlock.tiles.length - 1),
        });
        const newConsumption = ccsEmpLimits.limitConsumed + currentBlockLimit;
        ccsEmpLimits.limitConsumed = newConsumption;

        if (context === 'jobtype') {
            ccsPayrollLimits.maxPayrolls.push({
                id: context + (index + 1),
                value: 0,
            });
        }
        this.setUserInputs();
        this.checkFormviewState();
    }

    getContextBlock(context): JobtypeViewBlock {
        let contextBlock;
        if (context === 'include') {
            contextBlock = this.formviewState.includeBlock;
        } else if (context === 'exclude') {
            contextBlock = this.formviewState.excludeBlock;
        } else {
            contextBlock = this.formviewState.jobTypeBlock;
        }
        return contextBlock;
    }

    setInitialFormSubscriptions(): void {
        this.jobTypeFormGroup.valueChanges.subscribe((value) => {
            this.disabled = false;
            this.expanded = true;
        });
    }

    jobArrayValues(object) {
        return Object.values(object);
    }

    setProductIndex() {
        this._activatedRoute.params.subscribe((param) => {
            if (param && param['productIndex']) {
                this.productIndex = param['productIndex'];
            }
        });
    }

    validatePage(ccsEmpLimits: EmpLimits) {
        /**
         * Check for employee limits
         */
        if (ccsEmpLimits.totalEmployees < ccsEmpLimits.limitConsumed) {
            this.empLimitError = true;
        } else {
            this.empLimitError = false;
        }

        /**
         * Check include / exclude checkboxes
         */
        if (
            this.entityTypeValues &&
            this.entityTypeValues.allowInclude &&
            !this.includeOfficers.value
        ) {
            this.includeCheckError = true;
        } else {
            this.includeCheckError = false;
            this.excludeCheckError = false;
        }
        if (
            this.entityTypeValues &&
            this.entityTypeValues.allowExclude &&
            !this.excludeOfficers.value
        ) {
            this.excludeCheckError = true;
        } else {
            this.includeCheckError = false;
            this.excludeCheckError = false;
        }

        if (
            !this.empLimitError &&
            !(this.includeCheckError && this.excludeCheckError)
        ) {
            this.addEmpOrOfficerDetails();
        } else {
            this._analytics.eventTrack.next({
                category: 'JobType',
                action: 'INVALID_DETAILS',
                label: 'Invalid details',
            });
        }
    }

    goToNextPage($event) {
        this._analytics.eventTrack.next({
            category: 'JobType',
            action: 'BUTTON_CLICK',
            label: 'Submitted employee job type',
        });

        this._router.navigate([
            '/employer-liability-limits',
            this.productIndex,
        ]);
    }

    addEmpOrOfficerDetails(navigateNextPage: boolean = true) {
        this.currentProduct['officersCoverage'] = [];
        this.currentProduct['jobType'] = {};
        this.currentProduct['jobType']['workCompRateClasses'] = [];
        let isGoAvailable = true;

        // Include
        this.formviewState.includeBlock.tiles.forEach((tile) => {
            if (tile.formValues) {
                // const eachJobTypeData = new WorkCompRateClass();
                const coverageObj = new OfficersCoverage();

                coverageObj.name = tile.formValues.nameControl;
                coverageObj.title = tile.formValues.jobTitleControl;
                coverageObj.incExcCode = 'I';
                coverageObj.state = this.state;
                coverageObj.entityTypeCode = tile.formValues.entityTypeControl;
                this.currentProduct['officersCoverage'].push(coverageObj);
            } else {
                this.commonCommunicationService.recheckWcForms.next(true);
                isGoAvailable = false;
            }
        });
        this.formviewState.excludeBlock.tiles.forEach((tile) => {
            if (tile.formValues) {
                const coverageObj = new OfficersCoverage();
                coverageObj.name = tile.formValues.nameControl;
                coverageObj.title = tile.formValues.jobTitleControl;
                coverageObj.incExcCode = 'E';
                coverageObj.state = this.state;
                coverageObj.entityTypeCode = tile.formValues.entityTypeControl;
                this.currentProduct['officersCoverage'].push(coverageObj);
            } else {
                this.commonCommunicationService.recheckWcForms.next(true);
                isGoAvailable = false;
            }
        });
        this.formviewState.jobTypeBlock.tiles.forEach((tile) => {
            if (tile.formValues && tile.formValues.payrollControl !== '0') {
                const eachJobTypeData = new WorkCompRateClass();
                eachJobTypeData.fullTimeEmployeeCount =
                    tile.formValues.ftEmpControl || 0;
                eachJobTypeData.partTimeEmployeeCount =
                    tile.formValues.ptEmpControl || 0;

                eachJobTypeData.exposure = tile.formValues.payrollControl;

                if (this.checkJobtypeArray()) {
                    const result = this.jobTypeArray.filter(
                        (item) =>
                            tile.formValues.jobTypeControl.classCode ===
                                item.classCode &&
                            tile.formValues.jobTypeControl.descriptionId ===
                                item.descriptionId
                    );

                    if (result && result.length > 0) {
                        eachJobTypeData.wcClassCode = result[0].classCode;
                        eachJobTypeData.wcClassDescId = result[0].descriptionId;
                        eachJobTypeData.jobTypeData = result[0];
                    }
                }

                eachJobTypeData.category = 'commonJobType';
                this.currentProduct['jobType']['workCompRateClasses'].push(
                    eachJobTypeData
                );
            } else {
                this.commonCommunicationService.recheckWcForms.next(true);
                isGoAvailable = false;
            }
        });
        this._store.dispatch(new SetProductsAction(this.allProducts));
        if (isGoAvailable && this.ifShowContinue) {
            if (navigateNextPage) {
                this.goToNextPage(null);
            }
        } else {
            // show CA error
            if (
                this.state === WorkersCompJobTypeComponent.STATE_CA &&
                this.showCAInfo &&
                !this.CAWaiverChecked
            ) {
                this.caWaiverError = true;
            }
        }
    }

    convertToString(val) {
        if (val) {
            return val.toString();
        } else {
            return '';
        }
    }

    downloadWaiverForm() {
        this._analytics.eventTrack.next({
            category: 'CA Waiver',
            action: 'USER_INPUT',
            label: 'CA Waiver Form',
        });

        if (this.isDesktopDevice) {
            let headers = new HttpHeaders();
            headers = headers.set('Accept', 'application/pdf');
            return this.http
                .get(environment.caWaiverURL, {
                    headers: headers,
                    responseType: 'blob',
                })
                .subscribe((data) => {
                    const blob = new Blob([data], { type: 'application/pdf' });
                    saveAs(blob, 'Chubb_CA_Waiver_Form.pdf', '_blank');
                });
        } else if (this.isMobiledevice || this.isTabletDevice) {
            window.open(environment.caWaiverURL, '_blank');
        }
    }

    setUserInputs(formValue?, tile?) {
        if (tile && formValue) {
            tile.formValues = formValue;
        }
        // hideFlag = false;
        /**
         * Check if we have sufficient information to show continue
         */
        // let tileInFormValues = 0;
        const formviewState: boolean = this.checkFormviewState();
        // let showContinue = false;

        if (this.state === 'CA' && this.showCAInfo) {
            if (!this.CAWaiverChecked) {
                this.ifShowContinue = false;
            } else {
                // this.ifShowContinue = this.jobTypeValid && this.officerValid;
                this.ifShowContinue = formviewState;
            }
        } else {
            // this.ifShowContinue = this.jobTypeValid && this.officerValid;
            this.ifShowContinue = formviewState;
        }
    }

    checkFormviewState(): boolean {
        let valid = false;
        const isValid = [];
        let includesOfficer = false;
        let includesJobType = false;
        Object.keys(this.formviewState).forEach((key) => {
            const block = this.formviewState[key];
            if (block.isAdded) {
                block.tiles.forEach((tile) => {
                    // Below is the same as the emp-tile function isFormValid()
                    // After merging, that code should be removed for this code instead
                    const formValues = tile.formValues;
                    let validFlag = false;
                    if (formValues) {
                        if (tile && tile.type === 'include') {
                            includesOfficer = true;
                            validFlag =
                                !!formValues.nameControl &&
                                !!formValues.jobTitleControl &&
                                !!formValues.entityTypeControl;
                        } else if (tile && tile.type === 'exclude') {
                            includesOfficer = true;
                            validFlag =
                                !!formValues.nameControl &&
                                !!formValues.jobTitleControl &&
                                !!formValues.entityTypeControl;
                        } else {
                            includesJobType = true;
                            validFlag =
                                !!formValues.jobTypeControl &&
                                !!formValues.payrollControl &&
                                formValues.payrollControl !== '0' &&
                                (formValues.ftEmpControl > 0 ||
                                    formValues.ptEmpControl > 0);
                        }
                    }
                    isValid.push(validFlag);
                });
            }
        });
        valid =
            isValid.filter((each) => each).length === isValid.length &&
            includesOfficer &&
            includesJobType;
        if (
            !includesOfficer &&
            includesJobType &&
            this.state === 'CA' &&
            this.showCAInfo &&
            this.CAWaiverChecked &&
            isValid.filter((each) => each).length === isValid.length
        ) {
            valid = true;
        }
        return valid;
    }

    setJobTypeValid(ev) {
        this.jobTypeValid = ev;
    }

    setOfficerValid(ev) {
        this.officerValid = ev;
    }

    closeTile(
        block: string,
        tileIndex: number,
        ccsEmpLimits: EmpLimits,
        ccsPayrollLimits: PayrollLimits
    ): void {
        const currentBlock = this.formviewState[block];
        currentBlock.tiles.splice(tileIndex, 1);
        // Recalculate emp consumption and payroll consumption
        const removeId = block.replace('Block', '').toLowerCase() + tileIndex;
        ccsEmpLimits.consumption = ccsEmpLimits.consumption.filter(
            (limit) => limit.id !== removeId
        );
        let newLimitConsumed = 0;
        ccsEmpLimits.consumption.forEach((limit) => {
            newLimitConsumed = newLimitConsumed + limit.value;
        });

        ccsEmpLimits.limitConsumed = newLimitConsumed;
        this.commonCommunicationService.empLimits = ccsEmpLimits;

        ccsPayrollLimits.maxPayrolls = ccsPayrollLimits.maxPayrolls.filter(
            (limit) => limit.id !== removeId
        );
        this.commonCommunicationService.payrollLimits = ccsPayrollLimits;
        currentBlock.tiles.forEach((tile) =>
            this.setUserInputs(tile.formValues, tile)
        );
        this.checkFormviewState();
        // this.setUserInputs();
    }

    setFormInvalid(tile?: JobtypeTile) {
        if (tile) {
            tile.formValues = null;
        }
        this.ifShowContinue = false;
    }

    setFormValid() {
        this.ifShowContinue = true;
    }

    acceptCAViewer(ev, ccsEmpLimits: EmpLimits) {
        if (ev.checked) {
            this.caWaiverError = false;
            this.CAWaiverChecked = true;
            this.commonCommunicationService.caWaiverSectionChecked = true;
            if (!this.formviewState.jobTypeBlock.isAdded) {
                const newjobTypeTile = {
                    type: 'jobtype',
                    totalPayroll: this.totalPayroll.value,
                    titleList: this.entityTypeValues,
                    jobtypeListUrl: this.listOfJobTypeUrl,
                    totalEmployees: this.totalEmployees,
                };
                this.formviewState.jobTypeBlock.tiles.push(newjobTypeTile);
                this.formviewState.jobTypeBlock.isAdded = true;
            }
        } else {
            this.CAWaiverChecked = false;
            this.commonCommunicationService.caWaiverSectionChecked = false;
        }
        this.setUserInputs(ev.checked, []);

        this._analytics.eventTrack.next({
            category: 'CA Waiver',
            action: 'USER_INPUT',
            label: this.CAWaiverChecked
                ? 'CA Waiver Acknowledgement - Checked'
                : 'CA Waiver Acknowledgement - UnChecked',
        });
    }
    buildJobTypeData() {
        this.setPayrollLimitsFromProducts();
        let JobTypelist;
        const payload = {};
        payload['stateCode'] = this.state;
        payload['partnerName'] = 'csb.com';
        this._wcJobTypeService
            .getMarketPlaceJobCodes(payload)
            .subscribe((data) => {
                JobTypelist = data;
                if (JobTypelist) {
                    this.currentProduct.jobType.workCompRateClasses.forEach(
                        (ProductJobType) => {
                            const jobType = filter(JobTypelist, (listValue) => {
                                return listValue.classCode ===
                                    ProductJobType.wcClassCode &&
                                    listValue.descriptionId ===
                                        ProductJobType.wcClassDescId
                                    ? listValue
                                    : null;
                            });
                            ProductJobType['category'] = 'commonJobType';
                            ProductJobType.jobTypeData = jobType[0];
                        }
                    );
                }
                this.checkForBuildDirectView();
            });
    }

    setPayrollLimitsFromProducts() {
        let ccsPayrollLimits = this.commonCommunicationService.payrollLimits;
        if (!ccsPayrollLimits) {
            ccsPayrollLimits = new PayrollLimits();
            ccsPayrollLimits.totalPayroll = this.totalPayroll.value;
            ccsPayrollLimits.maxPayrolls = [];
            this.currentProduct.jobType.workCompRateClasses.forEach(
                (jobtypeVal, index) => {
                    const jobtypeLimit = {
                        id: 'jobtype' + index,
                        value: jobtypeVal.exposure,
                    };
                    ccsPayrollLimits.maxPayrolls.push(jobtypeLimit);
                }
            );
            this.commonCommunicationService.payrollLimits = ccsPayrollLimits;
        }
    }

    updatePayroll(amt: string) {
        const newPayroll = coerceNumberProperty(amt.replace(/,/g, ''));

        // Update payroll in questions
        this.questions.forEach((questionGroup) => {
            if (questionGroup.group_name === 'Additional Information') {
                questionGroup.questions.forEach((question) => {
                    if (
                        question.question_id === '8' ||
                        question.question_id === 8
                    ) {
                        question.value = newPayroll;
                    }
                });
            }
        });
        this._store.dispatch(new SetQuestionsAction(this.questions));

        // Update payroll in simpleQuestionsObj
        this.simpleQuestionsObj.qid_8.value = newPayroll;
        this._store.dispatch(
            new SetSimpleQuestionsObjAction(this.simpleQuestionsObj)
        );

        // Update payroll in CCS payroll limits
        this.commonCommunicationService.payrollLimits.totalPayroll = newPayroll;

        // broadcast the value to jobtype tiles
        this.formviewState.jobTypeBlock.tiles.forEach((tile) => {
            tile.totalPayroll = newPayroll;
        });
    }

    stateGuidelines() {
        this._analytics.eventTrack.next({
            category: 'State Statutory Guidelines',
            action: 'BUTTON_CLICK',
            label: 'State Statutory Guidelines',
        });
        this.dialog.open(DialogComponent, {
            width: '800px',
            data: {
                tag: 'STATE STATUTORY GUIDELINES',
                title: this.contents.State,
                content: this.contents.text,
                showEmail: false,
            },
        });
    }

    /**
     *  Before destroying the component it will update the store so that we don't loose any information.
     */
    ngOnDestroy(): void {
        this.addEmpOrOfficerDetails(false);
    }
}
