import { Action } from '@ngrx/store';

export enum RetrievedComProducerCodesActionTypes {
    ComProducerCodes = '[ set producer codes ] Set com producer codes from amp retrieve producer codes api ',
}

export class SetRetrievedComProducerCodesAction implements Action {
    readonly type = RetrievedComProducerCodesActionTypes.ComProducerCodes;

    constructor(public payload: any) {}
}

export type RetrievedComProducerCodesActionUnion = SetRetrievedComProducerCodesAction;
