import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsbQuestionComponent } from './csb-question.component';
import { CSBCoreModule } from '../../csb-core/csb-core.module';

@NgModule({
    declarations: [CsbQuestionComponent],
    imports: [CommonModule, CSBCoreModule],
    exports: [CsbQuestionComponent],
})
export class CsbQuestionModule {}
