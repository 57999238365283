import {
    RetrieveQuoteActionTypes,
    RetrieveQuoteActionUnion,
} from '../actions/retrievequote.action';

export let retrieveQuoteEmailInitialState: any;

export function retrieveQuoteEmailReducer(
    retrieveQuoteData = retrieveQuoteEmailInitialState,
    action: RetrieveQuoteActionUnion
) {
    switch (action.type) {
        // retrieveQuoteData Setter
        case RetrieveQuoteActionTypes.setRetrieveQuoteEmail: {
            const newRetrieveQuoteData = action.payload;
            return newRetrieveQuoteData;
        }

        default: {
            return retrieveQuoteData;
        }
    }
}
