import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketingProductStepsComponent } from './marketing-product-steps.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [MarketingProductStepsComponent],
    exports: [MarketingProductStepsComponent],
})
export class MarketingProductStepsModule {}
