import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@crux/services';
import { GAEvent } from '../../../csb-core/ga-event';

@Component({
    selector: 'app-homepage-blog-section',
    templateUrl: './homepage-blog-section.component.html',
    styleUrls: ['./homepage-blog-section.component.scss'],
})
export class HomepageBlogSectionComponent implements OnInit {
    constructor(public router: Router, private _analytics: AnalyticsService) {}

    ngOnInit() {}

    handleBlogs() {
        GAEvent.CapturEvent(
            this._analytics,
            GAEvent.ACTION.BUTTON_CLICK,
            GAEvent.CATEGORY.NAV_TO_BLOG,
            GAEvent.LABEL.Brouse_our_blogs
        );
    }

    handleClickQuote() {
        GAEvent.CapturEvent(
            this._analytics,
            GAEvent.ACTION.BUTTON_CLICK,
            GAEvent.CATEGORY.ProductSelection,
            GAEvent.LABEL.NEW_QUOTE
        );
        this.router.navigate(['/get-a-quote']);
    }
}
