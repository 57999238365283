import {
    Component,
    OnInit,
    HostListener,
    Inject,
    Output,
    EventEmitter,
} from '@angular/core';
import { AnalyticsService, CruxHttpClientService } from '@crux/services';
import { environment } from '../../../environments/environment';
import { CommonCommunicationService } from '../../services/common-communication.service';
import { DOCUMENT } from '@angular/common';
import { CallUsNumberModel } from '../../model/callUsNumber';
import navbarData from '../../../assets/json/nav-bar.json';
import { filter, find, isEmpty, isNil } from 'lodash';
import { AffinityPartnerFlags } from '../../model/affinity-partner';
import { Store, select } from '@ngrx/store';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    public logo: string;
    public logotext: string;
    public isActive: boolean = false;
    servicingPortalUrl = environment.servicePortalUrl;
    findAnAgentUrl = environment.findAnAgentUrl;
    public navfontcolor: string;
    notHidden = true;
    callUsNumber: CallUsNumberModel;
    public showMenuItemForPartner: boolean = true;
    navContent: any;
    blobURL = environment.blobStorageBaseURL;
    dataLoaded = false;
    currentURL: string;
    displayLeftNavsection = true;
    AffinityPartnerFlags: AffinityPartnerFlags;
    @Output() openHelpDrawer = new EventEmitter();

    constructor(
        public router: Router,
        private _analytics: AnalyticsService,
        public commonCommunicationService: CommonCommunicationService,
        private http: CruxHttpClientService,
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>,
        @Inject(DOCUMENT) document
    ) {
        router.events.subscribe((event: any) => {
            if (event.url === undefined) {
            } else if (
                event.url.includes('download') ||
                event.url.includes('print-disclosure')
            ) {
                this.notHidden = false;
            } else {
                this.notHidden = true;
            }

            if (event instanceof NavigationEnd) {
                this.currentURL = event.url;
                this.showLeftNavItems();
            }
        });

        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.AffinityPartnerFlags = AffinityPartnerFlags;
                        if (this.AffinityPartnerFlags.partnercode === 'CISA') {
                            var styleElem = document.head.appendChild(
                                document.createElement('style')
                            );
                            styleElem.innerHTML =
                                '.navbar-logotext {display: block !important; font-size: 14px; margin: 0 0 0 189px;}';
                            var styleElema = document.head.appendChild(
                                document.createElement('style')
                            );
                            styleElema.innerHTML =
                                '.navbar .menu-icon .navicon-white {display: none !important;}';

                            var menuElement = document.head.appendChild(
                                document.createElement('style')
                            );
                            menuElement.innerHTML =
                                '.navbar-menu-items {padding: 5px 0px 0px;}';

                            var iconElement = document.head.appendChild(
                                document.createElement('style')
                            );
                            iconElement.innerHTML =
                                '.navbar .menu-icon {padding: 30px 20px !important;}';
                            var navLogo = document.head.appendChild(
                                document.createElement('style')
                            );
                            navLogo.innerHTML =
                                '.navbar-logo {cursor: default !important;}';
                        }
                    }
                }
            });
    }

    ngOnInit() {
        const ts = new Date().getTime();
        this.http
            .get(this.blobURL + '/data/nav-bar.json?ts=' + ts, {
                responseType: 'json',
            })
            .subscribe((res) => {
                if (!isNil(res) && !isEmpty(res)) {
                    this.navContent = res;
                    this.dataLoaded = true;
                    this.showLeftNavItems();
                } else {
                    this.navContent = navbarData;
                    this.dataLoaded = true;
                    this.showLeftNavItems();
                }
            });
        this.navfontcolor = this.commonCommunicationService.navfontcolor;
        const items = document.getElementsByClassName('menu-item-has-children');
    }

    toggleDropDown(): void {
        document.getElementById('menu-btn')['checked'] = false;
    }
    handleClick() {
        if (document.getElementById('menu-btn')['checked']) {
            this.toggleDropDown();
        }
    }

    handleClickQuote(url, loadOnSamePage, navAction) {
        if (!isEmpty(url) && !isNil(url)) {
            if (url.startsWith('/')) {
                this.router.navigateByUrl(url);
            } else {
                if (loadOnSamePage) {
                    if (navAction === 'Logout') {
                        localStorage.clear();
                        sessionStorage.clear();
                    }
                    window.open(url, '_self');
                } else {
                    window.open(url);
                }
            }
        } else if (
            (isEmpty(url) || isNil(url)) &&
            navAction &&
            !isNil(navAction) &&
            !isEmpty(navAction)
        ) {
            if (navAction === 'Get help') {
                this.openHelpDrawer.emit();
            }
        }
        if (document.getElementById('menu-btn')['checked']) {
            this.toggleDropDown();
        }
    }

    showLeftNavItems() {
        if (
            this.navContent &&
            this.navContent.partnerSpecificStyles &&
            this.navContent.partnerSpecificStyles.length > 0
        ) {
            if (
                find(this.navContent.partnerSpecificStyles, [
                    'name',
                    this.commonCommunicationService.partnerSource,
                ])
            ) {
                this.navContent.partnerSpecificStyles.forEach((partner) => {
                    if (
                        partner.name ===
                        this.commonCommunicationService.partnerSource
                    ) {
                        if (
                            partner.hideLeftNavItems &&
                            !isNil(this.router.url) &&
                            !isEmpty(this.router.url) &&
                            (this.router.url === '/' ||
                                this.router.url.includes('partnercode'))
                        ) {
                            this.displayLeftNavsection = false;
                        } else {
                            this.displayLeftNavsection = true;
                        }
                    }
                });
            } else {
                this.displayLeftNavsection = true;
            }
        } else {
            this.displayLeftNavsection = true;
        }
    }

    navigateHome() {
        if (
            this.AffinityPartnerFlags &&
            this.AffinityPartnerFlags.partnercode &&
            !isNil(this.AffinityPartnerFlags.partnercode) &&
            !isEmpty(this.AffinityPartnerFlags.partnercode) &&
            this.AffinityPartnerFlags.partnercode === 'CISA'
        ) {
            return;
        } else {
            this._analytics.eventTrack.next({
                category: 'ApplNAVBARcationReview',
                action: 'NAVBAR_ITEM_CLICK',
                label: 'Home',
            });
            this.router.navigate(['/']);
        }
    }

    showLogoForPartner() {
        if (
            this.commonCommunicationService.PartnerId ===
                CommonCommunicationService.PARTNER_BOLDPENGUIN ||
            this.commonCommunicationService.PartnerId ===
                CommonCommunicationService.PARTNER_BOLDPENGUIN_NONAMAZON ||
            this.commonCommunicationService.PartnerId ===
                CommonCommunicationService.PARTNER_BRIZA
        ) {
            this.showMenuItemForPartner = false;
            return false;
        } else {
            this.showMenuItemForPartner = true;
            return true;
        }
    }

    logoURL(color) {
        if (
            this.navContent &&
            this.navContent.partnerSpecificStyles &&
            this.navContent.partnerSpecificStyles.length > 0 &&
            find(this.navContent.partnerSpecificStyles, [
                'name',
                this.commonCommunicationService.partnerSource,
            ])
        ) {
            this.navContent.partnerSpecificStyles.forEach((partner) => {
                if (
                    partner.name ===
                    this.commonCommunicationService.partnerSource
                ) {
                    if (
                        partner.navColor &&
                        !isNil(partner.navColor) &&
                        !isEmpty(partner.navColor)
                    ) {
                        let backgroundStyleElem = document.head.appendChild(
                            document.createElement('style')
                        );
                        backgroundStyleElem.innerHTML =
                            '.navbar, .navbar-menu-items-dropdown, .navbar-menu-items-dropdown-items-dropdown, .menu-btn:checked~.menu, input:checked~ul.sub-menu, input:checked~label {background-color: ' +
                            partner.navColor +
                            ' !important;}';
                    }
                    if (
                        partner.navBorderColor &&
                        !isNil(partner.navBorderColor) &&
                        !isEmpty(partner.navBorderColor)
                    ) {
                        let borderStyleElem = document.head.appendChild(
                            document.createElement('style')
                        );
                        borderStyleElem.innerHTML =
                            '.navbar {border-bottom: 4px solid ' +
                            partner.navBorderColor +
                            ' !important;}';
                    }
                    if (
                        partner.navItemsColor &&
                        !isNil(partner.navItemsColor) &&
                        !isEmpty(partner.navItemsColor)
                    ) {
                        let logoelement = document.head.appendChild(
                            document.createElement('style')
                        );
                        logoelement.innerHTML =
                            '.navbar-logotext {color: ' +
                            partner.navItemsColor +
                            ' !important;}';
                        logoelement.style.color = partner.navItemsColor;
                        let textStyleElem = document.head.appendChild(
                            document.createElement('style')
                        );
                        textStyleElem.innerHTML =
                            '.navbar-menu-items-links, .navbar-menu-items-dropdown-items-dropdown-items-links {color: ' +
                            partner.navItemsColor +
                            ' !important;}';
                        let linkStyleElem = document.head.appendChild(
                            document.createElement('style')
                        );
                        linkStyleElem.innerHTML =
                            'li:hover a:hover, li:hover label {border-bottom: 3px solid ' +
                            partner.navItemsColor +
                            ' !important;}';
                    }
                    if (
                        partner.logoWidth &&
                        !isNil(partner.logoWidth) &&
                        !isEmpty(partner.logoWidth)
                    ) {
                        let logoStyleElem = document.head.appendChild(
                            document.createElement('style')
                        );
                        logoStyleElem.innerHTML =
                            '.navbar-logo-chubb {width: ' +
                            partner.logoWidth +
                            ' !important; height: ' +
                            partner.logoHeight +
                            ' !important; padding-top: ' +
                            partner.topPadding +
                            ' !important;}';
                    }
                    if (
                        partner.logo &&
                        !isNil(partner.logo) &&
                        !isEmpty(partner.logo)
                    ) {
                        this.logo =
                            environment.blobStorageBaseURL + partner.logo;
                    } else {
                        if (color === 'black') {
                            this.logo =
                                '../../assets/logos/CHUBB_Logo_Black_RBG.png';
                            const logoelement = document.getElementById(
                                'navbar-logotext'
                            );
                            logoelement.classList.remove('blackfontcolor');
                            const navbarlinkelement = document.getElementsByClassName(
                                'navbar-menu-items-links'
                            );
                            for (let i = 0; i < navbarlinkelement.length; i++) {
                                navbarlinkelement[i].classList.remove(
                                    'blackfontcolor'
                                );
                            }
                        } else {
                            this.logo =
                                '../../assets/logos/CHUBB_Logo_Black_RBG.png';
                            const logoelement = document.getElementById(
                                'navbar-logotext'
                            );
                            logoelement.classList.add('blackfontcolor');
                            const navbarlinkelement = document.getElementsByClassName(
                                'navbar-menu-items-links'
                            );
                            for (let i = 0; i < navbarlinkelement.length; i++) {
                                navbarlinkelement[i].classList.add(
                                    'blackfontcolor'
                                );
                            }
                        }
                    }
                }
            });
        } else {
            if (color === 'black') {
                this.logo = '../../assets/logos/CHUBB_Logo_Black_RBG.png';
                const logoelement = document.getElementById('navbar-logotext');
                logoelement.classList.remove('blackfontcolor');
                const navbarlinkelement = document.getElementsByClassName(
                    'navbar-menu-items-links'
                );
                for (let i = 0; i < navbarlinkelement.length; i++) {
                    navbarlinkelement[i].classList.remove('blackfontcolor');
                }
            } else {
                this.logo = '../../assets/logos/CHUBB_Logo_Black_RBG.png';
                const logoelement = document.getElementById('navbar-logotext');
                logoelement.classList.add('blackfontcolor');
                const navbarlinkelement = document.getElementsByClassName(
                    'navbar-menu-items-links'
                );
                for (let i = 0; i < navbarlinkelement.length; i++) {
                    navbarlinkelement[i].classList.add('blackfontcolor');
                }
            }
        }
        return this.logo;
    }

    logoText() {
        if (
            this.navContent &&
            this.navContent.partnerSpecificStyles &&
            this.navContent.partnerSpecificStyles.length > 0 &&
            find(this.navContent.partnerSpecificStyles, [
                'name',
                this.commonCommunicationService.partnerSource,
            ])
        ) {
            this.navContent.partnerSpecificStyles.forEach((partner) => {
                if (
                    partner.name ===
                    this.commonCommunicationService.partnerSource
                ) {
                    this.logotext = partner.logoText;
                }
            });
        } else {
            this.logotext = this.navContent.logoText;
        }
        return this.logotext;
    }

    getShowMenuItemForPartner(notEligiblePartners, contactInfo?): boolean {
        if (contactInfo && contactInfo.length > 0) {
            contactInfo.forEach((item) => {
                let telLink: string;
                if (
                    item.partner ===
                    this.commonCommunicationService.partnerSource
                ) {
                    this.callUsNumber.phoneNumber = item.number;
                    telLink = item['number'].replace(/\D/g, '');
                    this.callUsNumber.phoneNumberLink = 'tel:+1' + telLink;
                } else {
                    this.callUsNumber = this.commonCommunicationService.callUsNumber();
                }
            });
        } else {
            this.callUsNumber = this.commonCommunicationService.callUsNumber();
        }
        if (
            this.commonCommunicationService.PartnerId !==
                CommonCommunicationService.PARTNER_BOLDPENGUIN &&
            this.commonCommunicationService.PartnerId !==
                CommonCommunicationService.PARTNER_BOLDPENGUIN_NONAMAZON &&
            this.commonCommunicationService.PartnerId !==
                CommonCommunicationService.PARTNER_BRIZA
        ) {
            if (
                notEligiblePartners &&
                notEligiblePartners.length > 0 &&
                notEligiblePartners.indexOf(
                    this.commonCommunicationService.partnerSource
                ) > -1
            ) {
                this.showMenuItemForPartner = false;
                return false;
            } else {
                this.showMenuItemForPartner = true;
                return true;
            }
        } else if (
            this.commonCommunicationService.PartnerId ===
                CommonCommunicationService.PARTNER_BOLDPENGUIN ||
            this.commonCommunicationService.PartnerId ===
                CommonCommunicationService.PARTNER_BOLDPENGUIN_NONAMAZON ||
            this.commonCommunicationService.PartnerId ===
                CommonCommunicationService.PARTNER_BRIZA
        ) {
            if (
                notEligiblePartners &&
                notEligiblePartners.length > 0 &&
                notEligiblePartners.indexOf(
                    this.commonCommunicationService.PartnerId
                ) > -1
            ) {
                this.showMenuItemForPartner = false;
                return false;
            } else {
                this.showMenuItemForPartner = true;
                return true;
            }
        } else {
            this.showMenuItemForPartner = true;
            return true;
        }
    }
}
