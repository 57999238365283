import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash';
import { environment } from '../../environments/environment';

@Pipe({
    name: 'dateEST',
})
export class DateESTPipe implements PipeTransform {
    static readonly MILLISECOND = 60000;

    transform(value: Date, format: string): string {
        const millisecondUTC =
            value.getTime() + this.getTimeZoneOffset(value.getTimezoneOffset());
        if (environment.isUTCDate) {
            value.setTime(millisecondUTC);
        } else {
            const timeZoneOffsetEST = environment.isESTDayLight ? 240 : 300;
            const millisecondEST =
                millisecondUTC - this.getTimeZoneOffset(timeZoneOffsetEST);
            value.setTime(millisecondEST);
        }
        return formatDate(value, format, 'en-US');
    }

    getTimeZoneOffset(offset: number): number {
        return offset * DateESTPipe.MILLISECOND;
    }
}
