import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Answer } from './model/answer';
import { QuestionBase } from '../csb-question/csb-question-base.component';
import { GAEvent } from '../../csb-core/ga-event';
import { AnalyticsService } from '@crux/services';
import {
    FormControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CommonCommunicationService } from '../../services/common-communication.service';

@Component({
    selector: 'app-csb-website',
    templateUrl: './csb-website.component.html',
    styleUrls: ['./csb-website.component.scss'],
})
export class CsbWebsiteComponent extends QuestionBase implements OnInit {
    @Input() name: string;
    @Input() selectButton: string;
    @Output() answer: EventEmitter<string> = new EventEmitter<string>();
    WebaddressForm: FormGroup;
    websiteaddress: FormControl;
    yes: string;
    no: string;
    cssClass: Array<string>;
    componentClass: Array<string>;
    showWebAddress = false;
    showinfoIcon: boolean = false;
    offSet: number = -17;
    urlPattern = /([\w@?^=%&amp;\/~+#-]+\.)+(com|edu|ai|org|net|biz|gov|edu|ca|mil|au|io)/;

    constructor(
        private _fb: FormBuilder,
        private _analytics: AnalyticsService,
        private router: Router,
        public _ccs: CommonCommunicationService,
    ) {
        super();
    }

    ngOnInit() {
        this.yes = Answer.Yes;
        this.no = Answer.No;
        this.cssClass = this.showImage
            ? ['col-4', 'col-md-4', 'col-sm-6']
            : ['col-7', 'col-md-7', 'col-sm-6'];

        this.componentClass = this.showImage
            ? ['col-5', 'col-md-5', 'col-sm-12']
            : ['col-5', 'col-md-5', 'col-sm-6'];

        this.websiteaddress = new FormControl('',[Validators.required]);
        this.WebaddressForm = this._fb.group({
            websiteaddress: this.websiteaddress,
        });

        if (this.data.value.isWebsite && this.data.value.isWebsite == 'Yes') {
            this.selectButton = this.data.value.isWebsite;    
            this.showWebAddress = true;
            this.websiteaddress.setValue(this.data.value.webSiteAddress);        
        }else{
            this.selectButton = this.data.value;
        }

        if (this.router.url.indexOf('/get-a-quote') > -1) {
            this.showinfoIcon = true;
        }
    }

    buttonHandler(event: MouseEvent) {
        this.answer.emit(this.selectButton);
        if (this.gaevent) {
            GAEvent.CapturEvent(
                this._analytics,
                this.gaevent.action,
                this.gaevent.category,
                this.question + ':' + this.selectButton
            );
        }
        if(this.selectButton === 'Yes'){
            this.showWebAddress = true;
            this.valid(false, this.selectButton);
        }else{
            this.showWebAddress = false;
            this.websiteaddress.setValue('');
            this.valid(true, this.selectButton);
        }
    }

    checkURLValue(e) {
        if (!this.urlPattern.test(e.target.value)) {
            this.WebaddressForm.get('websiteaddress').setErrors({ invalid: true });
        }else{
            let webSiteAnswer = [];
            webSiteAnswer['isWebsite'] = this.selectButton;
            webSiteAnswer['webSiteAddress'] = e.target.value;
            this.valid(true, webSiteAnswer);
        }
    }
}
