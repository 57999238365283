import { Component, OnInit, HostListener } from '@angular/core';
import slidesContent from '../../../../assets/json/banner_slides.json';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { environment } from '../../../../environments/environment';
import { isEmpty, isNil, wrap } from 'lodash-es';
import { CruxHttpClientService } from '@crux/services';
import { AffinityPartnerFlags } from '../../../model/affinity-partner';
import { MatDialog } from '@angular/material/dialog';
import { SquarespaceModelComponent } from '../../../components/squarespace-model/squarespace-model.component';
import {
    SetAffinityPartnerInfoAction,
    SetProducerCodesAction,
} from '../../../sales-store/partners-redirection-store/actions';
import { Store, select } from '@ngrx/store';
@Component({
    selector: 'app-csb-banner',
    templateUrl: './csb-banner.component.html',
    styleUrls: ['./csb-banner.component.scss'],
})
export class CsbBannerComponent implements OnInit {
    public carouselItems: any;
    public hideCarousel = false;
    blobURL = environment.blobStorageBaseURL;

    constructor(
        private _routeParams: ActivatedRoute,
        private router: Router,
        private _ccs: CommonCommunicationService,
        private http: CruxHttpClientService,
        private _store: Store<{
            producerCodes: any;
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>,
        private dialog: MatDialog
    ) {}

    AffinityPartnerFlags: AffinityPartnerFlags = new AffinityPartnerFlags();

    ngOnInit() {
        this.carouselItems = slidesContent;
        const ts = new Date().getTime();
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
        if (window.scrollY <= 510) {
            this.hideCarousel = false;
        } else {
            this.hideCarousel = true;
        }
    }

    handleClickQuote() {
        this.router.navigate(['/get-a-quote']);
    }

    navigateTo() {
        this.router.navigate(['/retrieve-quote']);
    }
    scrollToQuestions() {
        this._ccs.scrollToQuestions.nativeElement.scrollIntoView({
            behavior: 'smooth',
        });
    }
    scrollToVideoCarousel() {
        this._ccs.scrollToVideosSection.nativeElement.scrollIntoView({
            behavior: 'smooth',
        });
    }
}
