import { Action } from '@ngrx/store';
import { QuoteStep } from '../../../model/quote-step';

export enum QuoteStepType {
    StepComplete = '[Quote Step] StepComplete',
    QuestionInsurance = '[Quote Step] QuestionInsurance',
    QuestionRevenue = '[Quote Step] QuestionRevenue',
    QuestionZipCode = '[Quote Step] QuestionZipCode',
    QuestionBusiness = '[Quote Step] QuestionBusiness',
    QuestionEmail = '[Quote Step] QuestionEmail',
    QuestionData = '[Quote Step] QuestionData',
    QuestionResetData = '[Quote Step] QuestionResetData',
    QuestionWebsite = '[Quote Step] QuestionWebsite',
}

export class QuoteStepAction implements Action {
    readonly type = QuoteStepType.StepComplete;

    constructor(public payload: QuoteStep) {}
}

export class QuestionDataAction implements Action {
    readonly type = QuoteStepType.QuestionData;

    constructor(public payload: QuoteStep) {}
}

export class QuestionInsuranceAction implements Action {
    readonly type = QuoteStepType.QuestionInsurance;

    constructor(public payload: QuoteStep) {}
}

export class QuestionWebsiteAction implements Action {
    readonly type = QuoteStepType.QuestionWebsite;

    constructor(public payload: QuoteStep) {}
}

export class QuestionRevenueAction implements Action {
    readonly type = QuoteStepType.QuestionRevenue;

    constructor(public payload: QuoteStep) {}
}

export class QuestionZipCodeAction implements Action {
    readonly type = QuoteStepType.QuestionZipCode;

    constructor(public payload: QuoteStep) {}
}

export class QuestionBusinessAction implements Action {
    readonly type = QuoteStepType.QuestionBusiness;

    constructor(public payload: QuoteStep) {}
}

export class QuestionEmailAction implements Action {
    readonly type = QuoteStepType.QuestionEmail;

    constructor(public payload: QuoteStep) {}
}

export class QuestionResetDataAction implements Action {
    readonly type = QuoteStepType.QuestionResetData;

    constructor(public payload: QuoteStep) {}
}

export type quoteStepActionUnion =
    | QuoteStepAction
    | QuestionDataAction
    | QuestionInsuranceAction
    | QuestionWebsiteAction
    | QuestionRevenueAction
    | QuestionBusinessAction
    | QuestionZipCodeAction
    | QuestionEmailAction
    | QuestionResetDataAction;
