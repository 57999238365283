import { Action } from '@ngrx/store';

export enum SciOptionalCoveragesActionTypes {
    SetSciOptionalCoverages = '[SetSciOptionalCoverages[]] setSciOptionalCoverages',
}

export class SetSciOptionalCoveragesAction implements Action {
    readonly type = SciOptionalCoveragesActionTypes.SetSciOptionalCoverages;
    constructor(public payload: any) {}
}

export type SciOptionalCoveragesActionUnion = SetSciOptionalCoveragesAction;
