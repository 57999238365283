export const environment = {
    production: false,
    backend: '/api', // Put your backend here
    uri: {
        classifications:
            '/classification?resultCount=numberOfRecords&keyword=searchTerm&partner=name',
        classificationClassByProduct: '/classification/$productType/$id',
        states: '/state',
        products: '/#PARTNER#/product?classid=$classid$&stateid=$stateid$',
        validateZip: '/validateZip?Data.PostalCode=$search-term$',
        questions:
            '/#PARTNER#/questionsv2?classes=$classid$&products=$productid$',
        wcQuote: '/wcquote',
        wcUpdateQuote: '/wcupdatequote',
        quote: '/#PARTNER#/quote',
        issue: '/#PARTNER#/issue',
        // stripe: '/charge',
        brainTree: '/payment',
        brainTreeToken: '/paymentAuth',
        saveQuote: '/savequote',
        addressLookUp:
            '/lookupAddress?Data.AddressLine1=$searchTerm$&Data.Country=$country$&Data.PostalCode=$postalCode$&MaxCandidates=$numRows$',
        retrieveQuote: '/retrievequote',
        updateQuote: '/#PARTNER#/updatequote',
        retrieveQuoteByEmail: '/getquotes?email=$emailAddress$',
        retrieveQuoteByQuote: '/getquotes?quoteNumber=$quoteNumber$',
        retrieveQuoteByProducerCode: '/getquotes?producerCd=$producerCd$',
        retrieveQuoteByQuoteKey: '/getquotes',
        validatePolicy: '/validatepolicy',
        downloadCOI: '/downloadcoi',
        quickQuote: '/quickquote',
        eligibilityQuestions: '/eligibilityqns',
        // SCI Endpoints
        sciDownloadCOI: '/scidownloadcoi',
        scigetquestions: '/sciquestionsv3',
        sciGetAmazonUWQ: '/briza/sciquestionsv3',
        sciQuote: '/#PARTNER#/sciquotev3',
        sciQuoteUpdate: '/#PARTNER#/sciupdatequotev3',
        scigetuwquestions: '/sciquestions/quotenumber',
        sciupdatequestions: '/sciupdatequestions',
        scirate: '/scirate',
        scirateupdate: '/scirateupdate',
        sciissue: '/#PARTNER#/sciissue',
        scioptionalcoverages: '/#PARTNER#/scioptionalcoverages',
        sciupdateoptionalcoverages: '/#PARTNER#/sciupdateoptionalcoverages',
        sciretrievequote: '/#PARTNER#/sciretrievequote',
        scisemciquote: '/#PARTNER#/scisemciquote',
        scigetsemciuwq: '/scisemciuwquestions',
        scisemciupdatequote: '/#PARTNER#/scisemciupdatequote',
        scisemciretrievequote: '/scisemciretrievequote',
        // scisemciretrievequote: '/#PARTNER#/scisemciretrievequote',
        storePdfData: '/quotes',
        getPdfData: '/quotes/:quoteId',
        createpdf: '/createpdf',
        pdfData: '/pdfData',
        wcretrievequote: '/wcretrievequote',
        partnerClassifications: '/classification/partner',
        partnerredirection: '/partnerredirection',
        wcjobtype: '/wcjobtype?digitalCode=$digitalCode&state=$state',
        wcjobtypecodes: '/wcjobtypecodes',
        wcentity: '/wcentity?entityCode=$entityCode&state=$state',
        wcofficertitle: '/wcofficertitle?entityCode=$entityCode',
        scicybergetuwq: '/scicyberuwquestions',
        billingdetermination: '/billingSystemDetermination',
        majescopayemnt: '/majescoPayment',
        brainTreeMajescoToken: '/paymentAuthMajesco',
        scicyberQuote: '/scicyberquote',
        cyberQuoteUpdate: '/scicyberupdatequote',
        scicyberretrievequote: '/scicyberretrievequote',
        autoPaymentEnroll: '/recurring/enroll',
        downloadProposal: '/quotedocument/:quoteProposalId',
        producerInfo: '/producerinfo',
        cisaQuoteSubmission: '/cisaquotesubmission',
        retrieval: '/retrievalproducer',
        downloadPolicy: '/downloadpolicy',
        keywordSearch: '/keywordsearch',
        userProfile: '/userProfile',
        retrieveComProducerCodes: '/retrieveProducerCodes',
    },
    msalAgentConfig: {
        auth: {
            clientId: 'e98f655a-816a-4951-a17d-7cdbf1fce112', // Replace with your AppID/ClientID obtained from Azure Portal.
            authority:
                'https://auth.sit.chubb.com/auth.sit.chubb.com/B2C_1A_SubAgentPortal_SignIn', // Choose sign-up/sign-in user-flow as your default.
            knownAuthorities: ['auth.sit.chubb.com'], // You must identify your tenant's domain as a known authority.
            redirectUri: 'https://wwwsit30.chubbsmallbusiness.com/cisa', // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
            postLogoutRedirectUri:
                'https://auth.sit.chubb.com/auth.sit.chubb.com/B2C_1A_SubAgentPortal_SignIn', // Simply remove this line if you would like navigate to index page after logout.
            navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
        },
        cache: {
            cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
            storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
        },
        loginRequest: {
            scopes: [
                'https://cbaadb2c08sit.onmicrosoft.com/2d29f69a-6ccb-4416-a465-a6c82572214e/Write',
                'https://cbaadb2c08sit.onmicrosoft.com/2d29f69a-6ccb-4416-a465-a6c82572214e/Read',
            ],
        },
    },
    paymentToken: 'https://payments.sandbox.braintree-api.com/graphql',
    coverhoundAmex:
        'https://commercial-s-chubb-uat.coverhound.us/business-insurance?source=310',
    coverhoundCSB:
        'https://commercial-s-chubb-uat.coverhound.us/business-insurance?source=313',
    displayReqResJson: true,
    enableSymantec: true,
    // gtmTrackingId: 'GTM-5BNGF3F', // enable production gtmTrackingId ('GTM-5BNGF3F') after testing IRSessionId Cookie for dev environment
    gtmTrackingId: 'GTM-PWR7HTS', // disable dev gtmTrackingId ('GTM-PWR7HTS') after testing IRSessionId Cookie for dev environment
    digitalCxpClaimsUrl: 'https://claims-sit.chubbsmallbusiness.com/',
    stripePKKey: 'pk_test_WiYNEoGIn2yaxFY0e8pLpjx8',
    appInsights: {
        instrumentationKey: 'e1a261eb-9a40-4549-bdbe-d624a64408ac',
    },
    baseUrl: 'https://wwwsit30.chubbsmallbusiness.com',
    servicePortalUrl: 'https://commercialservice-sit.chubb.com',
    appVersion: 1,
    isSciExcluded: false,
    chargeEnvironment: 'sandbox',
    disableInstallmentPay: 2,
    servicingURL: {
        registration:
            'https://commercialservice-sit.chubb.com/registration/activate',
        login: 'https://commercialservice-sit.chubb.com',
    },
    npsSurveyURL: 'https://www.google.com/',
    caWaiverURL:
        'https://sitsmbserviceweb0102.blob.core.windows.net/csb-assets/pdf/california-waiver-of-wc-coverage-form.pdf',
    blobStorageBaseURL:
        'https://sitsmbserviceweb0102.blob.core.windows.net/csb-assets',
    excludeWorkCompStates: ['PA'],
    partnerIds: [
        'prtnr001',
        'nawbo',
        'paypal',
        'pbn',
        'capitalone',
        'boldpenguin',
        'briza',
    ],
    cp1SparkCardURL: 'https://www.capitalone.com/Chubbinsurance/externalid=',
    isCp1SparkCashBannerIncluded: false,
    findAnAgentUrl:
        'https://www.chubb.com/us-en/business-insurance/find-an-agent.html',
    isUTCDate: false,
    isESTDayLight: false,
    csbAPPId: 'e313b08c-5c07-4414-8d55-2afe307d0cbe',
    agentQuoteSubmissionEventKey:
        'APIEvent-b38f107f-7200-b1b8-290e-f28bad6fe737',
    userQuoteSubmissionEventKey:
        'APIEvent-0b04ee33-198a-dd05-0589-e4d55c616f9d',
    agentWelcomeEmailEventKey: 'APIEvent-0cf3236e-b80b-07da-78d2-f3a7545843ee',
    rsaAgentWelcomeEmailEventKey:
        'APIEvent-a4270c9b-5bc3-18f7-d239-537b31b512c0',
    userNewQuoteSubmissionEventKey:
        'APIEvent-fd2d1a1f-796e-a8f4-7f15-47988e18c1ae',
    issuanceFailureEmailEventKey:
        'APIEvent-a0ea5817-88a1-6f9a-7d52-eab04f94334d',
};
