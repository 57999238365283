import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { InputModule } from '@crux/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsModule } from '@crux/services';
import { SocialMediaModule } from '../social-media/social-media.module';
@NgModule({
    imports: [
        CommonModule,
        InputModule,
        ReactiveFormsModule,
        FormsModule,
        AnalyticsModule,
        SocialMediaModule,
    ],
    declarations: [FooterComponent],
    exports: [FooterComponent],
})
export class FooterModule {}
