import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-rating-banner',
    templateUrl: './rating-banner.component.html',
    styleUrls: ['./rating-banner.component.scss'],
})
export class RatingBannerComponent implements OnInit {
    @Input() leftcontent: string;
    @Input() imagepath: string;

    constructor() {}

    ngOnInit() {}
}
