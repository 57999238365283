import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailFormComponent } from './email-form.component';
import { InputModule } from '@crux/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsModule } from '@crux/services';
import { NotificationsModule } from '../notifications/notifications.module';

@NgModule({
    imports: [
        CommonModule,
        InputModule,
        ReactiveFormsModule,
        FormsModule,
        AnalyticsModule,
        NotificationsModule,
    ],
    declarations: [EmailFormComponent],
    exports: [EmailFormComponent],
})
export class EmailFormModule {}
