import { Component, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonCommunicationService } from '../../services/common-communication.service';
import footerData from '../../../assets/json/footer.json';
import { CruxHttpClientService } from '@crux/services';
import { find, isEmpty, isNil } from 'lodash';
import { Store, select } from '@ngrx/store';
import { AffinityPartnerFlags } from '../../model/affinity-partner';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    year: any;
    isFooterHidden = false;
    isShowFooter = true;
    footerContent: any;
    blobURL = environment.blobStorageBaseURL;
    partnerCode: string;
    logoId: string;
    affinityPartnerInfo: AffinityPartnerFlags;

    constructor(
        private router: Router,
        public _ccs: CommonCommunicationService,
        private http: CruxHttpClientService,
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>
    ) {
        this.router.events.subscribe((event: any) => {
            if (event.url === undefined) {
            } else if (event.url.includes('download')) {
                this.isFooterHidden = true;
            } else if (event.url.includes('print-disclosure')) {
                this.isShowFooter = false;
            } else {
                this.isFooterHidden = false;
                this.isShowFooter = true;
            }
        });

        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (
                    AffinityPartnerFlags &&
                    AffinityPartnerFlags.partnercode &&
                    !isNil(AffinityPartnerFlags.partnercode) &&
                    !isEmpty(AffinityPartnerFlags.partnercode)
                ) {
                    this.affinityPartnerInfo = AffinityPartnerFlags;
                }
                this.checkForPartner();
            });
    }

    ngOnInit() {
        this.checkForPartner();
        const ts = new Date().getTime();
        this.http
            .get(this.blobURL + '/data/footer.json?ts=' + ts, {
                responseType: 'json',
            })
            .subscribe((res) => {
                if (!isNil(res) && !isEmpty(res)) {
                    this.footerContent = res;
                    this.checkForPartner();
                } else {
                    this.footerContent = footerData;
                    this.checkForPartner();
                }
            });
        const currentDate = new Date();
        this.year = format(currentDate, 'yyyy');
    }

    checkForPartner() {
        if (
            this.affinityPartnerInfo &&
            this.affinityPartnerInfo.partnercode &&
            !isNil(this.affinityPartnerInfo.partnercode) &&
            !isEmpty(this.affinityPartnerInfo.partnercode)
        ) {
            if (
                this.footerContent &&
                this.footerContent.disclaimer &&
                this.footerContent.disclaimer.info &&
                find(this.footerContent.disclaimer.info, [
                    'partnersEligible',
                    this.affinityPartnerInfo.partnercode,
                ])
            ) {
                this.partnerCode = this.affinityPartnerInfo.partnercode;
            } else {
                this.partnerCode = 'All';
            }

            if (
                this.footerContent &&
                this.footerContent.logoInfo &&
                find(this.footerContent.logoInfo, [
                    'partnerId',
                    this.affinityPartnerInfo.partnercode,
                ])
            ) {
                this.logoId = this.affinityPartnerInfo.partnercode;
            } else {
                this.logoId = 'All';
            }
        } else {
            this.partnerCode = 'All';
            this.logoId = 'All';
        }
    }
}
