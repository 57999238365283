import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingBannerComponent } from './rating-banner.component';

@NgModule({
    imports: [CommonModule],
    declarations: [RatingBannerComponent],
    exports: [RatingBannerComponent],
})
export class RatingBannerModule {}
