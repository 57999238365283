import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentSectionComponent } from './content-section.component';
import { ContentSectionTileModule } from '../content-section-tile/content-section-tile.module';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
    declarations: [ContentSectionComponent],
    imports: [CommonModule, ContentSectionTileModule, CarouselModule],
    exports: [ContentSectionComponent],
})
export class ContentSectionModule {}
