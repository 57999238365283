import {
    Directive,
    Input,
    HostListener,
    ElementRef,
    EventEmitter,
    Output,
    OnInit,
} from '@angular/core';

@Directive({
    selector: '[appHighlight]',
})
export class HighlightDirective implements OnInit {
    @Input('appHighlight') highlightColor: string;

    @Input() defaultColor: string;

    @Output() color: EventEmitter<boolean> = new EventEmitter<boolean>();

    @HostListener('mouseenter')
    onMouseEnter() {
        this.color.emit(true);
        this.highlight(this.highlightColor);
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.color.emit(false);
        this.highlight(this.defaultColor);
    }

    @HostListener('focusin')
    onFocusIn() {
        this.color.emit(true);
        this.highlight(this.highlightColor);
    }

    @HostListener('focusout')
    onFocusOut() {
        this.color.emit(false);
        this.highlight(this.defaultColor);
    }

    constructor(private el: ElementRef) {}

    private highlight(color: string) {
        this.el.nativeElement.style.backgroundColor = color;
    }

    ngOnInit() {
        this.highlight(this.defaultColor);
    }
}
