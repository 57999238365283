import {
    RetrievedBusinessProfileActionTypes,
    RetrievedBusinessProfileActionUnion,
} from '../actions/retrieved-business-profile.action';

export let businessProfileInfoInitialState: any;

export function RetrievedBusinessProfileInfoReducer(
    BusinessProfileData = businessProfileInfoInitialState,
    action: RetrievedBusinessProfileActionUnion
) {
    switch (action.type) {
        case RetrievedBusinessProfileActionTypes.businessProfileData: {
            return action.payload;
        }
        default: {
            return BusinessProfileData;
        }
    }
}
