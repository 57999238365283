import { Component, OnInit } from '@angular/core';
import { CommonCommunicationService } from '../../services/common-communication.service';
import { FraudDisclosureService } from '../../services/fraud-disclosure.service';
import { FraudDisclosure } from '../../model/fraud-disclosure';
import { ProductService } from '../../services/product.service';
import { AppetiteEffects } from '../../sales-store/appetite-store/effects';
import { Store, select } from '@ngrx/store';
import { isEmpty, isNil } from 'lodash';
import disclosureInfo from '../../../assets/json/fraudDisclosures.json';
@Component({
    selector: 'app-fraud-disclosures',
    templateUrl: './fraud-disclosures.component.html',
    styleUrls: ['./fraud-disclosures.component.scss'],
})
export class FraudDisclosuresComponent implements OnInit {
    public fraudDisclosure = FraudDisclosure;
    public disclosure: any;
    public stateId: any;
    public commonCommunicationService: CommonCommunicationService;
    public disclosureTitle: string;
    public disclosureText: string;
    public sciOptionalCover: any;
    public productID: any;
    HNOAflag = false;
    discloserStateexist = false;
    disclosurecommonTitle: any;
    disclosurecommonText: any;

    constructor(
        private pcommonCommunicationService: CommonCommunicationService,
        private fraudDisclosureService: FraudDisclosureService,
        private _appetiteEffects: AppetiteEffects,
        private _store: Store<{
            sciOptionalCoverages: any;
        }>
    ) {
        this.commonCommunicationService = pcommonCommunicationService;
    }

    ngOnInit() {
        this.fraudDisclosureService
            .getFraudDisclosure()
            .subscribe((disclosure) => {
                if (!isNil(disclosure) && !isEmpty(disclosure)) {
                    this.disclosure = disclosure;
                } else {
                    this.disclosure = disclosureInfo;
                }
                if (!isNil(this.disclosure) && !isEmpty(this.disclosure)) {
                    this.disclosure.content.forEach((disclosureData) => {
                        if (this.stateId === disclosureData.StateProvince) {
                            this.discloserStateexist = true;
                        }
                    });
                    this.disclosurecommonTitle = this.disclosure.content[0].title;
                    this.disclosurecommonText = this.disclosure.content[0].text;
                }
            });
        this._appetiteEffects.readZipcodeFromStore$().subscribe((response) => {
            if (response && response.length > 0) {
                this.stateId = response[0].StateProvince;
            }
        });
        this._store
            .pipe(select('sciOptionalCoverages'))
            .subscribe((sciOptionalCoverages) => {
                if (sciOptionalCoverages) {
                    this.productID = sciOptionalCoverages.data[0].productId;
                    this.sciOptionalCover =
                        sciOptionalCoverages.data[0].forms[0].fieldSet.fields;

                    if (
                        this.sciOptionalCover &&
                        this.sciOptionalCover.length > 0
                    ) {
                        this.sciOptionalCover.forEach((sOCoverages) => {
                            if (
                                sOCoverages.name ===
                                    'LineCoverage_HiredAutoAndNonOwnedAuto_Events.IncludeHiredAutoAndNonOwnedAuto' &&
                                sOCoverages.value === '1'
                            ) {
                                this.HNOAflag = true;
                            }
                        });
                    }
                }
            });
    }

    displayDisclosure(ev) {
        this.disclosureTitle = ev[0].title;
        this.disclosureText = ev[0].text;
    }
}
