import { Action } from '@ngrx/store';

export enum MajescoActionTypes {
    setMajescoStatus = '[ set majesco ] Set Majesco Status',
}

export class SetMajescoStatusAction implements Action {
    readonly type = MajescoActionTypes.setMajescoStatus;

    constructor(public payload: boolean) {}
}

export type MajescotActionUnion = SetMajescoStatusAction;
