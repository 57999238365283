import { CommonCommunicationService } from './../../services/common-communication.service';
import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';

/**
 * Store imports
 */
import { Store, select } from '@ngrx/store';

/**
 * Partner Customization
 */
import { PartnerCustomization } from './../../partner-customization/partner-customization.helper';

@Injectable()
export class CustomPackagesGuard implements CanActivate {
    isAddInfoAnswered = false;
    // productIndexFromURL: number;
    retrievedQuoteObj: any;
    constructor(
        private _store: Store<{ 
            startQuote: boolean;
            retrieveQuoteData: any;
            isAdditioalInfo: any;
         }>,
        private _partnerCustomization: PartnerCustomization,
        private _router: Router,
        private _ccs: CommonCommunicationService
    ) {
        const splitUrl = this._router.url.split('/');
        this._store.pipe(select('retrieveQuoteData')).subscribe((data) => {
            this.retrievedQuoteObj = data;
        });
        if (
            typeof this._ccs.productIndexFromURL !== 'number' &&
            typeof this._ccs.productIndexFromURL !== 'string'
        ) {
            this._ccs.productIndexFromURL = this.retrievedQuoteObj
                ? 0
                : parseInt(splitUrl[splitUrl.length - 1], 10);
        }
        this._store.pipe(select('isAdditioalInfo')).subscribe((indexList) => {
            indexList.forEach((productIndex) => {
                if (productIndex === this._ccs.productIndexFromURL) {
                    this.isAddInfoAnswered = true;
                }
            });
        });
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        // Allow user to navigate through route only if user has answered questions and Policy has not been issued
        //
        if (this.isAddInfoAnswered) {
            return true;
        }

        /**
         * ! If above condition does not match go to home page
         */
        this._partnerCustomization.goToHome();
    }
}
