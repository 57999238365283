export class PartnersRedirectionFlags {
    isPartnerRedirected = false;
    isActiveQuoteWithPastDate = false;
    isActiveQuoteWithFutureDate = false;
    invalidParams: ErrorField[];
}
export class ErrorField {
    errorFieldId: any;
    errorFieldType: string;
    errorFieldText: string;
    showError: boolean;
}
