import { Component, OnInit } from '@angular/core';
import { CommonCommunicationService } from '../../../services/common-communication.service';

@Component({
    selector: 'app-step-one-retrieve-quote-wrapper',
    templateUrl: './step-one-retrieve-quote-wrapper.component.html',
    styleUrls: ['./step-one-retrieve-quote-wrapper.component.scss'],
})
export class StepOneRetrieveQuoteWrapperComponent implements OnInit {
    public commonCommunicationService: CommonCommunicationService;
    constructor(
        private pcommonCommunicationService: CommonCommunicationService
    ) {
        this.commonCommunicationService = pcommonCommunicationService;
    }

    ngOnInit() {}
}
