import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { InitialQuestions } from './initial-questions';
import { Routes } from './routes';
import { Router, ActivatedRoute } from '@angular/router';
import { findIndex, find, filter } from 'lodash-es';

@Injectable()
export class PartnerCustomization {
    public appVersion: number;
    public isSciExcluded: boolean;

    constructor(
        private _router: Router,
        private _activateRoute: ActivatedRoute
    ) {
        this.appVersion = environment.appVersion;
        this.isSciExcluded = environment.isSciExcluded;

        this._activateRoute.queryParams.subscribe((params) => {
            if (params['v'] === '4') {
                this.isSciExcluded = false;
            }
        });
    }

    getInitialQuestions() {
        /**
         * Always return initial questions with revenue
         */
        // return InitialQuestions[this.appVersion];

        return InitialQuestions[1];
    }

    /**
     * Below method redirects to next screen in normal flow if there is no action
     * If
     */

    goToNextScreen(currentScreen, action?) {
        let nextRoute = '';
        if (currentScreen == '/') {
            currentScreen = '/home';
        }
        //All error scenarios
        if (action == 'error') {
            nextRoute = Routes[this.appVersion].error;
        }
        /**
         * All normal flow routes, and partner redirection flows where
         * consider action as normal if not recieved from caller
         */
        let currentRouteIndex = Routes[this.appVersion].normal.indexOf(
            currentScreen
        );
        if (currentRouteIndex !== -1 && action == undefined) {
            nextRoute = Routes[this.appVersion].normal[currentRouteIndex + 1];
        }

        //retrieve-quote
        //redirection from home to retrieve quote
        // if (currentScreen == 'home' && action == 'retrieve-quote') {
        //     nextRoute =
        //         Routes[this.appVersion].retrieveQuoteByNumber[
        //             currentRouteIndex + 1
        //         ];
        // }
        // //retrieve quote to package selection / quote list scenario
        // if (currentScreen == 'retrieve-quote') {
        //     if (action == 'number') {
        //         if (params !== 'expired')
        //             nextRoute =
        //                 Routes[this.appVersion].retrieveQuoteByNumber[
        //                     currentRouteIndex + 1
        //                 ];
        //         else
        //             nextRoute =
        //                 Routes[this.appVersion].retrieveQuoteByNumber[
        //                     currentRouteIndex + 2
        //                 ];
        //     } else if (action == 'email') {
        //         if (params !== 'expired')
        //             nextRoute =
        //                 Routes[this.appVersion].retrieveQuoteByEmail[
        //                     currentRouteIndex + 1
        //                 ];
        //         else
        //             nextRoute =
        //                 Routes[this.appVersion].retrieveQuoteByNumber[
        //                     currentRouteIndex + 2
        //                 ];
        //     }
        // }
        //quote list to package selection scenario
        if (currentScreen == 'quote-list') {
            nextRoute =
                Routes[this.appVersion].retrieveQuoteByEmail[
                    currentRouteIndex + 1
                ];
        }
        this._router.navigate([nextRoute]);
    }

    /**
     * Redirect with partnerIndex Business Info and Additional Info Scenario
     */
    goToNextScreenWithIndex(currentScreen) {
        let splitRoute = currentScreen.split('/');
        let productIndexFromUrl = parseInt(splitRoute[splitRoute.length - 1]);
        let productIndex = 0;
        if (!isNaN(productIndexFromUrl)) {
            productIndex = productIndexFromUrl;
            currentScreen = '/' + splitRoute[splitRoute.length - 2];
        }

        let currentRouteIndex = Routes[this.appVersion].normal.indexOf(
            currentScreen
        );
        if (currentRouteIndex !== -1) {
            var nextRoute =
                Routes[this.appVersion].normal[currentRouteIndex + 1];
        }

        /**
         * ! Do not delete below commented code, below change can quickly make app working as per old flow
         *  where all the UW questions and all the quote can be shown in single screen
         */
        // if(this.appVersion == 0 )
        // productIndex = -1;
        this._router.navigate([nextRoute, productIndex]);
    }

    /**
     * Use the product steps and selected products to determine the next route - Additional info -> Policy Limits -> Optional Coverages -> Next product || application summary
     */
    goToNextScreenUsingProgressBarRoutes(
        currentRoute,
        selectedProducts,
        currentProductIndex,
        skipProduct = false,
        showEligibilityQuestions?
    ) {
        let currentStepIndex;
        let nextRoute: string;
        const currentProduct = selectedProducts[currentProductIndex];

        if (currentRoute === '/get-acquainted') {
            selectedProducts.forEach((product) => {
                if (
                    product.isEligible &&
                    !nextRoute &&
                    product.productSteps.length > 0
                ) {
                    nextRoute = product.productSteps[0].associatedUrl;
                }
            });
            //    nextRoute = selectedProducts[0].productSteps[0].associatedUrl;
        } else if (currentRoute === '/business-details') {
            /// If we're on business details, go to additional info for the first product

            // if (selectedProducts[0].productId === 'WORKCOMP') {
            //     selectedProducts[0].productSteps[0].associatedUrl =
            //         'workers-comp-job-type';
            // }
            // nextRoute = selectedProducts[0].productSteps[0].associatedUrl;
            if (showEligibilityQuestions) {
                nextRoute = '/get-acquainted';
            } else {
                if (selectedProducts && selectedProducts.length > 0) {
                    nextRoute =
                        selectedProducts[0].productSteps[0].associatedUrl;
                } else {
                    nextRoute = '/not-eligible';
                }
            }
        } else {
            // Find out what step index we are on
            currentStepIndex = findIndex(
                currentProduct.productSteps,
                (step) => {
                    return step['associatedUrl'] === currentRoute;
                }
            );
            // if there is another step for this product, go to the next step
            if (
                currentStepIndex < currentProduct.productSteps.length - 1 &&
                !skipProduct
            ) {
                // if SCI BOP was skipped previously, but now is revisited, unskip umbrella
                if (
                    (currentProduct.productId === 'BOP' ||
                        currentProduct.productId === 'GL') &&
                    currentProduct.productSourceSystem === 'SCI'
                ) {
                    // find umbrella
                    let umbrellaProduct = find(
                        selectedProducts,
                        (selectedProduct) => {
                            return selectedProduct.productId === 'UMBRELLA';
                        }
                    );
                    // Unskip umbrella
                    if (umbrellaProduct) {
                        umbrellaProduct['disqualifyProduct'] = false;
                    }
                }
                nextRoute =
                    currentProduct.productSteps[+currentStepIndex + 1]
                        .associatedUrl;
                // else go to the next product
            } else {
                // if SCI BOP was skipped from policy limits, but then revisited, unskip umbrella
                if (
                    (currentProduct.productId === 'BOP' ||
                        currentProduct.productId === 'GL') &&
                    currentProduct.productSourceSystem === 'SCI' &&
                    find(currentProduct.productSteps, (step) => {
                        return step.associatedUrl === currentRoute;
                    })
                ) {
                    // find umbrella
                    let umbrellaProduct = find(
                        selectedProducts,
                        (selectedProduct) => {
                            return selectedProduct.productId === 'UMBRELLA';
                        }
                    );
                    // Unskip umbrella
                    if (umbrellaProduct) {
                        umbrellaProduct['disqualifyProduct'] = false;
                    }
                }
                // if SCI BOP is skipped, skip umbrella
                if (
                    (currentProduct.productId === 'BOP' ||
                        currentProduct.productId === 'GL') &&
                    currentProduct.productSourceSystem === 'SCI' &&
                    skipProduct
                ) {
                    // find umbrella
                    let umbrellaProduct = find(
                        selectedProducts,
                        (selectedProduct) => {
                            return selectedProduct.productId === 'UMBRELLA';
                        }
                    );
                    // add a skip product flag
                    if (umbrellaProduct) {
                        umbrellaProduct['disqualifyProduct'] = true;
                    }
                }
                // check for next product, if product is not disqualifide, then skip to next section
                if (
                    selectedProducts[+currentProductIndex + 1] &&
                    !selectedProducts[+currentProductIndex + 1]
                        .disqualifyProduct
                ) {
                    // if exists, go to next products first product step
                    nextRoute =
                        selectedProducts[+currentProductIndex + 1]
                            .productSteps[0].associatedUrl;
                    // if there is a next product and its disqualified, check for next product after that, and navigate accordingly
                } else if (
                    selectedProducts[+currentProductIndex + 1] &&
                    selectedProducts[+currentProductIndex + 1]
                        .disqualifyProduct &&
                    selectedProducts[+currentProductIndex + 2]
                ) {
                    // Skip the disqualified product and go to the next
                    nextRoute =
                        selectedProducts[+currentProductIndex + 2]
                            .productSteps[0].associatedUrl;
                    // if no more products, go to additional info page
                } else {
                    nextRoute = '/review-application';
                }
            }
        }
        this._router.navigate([nextRoute]);
    }

    goToNextScreeenIfNoWCUWQuestion(currentRoute, selectedProducts) {
        let nextRoute: string;
        if (currentRoute === '/get-acquainted') {
            nextRoute = '/workers-comp-job-type';
        }
        // navigate to route
        this._router.navigate([nextRoute, 0]);
    }
    /**
     * Below method only holds logic for redirection from package selection / Policy limits
     */
    goToNextScreenOfQuote(currentScreen, previousFlag?: boolean) {
        let splitRoute = currentScreen.split('/');
        let productIndexFromUrl = parseInt(splitRoute[splitRoute.length - 1]);

        if (!isNaN(productIndexFromUrl)) {
            currentScreen = '/' + splitRoute[splitRoute.length - 2];
        }
        let currentRouteIndex = Routes[this.appVersion].normal.indexOf(
            currentScreen
        );
        if (currentRouteIndex !== -1) {
            var nextRoute =
                Routes[this.appVersion].normal[currentRouteIndex + 1]; // Review application
            var previousRoute =
                Routes[this.appVersion].normal[currentRouteIndex - 1]; // Additional Info
        }

        if (previousFlag)
            this._router.navigate([previousRoute, productIndexFromUrl + 1]);
        else this._router.navigate([nextRoute]);
    }

    /**
     * Scenarios where nextScreen logic is being taken care by component
     */

    goDirectToScreen(route, productIndex?) {
        this._router.navigate([route]);
    }

    // We do not use back navigation yet, but still we are keeping a provision for futute
    goToPreviousScreen() {}

    skipPolicyNavigation(productIndex?) {
        if (productIndex) {
            const nextRoute = Routes[this.appVersion].skipPolicy[0];
            this._router.navigate([nextRoute, productIndex]);
        } else {
            const nextRoute = Routes[this.appVersion].skipPolicy[1];
            this._router.navigate([nextRoute]);
        }
    }

    goToHome() {
        this._router.navigate(['']);
    }
}
