import { PartnersRedirectionFlags } from './../../../model/partners-redirection';
import {
    PartnerredirectionActionUnion,
    PartnersredirectionActionTypes,
} from './../actions';

export let FlagInitialState: PartnersRedirectionFlags;

export function partnersRedirectionFlagReducer(
    FlagState = FlagInitialState,
    action: PartnerredirectionActionUnion
) {
    switch (action.type) {
        // Flag Setter
        case PartnersredirectionActionTypes.PartnerFlag: {
            FlagState = action.payload;
            return FlagState;
        }

        default: {
            return FlagState;
        }
    }
}
