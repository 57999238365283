import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SquarespaceModelComponent } from './squarespace-model.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AnalyticsModule } from '@crux/services';

@NgModule({
    declarations: [SquarespaceModelComponent],
    imports: [CommonModule, MatDialogModule, AnalyticsModule],
    exports: [SquarespaceModelComponent],
})
export class SquarespaceModelModule {}
