import { QuoteActionTypes, QuoteActionUnion } from '../actions/quote.action';

export const summaryReviewInitialState = false;

export function summaryReviewReducer(
    reviewState = summaryReviewInitialState,
    action: QuoteActionUnion
) {
    switch (action.type) {
        case QuoteActionTypes.setSummaryReviewed: {
            reviewState = true;
            return reviewState;
        }
        default: {
            return reviewState;
        }
    }
}
