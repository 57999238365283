import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import {
    InputModule,
    ZipCodeModule as PostalCodeModule,
    CruxDropdownModule,
    AutocompleteModule,
} from '@crux/components';
import { SearchModalModule } from '../search-modal/search-modal.module';
import { AnalyticsModule } from '@crux/services';

import { AppetiteCheckComponent } from './appetite-check.component';
import { ErrorBoxModule } from '../../../components/error-box/error-box.module';
import { NotificationsModule } from '../../../components/notifications/notifications.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        InputModule,
        CruxDropdownModule,
        SearchModalModule,
        PostalCodeModule,
        AnalyticsModule,
        AutocompleteModule,
        ErrorBoxModule,
        NotificationsModule,
    ],
    declarations: [AppetiteCheckComponent],
    exports: [AppetiteCheckComponent],
})
export class AppetiteCheckModule {}
