import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CruxHttpClientService } from '@crux/services';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { QuickQuoteRequest } from '../model/quick-quote-request';
import { AppetiteEffects } from './../sales-store/appetite-store/effects';

@Injectable()
export class QuickQuoteService {
    wsgClassId: string;
    state: string;
    product: string;
    premiumBasis: string;
    revenue: number;
    quickQuoteUrl: string = environment.backend + environment.uri.quickQuote;

    constructor(
        private _http: CruxHttpClientService,
        private _appetiteEffects: AppetiteEffects
    ) {
        // Observables for reading class, zip and revenue(?) from store
        this._appetiteEffects
            .readClassificationFromStore$()
            .subscribe((classDetails) => {
                this.wsgClassId = classDetails.wsgClassId;
                this.premiumBasis = classDetails.premiumBasis;
            });
        this._appetiteEffects
            .readZipcodeFromStore$()
            .subscribe((locationDetails) => {
                if (locationDetails.length > 0) {
                    this.state = locationDetails[0].StateProvince;
                }
            });
        this._appetiteEffects.readRevenueFromStore$().subscribe((revenue) => {
            this.revenue = revenue;
        });
    }

    getQuickQuote(quickQuotePayload: any) {
        if (quickQuotePayload !== undefined) {
            return this._http
                .post(this.quickQuoteUrl, { body: quickQuotePayload })
                .pipe(
                    catchError((error: Response) => {
                        return throwError(error);
                    })
                );
        }
    }

    buildQuickQuoteRequest(productId: string) {
        const quickQuoteRequest = new QuickQuoteRequest();
        quickQuoteRequest.classId = this.wsgClassId;
        quickQuoteRequest.state = this.state;
        quickQuoteRequest.productId = productId;
        quickQuoteRequest.premiumBasis = this.premiumBasis;
        if (this.revenue) {
            quickQuoteRequest.value = this.revenue.toString();
        }
        return quickQuoteRequest;
    }
}
