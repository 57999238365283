import { QuestionsActionTypes, QuestionsActionUnion } from '../actions';
import { Question } from '../../../model/questions';
import { EligibilityQuestionModel } from '../../../model/eligibility';

export const initialEligibility: EligibilityQuestionModel[] = [];

export function setEligibilityReducer(
    eligilityState = initialEligibility,
    action: QuestionsActionUnion
) {
    switch (action.type) {
        case QuestionsActionTypes.resetEligibility: {
            eligilityState = action.payload;
            return eligilityState;
        }

        case QuestionsActionTypes.setEligibily: {
            eligilityState = action.payload;
            return eligilityState;
        }

        default: {
            return eligilityState;
        }
    }
}
