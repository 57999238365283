import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CommonCommunicationService } from '../../services/common-communication.service';
import { isNil, isEmpty } from 'lodash-es';

/**
 * Store imports
 */
import { Store, select } from '@ngrx/store';

/**
 * Partner Customization
 */
import { PartnerCustomization } from './../../partner-customization/partner-customization.helper';

@Injectable()
export class PaymentConfirmationGuard implements CanActivate {
    policyIssuanceAttemptCount: number;
    constructor(
        private _store: Store<{ issuanceAttemptCount: number }>,
        private _partnerCustomization: PartnerCustomization
    ) {
        this._store
            .pipe(select('issuanceAttemptCount'))
            .subscribe((attemptCount) => {
                this.policyIssuanceAttemptCount = attemptCount;
            });
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        /**
         * When Stripe Response is not null and is not empty and is not undefined
         * proceed with the route and the Step Number is > 7.
         *
         * Otherwise navigate to home.
         *
         * @todo Confirm if this is the right condition to activate the payment route.
         */
        if (this.policyIssuanceAttemptCount > 0) {
            return true;
        }

        /**
         * Navigate to home when the above condition does not match
         */
        this._partnerCustomization.goToHome();
    }
}
