import {
    Component,
    OnInit,
    HostBinding,
    Output,
    EventEmitter,
} from '@angular/core';
import { ClassificationService } from '../../../services/classification.service';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { DictionaryService } from '../../../services/dictionary.service';
import { Classification } from '../../../model/classification';
import { Store } from '@ngrx/store';
import { SetClassificationAction } from '../../../sales-store/appetite-store/actions';
import { AnalyticsService } from '@crux/services';

@Component({
    selector: 'app-classification',
    templateUrl: './classification.component.html',
    styleUrls: ['./classification.component.scss'],
})
export class ClassificationComponent implements OnInit {
    @HostBinding('@.disabled') disabled = true;
    @Output() modalSelected: EventEmitter<boolean> = new EventEmitter();
    public isSelected: boolean;
    labelDictionary: any;
    public classificationURL: string;
    // Default number or records from Classification to 10 records
    public numberOfRecords = '10';

    constructor(
        public dictionaryService: DictionaryService,
        private classificationData: ClassificationService,
        public commonCommunicationService: CommonCommunicationService,
        private _analytics: AnalyticsService,
        private _store: Store<{ classification: Classification }>
    ) {
        this.classificationURL = this.classificationData.classificationServiceUrl.replace(
            'numberOfRecords',
            this.numberOfRecords
        );
    }

    ngOnInit() {
        this.labelDictionary = this.dictionaryService.labelDict;
    }

    _classificationSelection(selectValue) {
        /**
         * Track Selection of Classification.
         */
        this._analytics.eventTrack.next({
            category: 'ProductSelection',
            action: 'CLASSIFICATION_CHOICE',
            label: selectValue.name,
        });
        this.modalSelected.emit(true);
        // Dispatch the store with selected classification details recieved from typeahead
        this._store.dispatch(new SetClassificationAction(selectValue));
        this.commonCommunicationService.isSearchActivate = false;
    }

    _checkEmptyValue(ev) {
        if (ev) {
            this.commonCommunicationService.quoteRequestData.selectedClass = null;
        }
    }
}
