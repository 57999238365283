import { ProductActionUnion, ProductActionTypes } from './../actions';

export const initialTotalPremium: number = 0;

export function setTotalPremiumReducer(
    totalPaymentState = initialTotalPremium,
    action: ProductActionUnion
) {
    switch (action.type) {
        case ProductActionTypes.setTotalPremium: {
            totalPaymentState = action.payload;
            return totalPaymentState;
        }
        default: {
            return totalPaymentState;
        }
    }
}
