import { Injectable } from '@angular/core';
import {
    CruxHttpClientService,
    PostalCodeOutput,
    ZipCodeErrorMessage,
} from '@crux/services';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class CsbZipcodeService {
    constructor(private _httpClient: CruxHttpClientService) {}

    lookup(url: string): Observable<PostalCodeOutput> {
        return this._httpClient.get<PostalCodeOutput>(url);
    }

    getErrorMessage(err: HttpErrorResponse) {
        let errorMessage;

        switch (err.status) {
            case 404:
                errorMessage = ZipCodeErrorMessage.NOT_FOUND;
                break;
            default:
                errorMessage = err.message;
                break;
        }

        return errorMessage;
    }
}
