import {
    AppetiteActionUnion,
    AppetiteActionTypes,
} from './../actions/appetite.action';

export var initialRevenueState: number;

export function revenueReducer(
    revenueState = initialRevenueState,
    action: AppetiteActionUnion
) {
    switch (action.type) {
        // Revenue Setter
        case AppetiteActionTypes.SetRevenue: {
            const newRevenueState = action.payload;

            return newRevenueState;
        }

        case AppetiteActionTypes.ResetRevenue: {
            const newRevenueState = action.payload;

            return newRevenueState;
        }

        default: {
            return revenueState;
        }
    }
}
