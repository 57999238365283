import { CruxHttpClientService } from '@crux/services';
import { Injectable } from '@angular/core';
import { Product } from '../model/product';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ProductsRequest } from '../model/products-request';
import { AppetiteEffects } from './../sales-store/appetite-store/effects';
import { PartnerCustomization } from './../partner-customization/partner-customization.helper';

@Injectable()
export class ProductService {
    SCIProductApi = '../../assets/mock-data/products-sci.json'; // environment.backend + environment.uri.products;
    WSGProductApi: string = environment.backend + environment.uri.products;
    // productApi: string = environment.backend + environment.uri.products; // '../../assets/mock-data/products.json'
    digitalClassCode: string;
    stateId: string;
    annualRevenue: number;
    wsgClassId: string;

    constructor(
        private _http: CruxHttpClientService,
        private _appetiteEffects: AppetiteEffects,
        private _partnerCustomization: PartnerCustomization
    ) {
        // Observables for reading class, zip and revenue(?) from store
        this._appetiteEffects
            .readClassificationFromStore$()
            .subscribe((classDetails) => {
                this.digitalClassCode = classDetails.digitalClassCode;
                this.wsgClassId = classDetails.wsgClassId;
            });
        this._appetiteEffects
            .readZipcodeFromStore$()
            .subscribe((locationDetails) => {
                if (locationDetails.length > 0) {
                    this.stateId = locationDetails[0].StateProvince;
                }
            });
        this._appetiteEffects.readRevenueFromStore$().subscribe((revenue) => {
            this.annualRevenue = revenue;
        });
    }

    getProducts(
        productsRequest: any,
        typeOfProduct?: any
    ): Observable<Product[]> {
        let productApi = '';

        if (typeOfProduct != null && typeOfProduct === 'SCI') {
            productApi = this.SCIProductApi;
            productApi = productApi
                .replace('$stateid$', this.stateId)
                .replace('$classid$', this.digitalClassCode);
            return this._http.get(productApi);
        } else {
            productApi = this.WSGProductApi;
            productApi = productApi
                .replace('$stateid$', this.stateId)
                .replace('$classid$', this.digitalClassCode);
            return this._http.post(productApi, { body: productsRequest });
        }
    }

    buildProductsRequest() {
        const productsRequest = new ProductsRequest();
        productsRequest.digitalClassCode = this.digitalClassCode;
        productsRequest.stateId = this.stateId;
        if (this.annualRevenue) {
            productsRequest.annualRevenue = this.annualRevenue.toString();
        }
        productsRequest.isSciExcluded = this._partnerCustomization.isSciExcluded;
        productsRequest.wsgClassId = this.wsgClassId;
        return productsRequest;
    }
}
