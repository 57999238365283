import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    HostListener,
    ViewContainerRef,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { Product } from '../../../model/product';
import { Observable } from 'rxjs';
import { QuickQuoteService } from '../../../services/quick-quote.service';
// import { Store, select } from '@ngrx/store';
import { Classification } from '../../../model/classification';
import { GAEvent } from '../../../csb-core/ga-event';
import { AnalyticsService } from '@crux/services';
import { DictionaryService } from '../../../services/dictionary.service';
import { isEmpty, isNil } from 'lodash';
import { select, Store } from '@ngrx/store';
import { AffinityPartnerFlags } from '../../../model/affinity-partner';
import { ZipcodeModel } from '../../../model/zipcode';

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
    @Input() product: Product;
    @Input() activateUmbrella: Observable<boolean>;
    @Input() body: string;
    @Input() quoteStep: any;
    @Output() selectedProduct: EventEmitter<Product> = new EventEmitter();
    @ViewChild('card') tooltipIcon: ElementRef;
    selected: boolean;
    flipped = false;
    umbrellaDisable: 'disabled' | 'active';
    classification: Classification;
    zipcode: any;
    rates: any;
    public labelsDictionary: any;
    AffinityPartnerFlags: AffinityPartnerFlags;
    tooltipTemplate = 'tooltip';
    tooltipContext: any;
    showRateError = false;
    rateProgress = false;
    businessLocation: any;
    discountInformation: any;

    tooltipText = {
        WORKCOMP: {
            // tslint:disable-next-line: max-line-length
            what:
                'Workers compensation helps protect businesses and their employees from financial loss when an employee is hurt on the job or gets sick from a work-related cause.',
            // tslint:disable-next-line: max-line-length
            why:
                'To protect employers from lawsuits resulting from workplace accidents and to provide medical care and compensation for lost income to employees hurt in workplace accidents. In almost every state, businesses are required to buy workers compensation insurance.',
        },
        MICROBOP: {
            what:
                'Bundles General Liability Insurance, Property and Business Income Insurance in a single business insurance package.',
            // tslint:disable-next-line: max-line-length
            why:
                'A BOP provides protection for direct physical loss or damage to business contents such as inventory, computers and other items used in the day-to-day operation of your business, and provides insurance coverage for non-employee injuries or damage to property for which you may be liable.',
        },
        MICROGL: {
            // tslint:disable-next-line: max-line-length
            what:
                'Required by law in many states, general liability helps protect your business against bodily injury liability, personal and advertising injury liability and property damage liability.',
            // tslint:disable-next-line: max-line-length
            why:
                'General liability insurance protects businesses in the event someone other than you or your employees are injured or suffer damage to their property for which you may be legally liable.',
        },
        UMBRELLA: {
            // tslint:disable-next-line: max-line-length
            what:
                'Umbrella insurance is a type of personal liability insurance that covers claims in excess of regular homeowners, auto, or watercraft policy coverage.',
            // tslint:disable-next-line: max-line-length
            why:
                "An umbrella policy protects your existing personal assets and future personal assets (like wages, your inheritance or that lottery you're planning to win) against the cost of losing a lawsuit over a car accident or an accident on your property.",
        },
        MICROCYBER: {
            // tslint:disable-next-line: max-line-length
            what:
                'Cyber insurance is there if your business is a victim of hacking, ransomware attacks, or data privacy breaches which result in the compromise of customer information.',
            // tslint:disable-next-line: max-line-length
            why:
                'Cyber insurance can be essential in helping your company recover after a data breach, with costs that can include business disruption, revenue loss, equipment damages, legal fees, public relations expenses, forensic analysis and costs associated with legally mandated notifications.',
        },
        'CYBER-ERM': {
            // tslint:disable-next-line: max-line-length
            what:
                'Cyber insurance is there if your business is a victim of hacking, ransomware attacks, or data privacy breaches which result in the compromise of customer information.',
            // tslint:disable-next-line: max-line-length
            why:
                'Cyber insurance can be essential in helping your company recover after a data breach, with costs that can include business disruption, revenue loss, equipment damages, legal fees, public relations expenses, forensic analysis and costs associated with legally mandated notifications.',
        },
        'CYBER-DIGITECT': {
            // tslint:disable-next-line: max-line-length
            what:
                'Cyber insurance is there if your business is a victim of hacking, ransomware attacks, or data privacy breaches which result in the compromise of customer information.',
            // tslint:disable-next-line: max-line-length
            why:
                'Cyber insurance can be essential in helping your company recover after a data breach, with costs that can include business disruption, revenue loss, equipment damages, legal fees, public relations expenses, forensic analysis and costs associated with legally mandated notifications.',
        },
        MICROMPL: {
            // tslint:disable-next-line: max-line-length
            what:
                "Professional liability insurance helps protect against a claim that professional advice or services you provided caused a client or customer financial harm due to mistakes on your business's part or because the business failed to perform some service.",
            // tslint:disable-next-line: max-line-length
            why:
                'Professional firms are constantly at risk of being sued for professional liability arising out of the professional services they perform. Even if the lawsuit is without merit, defense costs and the impact on your reputation can be costly.',
        },
        BOP: {
            what:
                'Bundles General Liability Insurance, Property and Business Income Insurance in a single business insurance package.',
            // tslint:disable-next-line: max-line-length
            why:
                'A BOP provides protection for direct physical loss or damage to business contents such as inventory, computers and other items used in the day-to-day operation of your business, and provides insurance coverage for non-employee injuries or damage to property for which you may be liable.',
        },
        GL: {
            // tslint:disable-next-line: max-line-length
            what:
                'Required by law in many states, general liability helps protect your business against bodily injury liability, personal and advertising injury liability and property damage liability.',
            // tslint:disable-next-line: max-line-length
            why:
                'General liability insurance protects businesses in the event someone other than you or your employees are injured or suffer damage to their property for which you may be legally liable.',
        },
    };
    offSet: number;

    constructor(
        private _quickQuote: QuickQuoteService,
        private _el: ElementRef,
        public dictionaryService: DictionaryService,
        private _analytics: AnalyticsService,
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
            zipcode: ZipcodeModel;
            producerCodesInfo: any;
        }>
    ) {
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.AffinityPartnerFlags = AffinityPartnerFlags;
                    }
                }
            });

        this._store.pipe(select('zipcode')).subscribe((zipData) => {
            this.businessLocation = zipData;
        });

        this._store
            .pipe(select('producerCodesInfo'))
            .subscribe((producerCodesInfo) => {
                if (!isNil(producerCodesInfo) && !isEmpty(producerCodesInfo)) {
                    producerCodesInfo.forEach((partner) => {
                        if (
                            this.AffinityPartnerFlags &&
                            this.AffinityPartnerFlags.partnercode &&
                            !isNil(this.AffinityPartnerFlags.partnercode) &&
                            !isEmpty(this.AffinityPartnerFlags.partnercode) &&
                            partner.partnerId &&
                            partner.partnerId ===
                                this.AffinityPartnerFlags.partnercode
                        ) {
                            this.discountInformation = partner;
                        }
                    });
                }
            });
    }

    ngOnInit() {
        this.labelsDictionary = this.dictionaryService.labelDict;
        this.umbrellaDisable = 'active';
        if (this.product.selected === undefined) {
            this.product.selected = false;
        }
        if (this.product.productId === 'UMBRELLA') {
            this.umbrellaDisable = 'disabled';
            this.activateUmbrella.subscribe((umb) => {
                this.umbrellaDisable = umb ? 'disabled' : 'active';
            });
        }

        this.zipcode = this.quoteStep.zipcode
            ? this.quoteStep.zipcode.value[0]
            : null;
        this.classification = this.quoteStep.business
            ? this.quoteStep.business.value
            : null;
        const width = window.innerWidth;
        const location = this._el.nativeElement.offsetLeft;
        if (location + 250 >= width) {
            this.findPosition(true);
        } else {
            this.findPosition(false);
        }
    }

    findPosition(offScreen: boolean): void {
        const card: number = this.tooltipIcon.nativeElement.offsetLeft;
        const win: number = window.innerWidth;
        let diff;
        if (offScreen) {
            diff = card - win + 300;
            this.offSet = diff < 500 ? 50 : -325;
        } else {
            diff = win - card;
            this.offSet = diff < 500 ? -325 : 50;
        }
    }

    select(): void {
        this.selectedProduct.emit(this.product);
    }

    flip(): void {
        this.flipped = !this.flipped;

        if (this.flipped) {
            GAEvent.CapturEvent(
                this._analytics,
                GAEvent.ACTION.LINK_CLICK,
                GAEvent.CATEGORY.ProductSelection,
                GAEvent.LABEL.Quick_Quote + '-' + this.product.name
            );
        }

        const qqr = this._quickQuote.buildQuickQuoteRequest(
            this.product.productId
        );
        if (
            this.rates === undefined ||
            (this.rates && this.rates.length === 0)
        ) {
            this.rateProgress = true;
            this._quickQuote.getQuickQuote(qqr).subscribe(
                (r) => {
                    this.rates = r;

                    if (r && r.length === 0) {
                        this.showRateError = true;
                    }
                    this.rateProgress = false;
                },
                (error) => {
                    this.rates = [];
                    this.showRateError = true;
                    this.rateProgress = false;
                }
            );
        }
    }
    checkForPartner() {
        if (
            !isNil(this.discountInformation) &&
            !isEmpty(this.discountInformation) &&
            this.discountInformation.discountInfo &&
            this.discountInformation.discountInfo.productDiscount &&
            this.discountInformation.discountInfo.productDiscount
                .displayDiscountInfo &&
            this.discountInformation.discountInfo.discountExcludedStates.indexOf(
                this.businessLocation[0].StateProvince
            ) < 0
        ) {
            return true;
        } else {
            return false;
        }
    }
}
