import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessTypeComponent } from './business-type.component';
import { InputModule, TypeaheadModule } from '@crux/components';

@NgModule({
    imports: [CommonModule, InputModule, TypeaheadModule],
    declarations: [BusinessTypeComponent],
    exports: [BusinessTypeComponent],
})
export class BusinessTypeModule {}
