export class ClassesByProduct {
    product: string;
    classid: string;
    classcode: string;
}

export class Classification {
    digitalClassCode: string;
    classId: string;
    name: string;
    description: string;
    tags: string;
    premiumBasis: string;
    classesByProduct?: any;
    classCode?: string;
    product?: string;
    keyWord?: string;
    wsgClassId: string;
}

export class PartnerClassificationReq {
    code: string;
    codeType: string;
}
