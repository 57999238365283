import { Classification } from './classification';
import { CustomerInfo } from './customer-info';
import { find } from 'lodash-es';
import { FraudDisclosure } from './fraud-disclosure';

export class QuoteRequestData {
    public selectedClass: Classification;
    public customerInfoData: CustomerInfo = new CustomerInfo();
    public confirm: any;
    public accept: any;
    public thirdpartyaccept: any;
    public initials: any;
    public stateId: object;
    public businessLocation: any;
    public ratingUWQuestions: any;
    public fraudDisclosures: any;
    public addressDetails: any = {};
    public revenue: number;

    public products: any;
    public productpackages: any[] = [];
    public productId: string;

    public totalPremium: number = 0;
}
