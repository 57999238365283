import { CruxHttpClientService } from '@crux/services';
import { find, isEmpty } from 'lodash';
import { environment } from '../../environments/environment';
import { Product } from '../model/product';

export class ProductSCI {
    private static _instance: ProductSCI;

    private blobURL = environment.blobStorageBaseURL;

    private _isInitialize: boolean;

    private _onlySCIProductSelection: boolean;

    constructor(enforcer: Enforcer) {}

    static instance(): ProductSCI {
        if (isEmpty(ProductSCI._instance)) {
            ProductSCI._instance = new ProductSCI(new Enforcer());
        }
        return ProductSCI._instance;
    }

    static getSCIProduct(products: Array<Product>): Array<Product> {
        products = products.filter(function (product) {
            return (
                product.productId !== 'MICROCYBER' &&
                product.productId !== 'MICROBOP' &&
                product.productId !== 'MICROGL'
            );
        });
        return products;
    }

    static filterSCIProduct(
        product: Product,
        products: Array<Product>
    ): boolean {
        const productId = product.productId;
        let displayProductIndicator = true;
        // Filter extra SCI products
        if (productId.startsWith('MICRO')) {
            const sciProduct = find(products, [
                'productId',
                productId.split('MICRO')[1],
            ]);
            if (sciProduct) {
                if (!sciProduct.selected) {
                    return displayProductIndicator;
                } else {
                    return ProductSCI.instance().onlySCIProductSelection; //fales to show back WSG product in product selection
                }
            } else {
                return displayProductIndicator;
            }
        } else {
            products.forEach((productFromStore) => {
                if (!product.selected) {
                    if ('MICRO' + productId === productFromStore.productId) {
                        displayProductIndicator = ProductSCI.instance()
                            .onlySCIProductSelection; //fales to show back WSG product in product selection
                    }
                } else {
                    displayProductIndicator = true;
                }
            });
        }
        return displayProductIndicator;
    }

    public initialize(http: CruxHttpClientService): void {
        if (!this._isInitialize) {
            const ts = new Date().getTime();
            http.get(
                this.blobURL + '/data/sci-product-selection.json?ts=' + ts,
                {
                    responseType: 'json',
                }
            ).subscribe((res) => {
                this._isInitialize = true;
                this._onlySCIProductSelection = res.onlySCIProductSelection;
            });
        }
    }

    public get isInitialize(): boolean {
        return this._isInitialize;
    }

    public get onlySCIProductSelection(): boolean {
        return this._onlySCIProductSelection;
    }
}

class Enforcer {}
