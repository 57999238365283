import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { select, Action, Store } from '@ngrx/store';
import { AppetiteActionTypes } from '../actions';
import {
    ProductActionTypes,
    SetProductsStatusAction,
} from '../../products-store/actions';
import { catchError, map, mergeMap, tap, switchMap } from 'rxjs/operators';
import { Classification } from '../../../model/classification';
import { ZipcodeModel } from '../../../model/zipcode';
import { CruxHttpClientService } from '@crux/services';
import { environment } from './../../../../environments/environment';
import { PartnerCustomization } from '../../../partner-customization/partner-customization.helper';
import { reject } from 'lodash-es';
import { Product } from '../../../model/product';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { AnalyticsService } from '@crux/services';
import { isNil, isEmpty } from 'lodash-es';
@Injectable()
export class AppetiteEffects {
    classification: Observable<Classification>;
    zipcodeData: Observable<ZipcodeModel[]>;
    revenue: Observable<number>;
    startQuote: Observable<boolean>;
    WSGProductApi: string = environment.backend + environment.uri.products;
    products: Product[];
    public productStatus: string;
    state: any;
    excludeWorkCompStates: any[] = environment.excludeWorkCompStates;
    constructor(
        private _action: Actions,
        private _http: CruxHttpClientService,
        private _ccs: CommonCommunicationService,
        private _analytics: AnalyticsService,
        private _store: Store<{
            classification: Classification;
            zipcode: ZipcodeModel[];
            revenue: number;
            startQuote: boolean;
        }>,
        private _partnerCustomization: PartnerCustomization
    ) {
        this.classification = this._store.pipe(select('classification'));
        this.zipcodeData = this._store.pipe(select('zipcode'));
        this.revenue = this._store.pipe(select('revenue'));
        this.startQuote = this._store.pipe(select('startQuote'));
    }

    /**
     * Start quote effect which will trigger set product action from products store
     */
    @Effect()
    getProducts$: Observable<any> = this._action
        .pipe(ofType(AppetiteActionTypes.StartQuote))
        .pipe(
            mergeMap((action) =>
                this._http
                    .post(
                        this.WSGProductApi.replace(
                            '#PARTNER#',
                            action['partner'] ? action['partner'] : '0'
                        ),
                        {
                            body: action['payload'],
                        }
                    )
                    .pipe(
                        tap(
                            (rawProductsFromService) => {
                                this.productStatus =
                                    rawProductsFromService.status;
                                this._store.dispatch(
                                    new SetProductsStatusAction(
                                        this.productStatus
                                    )
                                );

                                if (
                                    rawProductsFromService.status &&
                                    rawProductsFromService.status ===
                                        'Failure' &&
                                    rawProductsFromService.messageText
                                ) {
                                    //GA Event to track products returned
                                    if (
                                        rawProductsFromService.products &&
                                        (isNil(
                                            rawProductsFromService.products
                                        ) ||
                                            isEmpty(
                                                rawProductsFromService.products
                                            ) ||
                                            rawProductsFromService.products
                                                .length === 0)
                                    ) {
                                        this._analytics.eventTrack.next({
                                            category: 'API Performance',
                                            action: 'Appetite API - SUCCESS',
                                            label:
                                                'TTS: ' +
                                                action['payload'][
                                                    'digitalClassCode'
                                                ] +
                                                '; STATE: ' +
                                                action['payload']['stateId'] +
                                                ';  REVENUE: ' +
                                                action['payload'][
                                                    'annualRevenue'
                                                ] +
                                                '; PRODUCTS: ' +
                                                rawProductsFromService.messageText,
                                        });
                                    } else {
                                        this._analytics.eventTrack.next({
                                            category: 'API Performance',
                                            action: 'Appetite API - FAILURE',
                                            label:
                                                'TTS: ' +
                                                action['payload'][
                                                    'digitalClassCode'
                                                ] +
                                                '; STATE: ' +
                                                action['payload']['stateId'] +
                                                ';  REVENUE: ' +
                                                action['payload'][
                                                    'annualRevenue'
                                                ] +
                                                '; ERROR: ' +
                                                (rawProductsFromService.messageText
                                                    ? rawProductsFromService.messageText
                                                    : ''),
                                        });
                                    }
                                }

                                /**
                                 * For certain scenarios, products are getting as null,
                                 * convert them to empty array
                                 */
                                if (rawProductsFromService.products == null) {
                                    this.products = [];
                                } else {
                                    this.products =
                                        rawProductsFromService.products;
                                    let sciProducts = this.products.filter(
                                        (product) => {
                                            return (
                                                product.productSourceSystem ===
                                                'SCI'
                                            );
                                        }
                                    );
                                    //GA Event to track products returned
                                    this._analytics.eventTrack.next({
                                        category: 'API Performance',
                                        action: 'Appetite API - SUCCESS',
                                        label:
                                            'TTS: ' +
                                            action['payload'][
                                                'digitalClassCode'
                                            ] +
                                            '; STATE: ' +
                                            action['payload']['stateId'] +
                                            ';  REVENUE: ' +
                                            action['payload']['annualRevenue'] +
                                            '; PRODUCTS: ' +
                                            this.convertArrayItemsToString(
                                                sciProducts,
                                                'productId'
                                            ),
                                    });
                                }

                                if (this.products.length > 0) {
                                    let excludeWC = false;
                                    this.readZipcodeFromStore$().subscribe(
                                        (zipcode) => {
                                            this.state =
                                                zipcode[0]['StateProvince'];
                                        }
                                    );
                                    this.excludeWorkCompStates.forEach(
                                        (exState) => {
                                            if (exState === this.state) {
                                                excludeWC = true;
                                            }
                                        }
                                    );
                                    this.products = reject(
                                        this.products,
                                        (product) => {
                                            return (
                                                product.productId ===
                                                    'WORKCOMP' && excludeWC
                                            );
                                        }
                                    );
                                }

                                /* Temporary patch to add featured indicator in incoming products,
                            this has to be removed when API starts returning the featured flag */

                                this.products.forEach((product, index) => {
                                    /**
                                     * Patch to test if unique workfloworder works
                                     */
                                    // if (product.productId === 'BOP') {
                                    //     product.workflowOrder = 1;
                                    // } else if (
                                    //     product.productId === 'MICROBOP'
                                    // ) {
                                    //     product.workflowOrder = 2;
                                    // } else if (
                                    //     product.productId === 'MICROCYBER'
                                    // ) {
                                    //     product.workflowOrder = 6;
                                    // } else if (
                                    //     product.productId === 'MICROMPL'
                                    // ) {
                                    //     product.workflowOrder = 7;
                                    // } else if (
                                    //     product.productId === 'UMBRELLA'
                                    // ) {
                                    //     product.workflowOrder = 9;
                                    // } else if (product.productId === 'GL') {
                                    //     product.workflowOrder = 10;
                                    // } else if (
                                    //     product.productId === 'MICROGL'
                                    // ) {
                                    //     product.workflowOrder = 11;
                                    // }
                                    product.issuanceAttempted = false;
                                    if (product['isFeature'] !== undefined) {
                                        product['featured_ind'] =
                                            product['isFeature'];
                                    }
                                    const add_property: {
                                        [k: string]: any;
                                    } = {};
                                    add_property.amount = '';
                                    add_property.forPackage = '';
                                    add_property.selected = false;
                                    product['add_property'] = add_property;
                                    if (
                                        product.productId === 'MICROGL' ||
                                        product.productId === 'GL'
                                    ) {
                                        product.include_property = true;
                                    }
                                });
                            },
                            (error) => {
                                //GA Event to track when no response
                                this._analytics.eventTrack.next({
                                    category: 'API Performance',
                                    action: 'Appetite API - FAILURE',
                                    label:
                                        'TTS: ' +
                                        action['payload']['digitalClassCode'] +
                                        '; STATE: ' +
                                        action['payload']['stateId'] +
                                        ';  REVENUE: ' +
                                        action['payload']['annualRevenue'] +
                                        '; ERROR: Time Out',
                                });
                                this.productStatus = 'API Failure';
                                this._store.dispatch(
                                    new SetProductsStatusAction(
                                        this.productStatus
                                    )
                                );
                            }
                        ),
                        map(() => ({
                            type: ProductActionTypes.setProducts,
                            payload: this.products,
                        })),
                        catchError(() =>
                            of({
                                type: ProductActionTypes.setProductsError,
                                payload: [],
                            })
                        )
                    )
            )
        );

    /**
     * Read the Classification
     */
    readClassificationFromStore$(): Observable<Classification> {
        return this.classification;
    }

    /**
     * Read the zipcode object
     */
    readZipcodeFromStore$(): Observable<ZipcodeModel[]> {
        return this.zipcodeData;
    }

    /**
     * Read Revenue from store
     */
    readRevenueFromStore$(): Observable<number> {
        return this.revenue;
    }

    /**
     * Read start quote flag from store to check start quote is triggered
     */
    readStartQuoteFlag$(): Observable<boolean> {
        return this.startQuote;
    }

    convertArrayItemsToString = (array, prop) => {
        let output = '';
        array.forEach((item) => {
            if (output) {
                output += ',' + item[prop];
            } else {
                output += item[prop];
            }
        });
        return output;
    };
}
