import { map } from 'rxjs/operators';
import { CruxHttpClientService } from '@crux/services';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { CommonCommunicationService } from './common-communication.service';
import * as FileSaver from 'file-saver';

@Injectable()
export class DownloadCoiService {
    public url: string = environment.backend + environment.uri.validatePolicy;
    public coiurl: string = environment.backend + environment.uri.downloadCOI;
    public sciCoiUrl: string;
    public downloadpolicyUrl: string = environment.backend + environment.uri.downloadPolicy;

    constructor(
        private http: CruxHttpClientService,
        private _ccs: CommonCommunicationService
    ) {}

    validatePolicy(policyNumber, effectiveDate, postalCode): Observable<any> {
        const requestBody: any = {};
        requestBody.PolicyRequest = {};
        requestBody.PolicyRequest['@Type'] = 'Validate';
        requestBody.PolicyRequest.Credentials = {};
        requestBody.PolicyRequest.Credentials.ClientKey =
            'coverhound.commerciallines.micropackage.validation';
        requestBody.PolicyRequest.IsInternal = '1';
        requestBody.PolicyRequest.PolicyNumber = policyNumber;
        requestBody.PolicyRequest.EffectiveDate = effectiveDate;
        requestBody.PolicyRequest.ZipCode = postalCode;
        return this.http.post(this.url, { body: requestBody });
    }

    downloadCoi(policyToken): any {
        return this.http
            .get(this.coiurl, {
                headers: new HttpHeaders({
                    DigitalApiToken: policyToken,
                    'Content-Type': 'application/pdf',
                }),
                responseType: 'blob',
            })
            .pipe(
                map((res) => {
                    return new Blob([res], { type: 'application/pdf' });
                })
            );
    }
    sciDownloadCoi(requestBody: any): any {
        this.sciCoiUrl = environment.backend + environment.uri.sciDownloadCOI;
        return this.http
            .post(this.sciCoiUrl, {
                body: requestBody,
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                responseType: 'blob',
            })
            .pipe(
                map((res) => {
                    return new Blob([res], { type: 'application/pdf' });
                })
            );
    }

    downloadProposal(id: any): any {
        let downloadProposalurl = environment.backend + environment.uri.downloadProposal;
        downloadProposalurl = downloadProposalurl.replace(':quoteProposalId', id);
        return this.http
            .get(downloadProposalurl, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                responseType: 'blob',
            })
            .pipe(
                map((res) => {
                    return new Blob([res], { type: 'application/pdf' });
                })
            );
    }

    downloadPolicy(requestBody: any): any {
        return this.http
            .post(this.downloadpolicyUrl, {
                body: requestBody,
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                responseType: 'blob',
            })
            .pipe(
                map((res) => {                 
                    return res;
                })
            );
    }    
}
