export class QuoteStep {
    questionData: Array<any> = [];
    stepsComplete: boolean;
    insurance: any;
    revenue: any;
    zipcode: any;
    business: any;
    email: any;
    website: any;
}
