import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepOneRetrieveQuoteWrapperComponent } from './step-one-retrieve-quote-wrapper.component';
import { StepOneModule } from '../step-one/step-one.module';
import { RetrieveQuoteButtonModule } from '../retrieve-quote-button/retrieve-quote-button.module';

@NgModule({
    imports: [CommonModule, StepOneModule, RetrieveQuoteButtonModule],
    declarations: [StepOneRetrieveQuoteWrapperComponent],
    exports: [StepOneRetrieveQuoteWrapperComponent],
})
export class StepOneRetrieveQuoteWrapperModule {}
