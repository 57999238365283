import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
    selector: 'csb-gross-sales',
    templateUrl: './gross-sales.component.html',
    styleUrls: ['./gross-sales.component.scss'],
})
export class GrossSalesComponent {
    @Output() setRevenueInStore = new EventEmitter();

    @Input() placeholder: string = '';

    _setRevenueInStore(e) {
        if (e !== undefined) {
            e = e.target.value.toString().replace(/\D+/g, '');
        }
        this.setRevenueInStore.emit(e);
    }
}
