import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { CruxHttpClientService } from '@crux/services';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class LocationService {
    Location: any[] = [];
    locationServiceUrl: string = environment.backend + environment.uri.states;
    zipServiceUrl: string = environment.backend + environment.uri.validateZip;

    constructor(private _http: CruxHttpClientService) {}

    getLocationData(userInput?: string): any {
        let url = this.locationServiceUrl;
        if (
            userInput !== '' &&
            userInput !== null &&
            typeof userInput !== 'undefined'
        ) {
            url = url + '/' + userInput;
        }
        return this._http.get(url).pipe(
            catchError((error) => {
                return observableThrowError(error);
            })
        );
    }

    getStateFromZip(zipCode: string): any {
        let url = this.zipServiceUrl;
        if (zipCode) {
            url = url.replace('$search-term$', zipCode);
        }

        return this._http.get(url);
    }
}
