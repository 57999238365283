import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'productSelected',
})
export class ProductSelectedPipe implements PipeTransform {
    transform(items: any[], addedToCartFlag?: boolean): any {
        if (!items) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        if (!addedToCartFlag) {
            return items.filter((item) => item.selected === true);
        } else {
            return items.filter((item) => item.addedToCart === true); // filter components selected and added to cart.
        }
    }
}
export class NoReferralProductsPipe implements PipeTransform {
    transform(items: any[]): any {
        if (!items) {
            return items;
        }
        return items.filter((item) => item.selected === true);
    }
}
