import { Action } from '@ngrx/store';
import { BusinessClass } from '../../../model/p360-business-class';

export enum P360TotalClassesActionTypes {
    setP360TotalClasses = '[ set p360 all classes ] Set p360 all classes',
}

export class SetP360TotalClassesAction implements Action {
    readonly type = P360TotalClassesActionTypes.setP360TotalClasses;

    constructor(public payload: BusinessClass[]) {}
}

export type P360TotalClassesActionUnion = SetP360TotalClassesAction;
