import { QuoteActionTypes, QuoteActionUnion } from '../actions/quote.action';

export const issuaIntialCount = 0;

export function issueAttemptReducer(
    issueCountState = issuaIntialCount,
    action: QuoteActionUnion
) {
    switch (action.type) {
        case QuoteActionTypes.setIssuanceAttemp: {
            issueCountState = action.payload;
            return issueCountState;
        }
        default: {
            return issuaIntialCount;
        }
    }
}
