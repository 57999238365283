import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoverhoundComponent } from './coverhound.component';
import { RouterModule } from '@angular/router';
import { AnalyticsModule } from '@crux/services';

@NgModule({
    imports: [CommonModule, RouterModule, AnalyticsModule],
    declarations: [CoverhoundComponent],
    exports: [CoverhoundComponent],
})
export class CoverhoundModule {}
