import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { CruxHttpClientService } from '@crux/services';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class WcJobTypeService {
    constructor(private _http: CruxHttpClientService) {}

    public wcJobTypeUrl = environment.backend + environment.uri.wcjobtype;
    public wcOfficerTitleUrl =
        environment.backend + environment.uri.wcofficertitle;
    public wcEnityTypeUrl = environment.backend + environment.uri.wcentity;
    public url = environment.backend + environment.uri.wcjobtypecodes;

    getJobType(digitalCode, state) {
        return this._http.get(
            this.wcJobTypeUrl
                .replace('$digitalCode', digitalCode)
                .replace('$state', state)
        );
    }
    getMarketPlaceJobCodes(payload) {
        return this._http
            .post(this.url, {
                body: payload,
            })
            .pipe(
                catchError((error: Response) => {
                    return throwError(error);
                })
            );
    }
    getOfficerTitle(entityCode) {
        return this._http.get(
            this.wcOfficerTitleUrl.replace('$entityCode', entityCode)
        );
    }
    getCompanyStructure(entityCode, state) {
        return this._http.get(
            this.wcEnityTypeUrl
                .replace('$entityCode', entityCode)
                .replace('$state', state)
        );
    }
}
