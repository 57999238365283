import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { filter, isEmpty, isNil, map } from 'lodash-es';
import { AnalyticsService, CruxHttpClientService } from '@crux/services';
import { environment } from '../../environments/environment';
import { CommonCommunicationService } from './common-communication.service';
import { Store, select } from '@ngrx/store';
import { Classification } from '../model/classification';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class SciOptionalCoverageService {
    quoteServiceUrl: any[] = [];
    public classification: any;
    constructor(
        private http: CruxHttpClientService,
        private _ccs: CommonCommunicationService,
        private _analytics: AnalyticsService,
        private _store: Store<{
            classification: Classification;
        }>
    ) {
        this._store
            .pipe(select('classification'))
            .subscribe((classification) => {
                this.classification = classification;
            });
    }

    getOptionalCoverages(optionalCoverageRequestBody): any {
        let url = environment.backend + environment.uri.scioptionalcoverages;
        url = url.replace('#PARTNER#', this._ccs.PartnerId);
        if (
            optionalCoverageRequestBody != null ||
            optionalCoverageRequestBody != undefined
        ) {
            if (
                this._ccs.backendEnvVariable &&
                !isNil(this._ccs.backendEnvVariable) &&
                !isEmpty(this._ccs.backendEnvVariable)
            ) {
                const headers = {};
                headers['env'] = this._ccs.backendEnvVariable;
                return this.http
                    .post(url, {
                        body: optionalCoverageRequestBody,
                        headers: new HttpHeaders(headers),
                    })
                    .pipe(
                        catchError((error: Response) => {
                            try {
                                const chosenProducts = map(
                                    filter(
                                        this._ccs.quoteRequestData.products,
                                        {
                                            selected: true,
                                        }
                                    ),
                                    (product: any) => {
                                        return product['text'];
                                    }
                                ).join(';');

                                const chosenClassification = this.classification
                                    .name;

                                this._analytics.eventTrack.next({
                                    category: 'PackageSelection',
                                    action: 'OPTIONAL_COVERAGES_API_FAILURE',
                                    label:
                                        chosenClassification +
                                        ' : ' +
                                        chosenProducts,
                                });
                                return observableThrowError(error);
                            } catch (err) {}
                        })
                    );
            } else {
                return this.http
                    .post(url, { body: optionalCoverageRequestBody })
                    .pipe(
                        catchError((error: Response) => {
                            try {
                                const chosenProducts = map(
                                    filter(
                                        this._ccs.quoteRequestData.products,
                                        {
                                            selected: true,
                                        }
                                    ),
                                    (product: any) => {
                                        return product['text'];
                                    }
                                ).join(';');

                                const chosenClassification = this.classification
                                    .name;

                                this._analytics.eventTrack.next({
                                    category: 'PackageSelection',
                                    action: 'OPTIONAL_COVERAGES_API_FAILURE',
                                    label:
                                        chosenClassification +
                                        ' : ' +
                                        chosenProducts,
                                });
                                return observableThrowError(error);
                            } catch (err) {}
                        })
                    );
            }
        }
    }

    updateOptionalCoverages(optionalCoverageRequestBody): any {
        let url =
            environment.backend + environment.uri.sciupdateoptionalcoverages;
        url = url.replace('#PARTNER#', this._ccs.PartnerId);
        if (
            optionalCoverageRequestBody != null ||
            optionalCoverageRequestBody != undefined
        ) {
            if (
                this._ccs.backendEnvVariable &&
                !isNil(this._ccs.backendEnvVariable) &&
                !isEmpty(this._ccs.backendEnvVariable)
            ) {
                const headers = {};
                headers['env'] = this._ccs.backendEnvVariable;
                return this.http
                    .put(url, {
                        body: optionalCoverageRequestBody.payload,
                        headers: new HttpHeaders(headers),
                    })
                    .pipe(
                        catchError((error: Response) => {
                            try {
                                const chosenProducts = map(
                                    filter(
                                        this._ccs.quoteRequestData.products,
                                        {
                                            selected: true,
                                        }
                                    ),
                                    (product: any) => {
                                        return product['text'];
                                    }
                                ).join(';');

                                const chosenClassification =
                                    optionalCoverageRequestBody.classification
                                        .name;

                                this._analytics.eventTrack.next({
                                    category: 'PackageSelection',
                                    action: 'OPTIONAL_COVERAGES_API_FAILURE',
                                    label:
                                        chosenClassification +
                                        ' : ' +
                                        chosenProducts,
                                });
                                return observableThrowError(error);
                            } catch (err) {}
                        })
                    );
            } else {
                return this.http
                    .put(url, { body: optionalCoverageRequestBody.payload })
                    .pipe(
                        catchError((error: Response) => {
                            try {
                                const chosenProducts = map(
                                    filter(
                                        this._ccs.quoteRequestData.products,
                                        {
                                            selected: true,
                                        }
                                    ),
                                    (product: any) => {
                                        return product['text'];
                                    }
                                ).join(';');

                                const chosenClassification =
                                    optionalCoverageRequestBody.classification
                                        .name;

                                this._analytics.eventTrack.next({
                                    category: 'PackageSelection',
                                    action: 'OPTIONAL_COVERAGES_API_FAILURE',
                                    label:
                                        chosenClassification +
                                        ' : ' +
                                        chosenProducts,
                                });
                                return observableThrowError(error);
                            } catch (err) {}
                        })
                    );
            }
        }
    }

    buildSciOptionalCoveragesSaveRequest(optionalCoverages) {
        return createSaveRequest(optionalCoverages);
    }

    buildSciOptionalCoveragesRefreshRequest(
        optionalCoverages,
        field,
        type = 'row',
        groupId = null,
        rowId = null
    ) {
        return createRefreshRequest(
            optionalCoverages,
            field,
            groupId,
            rowId,
            type
        );
    }

    handleOptionalCoveragesGroupIds(optionalCoverages) {
        return handleOptionalCoveragesGroupIds(optionalCoverages);
    }

    handleOptionalCoveragesDropdownValues(optionalCoverages) {
        return handleOptionalCoveragesDropdownValues(optionalCoverages);
    }
}

const handleOptionalCoveragesGroupIds = (optionalCoveragesResponse) => {
    optionalCoveragesResponse.data.forEach((policy) => {
        policy.forms.forEach((form) => {
            let currentParentField;
            form.fieldSet.fields.forEach((formField) => {
                if (formField.fieldTemplate.displayMask === 'parentField') {
                    // create a group
                    currentParentField = formField;
                    currentParentField['groupIds'] = [];
                }
                if (
                    formField.fieldTemplate.controlType === 'text' &&
                    formField.fieldTemplate.displayMask !== 'formLink'
                ) {
                    currentParentField['optionalCoverageDescription'] =
                        formField.fieldTemplate.caption;
                }
                if (formField.fieldTemplate.controlType === 'group') {
                    if (
                        currentParentField &&
                        currentParentField.value &&
                        currentParentField.value === '1'
                    ) {
                        currentParentField['groupIds'].push(formField.id);
                    }
                }
            });
        });
    });
    return optionalCoveragesResponse;
};

const handleOptionalCoveragesDropdownValues = (optionalCoveragesResponse) => {
    optionalCoveragesResponse.data.forEach((policy) => {
        policy.forms.forEach((form) => {
            if (form.fieldSet.groups) {
                form.fieldSet.groups.forEach((group) => {
                    group.rows.forEach((row) => {
                        row.fieldSet.fields.forEach((field) => {
                            if (field.fieldTemplate.controlType === 'select') {
                                field.fieldTemplate['cruxDropdownOptions'] = [
                                    ...createDropdownArray(
                                        field.fieldTemplate.options
                                    ),
                                ];
                            }
                        });
                        if (
                            row.fieldSet.groups &&
                            row.fieldSet.groups.length &&
                            row.fieldSet.groups.length > 0
                        ) {
                            row.fieldSet.groups.forEach((childGroup) => {
                                childGroup.rows.forEach((childRow) => {
                                    childRow.fieldSet.fields.forEach(
                                        (childField) => {
                                            if (
                                                childField.fieldTemplate
                                                    .controlType === 'select'
                                            ) {
                                                childField.fieldTemplate[
                                                    'cruxDropdownOptions'
                                                ] = [
                                                    ...createDropdownArray(
                                                        childField.fieldTemplate
                                                            .options
                                                    ),
                                                ];
                                            }
                                        }
                                    );
                                });
                            });
                        }
                    });
                });
            }
        });
    });
    return optionalCoveragesResponse;
};

const createDropdownArray = (options) => {
    let dropdownArray = [];
    options.forEach((option) => {
        let dropdownArrayItem = {};
        dropdownArrayItem['text'] = option.caption;
        dropdownArrayItem['value'] = option.value;
        dropdownArray.push(dropdownArrayItem);
    });
    return dropdownArray;
};

const createSaveRequest = (optionalCoverages) => {
    let saveRequest = { ...optionalCoverages.data[0] };
    saveRequest['pageAction'] = {};
    saveRequest['pageAction'].formId = 'OptionalCoverages';
    saveRequest['pageAction'].actionType = 'Save';
    return saveRequest;
};

const createRefreshRequest = (
    optionalCoverages,
    field,
    groupId,
    rowId,
    type
) => {
    let refreshRequest = { ...optionalCoverages.data[0] };
    refreshRequest['pageAction'] = {};
    if (type === 'row') {
        refreshRequest['pageAction'].groupId = groupId;
        refreshRequest['pageAction'].rowId = rowId;
    }
    refreshRequest['pageAction'].formId = 'OptionalCoverages';
    refreshRequest['pageAction'].fieldName = field.name;
    refreshRequest['pageAction'].actionType = 'RefreshOnChange';
    return refreshRequest;
};
