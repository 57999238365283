import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketingCoverageComponent } from './marketing-coverage.component';
import { AnalyticsModule } from '@crux/services';
import { TooltipModule } from '@crux/components';

@NgModule({
    imports: [CommonModule, AnalyticsModule, TooltipModule],
    declarations: [MarketingCoverageComponent],
    exports: [MarketingCoverageComponent],
})
export class MarketingCoverageModule {}
