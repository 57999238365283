import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DictionaryService } from '../../../../../services/dictionary.service';
import { Product } from '../../../../../model/product';
import { select, Store } from '@ngrx/store';
import { AffinityPartnerFlags } from '../../../../../model/affinity-partner';
import { isEmpty, isNil } from 'lodash';
import { ZipcodeModel } from '../../../../../model/zipcode';
import { CommonCommunicationService } from '../../../../../services/common-communication.service';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
    @Input() product: Product;
    @Input() showUmbrellaError: boolean;
    @Input() alternateProductsExist: boolean;
    @Output() emitUmbrellaError: EventEmitter<boolean> = new EventEmitter();
    @Output() selectedProduct: EventEmitter<Product> = new EventEmitter();

    public labelDictionary: any;
    AffinityPartnerFlags: AffinityPartnerFlags;
    businessLocation: any;
    discountInformation: any;

    constructor(
        public dictionaryService: DictionaryService,
        private ccs: CommonCommunicationService,
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
            zipcode: ZipcodeModel;
            producerCodesInfo: any;
        }>
    ) {
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.AffinityPartnerFlags = AffinityPartnerFlags;
                    }
                }
            });

        this._store.pipe(select('zipcode')).subscribe((zipData) => {
            this.businessLocation = zipData;
        });

        this._store
            .pipe(select('producerCodesInfo'))
            .subscribe((producerCodesInfo) => {
                if (!isNil(producerCodesInfo) && !isEmpty(producerCodesInfo)) {
                    producerCodesInfo.forEach((partner) => {
                        if (
                            this.AffinityPartnerFlags &&
                            this.AffinityPartnerFlags.partnercode &&
                            partner.partnerId ===
                                this.AffinityPartnerFlags.partnercode
                        ) {
                            this.discountInformation = partner;
                        }
                    });
                }
            });
    }

    ngOnInit() {
        this.labelDictionary = this.dictionaryService.labelDict;
    }

    ngAfterViewInit() {
        var styleElem = document.head.appendChild(
            document.createElement('style')
        );
        styleElem.innerHTML =
            '.products-layout-title {color: ' +
            this.ccs.globalColorHeader +
            ' !important;}';

        var styleElemBtn = document.head.appendChild(
            document.createElement('style')
        );
        styleElemBtn.innerHTML =
            '.continue-btn-btn {background-color: ' +
            this.ccs.globalColorHeader +
            ' !important;}';

        var styleElemDisableBtn = document.head.appendChild(
            document.createElement('style')
        );
        styleElemDisableBtn.innerHTML =
            '.continue-btn-btn[disabled] {background-color: #d2d3d4 !important;}';

        var styleElemCheckBtn = document.head.appendChild(
            document.createElement('style')
        );
        styleElemCheckBtn.innerHTML =
            '.selection-btn-btn.Added {background: ' +
            this.ccs.globalColorHeader +
            ' !important;}';

        var styleElemtoggleBtn = document.head.appendChild(
            document.createElement('style')
        );
        styleElemtoggleBtn.innerHTML =
            '.selection-btn-btn {border: 1px solid ' +
            this.ccs.globalColorHeader +
            ' !important;}';
    }

    selectProduct(productObj): void {
        if (productObj.displayProduct === true) {
            this.selectedProduct.emit(productObj);
        }
    }

    closeMessage() {
        this.showUmbrellaError = false;
        this.emitUmbrellaError.emit(false);
    }

    checkForPartner() {
        if (
            !isNil(this.discountInformation) &&
            !isEmpty(this.discountInformation) &&
            this.discountInformation.discountInfo &&
            this.discountInformation.discountInfo.productDiscount &&
            this.discountInformation.discountInfo.productDiscount
                .displayDiscountInfo &&
            this.discountInformation.discountInfo.discountExcludedStates.indexOf(
                this.businessLocation[0].StateProvince
            ) < 0
        ) {
            return true;
        } else {
            return false;
        }
    }
}
