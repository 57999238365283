import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'csb-zip-code',
    templateUrl: './zip-code.component.html',
    styleUrls: ['./zip-code.component.scss'],
})
export class ZipCodeComponent {
    @Input() errorMessage: string = '';

    @Input() error: boolean = false;

    @Input() theme: string = '';

    @Input() zipcodePlaceholder: string = '';

    @Input() dropdownPlaceholder: string = '';

    @Input() isBusinessClassEditMode: string = '';

    @Input() zipServiceUrl: string = '';

    @Input() zipCodeShown: string = '';

    @Input() cityList: any[] = [];

    @Input() city: string = '';

    @Input() stateProvince: string = '';

    @Output() setMulticityData = new EventEmitter();

    @Output() resetPevValue = new EventEmitter();

    @Output() zipCodeDetails = new EventEmitter();

    _zipCodeDetails(validateResult) {
        this.zipCodeDetails.emit(validateResult);
    }

    _setMulticityData(citySelection) {
        this.setMulticityData.emit(citySelection);
    }

    _resetPevValue() {
        this.resetPevValue.emit();
    }
}
