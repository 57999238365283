import { Action } from '@ngrx/store';

export enum LoadingModalFactsActionTypes {
    LoadingModalFacts = '[ set loading modal facts ] Set loading modal facts from blob storage',
}

export class SetLoadingModalFactsAction implements Action {
    readonly type = LoadingModalFactsActionTypes.LoadingModalFacts;

    constructor(public payload: any) {}
}

export type LoadingModalFactsActionUnion = SetLoadingModalFactsAction;
