import { QuestionsActionTypes, QuestionsActionUnion } from '../actions';

const initialState = [];

export function addInfoReducer(
    addInfoState = initialState,
    action: QuestionsActionUnion
) {
    switch (action.type) {
        case QuestionsActionTypes.isAdditionalInfo: {
            addInfoState.push(action.payload);
            return addInfoState;
        }

        default: {
            return initialState;
        }
    }
}
