import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsbCoreZipcodeComponent } from './csb-core-zipcode.component';
import { CsbZipcodeService } from './csb-zipcode.service';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

// CRUX HTTP Client Providers
import { CruxHttpClientService, cruxHttpClient } from '@crux/services';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [CsbCoreZipcodeComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatFormFieldModule,
        MatInputModule,
        FlexLayoutModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        // CRUX Http Client Service
        {
            provide: CruxHttpClientService,
            useFactory: cruxHttpClient,
            deps: [HttpClient],
        },
        // CRUX Zip Code Service
        CsbZipcodeService,
    ],
    exports: [CsbCoreZipcodeComponent],
})
export class CsbCoreZipcodeModule {}
