import { Injectable } from '@angular/core';
import { CruxHttpClientService } from '@crux/services';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {
    EligibilityQuestionModel,
    EligibilityPayloadModel,
} from '../model/eligibility';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable()
export class EligibilityQuestionsService {
    public url = environment.backend + environment.uri.eligibilityQuestions;

    constructor(private http: CruxHttpClientService) {}

    getEligibilityQuestions(
        eligibilityQuestionsPayload: EligibilityPayloadModel
    ): Observable<any> {
        return this.http
            .post(this.url, {
                body: eligibilityQuestionsPayload,
            })
            .pipe(
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
    }
}
