import { Input, Output, EventEmitter, Directive } from '@angular/core';

@Directive()
export abstract class ControlBase {
    @Input() disabled = false;

    // ToDo: probably we can move `name` and `placeholder` input here as well

    @Input() label: string;

    @Input() appearance: 'legacy' | 'chubb' | 'material' = 'material';

    @Input() floatLabel: 'never' | 'always' | 'auto' = 'auto';

    @Input() autofocus = false;

    @Output() valueChange = new EventEmitter<any>();

    get isLegacyAppearance() {
        return this.appearance === 'legacy';
    }

    get isChubbAppearance(): boolean {
        return this.appearance === 'chubb';
    }

    setLabelFloatBehavior() {
        if (this.isLegacyAppearance) {
            this.floatLabel = 'never';
        }
    }
}
