import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './progress-bar.component';
import { FloatingLeftColumnModule } from '../quote/home-page/floating-left-column/floating-left-column.module';
import { NgCircleProgressModule } from 'ng-circle-progress';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FloatingLeftColumnModule,
        NgCircleProgressModule.forRoot({}),
    ],
    declarations: [ProgressBarComponent],
    exports: [ProgressBarComponent],
})
export class ProgressBarModule {}
