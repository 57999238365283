import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeBannerComponent } from './home-banner.component';
import { AnalyticsModule } from '@crux/services';

@NgModule({
    declarations: [HomeBannerComponent],
    imports: [CommonModule, AnalyticsModule],
    exports: [HomeBannerComponent],
})
export class HomeBannerModule {}
