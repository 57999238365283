import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CallUsNumberModel } from '../../model/callUsNumber';
import { CommonCommunicationService } from '../../services/common-communication.service';
import edeliveryContent from '../../../assets/json/e-delivery.json';
@Component({
    selector: 'app-e-delivery',
    templateUrl: './e-delivery.component.html',
    styleUrls: ['./e-delivery.component.scss'],
})
export class EDeliveryComponent implements OnInit {
    isWidgetView = false;
    public commonCommunicationService: CommonCommunicationService;
    callUsNumber: CallUsNumberModel;
    @Input() edeliveryData: any;
    constructor(public router: Router, public ccs: CommonCommunicationService) {
        if (this.router.url.includes('legal/e-delivery-consent')) {
            this.isWidgetView = true;
        }
    }

    ngOnInit() {
        this.callUsNumber = this.ccs.callUsNumber();
    }
    printDisclosure() {
        window.print();
    }
}
