export class OfficersCoverage {
    name: string;
    title: string;
    incExcCode: string;
    state: string;
    entityTypeCode: string;
    percentageOwnerShip: any;
}

export class JobType {
    addressRef: any;
    workCompRateClasses: [WorkCompRateClass];
}

export class WorkCompRateClass {
    fullTimeEmployeeCount: any;
    partTimeEmployeeCount: any;
    exposure: any;
    jobTypeData: any;
    wcClassCode: any;
    wcClassDescId: any;
    wcClassPremiumAmt: any;
    category: any;
}
