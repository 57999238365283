import { Action } from '@ngrx/store';

export enum QuoteActionTypes {
    setIsDisqualified = '[boolean] set disqualification',
    setNumberOfSelectedPackages = '[number] set number of selected packages',
    setSummaryReviewed = '[boolean] Set true if Application Summary reviewed',
    setIssuanceAttemp = '[number] Set total count of issued and failed policies',
}

export class SetIsDisqualifiedAction implements Action {
    readonly type = QuoteActionTypes.setIsDisqualified;
    constructor(public payload: boolean) {}
}

export class SetNumberOfSelectedPackagesAction implements Action {
    readonly type = QuoteActionTypes.setNumberOfSelectedPackages;
    constructor(public payload: number) {}
}
export class SetSummaryReviewedAction implements Action {
    readonly type = QuoteActionTypes.setSummaryReviewed;
}
export class SetIssueAttemptCountAction implements Action {
    readonly type = QuoteActionTypes.setIssuanceAttemp;
    constructor(public payload: number) {}
}

export type QuoteActionUnion =
    | SetIsDisqualifiedAction
    | SetNumberOfSelectedPackagesAction
    | SetSummaryReviewedAction
    | SetIssueAttemptCountAction;
