export const InitialQuestions = [
    {
        group_name: 'Initial Questions WSG',
        Type: 'QuestionGroup',
        questions: [
            {
                question_id: 101,
                iso_code: [],
                text: 'Business Type',
                value: '',
                tooltip: 'Business Type',
                required: true,
                minlength: 0,
                maxlength: 100,
                type: 2,
                parent_question_id: 'OPTIONAL',
                parent_trigger_value: 'OPTIONAL',
                length: 60,
                disqualifyingValue: 'NA',
                disqualifyingOperator: 'NA',
            },
            {
                question_id: 102,
                iso_code: [],
                text: 'Zip Code',
                value: '',
                tooltip: 'Zip Code',
                required: true,
                minlength: 0,
                maxlength: 100,
                type: 2,
                parent_question_id: 'OPTIONAL',
                parent_trigger_value: 'OPTIONAL',
                length: 60,
                disqualifyingValue: 'NA',
                disqualifyingOperator: 'NA',
            },
        ],
    },
    {
        group_name: 'Initial Questions SCI',
        Type: 'QuestionGroup',
        questions: [
            {
                question_id: 101,
                iso_code: [],
                text: 'Business Type',
                value: '',
                tooltip: 'Business Type',
                required: true,
                minlength: 0,
                maxlength: 100,
                type: 2,
                parent_question_id: 'OPTIONAL',
                parent_trigger_value: 'OPTIONAL',
                length: 60,
                disqualifyingValue: 'NA',
                disqualifyingOperator: 'NA',
            },
            {
                question_id: 102,
                iso_code: [],
                text: 'Zip Code',
                value: '',
                tooltip: 'Zip Code',
                required: true,
                minlength: 0,
                maxlength: 100,
                type: 2,
                parent_question_id: 'OPTIONAL',
                parent_trigger_value: 'OPTIONAL',
                length: 60,
                disqualifyingValue: 'NA',
                disqualifyingOperator: 'NA',
            },
            {
                question_id: 103,
                iso_code: [],
                text: 'Gross Revenue',
                value: '',
                tooltip: 'Gross Revenue',
                required: true,
                minlength: 0,
                maxlength: 100,
                type: 2,
                parent_question_id: 'OPTIONAL',
                parent_trigger_value: 'OPTIONAL',
                length: 60,
                disqualifyingValue: 'NA',
                disqualifyingOperator: 'NA',
            },
        ],
    },
];
