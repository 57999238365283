import { environment } from './../../environments/environment';
import { format, addYears } from 'date-fns';
import { Product } from '../../app/model/product';
import {
    getContractNumber,
    getPartnerLogo,
} from '../../app/services/sci-rate.service';
import { isEmpty, isNil } from 'lodash';

export class UserQuoteSubmission {
    public userQuoteSubmissionPayload = {
        ContactKey: 'qid_contactKey',
        EventDefinitionKey: 'qid_eventKey',
        EstablishContactKey: true,
        Data: {
            EmailAddress: 'qid_contactKey',
            subjectline: 'qid_subjectLine',
            PartnerLogo: 'qid_logo',
            AgencyName: 'qid_agencyName',
            AgentFullName: 'qid_agentName',
            AgentEmail: 'qid_agentEmail',
            OperationHours: 'qid_opHrs',
            Flow: 'qid_flow',
            Mobile: 'qid_telNumber',
            InsuredName: 'qid_insuredName',
            Product1: 'qid_product1',
            YearlyPremiumProduct1: 'qid_premium1',
            QuoteNumber1: 'qid_quote1',
            EffectiveDate1: 'qid_effectiveDate1',
            Product2: 'qid_product2',
            YearlyPremiumProduct2: 'qid_premium2',
            QuoteNumber2: 'qid_quote2',
            EffectiveDate2: 'qid_effectiveDate2',
            Product3: 'qid_product3',
            YearlyPremiumProduct3: 'qid_premium3',
            QuoteNumber3: 'qid_quote3',
            EffectiveDate3: 'qid_effectiveDate3',
            Product4: 'qid_product4',
            YearlyPremiumProduct4: 'qid_premium4',
            QuoteNumber4: 'qid_quote4',
            EffectiveDate4: 'qid_effectiveDate4',
            Product5: 'qid_product5',
            YearlyPremiumProduct5: 'qid_premium5',
            QuoteNumber5: 'qid_quote5',
            EffectiveDate5: 'qid_effectiveDate5',
            Product6: 'qid_product6',
            YearlyPremiumProduct6: 'qid_premium6',
            QuoteNumber6: 'qid_quote6',
            EffectiveDate6: 'qid_effectiveDate6',
            Product7: 'qid_product7',
            YearlyPremiumProduct7: 'qid_premium7',
            QuoteNumber7: 'qid_quote7',
            EffectiveDate7: 'qid_effectiveDate7',
            CoverageQuestion: 'qid_coverage',
            Comments: 'qid_comments',
            timestamp: 'qid_timestamp',
        },
    };

    // Build quote submission payload
    public buildUserQuoteSubmissionRequest(
        quoteSubmissionPayload: any,
        simpleQuestionsObj: any,
        products: any,
        partnerId: any,
        agencyInfo: any,
        customerInfoData: any,
        referData: any,
        agentEmail: any,
        partnerNameForEmail: string,
        isCisaFlow: boolean
    ) {
        let state: string;
        if (simpleQuestionsObj && simpleQuestionsObj.length > 0) {
            simpleQuestionsObj.forEach((questionGroup) => {
                if (
                    questionGroup.group_name === 'Business Address' &&
                    questionGroup.questions &&
                    questionGroup.questions.length > 0
                ) {
                    questionGroup.questions.forEach((question) => {
                        if (
                            question.question_id === '6' &&
                            !isNil(question.value) &&
                            !isEmpty(question.value)
                        ) {
                            state = question.value;
                        }
                    });
                }
            });
        }
        if (
            typeof quoteSubmissionPayload !== 'object' ||
            quoteSubmissionPayload === undefined ||
            products.length === 0
        ) {
            return;
        } else {
            for (const item in quoteSubmissionPayload) {
                if (
                    typeof quoteSubmissionPayload[item] === 'string' &&
                    quoteSubmissionPayload[item].includes('qid')
                ) {
                    if (
                        typeof simpleQuestionsObj[
                            quoteSubmissionPayload[item]
                        ] !== 'undefined'
                    ) {
                        quoteSubmissionPayload[item] =
                            simpleQuestionsObj[quoteSubmissionPayload[item]]
                                .value || '';
                    } else {
                        // Get the business name from the user answers
                        let businessName: any;
                        let effectiveDate: any;
                        simpleQuestionsObj.forEach((questionGroup) => {
                            if (
                                questionGroup.group_name ===
                                'Universal Questions'
                            ) {
                                questionGroup.questions.forEach((question) => {
                                    if (question.question_id === '1') {
                                        // Company name
                                        businessName = question.value;
                                    }
                                });
                            } else if (
                                questionGroup.group_name ===
                                'Coverage Start Date'
                            ) {
                                questionGroup.questions.forEach((question) => {
                                    effectiveDate = question.value;
                                });
                            }
                        });

                        // data from user answers
                        switch (quoteSubmissionPayload[item]) {
                            case 'qid_contactKey':
                                quoteSubmissionPayload[item] =
                                    customerInfoData.emailAddress;
                                break;

                            case 'qid_eventKey':
                                quoteSubmissionPayload[item] =
                                    environment.userQuoteSubmissionEventKey;
                                break;

                            case 'qid_subjectLine':
                                quoteSubmissionPayload[item] = isCisaFlow
                                    ? "We've received your quote request for " +
                                      businessName +
                                      '!'
                                    : 'We have received your Quote Request for ' +
                                      businessName +
                                      '!';
                                break;

                            case 'qid_logo':
                                quoteSubmissionPayload[item] = getPartnerLogo(
                                    partnerId
                                );
                                break;

                            case 'qid_agencyName':
                                quoteSubmissionPayload[item] =
                                    agencyInfo.agency;
                                break;

                            case 'qid_agentName':
                                quoteSubmissionPayload[item] =
                                    agencyInfo.agentName;
                                break;

                            case 'qid_flow':
                                quoteSubmissionPayload[item] = isCisaFlow
                                    ? 'CISA'
                                    : '';
                                break;

                            case 'qid_agentEmail':
                                quoteSubmissionPayload[item] =
                                    agencyInfo.agentEmail;
                                break;

                            case 'qid_telNumber':
                                quoteSubmissionPayload[item] =
                                    agencyInfo.agentMobile;
                                break;

                            case 'qid_opHrs':
                                quoteSubmissionPayload[item] =
                                    agencyInfo.agentHrsOfOperation;
                                break;

                            case 'qid_insuredName':
                                quoteSubmissionPayload[item] = businessName;
                                break;

                            case 'qid_coverage':
                                quoteSubmissionPayload[item] =
                                    referData &&
                                    referData.referReason &&
                                    referData.referReason.value
                                        ? referData.referReason.value
                                        : '';
                                break;

                            case 'qid_comments':
                                quoteSubmissionPayload[item] =
                                    referData &&
                                    referData.referExplaination &&
                                    referData.referExplaination.value
                                        ? referData.referExplaination.value
                                        : '';
                                break;

                            case 'qid_timestamp':
                                quoteSubmissionPayload[item] = format(
                                    new Date(),
                                    'MM/dd/yyyy h:mm:ss'
                                );
                                break;
                        }

                        // data from products selected
                        products.forEach((product: Product) => {
                            // Only send products that have been added to the cart
                            if (
                                product.selected ||
                                (product.productSourceSystem === 'SCI' &&
                                    parseInt(
                                        product.premium_amount.Premium,
                                        10
                                    ) > 0 &&
                                    product.productId !== 'UMBRELLA')
                            ) {
                                const seqOfProduct =
                                    products.indexOf(product) + 1;
                                switch (quoteSubmissionPayload[item]) {
                                    case 'qid_quote' + seqOfProduct: // quote numbers
                                        if (
                                            product.quote_number &&
                                            (!product.errorMessage ||
                                                isNil(product.errorMessage) ||
                                                isEmpty(product.errorMessage))
                                        ) {
                                            quoteSubmissionPayload[item] =
                                                product.quote_number;
                                        }
                                        break;

                                    case 'qid_product' + seqOfProduct: // product name
                                        if (product.quote_number) {
                                            quoteSubmissionPayload[item] =
                                                product.name;
                                        }
                                        break;

                                    case 'qid_effectiveDate' + seqOfProduct: // product effective date:
                                        if (product.quote_number) {
                                            quoteSubmissionPayload[
                                                item
                                            ] = effectiveDate;
                                        }
                                        break;

                                    case 'qid_premium' + seqOfProduct: // yearly premium value
                                        if (
                                            product.quote_number &&
                                            product.errorMessage != null
                                        ) {
                                            if (
                                                product.productSourceSystem !==
                                                    'SCI' &&
                                                (product.errorMessage !==
                                                    'Referral' ||
                                                    product.errorMessage !==
                                                        'Declined' ||
                                                    product.errorMessage !==
                                                        'Decline')
                                            ) {
                                                if (product.selectedpackage) {
                                                    quoteSubmissionPayload[
                                                        item
                                                    ] = Number.parseFloat(
                                                        product.selectedpackage
                                                            .premiumAmount
                                                    ).toFixed(2);
                                                } else {
                                                    quoteSubmissionPayload[
                                                        item
                                                    ] = Number.parseFloat(
                                                        product
                                                            .productPackages[0]
                                                            .premiumAmount
                                                    ).toFixed(2);
                                                }
                                            } else if (
                                                product.premium_amount &&
                                                (product.errorMessage !==
                                                    'Referral' ||
                                                    product.errorMessage !==
                                                        'Declined' ||
                                                    product.errorMessage !==
                                                        'Decline')
                                            ) {
                                                quoteSubmissionPayload[item] =
                                                    '$' +
                                                    Number.parseFloat(
                                                        product.premium_amount
                                                            .Premium
                                                    ).toFixed(2) +
                                                    '/year';
                                            }
                                        }
                                        break;
                                }
                            }
                        });
                        // After parsing if any qid is found make it empty
                        if (
                            quoteSubmissionPayload[item] !== undefined &&
                            quoteSubmissionPayload[item] !== null &&
                            quoteSubmissionPayload[item]
                                .toString()
                                .includes('qid')
                        ) {
                            quoteSubmissionPayload[item] = '';
                        }
                    }
                } else if (typeof quoteSubmissionPayload[item] === 'object') {
                    if (Array.isArray(quoteSubmissionPayload[item])) {
                        for (
                            let j = 0;
                            j < quoteSubmissionPayload[item].length;
                            j++
                        ) {
                            this.buildUserQuoteSubmissionRequest(
                                quoteSubmissionPayload[item][j],
                                simpleQuestionsObj,
                                products,
                                partnerId,
                                agencyInfo,
                                customerInfoData,
                                referData,
                                agentEmail,
                                partnerNameForEmail,
                                isCisaFlow
                            );
                        }
                    } else {
                        if (
                            Object.keys(quoteSubmissionPayload[item]).length !==
                            0
                        ) {
                            this.buildUserQuoteSubmissionRequest(
                                quoteSubmissionPayload[item],
                                simpleQuestionsObj,
                                products,
                                partnerId,
                                agencyInfo,
                                customerInfoData,
                                referData,
                                agentEmail,
                                partnerNameForEmail,
                                isCisaFlow
                            );
                        }
                    }
                }
            }
        }
        return quoteSubmissionPayload;
    }
}
