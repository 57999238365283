import {
    Component,
    OnInit,
    Inject,
    Input,
    ChangeDetectorRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticsService } from '@crux/services';
import { environment } from '../../../environments/environment';
import { CommonCommunicationService } from '../../services/common-communication.service';
@Component({
    selector: 'app-squarespace-model',
    templateUrl: './squarespace-model.component.html',
    styleUrls: ['./squarespace-model.component.scss'],
})
export class SquarespaceModelComponent implements OnInit {
    blobStorage = environment.blobStorageBaseURL;
    constructor(
        private _router: Router,
        private _analytics: AnalyticsService,
        public dialogRef: MatDialogRef<SquarespaceModelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public _ccs: CommonCommunicationService
    ) {}

    ngOnInit() {}

    onNoClick() {
        this._analytics.eventTrack.next({
            category: 'WELCOMEMODALPOPUP',
            action: 'WELCOMEMODALPOPUP_DETAILS',
            label: 'CLOSE WELCOME MODAL COMPONENT',
        });
        this.dialogRef.close();
    }

    navigateTo(url, text, navigateToExSite) {
        this._analytics.eventTrack.next({
            category: 'WELCOMEMODALPOPUP',
            action: 'WELCOMEMODALPOPUP_NAVIGATION',
            label: 'NAVIGATE TO ' + text.toUpperCase(),
        });
        this.dialogRef.close();
        if (navigateToExSite) {
            location.href = url;
        } else {
            this._router.navigate([url]);
        }
    }
}
