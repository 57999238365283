import {
    LoadingModalFactsActionTypes,
    LoadingModalFactsActionUnion,
} from './../actions';

export let LoadingModalFactsInitial: any;

export function LoadingModalFactsReducer(
    LoadingModalFacts = LoadingModalFactsInitial,
    action: LoadingModalFactsActionUnion
) {
    switch (action.type) {
        // Flag Setter
        case LoadingModalFactsActionTypes.LoadingModalFacts: {
            LoadingModalFacts = action.payload;
            return LoadingModalFacts;
        }

        default: {
            return LoadingModalFacts;
        }
    }
}
