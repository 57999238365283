import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentSectionTileComponent } from './content-section-tile.component';
import { ButtonModule } from '@crux/components';

@NgModule({
    declarations: [ContentSectionTileComponent],
    imports: [CommonModule, ButtonModule],
    exports: [ContentSectionTileComponent],
})
export class ContentSectionTileModule {}
