import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { CarouselData } from '../model/carousel-video';
import { Router } from '@angular/router';
import { CommonCommunicationService } from '../../../../services/common-communication.service';
import { GAEvent } from '../../../../csb-core/ga-event';
import { AnalyticsService } from '@crux/services';

@Component({
    selector: 'app-carousel-item-view',
    templateUrl: './carousel-item-view.component.html',
    styleUrls: ['./carousel-item-view.component.scss'],
})
export class CarouselItemViewComponent implements OnInit {
    @Input() carouselData: CarouselData;

    @Input() buttonName: string;

    @Input() linkTitle: string;

    @Input() currentIndex: number;

    @Input() nextIndex: number;

    @Input() nextPlusOneIndex: number;

    @Input() total: number;

    @Input() nextItem: CarouselData;

    @Input() nextPlusOneItem: CarouselData;

    @Output() moveTo: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('videoCarousel') videoCarousel: ElementRef;

    constructor(
        public router: Router,
        public _ccs: CommonCommunicationService,
        private _analytics: AnalyticsService
    ) {}

    ngOnInit() {
        this._ccs.scrollToVideosSection = this.videoCarousel;
    }

    onClickHandler(event) {
        GAEvent.CapturEvent(
            this._analytics,
            GAEvent.ACTION.BUTTON_CLICK,
            GAEvent.CATEGORY.Start_Cover_Grow,
            GAEvent.LABEL.NEW_QUOTE
        );
        this.router.navigate(['/get-a-quote']);
    }

    handleMoveTo(value) {
        this.moveTo.emit(value);
    }
}
