import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';

/**
 * Store imports
 */
import { Store, select } from '@ngrx/store';

/**
 * Partner Customization
 */
import { PartnerCustomization } from '../../../partner-customization/partner-customization.helper';

@Injectable()
export class ProductsGuard implements CanActivate {
    public startQuoteFlag: boolean = false;
    constructor(
        private _store: Store<{ startQuote: boolean }>,
        private _partnerCustomization: PartnerCustomization
    ) {
        this._store.pipe(select('startQuote')).subscribe((flag) => {
            this.startQuoteFlag = flag;
        });
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        /**
         *Only load the route if startQuote action is triggered
         *
         */
        if (this.startQuoteFlag) {
            return true;
        }

        /**
         * Navigate to home when the above condition does not match
         */
        this._partnerCustomization.goToHome();
    }
}
