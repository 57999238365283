import { Action } from '@ngrx/store';

export enum sciRateQuoteActionTypes {
    sciRateQuoteData = '[sciratequote] setsciratequote',
}

export class SetsciRateQuoteAction implements Action {
    readonly type = sciRateQuoteActionTypes.sciRateQuoteData;

    constructor(public payload: any) {}
}

export type sciRateQuoteActionUnion = SetsciRateQuoteAction;
