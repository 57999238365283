import { Component, OnInit } from '@angular/core';
import { DictionaryService } from '../../../services/dictionary.service';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { SetRevenueAction } from '../../../sales-store/appetite-store/actions/appetite.action';
import { Store } from '@ngrx/store';
import { AnalyticsService } from '@crux/services';

@Component({
    selector: 'app-gross-sales',
    templateUrl: './gross-sales.component.html',
    styleUrls: ['./gross-sales.component.scss'],
})
export class GrossSalesComponent implements OnInit {
    labelDictionary: any;
    constructor(
        public dictionaryService: DictionaryService,
        public commonCommunicationService: CommonCommunicationService,
        private _store: Store<{ revenue: string }>,
        private _analytics: AnalyticsService
    ) {}

    ngOnInit() {
        this.labelDictionary = this.dictionaryService.labelDict;
    }

    _setRevenueInStore(e) {
        /**
         * Track Selection of revenue.
         */
        this._analytics.eventTrack.next({
            category: 'ProductSelection',
            action: 'USER_INPUT',
            label: e,
        });
        this._store.dispatch(new SetRevenueAction(e));
    }
}
