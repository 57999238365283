import { forEach, filter } from 'lodash-es';
import { Injectable } from '@angular/core';
import { CommonCommunicationService } from './common-communication.service';
import { Router } from '@angular/router';
import { Product } from '../model/product';

@Injectable()
export class ProgressBarService {
    constructor(
        private ccs: CommonCommunicationService,
        private _router: Router
    ) {}

    public steps = [
        {
            name: 'Step 1',
            title: 'Product Selection',
            activeIcon: 'dark-blue-Check',
            inactiveIcon: 'step1-inactive',
            associatedUrl: '/products',
            isTouched: false,
            isCurrentSteps: false,
            stepNumber: 1,
        },

        {
            name: 'Step 2',
            title: 'Business Details',
            activeIcon: 'dark-blue-Check',
            inactiveIcon: 'step2-inactive',
            associatedUrl: '/business-details',
            isTouched: false,
            isCurrentSteps: false,
            stepNumber: 2,
        },
        {
            name: 'Step 3',
            title: 'Build Your Policy',
            activeIcon: 'dark-blue-Check',
            inactiveIcon: 'step3-inactive',
            associatedUrl: '',
            isTouched: false,
            isCurrentSteps: false,
            stepNumber: 3,
            selectedProduct: true,
            selectedProductArr: [],
            productSteps: {
                MICRO: [
                    {
                        name: 'Additional Information',
                        associatedUrl: {},
                        Url: '/additional-info/',
                        isTouched: false,
                        isCurrentSteps: false,
                        stepNumber: '',
                    },
                    {
                        name: 'Custom Packages',
                        associatedUrl: {},
                        Url: '/custom-packages/',
                        isTouched: false,
                        isCurrentSteps: false,
                        stepNumber: '',
                    },
                    {
                        name: 'Optional Coverages',
                        associatedUrl: '',
                        Url: '/optional-coverages/',
                        isComplete: false,
                        isTouched: false,
                        stepNumber: '',
                    },
                ],
                SCIWC: [
                    {
                        name: 'Getting Started',
                        associatedUrl: {},
                        Url: '/additional-info/',
                        isTouched: false,
                        isCurrentSteps: false,
                        stepNumber: '',
                    },
                    {
                        name: 'Job Type',
                        associatedUrl: {},
                        Url: '/workers-comp-job-type/',
                        isTouched: false,
                        isCurrentSteps: false,
                        stepNumber: '',
                    },
                    {
                        name: 'Employer Liability Limits',
                        associatedUrl: '',
                        Url: '/employer-liability-limits/',
                        isComplete: false,
                        isTouched: false,
                        stepNumber: '',
                    },
                ],
                SCICYB: [
                    {
                        name: 'Getting Started',
                        associatedUrl: {},
                        Url: '/additional-info/',
                        isTouched: false,
                        isCurrentSteps: false,
                        stepNumber: '',
                    },
                    {
                        name: 'Coverages & Policy Limits',
                        associatedUrl: {},
                        Url: '/cyber-policy-limits/',
                        isTouched: false,
                        isCurrentSteps: false,
                        stepNumber: '',
                    },
                ],
                SCINOTWC: [
                    {
                        name: 'Additional Information',
                        associatedUrl: '',
                        Url: '/additional-info/',
                        isTouched: false,
                        isCurrentSteps: false,
                        stepNumber: '',
                    },
                    {
                        name: 'Coverages & Policy Limits',
                        associatedUrl: '',
                        Url: '/policy-limits/',
                        isTouched: false,
                        isCurrentSteps: false,
                        stepNumber: '',
                    },
                    {
                        name: 'Optional Coverages',
                        associatedUrl: '',
                        Url: '/sci-optional-coverages/',
                        isComplete: false,
                        isTouched: false,
                        stepNumber: '',
                    },
                ],
            },
        },
        {
            name: 'Step 4',
            title: 'Application Review',
            activeIcon: 'dark-blue-Check',
            inactiveIcon: 'step4-inactive',
            associatedUrl: '/review-application',
            isTouched: false,
            isCurrentSteps: false,
            stepNumber: 4,
        },
        {
            name: 'Step 5',
            title: 'Payment',
            activeIcon: 'dark-blue-Check',
            inactiveIcon: 'step5-inactive',
            associatedUrl: '/payment',
            isTouched: false,
            isCurrentSteps: false,
            stepNumber: 5,
        },
        {
            name: 'Step 6',
            title: 'Confirmation',
            activeIcon: 'dark-blue-Check',
            inactiveIcon: 'step6-inactive',
            associatedUrl: '/payment-confirmation',
            isTouched: false,
            isCurrentSteps: false,
            stepNumber: 6,
        },
    ];
    public currentStepNumber: number;
    public percentage = 0;
    public productIndex: number;
    selectedProductArr = [];

    // This is where products receive their routing information, this routing information is used from additional-info to review-application
    updateStepActiveProperty(selectedProducts) {
        const url = this.getCurrentUrl();
        const steps = this.getSteps();
        steps.forEach((step) => {
            // ! main steps
            if (url === step.associatedUrl) {
                step.isTouched = true;
                this.currentStepNumber = step.stepNumber;
            } else if (url === '/get-acquainted') {
                this.currentStepNumber = 2;
                if (
                    step.stepNumber > this.currentStepNumber &&
                    step.isTouched
                ) {
                    step.isTouched = false;
                }
            } else if (step.associatedUrl === '') {
                step.selectedProductArr = selectedProducts;
                // Loop through each selected product
                step.selectedProductArr.forEach((product, index) => {
                    if (!product.productSteps) {
                        if (
                            product.productSourceSystem === 'SCI' &&
                            product.productId !== 'UMBRELLA' &&
                            product.productId !== 'WORKCOMP' &&
                            product.productId !== 'CYBER-ERM' &&
                            product.productId !== 'CYBER-DIGITECT'
                        ) {
                            product.productSteps = JSON.parse(
                                JSON.stringify(step.productSteps.SCINOTWC)
                            );
                        } else if (
                            product.productSourceSystem === 'SCI' &&
                            product.productId !== 'UMBRELLA' &&
                            product.productId === 'WORKCOMP'
                        ) {
                            product.productSteps = JSON.parse(
                                JSON.stringify(step.productSteps.SCIWC)
                            );
                        } else if (
                            product.productSourceSystem === 'SCI' &&
                            product.productId !== 'UMBRELLA' &&
                            (product.productId === 'CYBER-ERM' ||
                                product.productId === 'CYBER-DIGITECT')
                        ) {
                            product.productSteps = JSON.parse(
                                JSON.stringify(step.productSteps.SCICYB)
                            );
                        } else if (
                            product.productSourceSystem === 'SCI' &&
                            product.productId === 'UMBRELLA'
                        ) {
                            let umbrellaStep = [];
                            umbrellaStep[0] = JSON.parse(
                                JSON.stringify(step.productSteps.SCINOTWC[1])
                            );
                            product.productSteps = umbrellaStep;
                        } else {
                            product.productSteps = JSON.parse(
                                JSON.stringify(step.productSteps.MICRO)
                            );
                        }
                    }

                    // Loop through each step in the product
                    product.productSteps.forEach((subStep) => {
                        let productUrl;
                        if (product.productId !== 'WC') {
                            productUrl = subStep.Url + index;
                        } else {
                            productUrl = subStep.associatedUrl;
                        }
                        subStep.associatedUrl = productUrl;
                        if (url === productUrl) {
                            step.isTouched = product.isTouched = subStep.isTouched = true;
                            this.currentStepNumber = step.stepNumber;
                        } else if (
                            step.stepNumber > this.currentStepNumber &&
                            (step.isTouched || subStep.isTouched)
                        ) {
                            step.isTouched = product.isTouched = subStep.isTouched = false;
                        }
                    });
                });
            } else if (
                step.stepNumber > this.currentStepNumber &&
                step.isTouched
            ) {
                step.isTouched = false;
            }
        });
    }

    getCurrentUrl() {
        return this._router.url;
    }

    getSteps(): any {
        return this.steps;
    }

    recalculateStepNumber(steps) {
        steps.forEach((step, stepcount) => {
            if (step.stepNumber > 0) {
                const newstepcount = stepcount + 1;
                step.name = 'Step ' + newstepcount;
                step.stepNumber = newstepcount;
            }
        });
        return steps;
    }

    progress(value) {
        return (this.ccs.totalPercentage = value);
    }
}
