import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FraudDisclosuresComponent } from './fraud-disclosures.component';
import { CruxDropdownModule } from '@crux/components';

@NgModule({
    imports: [CommonModule, CruxDropdownModule],
    declarations: [FraudDisclosuresComponent],
    exports: [FraudDisclosuresComponent],
})
export class FraudDisclosuresModule {}
