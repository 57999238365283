import { Component, OnInit, Input, Inject, NgZone } from '@angular/core';
import {
    FormGroup,
    FormControl,
    Validators,
    FormBuilder,
} from '@angular/forms';
import { CommonCommunicationService } from '../../services/common-communication.service';
import { SetReferInfoAction } from './../../sales-store/partners-redirection-store/actions';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerInfo } from '../../model/customer-info';
import referCisaData from '../../../assets/json/refer-cisa.json';
import { isEmpty, isNil } from 'lodash';
import { Store, select } from '@ngrx/store';
import { ReferInformation } from '../../model/refer-info';
import { QuoteSubmission } from '../../../assets/json/quote-submission-payload';
import { Question } from '../../model/questions';
import {
    NoReferralProductsPipe,
    ProductSelectedPipe,
} from '../../pipes/productSelected.pipe';
import { Product } from '../../model/product';
import { AgentInformation } from '../../model/agent-info';
import { QuoteSubmissionService } from '../../services/quote-submission.service';
import { Router } from '@angular/router';
import { AffinityPartnerFlags } from '../../model/affinity-partner';
import { UserQuoteSubmission } from '../../../assets/json/user-quote-submission-payload';
import { AgencyInformation } from '../../model/agencyInfo';
import { SetProductsAction } from '../../sales-store/products-store/actions';
import { QuoteStep } from '../../model/quote-step';
import { referralResponseComponent } from '../../components/referral-response/referral-response.component';
import { SetCustomerInfoDataAction } from '../../sales-store/questions-store/actions';

@Component({
    selector: 'app-cisarefer-model',
    templateUrl: './cisarefer-model.component.html',
    styleUrls: ['./cisarefer-model.component.scss'],
})
export class CisareferModelComponent implements OnInit {
    public ccs: CommonCommunicationService;
    newhistoryForm: FormGroup;
    disabledNavigation = true;
    showSpinner = false;
    referDetails = referCisaData;
    formControlObj: any;
    maxchar: any = 250;
    referInformation: ReferInformation;
    showErrorPopup = false;
    quoteSubmission: QuoteSubmission = new QuoteSubmission();
    userQuoteSubmission: UserQuoteSubmission = new UserQuoteSubmission();
    agentInfo: AgentInformation = new AgentInformation();
    userEmailAgentInfo: AgencyInformation = new AgencyInformation();
    ratingUWQuestions: any;
    products: any;
    customerInfoData: any;
    affinityPartnerFlags: AffinityPartnerFlags;
    isCISAFlow: boolean;
    partnerName: string;
    quote: QuoteStep = new QuoteStep();
    public productIndex: any;
    public currentProduct: any;
    public selectedProducts: any;
    public showContinue: any;

    constructor(
        public dialogRef: MatDialogRef<CisareferModelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public pCommonCommunicationService: CommonCommunicationService,
        public quoteSubmissionService: QuoteSubmissionService,
        public dialog: MatDialog,
        public router: Router,
        private ngZone: NgZone,
        private fb: FormBuilder,
        private _store: Store<{
            referInfoSaved: ReferInformation;
            questions: Question[];
            products: Product[];
            customerInfoData: CustomerInfo;
            agentInfoSaved: AgentInformation;
            AffinityPartnerFlags: AffinityPartnerFlags;
            producerCodesInfo: any;
            quoteStep: QuoteStep;
        }>
    ) {
        this.ccs = pCommonCommunicationService;
        this._store.pipe(select('questions')).subscribe((questions) => {
            this.ratingUWQuestions = questions;
        });

        this._store.pipe(select('products')).subscribe((products) => {
            this.products = products;
        });

        this._store
            .pipe(select('quoteStep'))
            .subscribe((quoteStep: QuoteStep) => {
                this.quote = quoteStep;
            });

        this._store
            .pipe(select('customerInfoData'))
            .subscribe((customerInfoData) => {
                this.customerInfoData = customerInfoData;
                this.customerInfoData.emailAddress = data.email;
                this.customerInfoData.phoneNumber = data.telNumber;
                this.customerInfoData.firstName = data.firstName;
                this.customerInfoData.lastName = data.lastName;
            });

        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.affinityPartnerFlags = AffinityPartnerFlags;
                        if (this.affinityPartnerFlags.partnercode === 'CISA') {
                            this.isCISAFlow = true;
                        }
                    }
                }
            });

        this._store
            .pipe(select('producerCodesInfo'))
            .subscribe((producerCodesInfo) => {
                if (!isNil(producerCodesInfo) && !isEmpty(producerCodesInfo)) {
                    producerCodesInfo.forEach((partner) => {
                        if (
                            this.affinityPartnerFlags &&
                            this.affinityPartnerFlags.partnercode &&
                            partner.partnerId ===
                                this.affinityPartnerFlags.partnercode
                        ) {
                            if (
                                partner.eligibleForQuoteSubmission &&
                                partner.quoteSubmissionInfo &&
                                !isNil(partner.quoteSubmissionInfo) &&
                                !isEmpty(partner.quoteSubmissionInfo)
                            ) {
                                this.partnerName =
                                    partner.quoteSubmissionInfo.partnerNameForEmail;
                                this.userEmailAgentInfo.agency =
                                    partner.quoteSubmissionInfo.agency;
                                this.userEmailAgentInfo.agentEmail =
                                    partner.quoteSubmissionInfo.contactEmail;
                                this.userEmailAgentInfo.agentName =
                                    partner.quoteSubmissionInfo.agentName;
                                this.userEmailAgentInfo.agentMobile =
                                    partner.quoteSubmissionInfo.agentMobile;
                                this.userEmailAgentInfo.agentHrsOfOperation =
                                    partner.quoteSubmissionInfo.agentHrsOfOperation;
                            }
                        } else if (partner.partnerId === this.ccs.PartnerId) {
                            if (
                                partner.eligibleForQuoteSubmission &&
                                partner.quoteSubmissionInfo &&
                                !isNil(partner.quoteSubmissionInfo) &&
                                !isEmpty(partner.quoteSubmissionInfo)
                            ) {
                                this.partnerName =
                                    partner.quoteSubmissionInfo.partnerNameForEmail;
                                this.userEmailAgentInfo.agency =
                                    partner.quoteSubmissionInfo.agency;
                                this.userEmailAgentInfo.agentEmail =
                                    partner.quoteSubmissionInfo.contactEmail;
                                this.userEmailAgentInfo.agentName =
                                    partner.quoteSubmissionInfo.agentName;
                                this.userEmailAgentInfo.agentMobile =
                                    partner.quoteSubmissionInfo.agentMobile;
                                this.userEmailAgentInfo.agentHrsOfOperation =
                                    partner.quoteSubmissionInfo.agentHrsOfOperation;
                            }
                        }
                    });
                }
            });

        this._store.pipe(select('agentInfoSaved')).subscribe((res) => {
            this.agentInfo = new AgentInformation();
            if (
                res &&
                res.agencyName &&
                !isNil(res.agencyName) &&
                !isEmpty(res.agencyName)
            ) {
                this.agentInfo = res;
            }
        });
    }

    ngOnInit() {
        const currentURL = this.router.url;
        this.productIndex = currentURL.slice(-1);
        this.selectedProducts = new ProductSelectedPipe().transform(
            this.products
        );
        this.showContinue =
            this.selectedProducts.length - 1 > this.productIndex ? true : false;
        this.currentProduct = this.selectedProducts[this.productIndex];
        this.buildQuestionsForm();
    }

    buildQuestionsForm() {
        this.formControlObj = {};
        this.referDetails.referQuestions.forEach((questionSet) => {
            questionSet.questions.forEach((question) => {
                let preSetValue = '';
                if (
                    this.data.text &&
                    this.data.text === true &&
                    question.code === 'referReason'
                ) {
                    preSetValue = question.list_of_options[0].value;
                }

                if (
                    this.customerInfoData.phoneNumber &&
                    !isNil(this.customerInfoData.phoneNumber) &&
                    !isEmpty(this.customerInfoData.phoneNumber) &&
                    question.code === 'phoneNumber'
                ) {
                    preSetValue = this.customerInfoData.phoneNumber;
                }

                this.formControlObj[question.code] = new FormControl(
                    preSetValue,
                    Validators.required
                );
            });
        });
        this.newhistoryForm = new FormGroup(this.formControlObj);
    }

    handleStore() {
        if (this.newhistoryForm.valid) {
            if (
                this.newhistoryForm.value &&
                this.newhistoryForm.value.phoneNumber &&
                !isNil(this.newhistoryForm.value.phoneNumber) &&
                !isEmpty(this.newhistoryForm.value.phoneNumber)
            ) {
                this.customerInfoData.phoneNumber = this.newhistoryForm.value.phoneNumber;
            }
            this.disabledNavigation = false;
        } else {
            this.disabledNavigation = true;
        }
    }

    countMax(event) {
        this.maxchar = 250 - event.target.value.length;
        if (this.maxchar <= 0) {
            this.maxchar = 0;
            return false;
        }
    }

    setValues(e, efield) {
        if (e && e[0] && e[0].value) {
            this.newhistoryForm.controls[efield].setValue(e[0].value);
        }
        this.handleStore();
    }

    callUserQuoteSubmission() {
        this.ccs.showSpinner = true;
        let userQuoteSubmissionPayloadRequest: any = this.userQuoteSubmission
            .userQuoteSubmissionPayload;
        userQuoteSubmissionPayloadRequest = this.userQuoteSubmission.buildUserQuoteSubmissionRequest(
            userQuoteSubmissionPayloadRequest,
            this.ratingUWQuestions,
            new NoReferralProductsPipe().transform(this.products),
            this.ccs.PartnerId,
            this.userEmailAgentInfo,
            this.customerInfoData,
            this.newhistoryForm.controls,
            this.data.agentEmail,
            this.partnerName,
            this.isCISAFlow
        );
        this.quoteSubmissionService
            .cisaQuoteSubmission(userQuoteSubmissionPayloadRequest)
            .subscribe(
                (data) => {
                    if (data) {
                        this.ccs.showSpinner = false;
                        this.referInformation = this.newhistoryForm.value;
                        this._store.dispatch(
                            new SetReferInfoAction(this.referInformation)
                        );
                        this._store.dispatch(
                            new SetCustomerInfoDataAction(this.customerInfoData)
                        );
                        this.dialogRef.close('navigate');
                        if (this.currentProduct) {
                            if (
                                this.selectedProducts.length &&
                                this.selectedProducts.length > 1
                            ) {
                                this.dialog.open(referralResponseComponent, {
                                    width: '765px',
                                    data: {
                                        title:
                                            'Thank You for Submitting Your Quote.',
                                        text:
                                            'Would you like quoting the remaining lines of business?',
                                        currentProduct: this.currentProduct,
                                        productIndex: this.productIndex,
                                        selectedProducts: this.selectedProducts,
                                        currentRoute: this.router.url,
                                        showContinue: this.showContinue,
                                    },
                                });
                            } else {
                                this.dialog.open(referralResponseComponent, {
                                    width: '765px',
                                    data: {
                                        title:
                                            'Thank You for Submitting Your Quote.',
                                        currentProduct: this.currentProduct,
                                        productIndex: this.productIndex,
                                        selectedProducts: this.selectedProducts,
                                        currentRoute: this.router.url,
                                        showContinue: this.showContinue,
                                    },
                                });
                            }
                        }
                    } else {
                        this.ccs.showSpinner = false;
                        this.showErrorPopup = true;
                    }
                },
                (error: Response) => {
                    this.ccs.showSpinner = false;
                    this.showErrorPopup = true;
                }
            );
    }

    Submitcode() {
        if (this.newhistoryForm.valid) {
            this.ccs.showSpinner = true;
            let quoteSubmissionPayloadRequest: any = this.quoteSubmission
                .quoteSubmissionPayload;
            quoteSubmissionPayloadRequest = this.quoteSubmission.buildQuoteSubmissionRequest(
                quoteSubmissionPayloadRequest,
                this.ratingUWQuestions,
                new NoReferralProductsPipe().transform(this.products),
                this.ccs.PartnerId,
                this.agentInfo,
                this.customerInfoData,
                this.newhistoryForm.controls,
                this.data.agentEmail,
                this.partnerName,
                this.isCISAFlow,
                this.quote
            );
            this.quoteSubmissionService
                .cisaQuoteSubmission(quoteSubmissionPayloadRequest)
                .subscribe(
                    (data) => {
                        if (data) {
                            this.products.forEach((product) => {
                                if (product.selected) {
                                    product.isQuoteReferred = true;
                                }
                            });
                            this._store.dispatch(
                                new SetProductsAction(this.products)
                            );
                            this.callUserQuoteSubmission();
                        } else {
                            this.ccs.showSpinner = false;
                            this.showErrorPopup = true;
                        }
                    },
                    (error: Response) => {
                        this.ccs.showSpinner = false;
                        this.showErrorPopup = true;
                    }
                );
        }
    }

    tryagain() {
        this.showErrorPopup = false;
    }

    onNoClick() {
        this.dialogRef.close();
    }
}
