import * as Msal from '@azure/msal-browser';
import { Injectable } from '@angular/core';
import { Observable, from, Subject, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CruxHttpClientService } from '@crux/services';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { isEmpty, isNil } from 'lodash';
import { CommonCommunicationService } from './common-communication.service';

@Injectable({
    providedIn: 'root',
})
export class MsalAuthService {
    public myMSALObj: any;
    public accountId: any;
    public username: any;
    public msalConfig: any;
    public loginReq: any;
    public accessToken: any;
    public clearSilentRedirect: any;
    private responseHandleSub$ = new BehaviorSubject(this.isLoggedIn());

    constructor(
        private http: CruxHttpClientService,
        public _ccs: CommonCommunicationService
    ) {
        this.msalConfig = environment.msalAgentConfig;
        this.loginReq = this.msalConfig.loginRequest;
        this.myMSALObj = new Msal.PublicClientApplication(this.msalConfig);
    }

    signIn() {
        this.myMSALObj.loginRedirect(this.loginReq).then(this.handleResponse);
    }

    handleResponse(response) {
        if (response) {
            // if response contains an access token, store it
            if (response.accessToken && response.accessToken !== '') {
                sessionStorage.setItem('accessToken', response.accessToken);
                sessionStorage.setItem(
                    'chubbAccessToken',
                    response.accessToken
                );
                sessionStorage.setItem('refreshTime', response.expiresOn);
                let ampUser = {
                    id: response.idTokenClaims.Userid,
                    firstName: response.idTokenClaims.firstName,
                    lastName: response.idTokenClaims.lastName,
                    phone: response.idTokenClaims.telephone,
                    email: response.idTokenClaims.email,
                };
                sessionStorage.setItem('ampUser', JSON.stringify(ampUser));
                this.silentRedirect.bind(this);
                this.isLoggedIn();
                this.responseHandleSub$.next(this.isLoggedIn());
            }
        }
        if (isNil(response) || isEmpty(response)) {
            let token = sessionStorage.getItem('chubbAccessToken');
            if (token && !isNil(token) && !isEmpty(token)) {
                this._ccs.ssoUserIsAuthenticated = true;
            }
        }
    }

    isLoggedIn(): any {
        if (
            localStorage.getItem('accessToken') ||
            sessionStorage.getItem('chubbAccessToken') ||
            sessionStorage.getItem('accessToken')
        ) {
            return true;
        } else {
            return false;
        }
    }

    public getTokenRedirect() {
        const currentAccounts = this.myMSALObj.getAllAccounts();
        if (currentAccounts && currentAccounts.length > 0) {
            this.accountId = currentAccounts[0].homeAccountId;
        }
        let request = {
            account: this.myMSALObj.getAccountByHomeId(this.accountId),
            scopes: this.loginReq.scopes,
        };
        // alert('Session timeout, click ok to continue');
        /**
         * See here for more info on account retrieval:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
         */
        // request.account = this.myMSALObj.getAccountByHomeId(this.accountId);
        return this.myMSALObj
            .acquireTokenSilent(request)
            .then((response) => {
                // In case the response from B2C server has an empty accessToken field
                // throw an error to initiate token acquisition
                if (!response.accessToken || response.accessToken === '') {
                    throw new Msal.InteractionRequiredAuthError();
                }
                return this.handleResponse(response);
            })
            .catch((error) => {
                if (error instanceof Msal.InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return this.myMSALObj.acquireTokenRedirect(request);
                } else {
                    console.log(error);
                }
            });
    }

    getRedirectResponse(): Observable<any> {
        return this.responseHandleSub$.asObservable();
    }

    handleRedirectPromise(): Observable<any> {
        return from(
            this.myMSALObj
                .handleRedirectPromise()
                .then(this.handleResponse.bind(this))
                .catch((error) => {
                    console.log(error);
                    // Check for forgot password error
                    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                })
        );
    }

    signOut() {
        /**
         * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
         */

        // Choose which account to logout from by passing a homeAccountId.
        const logoutRequest = {
            account: this.myMSALObj.getAccountByHomeId(this.accountId),
        };

        this.myMSALObj.logout(logoutRequest);
    }

    silentRedirect() {
        const CurrentTime = new Date().getTime();
        const msalExpireTime = new Date(
            // localStorage.getItem('refreshTime')
            sessionStorage.getItem('refreshTime')
        ).getTime();
        const differenceTime = msalExpireTime - CurrentTime;
        const RefreshTime = differenceTime - 300000; //55min timer
        clearInterval(this.clearSilentRedirect);
        this.clearSilentRedirect = setInterval(
            this.getTokenRedirect.bind(this),
            RefreshTime
        );
    }

    /* Get producer code for the authenticated user from amp user profile API */
    getProducerCode(requestObj) {
        let url: string = environment.backend + environment.uri.userProfile;
        return this.http
            .post(url, {
                body: requestObj,
            })
            .pipe(
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
    }

    /* Get commercial line producer codes for the authenticated user from amp retrieve producer codes API */
    retrieveCOMProducerCode(requestObj) {
        let url: string =
            environment.backend + environment.uri.retrieveComProducerCodes;
        return this.http
            .post(url, {
                body: requestObj,
            })
            .pipe(
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
    }
}
