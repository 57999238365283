import {
    ProductActionUnion,
    ProductActionTypes,
} from './../actions/products.actions';

export const getQuestionsState: boolean = false;
export const getSciQuestionsState: boolean = false;

export function getQuestionsReducer(
    questionsState = getQuestionsState,
    action: ProductActionUnion
) {
    switch (action.type) {
        case ProductActionTypes.getQuestions: {
            questionsState = true;
            return questionsState;
        }
        case ProductActionTypes.getSciQuestions: {
            questionsState = true;
            return questionsState;
        }
        case ProductActionTypes.getSciCyberQuestions: {
            questionsState = true;
            return questionsState;
        }
        case ProductActionTypes.getSciChildQuestions: {
            questionsState = true;
            return questionsState;
        }
        default: {
            return questionsState;
        }
    }
}
