import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketingBannerComponent } from './marketing-banner.component';
import { AppetiteCheckModule } from '../../quote/home-page/appetite-check/appetite-check.module';
import { RouterModule } from '@angular/router';
@NgModule({
    imports: [CommonModule, AppetiteCheckModule, RouterModule],
    declarations: [MarketingBannerComponent],
    exports: [MarketingBannerComponent],
})
export class MarketingBannerModule {}
