import { CruxHttpClientService } from '@crux/services';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable()
export class ClassificationService {
    constructor(private _http: CruxHttpClientService) {}

    public classificationServiceUrl: string =
        environment.backend + environment.uri.classifications;
    public classificationClassByProduct: string =
        environment.backend + environment.uri.classificationClassByProduct;
    getClassification(searchTerm, numberOfRecords) {
        return this._http.get(
            this.classificationServiceUrl
                .replace('numberOfRecords', numberOfRecords)
                .replace('searchTerm', searchTerm)
        );
    }

    getClassificationFromClassesByProduct(product, id) {
        return this._http.get(
            this.classificationClassByProduct
                .replace('$productType', product)
                .replace('$id', id)
        );
    }
}
