import {
    ProducerCodesActionUnion,
    ProducerCodesActionTypes,
} from './../actions';

export let ProducerCodesInitial: any;

export function ProducerCodesReducer(
    ProducerCodesInfo = ProducerCodesInitial,
    action: ProducerCodesActionUnion
) {
    switch (action.type) {
        // Flag Setter
        case ProducerCodesActionTypes.ProducerCodes: {
            ProducerCodesInfo = action.payload;
            return ProducerCodesInfo;
        }

        default: {
            return ProducerCodesInfo;
        }
    }
}
