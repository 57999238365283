export class Product {
    productId: string;
    name: string;
    descriptions: string[];
    productGroup?: string;
    oneLiner: string;
    productColor: string;
    triaDisclosure: boolean;
    productPackages: any;
    selectedpackage?: any = null;
    includeSymantec?: number;
    selected?: boolean;
    errorMessage?: any;
    quoteStatus?: string;
    failureReason?: any;
    issuanceFailureReason?: string;
    isQuoteReferred?: boolean;
    classType?: string;
    mpWebUrl?: string;
    quoteProposalId?: string;
    quoteID?: string;
    add_property?: any;
    policyIssuedData?: any;
    policyIssued?: boolean;
    makePaymentOptions?: any;
    productSourceSystem?: string;
    productSteps?: any;
    include_property?: boolean;
    upgradeProduct?: any;
    featured_ind?: boolean;
    premium_amount?: any;
    policySubTypeCode?: string;
    policyTypeCode?: string;
    issuanceAttempted?: boolean;
    quote_number?: string;
    quotePattern?: string;
    buildingCoverageWithbop?: boolean;
    coverageAndLimits?: any;
    workflowOrder?: number;
    writing_company?: string;
    addedToCart?: boolean;
    emailJourneyStarted?: boolean;
    displayProduct?: boolean;
    skipFlag?: boolean;
    orderNumber?: number;
    createNewQuote?: boolean;
    updateQuote?: boolean;
    isEligible?: boolean;
    percentage?: number;
    effective_date?: any;
    expiration_date?: any;
    isFeature?: boolean;
    enableRecalculate?: boolean;
}
