import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cisa-footer',
    templateUrl: './cisa-footer.component.html',
    styleUrls: ['./cisa-footer.component.scss'],
})
export class CisaFooterComponent implements OnInit {
    showContactUs = true;
    footerYear = '© ' + new Date().getFullYear() + ' Chubb';
    constructor() {}

    ngOnInit(): void {
        if (!sessionStorage.getItem('chubbAccessToken')) {
            this.showContactUs = false;
        }
    }
}
