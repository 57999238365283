import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ViewChild,
    ComponentFactoryResolver,
} from '@angular/core';
import { HostDirective } from '../directive/host.directive';
import { StepItem } from './model/step-item';
import { StepData } from './model/step-data';

@Component({
    selector: 'app-step-navigator',
    templateUrl: './step-navigator.component.html',
    styleUrls: ['./step-navigator.component.scss'],
})
export class StepNavigatorComponent implements OnInit {
    @Input() steps: Array<StepItem> = [];

    @Input() visitedAllSteps = false;

    @Output() complete: EventEmitter<boolean> = new EventEmitter();

    @ViewChild(HostDirective, {static: true}) hostDirective: HostDirective;

    selectedIndex = -1;
    visited: Array<boolean> = [];
    disabledNavigation = false;
    internalNavigation = false;
    componentInstanceRef: any;

    internalBackVisible: boolean;
    internalNextVisible: boolean;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

    ngOnInit() {
        this.initializeSteps();
    }

    /**
     * Initial the default step and enable the page which were navigated.
     */
    initializeSteps() {
        if (!this.steps || !this.steps.length) {
            return;
        }
        this.selectedIndex = 0;
        this.steps.forEach((value, index: number) => {
            this.visited[index] = this.visitedAllSteps;
        });
        this.visited[this.selectedIndex] = true;
        this.loadComponent();
    }

    /**
     * It will load the component directly and will be added to the view.
     */
    loadComponent() {
        const item = this.steps[this.selectedIndex];

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            item.component
        );

        const viewContainerRef = this.hostDirective.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const ref = (this.componentInstanceRef = componentRef.instance);

        if (ref) {
            if (item.data) {
                ref.data = JSON.parse(JSON.stringify(item.data));
            }

            this.internalNavigation = ref.internalNavigation =
                item.internalNavigation;

            if (ref.validEvent) {
                ref.validEvent.subscribe((value) => {
                    if (value && item.data) {
                        item.data = JSON.parse(
                            JSON.stringify(
                                (<StepData>componentRef.instance).data
                            )
                        );
                    }

                    this.disabledNavigation = !value;
                });
            }

            if (ref.showInternalNavigationEvent) {
                ref.showInternalNavigationEvent.subscribe((value: boolean) => {
                    this.internalNavigation = ref.internalNavigation = value;
                });
            }

            if (ref.backNavigationEvent) {
                ref.backNavigationEvent.subscribe((value: boolean) => {
                    if (value) {
                        this.previousView();
                    }
                });
            }

            if (ref.nextNavigationEvent) {
                ref.nextNavigationEvent.subscribe((value: boolean) => {
                    if (value) {
                        this.nextView();
                    }
                });
            }

            if (ref.showInternalBackButtonEvent) {
                ref.showInternalBackButtonEvent.subscribe((value: boolean) => {
                    this.internalBackVisible = value;
                });
            }

            if (ref.showInternalNextButtonEvent) {
                ref.showInternalNextButtonEvent.subscribe((value: boolean) => {
                    this.internalNextVisible = value;
                });
            }
        }
    }

    /**
     * Open the page based on selected navigation bar index.
     * @param index
     */
    selectedview(index: number) {
        if (this.selectedIndex === index) {
            return;
        } else if (this.visited[index]) {
            this.selectedIndex = index;
            this.loadComponent();
        }
    }

    /**
     * Navigate to the next view.
     */
    nextView() {
        if (this.selectedIndex < this.steps.length) {
            this.selectedIndex++;
            if (this.selectedIndex !== this.steps.length) {
                this.visited[this.selectedIndex] = true;
                this.visited = [...this.visited];
                this.loadComponent();
            }
        }

        if (this.selectedIndex === this.steps.length) {
            this.complete.emit(true);
        }
    }

    /**
     * Navigate to the previous index.
     */
    previousView() {
        if (this.selectedIndex > 0) {
            this.selectedIndex--;
            this.loadComponent();
        }
    }

    /**
     *
     */
    internalPreviousView() {
        if (this.componentInstanceRef) {
            this.componentInstanceRef.backInternalView();
        }
    }

    /**
     *
     */
    internalNextView() {
        if (this.componentInstanceRef) {
            this.componentInstanceRef.nextInternalView();
        }
    }

    /**
     * Check the index whether it was visited earlier or not, return true if visited else false.
     * @param index
     */
    checkActive(index: number): boolean {
        return !this.visited[index];
    }
}
