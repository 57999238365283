import { Component, OnInit, ViewChild } from '@angular/core';
import { CruxHttpClientService, AnalyticsService } from '@crux/services';
import { CarouselComponent } from '@crux/components';
import {
    CarouselVideo,
    CarouselData,
    CarouselLinkData,
} from './model/carousel-video';
import { environment } from '../../../../environments/environment';
import { GAEvent } from '../../../csb-core/ga-event';
import { CommonCommunicationService } from '../../../services/common-communication.service';

@Component({
    selector: 'app-video-carousel',
    templateUrl: './video-carousel.component.html',
    styleUrls: ['./video-carousel.component.scss'],
})
export class VideoCarouselComponent implements OnInit {
    @ViewChild(CarouselComponent) carouselView: CarouselComponent;

    carouselData: CarouselVideo;

    carousel: Array<CarouselData> = [];

    private blobURL = environment.blobStorageBaseURL;

    constructor(
        private http: CruxHttpClientService,
        private _analytics: AnalyticsService,
        private _ccs: CommonCommunicationService
    ) {}

    ngOnInit() {
        const ts = new Date().getTime();

        this.http
            .get(this.blobURL + '/data/carousel-video.json?ts=' + ts, {
                responseType: 'json',
            })
            .subscribe((res) => {
                this.carouselData = res;
                this.carousel = [...this.carouselData.carousel];
            });
    }

    getNextIndex(index: number) {
        const len = this.carousel.length - 1;
        let i;
        if (index === len) {
            i = 0;
        } else {
            i = index + 1;
        }
        return i;
    }

    getNextPlusOneIndex(index: number) {
        const len = this.carousel.length - 1;
        let i;
        if (index === len) {
            i = 1;
        } else if (index === len - 1) {
            i = 0;
        } else {
            i = index + 2;
        }
        return i;
    }

    handleMoveTo(value: number) {
        const carouselitem = this.carousel[value];
        if (carouselitem !== null) {
            GAEvent.CapturEvent(
                this._analytics,
                GAEvent.ACTION.IMAGE_CLICK,
                GAEvent.CATEGORY.Start_Cover_Grow,
                'View "' + carouselitem.title + '" Section',
                GAEvent.TYPE.VIDEO_SMALL_IMG_CLICK
            );
        }
        this.carouselView.moveTo(value);
    }
}
