import { Component, Input, OnInit } from '@angular/core';
import productsData from '../../../../assets/json/home-page-content.json';
import { Router } from '@angular/router';
import { AnalyticsService } from '@crux/services';
import { environment } from '../../../../environments/environment';
import { select, Store } from '@ngrx/store';
import { AffinityPartnerFlags } from '../../../model/affinity-partner';
import { isEmpty, isNil } from 'lodash';

@Component({
    selector: 'app-products-offered',
    templateUrl: './products-offered.component.html',
    styleUrls: ['./products-offered.component.scss'],
})
export class ProductsOfferedComponent implements OnInit {
    blobURL = environment.blobStorageBaseURL;
    affinityPartnerFlags: AffinityPartnerFlags;
    @Input() productsContent: any;

    constructor(
        private router: Router,
        private _analytics: AnalyticsService,
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>
    ) {
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.affinityPartnerFlags = AffinityPartnerFlags;
                    }
                }
            });
    }

    ngOnInit(): void {}

    navigateTo(action) {
        this._analytics.eventTrack.next({
            category: 'HomePage',
            action: 'BUTTON_CLICK',
            label: `${action.text}`,
        });
        this.router.navigate([action.url]);
    }

    checkToDisplayLink(product) {
        if (
            this.affinityPartnerFlags &&
            this.affinityPartnerFlags.partnercode &&
            !isNil(this.affinityPartnerFlags.partnercode) &&
            !isEmpty(this.affinityPartnerFlags.partnercode) &&
            (this.affinityPartnerFlags.partnercode === 'sentinelone' ||
                this.affinityPartnerFlags.partnercode === 'callis' ||
                this.affinityPartnerFlags.partnercode === 'sentara')
        ) {
            if (
                product &&
                product.name &&
                !isNil(product.name) &&
                !isEmpty(product.name) &&
                product.name !== 'Cyber'
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
}
