import {
    PaymentInfoActionTypes,
    PaymentInfoActionUnion,
} from '../actions/payment-info.actions';

export let initialPaymentInfo: any;

export function paymentInfoReducer(
    PaymentState = initialPaymentInfo,
    action: PaymentInfoActionUnion
) {
    switch (action.type) {
        // Flag Setter
        case PaymentInfoActionTypes.PaymentInfo: {
            PaymentState = action.payload;
            return PaymentState;
        }

        default: {
            return PaymentState;
        }
    }
}
