import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZipCodeComponent } from './zip-code.component';
import { ZipCodeModule as CsbZipCodeModule } from '../../../@csb/sales-common/components/zip-code/zip-code.module';

@NgModule({
    imports: [CommonModule, CsbZipCodeModule],
    declarations: [ZipCodeComponent],
    exports: [ZipCodeComponent],
})
export class ZipCodeModule {}
