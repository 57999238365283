import {
    Component,
    OnInit,
    EventEmitter,
    Input,
    Output,
    OnDestroy,
} from '@angular/core';
import { CsbZipcodeService } from './csb-zipcode.service';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ZipCodeDetailsEvent } from './model/zipcode.details.event';
import { ControlBase } from './control-base';
import {
    PostalCodeOutput,
    PostalCodeItem,
    ZipCodeErrorMessage,
    ZipCodeDefaults,
} from '@crux/services';
import { isNil } from 'lodash-es';
import {
    takeUntil,
    distinctUntilChanged,
    filter,
    debounceTime,
    switchMap,
} from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'app-csb-core-zipcode',
    templateUrl: './csb-core-zipcode.component.html',
    styleUrls: ['./csb-core-zipcode.component.scss'],
})
export class CsbCoreZipcodeComponent extends ControlBase
    implements OnInit, OnDestroy {
    /**
     * Error object used in the Zip Code Component
     */
    apiError: any;

    /**
     * API to be used to get the Zip Code details
     */
    @Input() api: string;

    /**
     * Postal Code Param to be replaced with the user Zip Code input
     */
    @Input() postalCodeParam: string;

    /**
     * Whether or not the field should be autocompleted
     */
    @Input() autoComplete: 'on' | 'off' = 'off';

    /**
     * Placeholder for the Zip Code Component.
     * Defaults to `Zip Code`
     */
    @Input() placeholder: string = ZipCodeDefaults.PLACEHOLDER;

    /**
     * Whether or not to show error(s)
     */
    @Input() showErrors = true;

    @Input() hideRequiredMarker = false;

    /**
     * Form Control
     */
    @Input() zipCodeFormControl = new FormControl();

    /**
     * Subject to programmatically change the value in
     * the input field of component
     */
    @Input() zipCodeValue$ = new Subject<string>();

    /**
     * FormControl specifically to bind to the matInput in side of of template
     */
    inputFormControl = new FormControl();

    /**
     * Output which emits the Zip Code details
     */
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onDetails = new EventEmitter<ZipCodeDetailsEvent>(); // ZipCodeDetailsEvent

    constructor(private _zipCodeService: CsbZipcodeService) {
        super();
    }

    /**
     * Called whenever the Zip Code value changes
     */
    onZipChange(zipCode) {
        if (zipCode < ZipCodeDefaults.LENGTH) {
            if (this.zipCodeFormControl.value) {
                this._valueChange(null);
            }

            return;
        }

        /**
         * Zip Code details are fetched only when the length of the input is greater than or equal to 5 characters
         */
        this.apiError = null;
        if (this.api.indexOf(this.postalCodeParam) < 0) {
            this.onNoPostalCodeParam();
            return;
        }
        const api = this.api.replace(this.postalCodeParam, zipCode);
        /**
         * Lookup for Zip Code details using the API URL
         */
        this._zipCodeService.lookup(api).subscribe(
            (data: PostalCodeOutput) => {
                /**
                 * Check if the data fetched contains `output_port` and
                 * does not have a status of `F` - which means the Zip Code is not found
                 */
                if (this.hasZipCodeDetails(data)) {
                    this._valueChange(data.output_port);
                } else {
                    this.onUnknownZipCode(data);
                }
            },
            (err) => {
                /**
                 * If the error returned is an instance of HttpErrorResponse handle HTTP Error Status Codes
                 */
                if (err instanceof HttpErrorResponse) {
                    this.onHttpError(err);
                }
            }
        );
    }

    ngOnInit() {
        this.setLabelFloatBehavior();

        this.zipCodeValue$
            // .pipe(
            //   // filter((val) => val.length === ZipCodeDefaults.LENGTH),
            //   // debounceTime(500),
            //   // distinctUntilChanged(),
            //   takeUntil(this.componentDestroyed(this))
            // )
            .subscribe((zipCode: any) => {
                if (this.inputFormControl.value !== zipCode) {
                    this.inputFormControl.setValue(zipCode);
                }
            });

        this.inputFormControl.valueChanges
            .pipe(
                filter(
                    (val) =>
                        val &&
                        (val.length === ZipCodeDefaults.LENGTH ||
                            val.length === 0)
                ),
                debounceTime(500),
                distinctUntilChanged(),
                takeUntil(this.componentDestroyed(this))
            )
            .subscribe((zipCode: any) => {
                this.onZipChange(zipCode);
            });

        /**
         * Validate if the Postal Code API and the param to replace with user Zip Code Input is provided as part of the component.
         */
        if (!this.api || !this.postalCodeParam) {
            this.apiError = ZipCodeErrorMessage.UNDEFINED_API;
        }

        // Coerce Boolean property for showErrors input attribute
        this.showErrors = coerceBooleanProperty(this.showErrors);
    }

    /**
     * It is called on focus out
     * @param event
     */
    onFocusOut(event): void {
        if (this.inputFormControl.value.length < ZipCodeDefaults.LENGTH) {
            if (this.inputFormControl.value.length === 0) {
                this._valueChange(null);
            } else {
                this.onZipChange(this.inputFormControl.value);
            }
        }
    }

    ngOnDestroy(): void {
        if (!isNil(this.zipCodeValue$)) {
            this.zipCodeValue$.unsubscribe();
        }
    }

    /**
     * Called when the Postal Code Param to replace provided in the input does not match the param provided in the API URL.
     * Emits a ZipCodeDetailsEvent with a null PostalCodeItem and an error of type Unprocessable Entity
     */
    protected onNoPostalCodeParam(): void {
        const error = {
            status: 422,
            statusText: ZipCodeErrorMessage.UNPROCESSABLE_ENTITY,
        };

        this.apiError = error.statusText;

        this._valueChange(null, error);
    }

    /**
     * Called
     */
    protected onUnknownZipCode(data: PostalCodeOutput): void {
        const error = {
            status: 404,
            statusText: ZipCodeErrorMessage.NOT_FOUND,
        };

        this.apiError = error.statusText;

        this._valueChange(
            data && data.output_port ? data.output_port : null,
            error
        );
    }

    protected hasZipCodeDetails(data: PostalCodeOutput): boolean {
        return (
            data.hasOwnProperty(ZipCodeDefaults.OUTPUT_KEY) &&
            data[ZipCodeDefaults.OUTPUT_KEY][0]['Status'] !== 'F'
        );
    }

    protected onHttpError(err: HttpErrorResponse): void {
        this.apiError = this._zipCodeService.getErrorMessage(err);
        this._valueChange(null, err);
    }

    private _valueChange(postalCodeItem: PostalCodeItem[], error?: any) {
        const value = postalCodeItem
            ? new ZipCodeDetailsEvent(postalCodeItem, error)
            : null;

        this.zipCodeFormControl.setValue(value);
        this.onDetails.emit(value);
    }

    componentDestroyed(component): any {
        const oldNgOnDestroy = component.ngOnDestroy;
        const stop$ = new Subject();
        component.ngOnDestroy = function() {
            if (oldNgOnDestroy) {
                oldNgOnDestroy.apply(component);
            }
            stop$.next(undefined);
            stop$.complete();
        };
        return stop$;
    }
}
