import { QuestionsActionTypes, QuestionsActionUnion } from '../actions';

const initialState = false;

export function bizDetailsReducer(
    bizInitialState = initialState,
    action: QuestionsActionUnion
) {
    switch (action.type) {
        case QuestionsActionTypes.istBizDetails: {
            return true;
        }

        default: {
            return bizInitialState;
        }
    }
}
