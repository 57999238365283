import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrossSalesComponent } from './gross-sales.component';
import { GrossSalesModule as CsbGrossSalesModule } from '../../../@csb/sales-common/components/gross-sales/gross-sales.module';

@NgModule({
    imports: [CommonModule, CsbGrossSalesModule],
    declarations: [GrossSalesComponent],
    exports: [GrossSalesComponent],
})
export class GrossSalesModule {}
