import { Action } from '@ngrx/store';

export enum ProducerCodesActionTypes {
    ProducerCodes = '[ set producer codes ] Set producer codes from blob storage ',
}

export class SetProducerCodesAction implements Action {
    readonly type = ProducerCodesActionTypes.ProducerCodes;

    constructor(public payload: any) {}
}

export type ProducerCodesActionUnion = SetProducerCodesAction;
