import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsService , CruxHttpClientService} from '@crux/services';

@Component({
    selector: 'app-loading-modal',
    templateUrl: './loading-modal.component.html',
    styleUrls: ['./loading-modal.component.scss'],
})
export class LoadingModalComponent implements OnInit {
    public items: any = [];
    constructor(
        private _analytics: AnalyticsService,
        public dialogRef: MatDialogRef<LoadingModalComponent>,
        private http: CruxHttpClientService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {}
}
