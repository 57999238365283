import {
    SciOptionalCoveragesActionTypes,
    SciOptionalCoveragesActionUnion,
} from '../actions/sci-optional-coverages.action';

export let sciOptionalCoveragesInitialState: any;

export function sciOptionalCoveragesReducer(
    sciOptionalCoveragesData = sciOptionalCoveragesInitialState,
    action: SciOptionalCoveragesActionUnion
) {
    switch (action.type) {
        // sciOptionalCoveragesData Setter
        case SciOptionalCoveragesActionTypes.SetSciOptionalCoverages: {
            return action.payload;
        }
        default: {
            return sciOptionalCoveragesData;
        }
    }
}
