import { Component, OnInit, Input } from '@angular/core';
import { DictionaryService } from '../../../services/dictionary.service';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { CallUsNumberModel } from '../../../model/callUsNumber';
import errorMessagesData from '../../../../assets/json/error-screens-content.json';
import { Store, select } from '@ngrx/store';
import { AffinityPartnerFlags } from '../../../model/affinity-partner';
import { find, isEmpty, isNil } from 'lodash-es';
import { CruxHttpClientService } from '@crux/services';
import { environment } from '../../../../environments/environment';
import { QuoteStep } from '../../../model/quote-step';
import { Router } from '@angular/router';

@Component({
    selector: 'app-referral-message',
    templateUrl: './referral-message.component.html',
    styleUrls: ['./referral-message.component.scss'],
})
export class ReferralMessageComponent implements OnInit {
    public labels: any;
    @Input() isRetrieveQuote: boolean;
    @Input() QuoteStatus: string;
    @Input() noAppetite: boolean;
    private quote: QuoteStep = new QuoteStep();
    callUsNumber: CallUsNumberModel;
    affinityPartnerFlags: AffinityPartnerFlags;
    errorContent: any;
    errorMessages: any;
    blobURL = environment.blobStorageBaseURL;
    constructor(
        private http: CruxHttpClientService,
        private dictionaryService: DictionaryService,
        public router: Router,
        public _ccs: CommonCommunicationService,
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
            quoteStep: QuoteStep;
        }>
    ) {
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.affinityPartnerFlags = AffinityPartnerFlags;
                    }
                }
            });

        this._store
            .pipe(select('quoteStep'))
            .subscribe((quoteStep: QuoteStep) => {
                this.quote = quoteStep;
            });
    }

    ngOnInit() {
        if (
            this._ccs.errorScreenContent &&
            !isNil(this._ccs.errorScreenContent) &&
            !isEmpty(this._ccs.errorScreenContent)
        ) {
            this.errorMessages = this._ccs.errorScreenContent;
            this.getErrorContent();
        } else {
            this.errorMessages = errorMessagesData;
            this.getErrorContent();
        }

        this.errorContent.errors.forEach((error) => {
            if (
                error.type &&
                error.type === 'noAppetiteError' &&
                this.noAppetite
            ) {
                error.message1 = error.message1.replace(
                    '#state#',
                    this.quote.zipcode.value[0].StateProvince
                );
                error.message2 = error.message2.replace(
                    '#TTSCodeName#',
                    this.quote.business.value.name
                );
                error.message3 = error.message3.replace(
                    '#businesstypedesc#',
                    this.quote.business.value.description
                );
            }
        });
        this.labels = this.dictionaryService.labelDict;
        this.callUsNumber = this._ccs.callUsNumber();
    }

    tryAgainHandle() {
        this.router.navigate(['/get-a-quote']);
    }

    getCallUsNumber() {
        this.callUsNumber = this._ccs.callUsNumber();
        return this.callUsNumber.phoneNumber;
    }

    getErrorContent() {
        if (
            this.affinityPartnerFlags &&
            this.affinityPartnerFlags.partnercode &&
            !isNil(this.affinityPartnerFlags.partnercode) &&
            !isEmpty(this.affinityPartnerFlags.partnercode)
        ) {
            if (
                find(this.errorMessages, [
                    'partner',
                    this.affinityPartnerFlags.partnercode,
                ])
            ) {
                this.errorContent = this.errorMessages.filter((data) => {
                    return (
                        data.partner &&
                        !isNil(data.partner) &&
                        !isEmpty(data.partner) &&
                        data.partner === this.affinityPartnerFlags.partnercode
                    );
                });
            } else {
                this.errorContent = this.errorMessages.filter((data) => {
                    return (
                        data.partner &&
                        !isNil(data.partner) &&
                        !isEmpty(data.partner) &&
                        data.partner === 'default'
                    );
                });
            }
        } else {
            this.errorContent = this.errorMessages.filter((data) => {
                return (
                    data.partner &&
                    !isNil(data.partner) &&
                    !isEmpty(data.partner) &&
                    data.partner === 'default'
                );
            });
        }
        this.errorContent = this.errorContent[0];
    }
}
