import { QuickQuoteService } from './services/quick-quote.service';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { FlexLayoutModule } from '@angular/flex-layout';

import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';

/***
 * ! Angular Material
 * ***/
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { Overlay, BlockScrollStrategy } from '@angular/cdk/overlay';

import { ScrollDirective } from './directives/scroll.directive';
import { ClassificationService } from './services/classification.service';
import { SingletonModule } from './singleton.module';
import { LocationService } from './services/location.service';
import {
    ReadJsonService,
    CruxHttpClientService,
    cruxHttpClient,
    AnalyticsModule,
    GoogleTagManagerService,
    ApplicationInsightsService,
} from '@crux/services';
import { CruxAccordionModule, SpinnerModule } from '@crux/components';
import { CommonCommunicationService } from './services/common-communication.service';
import { ProgressBarService } from './services/progress-bar.service';
import { RatingQuestionsService } from './services/rating-questions.service';
import { ApplicationMessagesService } from './services/application-messages.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
/***
 * ! Service
 * ***/
import { ProductService } from './services/product.service';
import { CustomerInfoService } from './services/customer-info.service';
import { FraudDisclosureService } from './services/fraud-disclosure.service';
import { QuoteResponseService } from './services/quote-response.service';
import { IssuePolicyService } from './services/issue-policy.service';
import { RetrieveQuoteService } from './services/retrieve-quote.service';
import { DownloadCoiService } from './services/download-coi.service';
import { PartnerCustomization } from './partner-customization/partner-customization.helper';

import { SaveEmailService } from './services/save-email.service';
import { UpdateQuoteService } from './services/update-quote.service';
import { SciOptionalCoverageService } from './services/sci-optional-coverages-service';
import { PartnerClassificationService } from './services/partner-classification.service';
import { EligibilityQuestionsService } from './services/eligibility-questions.service';

/**
 * App Routing Module.
 * Defines routes for the app (including lazily loaded modules).
 */
import { AppRoutingModule } from './app-routing.module';

/**
 * Component Module imports
 */
import { RetrieveQuoteButtonModule } from './quote/home-page/retrieve-quote-button/retrieve-quote-button.module';
import { ClassificationModule } from './quote/home-page/classification/classification.module';
import { ZipCodeModule } from './quote/home-page/zip-code/zip-code.module';
import { HomePageContainerModule } from './quote/home-page/home-page-container/home-page-container.module';
import { environment } from '../environments/environment';
import { GrossSalesModule } from './quote/home-page/gross-sales/gross-sales.module';

/**
 * ngrx store imports
 */
import { StoreModule } from '@ngrx/store';
import {
    classificationReducer,
    zipcodeReducer,
    revenueReducer,
    startQuoteReducer,
    quoteStepReducer,
    p360ClassReducer,
    p360TotalClassesReducer,
} from './sales-store/appetite-store/reducers';
import {
    productReducer,
    getQuestionsReducer,
    setTotalPremiumReducer,
    setSelectedPackageCountReducer,
} from './sales-store/products-store/reducers';
import {
    setQuestionsReducer,
    setQuestionsErrorReducer,
    setSimpleQuestionsObjReducer,
    setCustomerInfoDataReducer,
    bizDetailsReducer,
    addInfoReducer,
    setEligibilityReducer,
    setSetBusinessMailingAddressReducer,
    setQuestionsAnswerReducer,
} from './sales-store/questions-store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { AppetiteEffects } from './sales-store/appetite-store/effects';
import { ProductEffects } from './sales-store/products-store/effects';
import { QuestionsEffects } from './sales-store/questions-store/effects';
import { QuoteEffects } from './sales-store/quote-store/effects';
import {
    retrieveQuoteReducer,
    isDisqualifiedReducer,
    numberOfSelectedPackages,
    sciOptionalCoveragesReducer,
    retrieveQuoteEmailReducer,
    summaryReviewReducer,
    issueAttemptReducer,
    sciRateQuoteReducer,
    LossDetailsReducer,
    RetrievedBusinessProfileInfoReducer,
} from './sales-store/quote-store/reducers';

import { FooterModule } from './components/footer/footer.module';
import { SCIRateService } from './services/sci-rate.service';
import { DownloadQuoteService } from './services/download-quote.service';
import { DownloadQuoteModule } from './components/download-quote/download-quote.module';
import { DownloadReceiptModule } from './post-issuance/download-receipt/download-receipt.module';
import {
    partnersRedirectionFlagReducer,
    ProducerCodesReducer,
    PartnerOffersReducer,
    affinityPartnerInfoFlagReducer,
    agentInfoFlagReducer,
    referInfoFlagReducer,
    LoadingModalFactsReducer,
    RetrievedComProducerCodesReducer,
} from './sales-store/partners-redirection-store/reducers';
import { WorkersCompJobTypeModule } from './quote/form/workers-comp-job-type/workers-comp-job-type.module';
import { FraudDisclosuresModule } from './issuance/fraud-disclosures/fraud-disclosures.module';
import { ThirdpartyDisclosuresModule } from './issuance/thirdparty-disclosures/thirdparty-disclosures.module';
import { EDeliveryModule } from './issuance/e-delivery/e-delivery.module';
import { cisaDisclosureModule } from './issuance/cisa-disclosure/cisa-disclosure.module';
import { PrintDisclosureModule } from './print-disclosure/print-disclosure.module';
import { WcJobTypeService } from './services/wc-job-type.service';
import { setproductsStatusReducer } from './sales-store/products-store/reducers/productsStatus.reducer';
import { BlogModule } from './blog/blog.module';
import { CsbMarketingpagesModule } from './static-pages/csb-marketingpages/csb-marketingpages.module';
import { DialogComponent } from './components/dialog/dialog.component';
import { MajesoPaymentService } from './services/majeso-payment.service';
import { majescoReducer } from './sales-store/products-store/reducers/majesco.reducer';
import { DateESTPipe } from './pipes/dateEST.pipe';
import { sciCyberQuoteReducer } from './sales-store/quote-store/reducers/sci-cyber-quote.reducer';
import { SquarespaceModelComponent } from './components/squarespace-model/squarespace-model.component';
import { TermsConditionsModelComponent } from './components/terms-conditions-model/terms-conditions-model.component';
import { AutoPaymentService } from './services/auto-payment.service';
import { PaymentRequestService } from './services/payment.service';
import { paymentInfoReducer } from './sales-store/payment-store/reducers/setPaymentInfo.reducer';
import { QuoteSubmissionService } from './services/quote-submission.service';
import { p360APIService } from './services/p360API.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
import { LoadingModalModule } from './components/loading-modal/loading-modal.module';
import { GetBlobContentService } from './services/get-blob-content.service';
import { MsalAuthService } from './services/msal-auth.service';
import { CisaFooterModule } from './components/cisa-footer/cisa-footer.module';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
    return () => overlay.scrollStrategies.block();
}

@NgModule({
    declarations: [AppComponent, NavbarComponent, ScrollDirective, DateESTPipe],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        DeviceDetectorModule.forRoot(),
        BrowserModule,
        // HTTP Client Module
        HttpClientModule,
        // Flex Layout Module
        FlexLayoutModule,
        // Application Routing Module
        AppRoutingModule,
        /**
         * Component modules.
         * Only **add modules** required for the **home page**
         */

        HomePageContainerModule,
        MatSidenavModule,
        CruxAccordionModule,
        RetrieveQuoteButtonModule,
        ClassificationModule,
        GrossSalesModule,
        ZipCodeModule,
        SpinnerModule,
        MatProgressBarModule,
        DownloadQuoteModule,
        DownloadReceiptModule,
        FraudDisclosuresModule,
        ThirdpartyDisclosuresModule,
        EDeliveryModule,
        cisaDisclosureModule,
        PrintDisclosureModule,
        SingletonModule.forRoot(),
        AnalyticsModule.forRoot(
            [GoogleTagManagerService, ApplicationInsightsService],
            {
                applicationInsights: {
                    instrumentationKey:
                        environment.appInsights.instrumentationKey, // @todo Replace with instrumentation key for CSB 3.0
                },
            }
        ),
        /**
         * store imports
         */
        StoreModule.forRoot(
            {
                quoteStep: quoteStepReducer,
                classification: classificationReducer,
                zipcode: zipcodeReducer,
                majescoStatus: majescoReducer,
                revenue: revenueReducer,
                startQuote: startQuoteReducer,
                products: productReducer,
                getQuestions: getQuestionsReducer,
                setQuestions: setQuestionsReducer,
                setQuestionsAnswer: setQuestionsAnswerReducer,
                setQuestionsError: setQuestionsErrorReducer,
                questionsError: setQuestionsErrorReducer,
                questions: setQuestionsReducer,
                simpleQuestionsObj: setSimpleQuestionsObjReducer,
                customerInfoData: setCustomerInfoDataReducer,
                retrieveQuoteData: retrieveQuoteReducer,
                retrieveQuoteEmailData: retrieveQuoteEmailReducer,
                totalPremium: setTotalPremiumReducer,
                productsStatus: setproductsStatusReducer,
                isDisqualified: isDisqualifiedReducer,
                numberOfSelectedPackages: numberOfSelectedPackages,
                sciOptionalCoverages: sciOptionalCoveragesReducer,
                isBizDetails: bizDetailsReducer,
                isAdditioalInfo: addInfoReducer,
                isSummaryReviewed: summaryReviewReducer,
                issuanceAttemptCount: issueAttemptReducer,
                setEligibility: setEligibilityReducer,
                partnersRedirectionFlag: partnersRedirectionFlagReducer,
                AffinityPartnerFlags: affinityPartnerInfoFlagReducer,
                agentInfoSaved: agentInfoFlagReducer,
                referInfoSaved: referInfoFlagReducer,
                paymentInfo: paymentInfoReducer,
                producerCodesInfo: ProducerCodesReducer,
                LoadingModalFacts: LoadingModalFactsReducer,
                partnerOffersInfo: PartnerOffersReducer,
                selectedPackageCount: setSelectedPackageCountReducer,
                sciRateQuote: sciRateQuoteReducer,
                sciCyberQuote: sciCyberQuoteReducer,
                lossDetailsInfo: LossDetailsReducer,
                setBusinessMailingAddress: setSetBusinessMailingAddressReducer,
                p360ClassInfo: p360ClassReducer,
                p360TotalClassesInfo: p360TotalClassesReducer,
                retrievedBusinessProfileData: RetrievedBusinessProfileInfoReducer,
                retrievedComProducerCodes: RetrievedComProducerCodesReducer,
            },
            {
                runtimeChecks: {
                    strictStateImmutability: false,
                    strictActionImmutability: false,
                },
            }
        ),
        EffectsModule.forRoot([
            AppetiteEffects,
            ProductEffects,
            QuestionsEffects,
            QuoteEffects,
        ]),
        FooterModule,
        CisaFooterModule,
        WorkersCompJobTypeModule,
        BlogModule,
        CsbMarketingpagesModule,
        LoadingModalModule,
    ],
    providers: [
        /**
         * @todo Remove the ones which need not be
         * provided all through the app.
         */
        ClassificationService,
        CommonCommunicationService,
        ProgressBarService,
        LocationService,
        ReadJsonService,
        RatingQuestionsService,
        ApplicationMessagesService,
        ProductService,
        QuoteResponseService,
        CustomerInfoService,
        FraudDisclosureService,
        GetBlobContentService,
        {
            provide: MAT_SELECT_SCROLL_STRATEGY,
            useFactory: scrollFactory,
            deps: [Overlay],
        },
        {
            provide: CruxHttpClientService,
            useFactory: cruxHttpClient,
            deps: [HttpClient],
        },
        IssuePolicyService,
        SaveEmailService,
        QuoteSubmissionService,
        RetrieveQuoteService,
        UpdateQuoteService,
        DownloadCoiService,
        DownloadQuoteService,
        PartnerCustomization,
        SCIRateService,
        SciOptionalCoverageService,
        // { provide: CRUX_APP_BUILD, useValue: { platform: 'DESKTOP' } },
        PartnerClassificationService,
        WcJobTypeService,
        QuickQuoteService,
        EligibilityQuestionsService,
        MajesoPaymentService,
        AutoPaymentService,
        PaymentRequestService,
        p360APIService,
        MsalAuthService,
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        DialogComponent,
        SquarespaceModelComponent,
        LoadingModalComponent,
    ],
})
export class AppModule {}
