import { Component, OnInit } from '@angular/core';
import { DictionaryService } from './../services/dictionary.service';
import { CommonCommunicationService } from './../services/common-communication.service';
import { ProgressBarService } from './../services/progress-bar.service';
import { Router } from '@angular/router';

import { Classification } from './../model/classification';
/**
 * NGRX imports
 */
import { Store, select } from '@ngrx/store';
import { ProductEffects } from './../sales-store/products-store/effects';
import { Product } from './../model/product';
import { AppetiteEffects } from './../sales-store/appetite-store/effects';
import { ZipcodeModel } from '../model/zipcode';
import { AnalyticsService } from '@crux/services';
import { AffinityPartnerFlags } from '../model/affinity-partner';
import { isEmpty, isNil } from 'lodash';
import leftRailContent from '../../assets/json/left-rail-content.json';
import { LeftRailCTAContent } from '../model/left-rail-info';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
    products: Product[];
    selectedProductArr;
    public ccs: CommonCommunicationService;
    public labelDictionary: any;
    public title = 'Pediatric Potentials';
    public subTitle = 'Let’s build your policy';
    public pbs: ProgressBarService;
    public revenue = 0;
    public currentSteps = 0;
    public retrievedQuoteObj: any;
    public isRetrieveQuoteFlow = false;

    public selectedClass: Classification;
    public selectedZipcode: ZipcodeModel[];
    public selectedRevenue: any;
    public steps: any[];
    public totalNumberOfSteps = 0;
    public currentURL: string;
    AffinityPartnerFlags: AffinityPartnerFlags;
    ctaSectionContent: LeftRailCTAContent;

    constructor(
        private pCcs: CommonCommunicationService,
        private progressBarService: ProgressBarService,
        private dictionaryService: DictionaryService,
        private _productEffects: ProductEffects,
        private _appetiteEffects: AppetiteEffects,
        private _router: Router,
        private _store: Store<{
            classification: Classification;
            retrieveQuoteObj: any;
            retrieveQuoteData: any;
            zipcode: ZipcodeModel[];
            revenue: number;
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>,
        private _analytics: AnalyticsService
    ) {
        this.ccs = pCcs;
        this.pbs = progressBarService;
        this._productEffects.readProductsFromStore$().subscribe((products) => {
            this.products = products;
            const selectedProduct = this.products.filter(
                (product) => product.selected === true
            );
            this.selectedProductArr = selectedProduct;
        });

        this._store.pipe(select('classification')).subscribe((classData) => {
            this.selectedClass = classData;
        });
        this._store.pipe(select('retrieveQuoteData')).subscribe((data) => {
            this.retrievedQuoteObj = data;
        });

        this._store.pipe(select('zipcode')).subscribe((zipcode) => {
            this.selectedZipcode = zipcode;
        });

        this._store.pipe(select('revenue')).subscribe((revenue) => {
            this.selectedRevenue = revenue;
        });
        this.steps = this.progressBarService.getSteps();
        if (this.steps) {
            this.totalNumberOfSteps = this.steps.length;
        }
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (
                    AffinityPartnerFlags &&
                    AffinityPartnerFlags.partnercode &&
                    !isNil(AffinityPartnerFlags.partnercode) &&
                    !isEmpty(AffinityPartnerFlags.partnercode)
                ) {
                    this.AffinityPartnerFlags = AffinityPartnerFlags;
                    if (
                        this.AffinityPartnerFlags.partnercode.toLowerCase() ===
                            'cisa' &&
                        this.steps &&
                        this.steps.length > 0
                    ) {
                        this.steps.forEach((step) => {
                            if (step.title === 'Payment') {
                                step.title = 'Billing Information';
                            }
                        });
                    }
                }
            });
    }

    ngOnInit() {
        this.labelDictionary = this.dictionaryService.labelDict;
        this.progressBarService.updateStepActiveProperty(
            this.selectedProductArr
        );
        this.checkRetrieveQuote();
        this.checkCurrentSteps(this.steps);
        this.setLeftRailContent();
        this.currentURL = this._router.url;
    }

    ngAfterViewInit() {
        var styleElem = document.head.appendChild(
            document.createElement('style')
        );
        styleElem.innerHTML =
            '.active:after {border-color: ' +
            this.ccs.globalColor1 +
            ' !important;}';

        var styleElemrail = document.head.appendChild(
            document.createElement('style')
        );
        styleElemrail.innerHTML =
            '.active:not(:last-child):before {border-left: 5px solid ' +
            this.ccs.globalColor1 +
            ' !important;}';
    }

    checkRetrieveQuote() {
        if (this.retrievedQuoteObj) {
            this.isRetrieveQuoteFlow = true;
        }
    }

    setLeftRailContent() {
        if (
            this.AffinityPartnerFlags &&
            this.AffinityPartnerFlags.partnercode &&
            !isNil(this.AffinityPartnerFlags.partnercode) &&
            !isEmpty(this.AffinityPartnerFlags.partnercode) &&
            this.AffinityPartnerFlags.partnercode.toLowerCase() === 'cisa'
        ) {
            this.ctaSectionContent = leftRailContent.CTASection.agentContent;
        } else {
            this.ctaSectionContent = leftRailContent.CTASection.digitalContent;
        }
    }

    switchSteps(step) {
        // if (step.isTouched && step.associatedUrl !== '') {
        //     const GaCategory = step.name.replace(/ /g, '');
        //     this._analytics.eventTrack.next({
        //         category: GaCategory,
        //         action: 'LEFT_RAIL',
        //         label: step.name,
        //     });
        //     this._router.navigate([step.associatedUrl]);
        // }
    }
    checkCurrentSteps(steps) {
        steps.forEach((step) => {
            if (step.stepNumber === this.pbs.currentStepNumber) {
                step.isCurrentSteps = true;
            } else {
                step.isCurrentSteps = false;
            }
        });
    }
    navigateToHomePage() {
        this._router.navigate(['']);
        this._analytics.eventTrack.next({
            category: 'Restart Quote',
            action: 'RESTART_QUOTE_LINK',
            label: this._router.url + ' - Restart your quote',
        });
    }
}
