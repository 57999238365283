import { AnalyticsService } from '@crux/services';

export class GAEvent {
    static TYPE = {
        VIDEO_SMALL_IMG_CLICK: 'When user clicks on small image',
        START: 'START',
        PROTECT: 'PROTECT',
        COVER: 'COVER',
    };

    static ACTION = {
        USER_INPUT: 'USER_INPUT',
        VALID_DETAILS: 'VALID_DETAILS',
        SELECT_CITY: 'SELECT_CITY',
        BUTTON_CLICK: 'BUTTON_CLICK',
        IMAGE_CLICK: 'IMAGE_CLICK',
        LINK_CLICK: 'LINK_CLICK',
        SELECT: 'SELECT',
        DESELECT: 'DESELECT',
        CHOSEN_PRODUCTS: 'CHOSEN_PRODUCTS',
    };

    static CATEGORY = {
        Question_1: 'Question_1',
        Question_2: 'Question_2',
        Question_3: 'Question_3',
        Question_4: 'Question_4',
        Question_5: 'Question_5',
        Get_A_Quote: 'Get_A_Quote',
        SUBMIT: 'SUBMIT',
        NAV_TO_BLOG: 'NAV_TO_BLOG',
        ProductSelection: 'ProductSelection',
        Start_Cover_Grow: 'Start_Cover_Grow',
    };

    static LABEL = {
        Details_Submitted: 'Details Submitted',
        No_Products_Offered: 'No Products Offered',
        Brouse_our_blogs: 'Brouse our blogs',
        NEW_QUOTE: 'NEW QUOTE',
        Quick_Quote: 'Quick Quote',
    };

    static CapturEvent(
        analytics: AnalyticsService,
        action: string,
        category: string,
        label: string,
        type?: string
    ) {
        analytics.eventTrack.next({
            category: category,
            action: action,
            label: label,
            type: type ? type : '',
        });
    }
}
