import { PostalCodeItem } from '@crux/services';

export class ZipCodeDetailsEvent {
    data: Partial<PostalCodeItem>[];
    error?: object;

    constructor(data?: Partial<PostalCodeItem>[], error?: object) {
        if (data) {
            this.data = data;
        }

        if (error) {
            this.error = error;
        }
    }
}
