import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { isEmpty, isNil, wrap, find } from 'lodash-es';
/**
 * NGRX store imports
 */
import { Store, select } from '@ngrx/store';
import { RetrievedQuoteObj } from '../../../model/retrieve-quote-status';

import { AffinityPartnerFlags } from '../../../model/affinity-partner';
import { MatDialog } from '@angular/material/dialog';
import { SquarespaceModelComponent } from '../../../components/squarespace-model/squarespace-model.component';
import { SetAffinityPartnerInfoAction } from '../../../sales-store/partners-redirection-store/actions/affinity-partnerInfo.actions';
import { CruxHttpClientService } from '@crux/services';
import { environment } from '../../../../environments/environment';
import { GetBlobContentService } from '../../../services/get-blob-content.service';
import homePageContentFromJson from '../../../../assets/json/home-page-content.json';
import { MsalAuthService } from '../../../services/msal-auth.service';

@Component({
    selector: 'app-home-page-container',
    templateUrl: './home-page-container.component.html',
    styleUrls: ['./home-page-container.component.scss'],
})
export class HomePageContainerComponent implements OnInit {
    public commonCommunicationService: CommonCommunicationService;
    public isShowAll = false;
    welcomeModalData: any;
    modalPopupData: any;
    homePageContent: any;
    gotDataFromBlob: boolean;
    quoteDetailsList: RetrievedQuoteObj;
    @ViewChild('homePage') homePage: ElementRef;

    constructor(
        private _routeParams: ActivatedRoute,
        private pcommonCommunicationService: CommonCommunicationService,
        private msalAuthService: MsalAuthService,
        public getBlobDataService: GetBlobContentService,
        private http: CruxHttpClientService,
        private titleService: Title,
        private metaService: Meta,
        private _router: Router,
        private _store: Store<{
            quoteDetailsList: RetrievedQuoteObj;
            retrieveQuoteData: any;
        }>,
        private _AffinityPartnerFlagstore: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>,
        public dialog: MatDialog
    ) {
        this.homePageContent = this.getBlobDataService.getHomePageContent();
        this.getBlobDataService.getHomePageContent().subscribe(
            (contentFromBlob) => {
                if (
                    contentFromBlob &&
                    !isNil(contentFromBlob) &&
                    !isEmpty(contentFromBlob)
                ) {
                    this.homePageContent = contentFromBlob;
                    this.gotDataFromBlob = true;
                } else {
                    this.homePageContent = homePageContentFromJson;
                    this.gotDataFromBlob = true;
                }
            },
            (error: Response) => {
                this.homePageContent = homePageContentFromJson;
                this.gotDataFromBlob = true;
            }
        );
        this._store
            .pipe(select('retrieveQuoteData'))
            .subscribe((quoteDetailsList) => {
                this.quoteDetailsList = quoteDetailsList;
            });
        this.commonCommunicationService = pcommonCommunicationService;
        this.titleService.setTitle(
            'Crafted Business Insurance. Affordable. | Chubb'
        );
        const descriptionAttributeSelector = 'name="description"';
        const keywordAttributeSelector = 'name="keywords"';
        this.metaService.updateTag(
            {
                name: 'description',
                content:
                    'Chubb offers business insurance that is crafted to fit the needs of small business owners.',
            },
            descriptionAttributeSelector
        );
        this.metaService.updateTag(
            {
                name: 'keywords',
                content:
                    // tslint:disable-next-line: max-line-length
                    'Chubb, Small, Business, Insurance, Cyber, General, Liability, Business, Owners, Policy, Quote, Price, Cost, Affordable, Cheap, Best, Coverage, Type, Commercial, How, Much, Florida, Texas, Georgia, New York, New Jersey, California, Virginia, Illinois, Pennsylvania, North Carolina',
            },
            keywordAttributeSelector
        );
    }

    AffinityPartnerFlags: AffinityPartnerFlags = new AffinityPartnerFlags();

    ngOnInit() {
        this.isShowAll =
            this._routeParams.snapshot.params.isShowAll ||
            this.commonCommunicationService.PartnerId === 'nawbo' ||
            this.commonCommunicationService.PartnerId === 'pbn';
        this.commonCommunicationService.scrollToWidget = this.homePage;
        this.isShowAll = coerceBooleanProperty(this.isShowAll);
        if (this._routeParams.snapshot.params.partner) {
            this._router.navigateByUrl(
                '?utm_source=' + this._routeParams.snapshot.params.partner
            );
        }
    }

    showProducts(event): void {
        this.commonCommunicationService.productReceived = event;
    }
}
