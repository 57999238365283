import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CommonCommunicationService } from '../../services/common-communication.service';
import { Router } from '@angular/router';
/**
 * Store imports
 */
import { Store, select } from '@ngrx/store';

/**
 * Partner Customization
 */
import { PartnerCustomization } from './../../partner-customization/partner-customization.helper';

@Injectable()
export class PaymentGuard implements CanActivate {
    isApplicationReviewed: boolean;
    constructor(
        private _store: Store<{ isSummaryReviewed: boolean }>,
        private _partnerCustomization: PartnerCustomization
    ) {
        this._store
            .pipe(select('isSummaryReviewed'))
            .subscribe((appReviewFlag) => {
                this.isApplicationReviewed = appReviewFlag;
            });
    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        /**
         * Only load the route when the step number is greater than 0.
         * This is temporary and needs to be changed.
         *
         * @todo only route when the path has business type and postal code.
         */
        if (this.isApplicationReviewed) {
            return true;
        }

        // /**
        //  * Navigate to home when the above condition does not match
        //  */
        // this.router.navigate(['']);
        this._partnerCustomization.goToHome();
    }
}
