import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from '@crux/components';
import { CsbBannerComponent } from './csb-banner.component';
import { AnalyticsModule } from '@crux/services';

@NgModule({
    declarations: [CsbBannerComponent],
    imports: [CommonModule, CarouselModule, AnalyticsModule],
    exports: [CsbBannerComponent],
})
export class CsbBannerModule {}
