import { BusinessClass } from '../../../model/p360-business-class';
import {
    P360TotalClassesActionTypes,
    P360TotalClassesActionUnion,
} from '../actions/p360-total-classes.action';

export let p360TotalClassesInitialState: BusinessClass[];

export function p360TotalClassesReducer(
    p360TotalClassesState = p360TotalClassesInitialState,
    action: P360TotalClassesActionUnion
) {
    switch (action.type) {
        // Set P360 Classes
        case P360TotalClassesActionTypes.setP360TotalClasses: {
            p360TotalClassesState = action.payload;
            return p360TotalClassesState;
        }
        default: {
            return p360TotalClassesState;
        }
    }
}
