import { Questions, SimpleQuestions } from '../../../model/questions';

import {
    QuestionsActionTypes,
    QuestionsActionUnion,
    SetSimpleQuestionsObjAction,
} from '../actions';
import {
    CustomerInfo,
    BusinessMailingAddress,
} from '../../../model/customer-info';
import { filter } from 'lodash-es';

export const questionsInitialState: any = [];
export const questionsAnswerInitialState: any = [];
export const questionsErrorInitialState: any = [];
// TODO: Set up the initial state of the sci questions with a model
export const sciQuestionsInitialState: any = {};
export const simpleQuestionsInitialState: SimpleQuestions = new SimpleQuestions();
export const customerInfoInitialDataState: CustomerInfo = new CustomerInfo();
export const businessMailingAddressInitialState: BusinessMailingAddress = new BusinessMailingAddress();

export function setQuestionsReducer(
    questionsState = questionsInitialState,
    action: QuestionsActionUnion
) {
    switch (action.type) {
        case QuestionsActionTypes.setQuestions: {
            questionsState = action.payload;
            return questionsState;
        }

        case QuestionsActionTypes.setSciQuestions: {
            questionsState = filter(questionsState, function (question) {
                return question['group_name'] !== 'GLUnderwritingQuestions';
            });
            questionsState = [...questionsState, ...action.payload];
            return questionsState;
        }
        case QuestionsActionTypes.setSciCyberQuestions: {
            questionsState = filter(questionsState, function (question) {
                return question['group_name'] !== 'CyberUnderwritingQuestions';
            });
            questionsState = [...questionsState, ...action.payload];
            return questionsState;
        }
        case QuestionsActionTypes.setSciChildQuestions: {
            questionsState = filter(questionsState, function (question) {
                return question['group_name'] !== 'GLUnderwritingQuestions';
            });
            questionsState = [...questionsState, ...action.payload];
            return questionsState;
        }
        default: {
            return questionsState;
        }
    }
}

export function setQuestionsErrorReducer(
    questionsErrorState = questionsErrorInitialState,
    action: QuestionsActionUnion
) {
    switch (action.type) {
        case QuestionsActionTypes.setQuestionsError: {
            questionsErrorState = action.payload;
            return questionsErrorState;
        }
        case QuestionsActionTypes.setSciQuestionsError: {
            questionsErrorState = action.payload;
            return questionsErrorState;
        }
        case QuestionsActionTypes.setSciCyberQuestionsError: {
            questionsErrorState = action.payload;
            return questionsErrorState;
        }
        case QuestionsActionTypes.setSciChildQuestionsError: {
            questionsErrorState = action.payload;
            return questionsErrorState;
        }
        default: {
            return questionsErrorState;
        }
    }
}

export function setSimpleQuestionsObjReducer(
    simpleQuestionsObjState = simpleQuestionsInitialState,
    action: QuestionsActionUnion
) {
    switch (action.type) {
        case QuestionsActionTypes.setSimpleQuestionsObj: {
            simpleQuestionsObjState = action.payload;
            return simpleQuestionsObjState;
        }

        default: {
            return simpleQuestionsObjState;
        }
    }
}

export function setCustomerInfoDataReducer(
    customerInfoDataState = customerInfoInitialDataState,
    action: QuestionsActionUnion
) {
    switch (action.type) {
        case QuestionsActionTypes.setCustomerInfoData: {
            customerInfoDataState = action.payload;
            return customerInfoDataState;
        }

        default: {
            return customerInfoDataState;
        }
    }
}

export function setSetBusinessMailingAddressReducer(
    businessMailingAddressState = businessMailingAddressInitialState,
    action: QuestionsActionUnion
) {
    switch (action.type) {
        case QuestionsActionTypes.setBusinessAddress: {
            businessMailingAddressState = action.payload;
            return businessMailingAddressState;
        }

        default: {
            return businessMailingAddressState;
        }
    }
}

export function setQuestionsAnswerReducer(
    questionsAnswerState = questionsAnswerInitialState,
    action: QuestionsActionUnion
) {
    switch (action.type) {
        case QuestionsActionTypes.setQuestionsAnswer: {
            questionsAnswerState = action.payload;
            return questionsAnswerState;
        }

        default: {
            return questionsAnswerState;
        }
    }
}
