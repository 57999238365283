import { Action } from '@ngrx/store';

export enum PaymentInfoActionTypes {
    PaymentInfo = '[ set payment information ] Set payment information from transactions made',
}

export class SetPaymentInfoAction implements Action {
    readonly type = PaymentInfoActionTypes.PaymentInfo;

    constructor(public payload: any) {}
}

export type PaymentInfoActionUnion = SetPaymentInfoAction;
