import { QuoteStep } from '../../../model/quote-step';
import { quoteStepActionUnion, QuoteStepType } from '../actions';

export const quoteStepInitialState: QuoteStep = new QuoteStep();

export function quoteStepReducer(
    quoteStepState = quoteStepInitialState,
    action: quoteStepActionUnion
) {
    switch (action.type) {
        case QuoteStepType.StepComplete: {
            quoteStepState = {
                ...quoteStepState,
                stepsComplete: action.payload.stepsComplete,
            };
            return quoteStepState;
        }

        case QuoteStepType.QuestionData: {
            quoteStepState = {
                ...quoteStepState,
                questionData: action.payload.questionData,
            };
            return quoteStepState;
        }

        case QuoteStepType.QuestionInsurance: {
            quoteStepState = {
                ...quoteStepState,
                insurance: action.payload.insurance,
            };
            return quoteStepState;
        }

        case QuoteStepType.QuestionWebsite: {
            quoteStepState = {
                ...quoteStepState,
                website: action.payload.website,
            };
            return quoteStepState;
        }

        case QuoteStepType.QuestionBusiness: {
            quoteStepState = {
                ...quoteStepState,
                business: action.payload.business,
            };
            return quoteStepState;
        }

        case QuoteStepType.QuestionRevenue: {
            quoteStepState = {
                ...quoteStepState,
                revenue: action.payload.revenue,
            };
            return quoteStepState;
        }

        case QuoteStepType.QuestionZipCode: {
            quoteStepState = {
                ...quoteStepState,
                zipcode: action.payload.zipcode,
            };
            return quoteStepState;
        }

        case QuoteStepType.QuestionEmail: {
            quoteStepState = {
                ...quoteStepState,
                email: action.payload.email,
            };
            return quoteStepState;
        }

        case QuoteStepType.QuestionResetData: {
            quoteStepState = {
                ...action.payload,
            };
            return quoteStepState;
        }

        default: {
            return quoteStepState;
        }
    }
}
