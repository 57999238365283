import {
    Component,
    HostBinding,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';

@Component({
    selector: 'csb-business-type',
    templateUrl: './business-type.component.html',
    styleUrls: ['./business-type.component.scss'],
})
export class BusinessTypeComponent {
    @Input() placeholder: string;
    @Input() classificationURL: string;
    @Input() numberOfRecords: string;

    @Output() classificationSelection = new EventEmitter();
    @Output() checkEmptyValue = new EventEmitter<boolean>();

    @HostBinding('@.disabled') disabled = true;

    _classificationSelection(selectValue) {
        this.classificationSelection.emit(selectValue);
    }

    _checkEmptyValue(ev) {
        this.checkEmptyValue.emit(ev.target.value === '');
    }
}
