import { Product } from './../../../model/product';

import { ProductActionUnion, ProductActionTypes } from './../actions';

export let productsInitialState: Product[];

export function productReducer(
    productState = productsInitialState,
    action: ProductActionUnion
) {
    switch (action.type) {
        // Product Setter
        case ProductActionTypes.setProducts: {
            productState = action.payload;
            return productState;
        }

        // Prooduct API failure case
        case ProductActionTypes.setProductsError: {
            productState = action.payload;
            return productState;
        }

        default: {
            return productState;
        }
    }
}
