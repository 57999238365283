import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputModule, TooltipModule } from '@crux/components';
import { EmailComponent } from './email.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CSBCoreModule } from '../../../csb-core/csb-core.module';

@NgModule({
    declarations: [EmailComponent],
    imports: [
        CommonModule,
        InputModule,
        FormsModule,
        ReactiveFormsModule,
        CSBCoreModule,
        TooltipModule,
    ],
    exports: [EmailComponent, TooltipModule],
    entryComponents: [EmailComponent],
})
export class EmailModule {}
