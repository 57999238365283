import { ProductActionUnion, ProductActionTypes } from './../actions';

export const productsStatus = '';

export function setproductsStatusReducer(
    productsStatusState = productsStatus,
    action: ProductActionUnion
) {
    switch (action.type) {
        case ProductActionTypes.setProductsStatus: {
            productsStatusState = action.payload;
            return productsStatusState;
        }
        default: {
            return productsStatusState;
        }
    }
}
