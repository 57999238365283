import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    InputModule,
    CruxDropdownModule,
    // ZipCodeModule is a crux module that has the same name as this module
    // Importing it as CruxZipCodeModule to prevent errors
    ZipCodeModule as CruxZipCodeModule,
} from '@crux/components';
import { ZipCodeComponent } from './zip-code.component';

@NgModule({
    imports: [CommonModule, InputModule, CruxZipCodeModule, CruxDropdownModule],
    declarations: [ZipCodeComponent],
    exports: [ZipCodeComponent],
})
export class ZipCodeModule {}
