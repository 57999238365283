import { Injectable } from '@angular/core';
import { CruxHttpClientService } from '@crux/services';
import { environment } from '../../environments/environment';
import { CommonCommunicationService } from './common-communication.service';
import { find, isEmpty, isNil } from 'lodash';
import paymentDrawerJson from '../../assets/json/payment-options-drawer.json';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { SetLoadingModalFactsAction } from '../sales-store/partners-redirection-store/actions';

@Injectable({
    providedIn: 'root',
})
export class GetBlobContentService {
    blobURL = environment.blobStorageBaseURL;

    constructor(
        private _http: CruxHttpClientService,
        public _ccs: CommonCommunicationService,
        private _store: Store<{}>,
    ) {}

    getPaymentDrawerContent() {
        const ts = new Date().getTime();
        this._http
            .get(this.blobURL + '/data/payment-options-drawer.json?ts=' + ts)
            .subscribe(
                (paymentDrawerRes) => {
                    if (
                        paymentDrawerRes &&
                        !isNil(paymentDrawerRes) &&
                        !isEmpty(paymentDrawerRes)
                    ) {
                        this._ccs.paymentDrawerOptions = this.getDataBasedOnPartner(
                            paymentDrawerRes
                        );
                        this._ccs.paymentDrawerOptions = this._ccs.paymentDrawerOptions[0];
                    } else {
                        this._ccs.paymentDrawerOptions = this.getDataBasedOnPartner(
                            paymentDrawerJson
                        );
                        this._ccs.paymentDrawerOptions = this._ccs.paymentDrawerOptions[0];
                    }
                },
                (error: Response) => {
                    this._ccs.paymentDrawerOptions = this.getDataBasedOnPartner(
                        paymentDrawerJson
                    );
                    this._ccs.paymentDrawerOptions = this._ccs.paymentDrawerOptions[0];
                }
            );
    }

    getHomePageContent() {
        const ts = new Date().getTime();
        return this._http
            .get(this.blobURL + '/data/home-page-content.json?ts=' + ts)
            .pipe(
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
    }

    getIndustriesContent() {
        const ts = new Date().getTime();
        return this._http
            .get(this.blobURL + '/data/who-we-insure.json?ts=' + ts)
            .pipe(
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
    }

    getDataBasedOnPartner(inputData) {
        let filteredContent: any;
        if (
            this._ccs.partnerSource &&
            !isNil(this._ccs.partnerSource) &&
            !isEmpty(this._ccs.partnerSource)
        ) {
            if (find(inputData, ['partnerId', this._ccs.partnerSource])) {
                filteredContent = inputData.filter((item) => {
                    return item.partnerId === this._ccs.partnerSource;
                });
                return filteredContent;
            } else {
                filteredContent = inputData.filter((item) => {
                    return item.partnerId === 'default';
                });
                return filteredContent;
            }
        } else {
            filteredContent = inputData.filter((item) => {
                return item.partnerId === 'default';
            });
            return filteredContent;
        }
    }

    getLoadingModalFacts(){
        const ts = new Date().getTime();
        this._http
        .get(this.blobURL + '/data/loading-modal-facts.json?ts=' + ts, {
            responseType: 'json',
        })
        .subscribe((res) => {
            if (!isNil(res) && !isEmpty(res)) {
                this._store.dispatch(new SetLoadingModalFactsAction(res));
            }
        });
    }
}
