import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AnalyticsService } from '@crux/services';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
    @Input() showTitle: boolean;

    @Input() title: string;

    @Input() index: number;

    @Input() videoURL: string;

    @Input() mobileVideoURL: string;

    @Input() showSmallImage: boolean;

    @Output() moveTo: EventEmitter<number> = new EventEmitter<number>();

    constructor(private _analytics: AnalyticsService) {}

    ngOnInit() {}

    handleClick(e) {
        this._analytics.eventTrack.next({
            category: 'Start_Cover_Grow',
            action: 'IMAGE_CLICK',
            label: 'View ' + this.title + ' section',
        });
        this.moveTo.emit(this.index);
    }
}
