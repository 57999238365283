import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CruxHttpClientService } from '@crux/services';
import { environment } from '../../environments/environment';
import { CommonCommunicationService } from './common-communication.service';
import { select, Store } from '@ngrx/store';
import { AffinityPartnerFlags } from '../model/affinity-partner';
import { isEmpty, isNil } from 'lodash-es';
import { HttpHeaders } from '@angular/common/http';

enum Partner {
    TeamFocus = 0,
    Amex,
}

@Injectable()
export class IssuePolicyService {
    issueServiceUrl: any[] = [];
    affinityPartnerFlags = new AffinityPartnerFlags();
    partnerCode: string;
    constructor(
        private _http: CruxHttpClientService,
        private _ccs: CommonCommunicationService,
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>
    ) {
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    this.affinityPartnerFlags.partnercode =
                        AffinityPartnerFlags.partnercode;
                    if (
                        this.affinityPartnerFlags.partnercode &&
                        !isNil(this.affinityPartnerFlags.partnercode) &&
                        !isEmpty(this.affinityPartnerFlags.partnercode)
                    ) {
                        if (
                            this.affinityPartnerFlags.partnercode !== 'walmart'
                        ) {
                            if (
                                this.affinityPartnerFlags.partnercode ===
                                'cospring5'
                            ) {
                                this.partnerCode = 'capitalone5';
                            } else if (
                                this.affinityPartnerFlags.partnercode ===
                                'cospring10'
                            ) {
                                this.partnerCode = 'capitalone10';
                            } else {
                                this.partnerCode = this.affinityPartnerFlags.partnercode;
                            }
                        }
                    }
                }
            });
    }

    getPolicyIssueData(params, partnerId, policyStateCode) {
        let url: string;
        if (!isNil(this.partnerCode) && !isEmpty(this.partnerCode)) {
            url =
                environment.backend +
                environment.uri.issue.replace('#PARTNER#', this.partnerCode);
        } else {
            url =
                environment.backend +
                environment.uri.issue.replace('#PARTNER#', partnerId);
        }
        if (params != null || params != undefined) {
            let reqObj: any = {};
            reqObj.PolicyRequest = {};
            reqObj.PolicyRequest = params.PolicyRequest;

            return this._http.post(url, { body: reqObj }).pipe(
                timeout(120000),
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
        }
    }

    getSciPolicyIssueData(params) {
        let url = environment.backend + environment.uri.sciissue;
        url = url.replace('#PARTNER#', this._ccs.PartnerId);
        if (params !== null && params !== undefined) {
            if (
                this._ccs.backendEnvVariable &&
                !isNil(this._ccs.backendEnvVariable) &&
                !isEmpty(this._ccs.backendEnvVariable)
            ) {
                const headers = {};
                headers['env'] = this._ccs.backendEnvVariable;
                return this._http
                    .post(url, {
                        body: params,
                        headers: new HttpHeaders(headers),
                    })
                    .pipe(
                        timeout(120000),
                        catchError((error: Response) => {
                            return observableThrowError(error);
                        })
                    );
            } else {
                return this._http.post(url, { body: params }).pipe(
                    timeout(120000),
                    catchError((error: Response) => {
                        return observableThrowError(error);
                    })
                );
            }
        }
    }
}
