import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CruxHttpClientService, AnalyticsService } from '@crux/services';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';

@Component({
    selector: 'app-content-section',
    templateUrl: './content-section.component.html',
    styleUrls: ['./content-section.component.scss'],
})
export class ContentSectionComponent implements OnInit {
    public contents: any;
    public blobURL = environment.blobStorageBaseURL;
    customOptions: OwlOptions = {
        loop: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1,
            },
            375: {
                items: 1,
            },
            400: {
                items: 2,
            },
            768: {
                items: 3,
            },
            1024: {
                items: 4,
            },
        },
        nav: true,
    };
    constructor(
        private http: CruxHttpClientService,
        private _router: Router,
        private _analytics: AnalyticsService
    ) {}

    ngOnInit() {
        const ts = new Date().getTime();
        this.http
            .get(this.blobURL + '/data/content-section-data.json?ts=' + ts, {
                responseType: 'json',
            })
            .subscribe((res) => {
                this.contents = res;
            });
    }

    navigateTo(url: string, itemNo: number, title: string) {
        this._analytics.eventTrack.next({
            category: 'BLOG_ARTICLE_' + itemNo.toString(),
            action: 'BLOG_ITEM_CLICK',
            label: title,
        });
        this._router.navigateByUrl(url);
    }
}
