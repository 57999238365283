import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsTileComponent } from './products-tile.component';
import { ProductModule } from './product/product.module';

@NgModule({
    declarations: [ProductsTileComponent],
    imports: [CommonModule, ProductModule],
    exports: [ProductsTileComponent],
})
export class ProductsTileModule {}
