import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {
    QuestionType,
    DataType,
} from '../../../csb-quote/csb-question/model/question-type';
import {
    QuoteStepAction,
    QuestionDataAction,
    QuestionInsuranceAction,
    QuestionRevenueAction,
    QuestionBusinessAction,
    QuestionZipCodeAction,
    QuestionEmailAction,
    QuestionResetDataAction,
    StartQuoteAction,
    ResetRevenueDataQuoteAction,
    ResetZipcodeDataQuoteAction,
    ResetClassificationQuoteAction,
} from '../../../sales-store/appetite-store/actions';
import {
    SetAffinityPartnerInfoAction,
    SetAgentInfoAction,
    SetProducerCodesAction,
    SetRetrievedComProducerCodesAction,
} from '../../../sales-store/partners-redirection-store/actions';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { environment } from '../../../../environments/environment';
import {
    FormControl,
    FormBuilder,
    FormGroup,
    Validators,
    ValidatorFn,
    AbstractControl,
} from '@angular/forms';
import { QuoteStep } from '../../../model/quote-step';
import { Store, select } from '@ngrx/store';
import { CruxHttpClientService, AnalyticsService } from '@crux/services';
import { find, isEmpty, isNil, isNull } from 'lodash';
import { AffinityPartnerFlags } from '../../../model/affinity-partner';
import { CustomerInfo } from '../../../model/customer-info';
import { QuestionsEffects } from '../../../sales-store/questions-store/effects';
import { SetCustomerInfoDataAction } from '../../../sales-store/questions-store/actions';
import { AgentInformation } from '../../../model/agent-info';
import { HttpHeaders } from '@angular/common/http';
import { MsalAuthService } from '../../../services/msal-auth.service';
import { UserProfileRequest } from '../../../model/user-profile-req';
import { GetCOMProducerCodesRequest } from '../../../model/get-com-producer-codes-request';

@Component({
    selector: 'app-cisa-homepage',
    templateUrl: './cisa-homepage.component.html',
    styleUrls: ['./cisa-homepage.component.scss'],
})
export class CisaHomepageComponent implements OnInit {
    cisaForm: FormGroup;
    producerCode: FormControl;
    emailField: FormControl;
    firstName: FormControl;
    lastName: FormControl;
    phoneNumber: FormControl;
    disabledNavigation = true;
    disabledretrieveNavigation = false;
    disabledquoteNavigation = false;
    showVerify = true;
    defaultProducercode = '';
    defaultEmail = '';
    defaultFirstName = '';
    defaultLastName = '';
    defaultPhoneNumber = '';
    private quote: QuoteStep = new QuoteStep();
    private blobURL = environment.blobStorageBaseURL;
    questionData: Array<QuestionType>;
    producerCodesContent: any;
    AffinityPartnerFlags: AffinityPartnerFlags;
    customerInfoData: CustomerInfo;
    agencyName: any;
    agentInformation: AgentInformation = new AgentInformation();
    ssoUserInfo: any;
    retrievedCOMProducerCodes = [];

    constructor(
        private router: Router,
        public _ccs: CommonCommunicationService,
        private msalAuthService: MsalAuthService,
        private http: CruxHttpClientService,
        private _fb: FormBuilder,
        private _questionsEffects: QuestionsEffects,
        private _store: Store<{
            quoteStep: QuoteStep;
            producerCodesInfo: any;
            AffinityPartnerFlags: AffinityPartnerFlags;
            agentInfoSaved: AgentInformation;
            retrievedComProducerCodes: any;
        }>
    ) {
        const ts = new Date().getTime();

        this.http
            .get(this.blobURL + '/data/quote-normal-question.json?ts=' + ts, {
                responseType: 'json',
            })
            .subscribe((res) => {
                this.questionData = res.data;
                this.updateHomeQuestion();
            });
        this._store
            .pipe(select('quoteStep'))
            .subscribe((quoteStep: QuoteStep) => {
                if (
                    quoteStep &&
                    quoteStep.email &&
                    !isNil(quoteStep.email) &&
                    !isEmpty(quoteStep.email)
                ) {
                    this.quote.email = quoteStep.email;
                    this.defaultEmail = quoteStep.email.value;
                    this.updateHomeQuestion();
                }
            });

        this._questionsEffects
            .readCustomerInfoDataFromStore$()
            .subscribe((customerInfoData) => {
                this.customerInfoData = customerInfoData;
                this.defaultFirstName = this.customerInfoData.firstName;
                this.defaultLastName = this.customerInfoData.lastName;
                this.defaultPhoneNumber = this.customerInfoData.phoneNumber;
            });

        this._store
            .pipe(select('producerCodesInfo'))
            .subscribe((producerCodesInfo) => {
                if (!isNil(producerCodesInfo) && !isEmpty(producerCodesInfo)) {
                    this.producerCodesContent = producerCodesInfo;
                    this.producerCodesContent.forEach((producercode) => {
                        if (producercode.partnerId === 'CISA') {
                            this.defaultProducercode = producercode.defaultCode;
                        }
                    });
                }
            });

        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.AffinityPartnerFlags = AffinityPartnerFlags;
                    }
                }
            });

        this._store.pipe(select('agentInfoSaved')).subscribe((res) => {
            if (
                res &&
                res.agencyName &&
                !isNil(res.agencyName) &&
                !isEmpty(res.agencyName)
            ) {
                this.agencyName = res.agencyName;
            }
        });
        this._store
            .pipe(select('retrievedComProducerCodes'))
            .subscribe((res) => {
                if (res && !isNil(res) && !isEmpty(res)) {
                    this.retrievedCOMProducerCodes = res;
                }
            });
    }

    ngOnInit() {
        // this.ssoUserInfo = localStorage.getItem('ampUser');
        this.ssoUserInfo = sessionStorage.getItem('ampUser');
        if (
            this.ssoUserInfo &&
            !isNil(this.ssoUserInfo) &&
            !isEmpty(this.ssoUserInfo) &&
            this.retrievedCOMProducerCodes &&
            this.retrievedCOMProducerCodes.length === 0
        ) {
            this.ssoUserInfo = JSON.parse(this.ssoUserInfo);
            this.defaultFirstName = this.ssoUserInfo.firstName;
            this.defaultLastName = this.ssoUserInfo.lastName;
            this.defaultEmail = this.ssoUserInfo.email;
            this.defaultPhoneNumber = this.ssoUserInfo.phone;
            this._ccs.showSpinner = true;

            /* Get User basic details */
            let requestObj: UserProfileRequest = new UserProfileRequest();
            requestObj.userId = this.ssoUserInfo.id;
            requestObj.email = '';
            requestObj.firstName = '';
            requestObj.lastName = '';
            requestObj.adminUserId = '';
            requestObj.admin = '';
            requestObj.phone = '';
            requestObj.roleInfoRequired = true;
            requestObj.basicInfoRequired = true;
            requestObj.profileLinkingSearchRequired = false;
            requestObj.pasCodeRequired = true;
            requestObj.stepValueRequired = true;
            this.userProfileAPI(requestObj);

            /* Get Com producer codes for the sso user */
            let getCOMProducerCodesRequest: GetCOMProducerCodesRequest = new GetCOMProducerCodesRequest();
            getCOMProducerCodesRequest.userID = this.ssoUserInfo.id;
            getCOMProducerCodesRequest.adminFlagRequired = false;
            getCOMProducerCodesRequest.onlyAdminCode = false;
            getCOMProducerCodesRequest.assignedFlag = true;
            getCOMProducerCodesRequest.legacyCodeRequired = false;
            getCOMProducerCodesRequest.categoryGroupCode = ['COM'];
            this.retrieveCOMProducerCodes(getCOMProducerCodesRequest);
        }
        this.producerCode = new FormControl(this.defaultProducercode, [
            Validators.required,
        ]);
        this.emailField = new FormControl(this.defaultEmail, [
            Validators.required,
        ]);
        this.firstName = new FormControl(this.defaultFirstName, [
            Validators.required,
        ]);
        this.lastName = new FormControl(this.defaultLastName, [
            Validators.required,
        ]);
        this.phoneNumber = new FormControl(this.defaultPhoneNumber);
        this.cisaForm = this._fb.group({
            producerCode: this.producerCode,
            emailField: this.emailField,
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
        });

        if (this.defaultEmail !== '' && this.defaultProducercode !== '') {
            this.disabledretrieveNavigation = true;
            this.disabledquoteNavigation = true;
            this.showVerify = false;
        }
    }

    updateHomeQuestion = () => {
        if (this.questionData) {
            this.questionData.forEach((item) => {
                const name = item.data.name;
                if (this.quote[name]) {
                    item.data.valid = this.quote[name].valid;
                    item.data.value = this.quote[name].value;
                }
            });
            this.quote.questionData = [...this.questionData];
        }
    };

    handleStore(data) {
        if (this.emailField.valid) {
            this.quote.questionData.forEach((qdata) => {
                if (qdata.data.name === 'email') {
                    qdata.data.valid = true;
                    qdata.data.value = data.target.value;
                    this.dispatchStoreDataEvent(qdata.data);
                }
            });
            this._store.dispatch(new QuestionDataAction(this.quote));
            this.customerInfoData.emailAddress = data.target.value;
            this._store.dispatch(
                new SetCustomerInfoDataAction(this.customerInfoData)
            );
        }
    }

    dispatchStoreDataEvent(data: DataType) {
        if (data && data.valid) {
            switch (data.name) {
                case 'email':
                    this.quote.email = data;
                    this._store.dispatch(new QuestionEmailAction(this.quote));
                    break;

                default:
                    break;
            }
        }
    }

    checkalphanum(event) {
        var inp = String.fromCharCode(event.keyCode);

        if (/[a-zA-Z0-9]/.test(inp)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    checkValidity(e, field) {
        if (field === 'emailField') {
            const regExp: RegExp = new RegExp(
                '^[a-zA-Z0-9!._]*@([a-zA-Z0-9-]*[.]{1})[a-zA-Z0-9-]*$',
                'gm'
            );
            const invalidState = regExp.test(e.target.value);
            if (e.target.value === '') {
                this.emailField.setErrors({ required: true });
            } else if (!invalidState) {
                this.emailField.setErrors({ invalid: true });
            }
            this.handleStore(e);
        }
        if (field === 'producerCode') {
            this.alphanumer(e);
        }
        this.disabledretrieveNavigation = false;
        this.disabledquoteNavigation = false;
        this.showVerify = true;
        if (this.cisaForm.valid) {
            this.disabledNavigation = false;
            this.disabledretrieveNavigation = true;
            this.disabledquoteNavigation = true;
        } else {
            this.disabledNavigation = true;
            this.disabledretrieveNavigation = false;
            this.disabledquoteNavigation = false;
        }
    }

    alphanumer(e) {
        // let pattern = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$');
        if (isNil(e) || isEmpty(e) || (e && e.length && e.length === 0)) {
            this.producerCode.setErrors({ required: true });
            this.agencyName = '';
        } else if (e && e.length && e.length === 1) {
            // this.producerCode.setErrors({ invalid: true });
            // this.agencyName = '';
            this.defaultProducercode = e[0];
            this.retrievedCOMProducerCodes.forEach((code) => {
                if (
                    code.zCode &&
                    !isNil(code.zCode) &&
                    !isEmpty(code.zCode) &&
                    code.zCode === e[0].zCode
                ) {
                    code.selected = true;
                }
            });
            this.agentInformation.producerName = this.defaultProducercode[
                'producerName'
            ];
            this.retrieveAPI(this.defaultProducercode['legacyCode']);
        }
    }

    Submitcode() {
        if (this.cisaForm.valid) {
            this._ccs.showSpinner = true;
            //call API to verify producer code
            // let url = environment.backend + environment.uri.producerInfo;
            // let RequestBody = {
            //     ProducersInfo: [
            //         { PASSourceProducerCode: this.producerCode.value },
            //     ],
            //     ProducerGroupCode: '2249',
            //     serviceConsumerId: environment.csbAPPId,
            //     requestId: crypto['randomUUID'](),
            // };
            // this.http.post(url, { body: RequestBody }).subscribe(
            //     (res) => {
            //         this._ccs.showSpinner = false;
            //         if (
            //             res.status === 'Success' &&
            //             res.producersInfo.length > 0
            //         ) {
            //             let prodnumber =
            //                 res.producersInfo[0].producerNumber.slice(-5) +
            //                 '-' +
            //                 this.trimNumber(
            //                     res.producersInfo[0].producerSubNumber
            //                 );
            //             this.agentInformation.producerName =
            //                 res.producersInfo[0].producerName;
            //             this.retrieveAPI(prodnumber);
            //         } else if (res.status === 'Failure') {
            //             this.agencyName = '';
            //             this.producerCode.setErrors({ invalid: true });
            //         }
            //     },
            //     (error: Response) => {
            //         this._ccs.showSpinner = false;
            //         this.agencyName = '';
            //         this.producerCode.setErrors({ incorrect: true });
            //     }
            // );
            //end
            this.retrieveAPI(this.defaultProducercode['legacyCode']);
        }
    }

    redirectURL(ur) {
        if (ur === 'retrieve') {
            this.router.navigate(['/retrieve-customer']);
        } else if (ur === 'getaquote') {
            this.router.navigate(['/get-a-quote']);
        }
    }

    trimNumber(e) {
        let s = e.replace(/^0+/, '');
        if (s.length < 3) {
            s = e.slice(-3);
        }
        return s;
    }

    userProfileAPI(searchTerm: UserProfileRequest) {
        this.msalAuthService.getProducerCode(searchTerm).subscribe(
            (res) => {
                this._ccs.showSpinner = false;
                if (
                    res &&
                    res.userProfileSearchList &&
                    res.userProfileSearchList.length > 0
                ) {
                    this.defaultPhoneNumber =
                        res.userProfileSearchList[0].phoneNumber;
                    this.phoneNumber.setValue(this.defaultPhoneNumber);
                    this.customerInfoData.phoneNumber = this.phoneNumber.value;
                    this._store.dispatch(
                        new SetCustomerInfoDataAction(this.customerInfoData)
                    );
                }
            },
            (error: Response) => {
                this._ccs.showSpinner = false;
            }
        );
    }

    retrieveCOMProducerCodes(reqObj: GetCOMProducerCodesRequest) {
        this.msalAuthService.retrieveCOMProducerCode(reqObj).subscribe(
            (res) => {
                if (
                    res &&
                    res.message &&
                    res.message.toLowerCase() === 'success'
                ) {
                    if (res.producer && res.producer.length > 0) {
                        res.producer.forEach((code) => {
                            let obj = {};
                            obj['zCode'] = code.producerCode;
                            let str = code.legacyChubbCode.split('-');
                            obj['legacyCode'] =
                                str[0].slice(-5) +
                                '-' +
                                this.trimNumber(str[1]);
                            obj['selected'] = false;
                            obj['producerName'] = code.producerName;
                            this.retrievedCOMProducerCodes.push(obj);
                        });
                        this.defaultProducercode = this.retrievedCOMProducerCodes[0];
                        this.retrievedCOMProducerCodes[0].selected = true;
                        this.agentInformation.producerName = this.retrievedCOMProducerCodes[0].producerName;
                        this.producerCode.setValue(
                            this.retrievedCOMProducerCodes[0].zCode
                        );
                        this.Submitcode();
                    }
                } else {
                    this._ccs.showSpinner = false;
                    this.agencyName = '';
                    this.producerCode.setErrors({ invalid: true });
                }
            },
            (error: Response) => {
                this._ccs.showSpinner = false;
                this.agencyName = '';
                this.producerCode.setErrors({ incorrect: true });
            }
        );
    }

    retrieveAPI(prodnumber, errcount = 0) {
        this._ccs.showSpinner = true;
        let urlepic = environment.backend + environment.uri.retrieval;
        let RequestBodyEpic = {
            producerType: 'LEGACYPRODUCER',
            producerNumber: prodnumber,
        };
        this.http.post(urlepic, { body: RequestBodyEpic }).subscribe(
            (ress) => {
                this._ccs.showSpinner = false;
                if (ress.length > 0) {
                    this.agencyName = ress[0].accountName
                        ? ress[0].accountName
                        : '';
                    this.agentInformation.agencyName = ress[0].accountName
                        ? ress[0].accountName
                        : '';
                    this.agentInformation.producerCode = this.producerCode.value.zCode;
                    this.agentInformation.LegacyChubbCode = ress[0].legacyCode
                        ? ress[0].legacyCode
                        : '';
                    this.agentInformation.producerEmail = this.emailField.value;

                    this._store.dispatch(
                        new SetAgentInfoAction(this.agentInformation)
                    );
                    this._store.dispatch(new QuoteStepAction(this.quote));

                    this.customerInfoData.emailAddress = this.emailField.value;
                    this.customerInfoData.firstName = this.firstName.value;
                    this.customerInfoData.lastName = this.lastName.value;
                    this.customerInfoData.phoneNumber = this.phoneNumber.value;
                    this._store.dispatch(
                        new SetCustomerInfoDataAction(this.customerInfoData)
                    );
                    let cisaProducer = (this.producerCodesContent = this.producerCodesContent.filter(
                        function (resData) {
                            return resData.partnerId === 'CISA';
                        }
                    ));
                    cisaProducer = cisaProducer[0];
                    cisaProducer.partnerId = this.AffinityPartnerFlags.partnercode;
                    cisaProducer.defaultCode = this.producerCode.value;

                    this.producerCodesContent = this.producerCodesContent.filter(
                        function (resData) {
                            return resData.partnerId !== 'CISA';
                        }
                    );
                    this.producerCodesContent.push(cisaProducer);
                    this._store.dispatch(
                        new SetProducerCodesAction(this.producerCodesContent)
                    );
                    this._store.dispatch(
                        new SetRetrievedComProducerCodesAction(
                            this.retrievedCOMProducerCodes
                        )
                    );
                    if (
                        this.emailField.value &&
                        !isNil(this.emailField.value) &&
                        !isEmpty(this.emailField.value)
                    ) {
                        this.quote.questionData.forEach((qdata) => {
                            if (qdata.data.name === 'email') {
                                qdata.data.valid = true;
                                qdata.data.value = this.emailField.value;
                                this.dispatchStoreDataEvent(qdata.data);
                            }
                        });
                        this._store.dispatch(
                            new QuestionDataAction(this.quote)
                        );
                    }
                } else {
                    errcount = errcount + 1;
                    if (errcount > 3) {
                        this.agencyName = '';
                        this.agentInformation.agencyName = this.agencyName;
                        this.agentInformation.producerCode = this.producerCode.value;
                        this.agentInformation.LegacyChubbCode = '';
                        this._store.dispatch(
                            new SetAgentInfoAction(this.agentInformation)
                        );
                        this._store.dispatch(new QuoteStepAction(this.quote));
                        this.customerInfoData.emailAddress = this.emailField.value;
                        this._store.dispatch(
                            new SetCustomerInfoDataAction(this.customerInfoData)
                        );
                        let cisaProducer = (this.producerCodesContent = this.producerCodesContent.filter(
                            function (resData) {
                                return resData.partnerId === 'CISA';
                            }
                        ));
                        cisaProducer = cisaProducer[0];
                        cisaProducer.partnerId = this.AffinityPartnerFlags.partnercode;
                        cisaProducer.defaultCode = this.producerCode.value;

                        this.producerCodesContent = this.producerCodesContent.filter(
                            function (resData) {
                                return resData.partnerId !== 'CISA';
                            }
                        );

                        this.producerCodesContent.push(cisaProducer);
                        this._store.dispatch(
                            new SetProducerCodesAction(
                                this.producerCodesContent
                            )
                        );
                        this._store.dispatch(
                            new SetRetrievedComProducerCodesAction(
                                this.retrievedCOMProducerCodes
                            )
                        );
                    } else {
                        this.retrieveAPI(prodnumber, errcount);
                    }
                }
                this.disabledretrieveNavigation = true;
                this.disabledquoteNavigation = true;
                this.showVerify = false;
            },
            (error: Response) => {
                this._ccs.showSpinner = false;
                this.agencyName = '';
                this.agentInformation = new AgentInformation();
                this.agentInformation.agencyName = this.agencyName;
                this.agentInformation.producerCode = this.producerCode.value;
                this.agentInformation.LegacyChubbCode = '';
                this._store.dispatch(
                    new SetAgentInfoAction(this.agentInformation)
                );
                this._store.dispatch(new QuoteStepAction(this.quote));
                this.customerInfoData.emailAddress = this.emailField.value;
                this._store.dispatch(
                    new SetCustomerInfoDataAction(this.customerInfoData)
                );
                let cisaProducer = (this.producerCodesContent = this.producerCodesContent.filter(
                    function (resData) {
                        return resData.partnerId === 'CISA';
                    }
                ));
                cisaProducer = cisaProducer[0];
                cisaProducer.partnerId = this.AffinityPartnerFlags.partnercode;
                cisaProducer.defaultCode = this.producerCode.value;

                this.producerCodesContent = this.producerCodesContent.filter(
                    function (resData) {
                        return resData.partnerId !== 'CISA';
                    }
                );

                this.producerCodesContent.push(cisaProducer);
                this._store.dispatch(
                    new SetProducerCodesAction(this.producerCodesContent)
                );
                this._store.dispatch(
                    new SetRetrievedComProducerCodesAction(
                        this.retrievedCOMProducerCodes
                    )
                );
                this.disabledretrieveNavigation = true;
                this.disabledquoteNavigation = true;
                this.showVerify = false;
            }
        );
    }
}
