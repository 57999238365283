import { Action } from '@ngrx/store';

export enum sciCyberQuoteActionTypes {
    sciCyberQuoteData = '[scicyberquote] setscicyberquote',
}

export class SetsciCyberQuoteAction implements Action {
    readonly type = sciCyberQuoteActionTypes.sciCyberQuoteData;

    constructor(public payload: any) {}
}

export type sciCyberQuoteActionUnion = SetsciCyberQuoteAction;
