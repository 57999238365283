import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
    @Input() isIE: boolean;
    @Input() ieNotificationText: string;
    @Input() showClose: boolean = true;
    @Input() styleType: any;
    @Input() iconClass: string;

    public showIe: boolean = true;

    closeIeMessage() {
        this.showIe = false;
    }
}
