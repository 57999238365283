import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CruxHttpClientService } from '@crux/services';
import { environment } from '../../environments/environment';
import { CommonCommunicationService } from './common-communication.service';
import { isEmpty, isNil } from 'lodash';

@Injectable()
export class PaymentRequestService {
    constructor(
        private _http: CruxHttpClientService,
        private _ccs: CommonCommunicationService
    ) {}

    getPaymentIssuedData(params) {
        let url = environment.backend + environment.uri.sciissue;
        url = url.replace('#PARTNER#', this._ccs.PartnerId);
        if (params !== null && params !== undefined) {
            return this._http.post(url, { body: params }).pipe(
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
        }
    }

    doCharge(request, apiUrl) {
        let url = apiUrl;
        if (!isNil(request) && !isEmpty(request)) {
            return this._http.post(url, { body: request }).pipe(
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
        }
    }
}
