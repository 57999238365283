import { Component, OnInit } from '@angular/core';
import { CommonCommunicationService } from '../../services/common-communication.service';
import { ProductSelectedPipe } from '../../pipes/productSelected.pipe';
import { DictionaryService } from '../../services/dictionary.service';
import { format } from 'date-fns';

/**
 * NGRX store imports
 */
import { ProductEffects } from '../../sales-store/products-store/effects';
import { Product } from '../../model/product';
import { Store, select } from '@ngrx/store';
import { attempt } from 'lodash-es';
import {
    SetProductsAction,
    SetTotalPremiumAction,
} from '../../sales-store/products-store/actions';
import { QuestionsEffects } from '../../sales-store/questions-store/effects';
import { CustomerInfo } from '../../model/customer-info';
import { ActivatedRoute } from '@angular/router';
import { DownloadQuoteService } from '../../services/download-quote.service';
import { PdfData } from '../../model/pdfData';

@Component({
    selector: 'app-download-quote',
    templateUrl: './download-quote.component.html',
    styleUrls: ['./download-quote.component.scss'],
})
export class DownloadQuoteComponent implements OnInit {
    public countOfProducts = 0;
    public labelDict: any;
    public products: Product[];
    public totalPremium: number;
    newProductIndex: number;
    customerInfoData: CustomerInfo;
    date: any;
    quoteId: string;
    pdfData: PdfData;

    constructor(
        public ccs: CommonCommunicationService,
        public dictionaryService: DictionaryService,
        private _productEffects: ProductEffects,
        private _store: Store<{ totalPremium: number; products: Product[] }>,
        private _questionsEffects: QuestionsEffects,
        private _route: ActivatedRoute,
        private _downloadService: DownloadQuoteService
    ) {
        this._store.pipe(select('products')).subscribe((products) => {
            this.products = products;
        });
        this._store.pipe(select('totalPremium')).subscribe((totalPremium) => {
            this.totalPremium = totalPremium;
        });
        this._questionsEffects
            .readCustomerInfoDataFromStore$()
            .subscribe((customerInfoData) => {
                this.customerInfoData = customerInfoData;
            });
        this._route.paramMap.subscribe((params) => {
            this.quoteId = params.get('quoteId');
        });
        const currentDate = new Date();
        this.date = format(currentDate, 'MM/dd/yyyy');
        if (this.quoteId) {
            this.pdfDataInit();
        } else {
            this.countOfProducts = new ProductSelectedPipe().transform(
                this.products
            ).length;
            if (this.ccs.isSCIScenario) {
                this.totalPremium = 0;
                this.calculatePremiumOfAllProducts();
                this.countOfSelectedProducts();
            }
        }
        this.labelDict = this.dictionaryService.labelDict;
    }

    ngOnInit() {
        // this._route.paramMap.subscribe((params) => {
        //     this.quoteId = params.get('quoteId');
        // });
        // const currentDate = new Date();
        // this.date = format(currentDate, 'MM/DD/YYYY');
        // if (this.quoteId) {
        //     this.pdfDataInit();
        // } else {
        //     this.countOfProducts = new ProductSelectedPipe().transform(
        //         this.products
        //     ).length;
        //     if (this.ccs.isSCIScenario) {
        //         this.totalPremium = 0;
        //         this.calculatePremiumOfAllProducts();
        //         this.countOfSelectedProducts();
        //     }
        // }
        // this.labelDict = this.dictionaryService.labelDict;
    }

    countOfSelectedProducts() {
        this.products.forEach((product) => {
            if (product.selected === true && product.selectedpackage != null) {
                this.countOfProducts += 1;
            }
        });
    }
    calculatePremiumOfAllProducts() {
        new ProductSelectedPipe()
            .transform(this.products, true)
            .forEach((product) => {
                this.totalPremium +=
                    // tslint:disable-next-line: radix
                    parseInt(product.selectedpackage.premiumAmount) +
                    // tslint:disable-next-line: radix
                    parseInt(
                        product.productPackages[0].optional_coverages
                            .total_premium
                    );
            });
    }

    cartDateFormat(currentDate) {
        return format(Date.parse(currentDate), 'MM/dd/yyyy');
    }

    pdfDataInit() {
        this._downloadService.getPdfData(this.quoteId).subscribe((response) => {
            this.products = response.data;
            this.countOfProducts = new ProductSelectedPipe().transform(
                this.products
            ).length;
            this.totalPremium = 0;
            this.products.forEach((product) => {
                this.totalPremium +=
                    // tslint:disable-next-line: radix
                    parseInt(product.selectedpackage.premiumAmount);
                // tslint:disable-next-line: radix
                // parseInt(
                //     product.productPackages[0].optional_coverages
                //         .total_premium
                // );
            });
            this.countOfSelectedProducts();
        });
    }

    convertNumber(str) {
        if (!str) {
            return;
        }
        str = str.replace(/,/g, '');
        const amt = parseInt(str, 10);
        return amt;
    }

    ifPremium(prod) {
        if (prod && prod.premium_amount && prod.premium_amount.Premium) {
            if (parseInt(prod.premium_amount.Premium, 10) > 0) {
                return true;
            }
        }
        return false;
    }

    // newTotalPremium(productIndex) {
    //     let selectedPackageAmount = this.products[productIndex].selectedpackage
    //         .premiumAmount;
    //     this.totalPremium = this.totalPremium - selectedPackageAmount;
    //     this._store.dispatch(new setTotalPremiumAction(this.totalPremium));
    // }
}
