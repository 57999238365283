import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    AfterViewInit,
} from '@angular/core';
import { Product } from '../../../model/product';
import { Store, select } from '@ngrx/store';
import { find, findIndex, isEmpty, isNil } from 'lodash-es';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import {
    SetProductsAction,
    GetQuestionsAction,
} from '../../../sales-store/products-store/actions';
import { AnalyticsService } from '@crux/services';
import { PartnerCustomization } from '../../../partner-customization/partner-customization.helper';
import { Router } from '@angular/router';
import { ProductsRequest } from '../../../model/products-request';
import { QuoteStep } from '../../../model/quote-step';
import { Subject } from 'rxjs';
import { ProgressBarService } from '../../../services/progress-bar.service';
import {
    SetEligibilityAction,
    SetSciCyberQuestionsAction,
    SetSciQuestionsAction,
} from '../../../sales-store/questions-store/actions';
import { GAEvent } from '../../../csb-core/ga-event';
import { ProductSCI } from '../../../csb-core/product-sci';
import { environment } from '../../../../environments/environment';
import { Classification } from '../../../model/classification';
import { Questions } from '../../../model/questions';
import { ZipcodeModel } from '../../../model/zipcode';
import { AffinityPartnerFlags } from '../../../model/affinity-partner';
import leftRailSteps from '../../../../assets/json/left-rail-steps.json';
@Component({
    selector: 'app-home-page-product-selection',
    templateUrl: './home-page-product-selection.component.html',
    styleUrls: ['./home-page-product-selection.component.scss'],
})
export class HomePageProductSelectionComponent implements OnInit {
    products: Product[];
    noProductsSelected: boolean;
    showProductsView: boolean;
    showSpinnerInProductSelection: boolean;
    R02_value = ['No Claims'];
    showUmbrellaError = true;
    activateUmbrella: Subject<boolean> = new Subject();
    showContinue: boolean;
    @Output() showProducts: EventEmitter<boolean> = new EventEmitter();
    quoteStep: any;
    noOfProducts: string;
    isDesktopDevice = false;
    AffinityPartnerFlags: AffinityPartnerFlags;
    words = ['', 'one', 'two', 'three', 'four', 'five', 'six'];
    productText = {
        GL:
            'Cover the bases with insurance that covers your liability for damage or injury to third parties.',
        MICROGL:
            'Cover the bases with insurance that covers your liability for damage or injury to third parties.',
        BOP:
            'Get your ducks in a row with a single policy that covers a wide range of business risks.',
        MICROBOP:
            'Get your ducks in a row with a single policy that covers a wide range of business risks.',
        MICROCYBER:
            'Reach for the stars with a policy that brings peace of mind for risks that are harder to see.',
        UMBRELLA:
            'Build a layer of extra coverage that protects your business from specific risks.',
        WORKCOMP:
            'Bounce back into action after an accident with comprehensive coverage for you and your staff.',
        MPL:
            'Grow with confidence, knowing your professional reputation is protected and secured.',
        MICROMPL:
            'Grow with confidence, knowing your professional reputation is protected and secured.',
        'CYBER-DIGITECT':
            'Reach for the stars with a policy that brings peace of mind for risks that are harder to see.',
        'CYBER-ERM':
            'Reach for the stars with a policy that brings peace of mind for risks that are harder to see.',
    };

    customOptions: any = {
        loop: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        navSpeed: 700,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1,
            },
            325: {
                items: 1,
                stagePadding: 25,
            },
            375: {
                items: 1,
                stagePadding: 50,
            },
            400: {
                items: 1,
                stagePadding: 65,
            },
            769: {
                items: 2,
            },
            850: {
                items: 3,
            },
        },
        nav: true,
    };
    public classData: any;
    public steps: any[];
    eligibilityQuestions: any;
    constructor(
        private store: Store<{
            products: Product[];
            quoteStep: QuoteStep;
            classification: Classification;
            setEligibility: Questions[];
            zipcode: ZipcodeModel[];
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>,
        private __ccs: CommonCommunicationService,
        private _analytics: AnalyticsService,
        private _partnerCustomization: PartnerCustomization,
        private _router: Router,
        private progressBarService: ProgressBarService
    ) {
        this.store.pipe(select('classification')).subscribe((classData) => {
            this.classData = classData;
        });
        this.store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.AffinityPartnerFlags = AffinityPartnerFlags;
                    }
                }
            });
        this.store.pipe(select('zipcode')).subscribe((zipData) => {
            if (zipData && zipData.length > 1) {
                zipData.forEach((address) => {
                    if (address.selected) {
                        const addressSelected = [];
                        addressSelected.push(address);
                        this.__ccs.stateSelected =
                            addressSelected[0].StateProvince;
                    }
                });
            } else {
                this.__ccs.stateSelected = zipData[0].StateProvince;
            }
        });

        this.store.pipe(select('quoteStep')).subscribe((qs) => {
            this.quoteStep = qs;
        });
        this.store
            .pipe(select('setEligibility'))
            .subscribe((eligibilityFromStore) => {
                this.eligibilityQuestions = eligibilityFromStore;
            });
        this.store.pipe(select('products')).subscribe((products) => {
            //--- SCI Product Filter Start----//
            const productSCI: ProductSCI = ProductSCI.instance();
            if (productSCI.onlySCIProductSelection) {
                products = ProductSCI.getSCIProduct(products);
            }
            //--- SCI Product Filter End----//
            products.forEach((product) => {
                if (product.selected) {
                    this.showContinue = true;
                }
                // if (product.productId === 'WORKCOMP') {
                //     product.isFeature = true;
                // }
                if (product.isFeature !== undefined) {
                    product.featured_ind = product.isFeature;
                }
            });
            this.products = products;

            this.products = this.products.filter(function (product) {
                return product.productId !== 'MICROMPL';
            });

            const R02_value = this.R02_value;

            const glExists = R02_value.indexOf('Liability') > -1 ? true : false;

            const bopExists = R02_value.indexOf('Property') > -1 ? true : false;

            if (this.products && this.products.length > 0) {
                this.products.forEach(function (item) {
                    item.displayProduct = false;
                    item.selectedpackage = null;
                    item.skipFlag = false;
                    if (
                        (item.name !== 'Business Owners Policy' &&
                            item.name !== 'General Liability' &&
                            item.name !== 'Umbrella' &&
                            R02_value.indexOf(item.name) < 0) ||
                        (item.name === 'Business Owners Policy' &&
                            !bopExists &&
                            !glExists) ||
                        (item.name === 'General Liability' && !glExists) ||
                        (item.name === 'Umbrella' && !glExists)
                    ) {
                        item.displayProduct = true;
                    }
                });

                if (this.products.every((el) => el.displayProduct === false)) {
                    this.noProductsSelected = true;
                }
            } else if (this.products) {
                /**
                 * Handle condition if there are no products available even if response came or
                 * BOP products has been removed for eligibilty rejection
                 */
                this.showProductsView = true;
                this.showSpinnerInProductSelection = false;
            }
            if (this.products && this.products.length > 0) {
                let bopExists = false;
                let glExists = false;
                let productIds = [];
                this.products.forEach((product) => {
                    if (product.productId === 'BOP') {
                        bopExists = true;
                    }
                    if (product.productId === 'GL') {
                        glExists = true;
                    }
                });
                this.products.forEach((product) => {
                    productIds.push(product.productId);
                });
                productIds.forEach((id, index, obj) => {
                    if (id === 'MICROBOP' && bopExists) {
                        obj.splice(index, 1);
                    }
                    if (id === 'MICROGL' && glExists) {
                        obj.splice(index, 1);
                    }
                });
                this.noOfProducts = this.words[productIds.length];
            }
        });
    }

    filterProducts(product) {
        return ProductSCI.filterSCIProduct(product, this.products);
        /*const productId = product.productId;
        let displayProductIndicator = true;
        // Filter extra SCI products
        if (productId.startsWith('MICRO')) {
            const sciProduct = find(this.products, [
                'productId',
                productId.split('MICRO')[1],
            ]);
            if (sciProduct) {
                if (!sciProduct.selected) {
                    return displayProductIndicator;
                } else {
                    return false;
                }
            } else {
                return displayProductIndicator;
            }
        } else {
            this.products.forEach((productFromStore) => {
                if (!product.selected) {
                    if ('MICRO' + productId === productFromStore.productId) {
                        displayProductIndicator = false;
                    }
                } else {
                    displayProductIndicator = true;
                }
            });
        }
        return displayProductIndicator;*/
    }

    ngOnInit() {
        if (
            !isNil(this.AffinityPartnerFlags) &&
            !isEmpty(this.AffinityPartnerFlags)
        ) {
            if (
                this.AffinityPartnerFlags.partnercode &&
                this.AffinityPartnerFlags.partnercode === 'ISBA10'
            ) {
                this.products = this.products.filter(function (product) {
                    return (
                        product.productId !== 'CYBER-ERM' &&
                        product.productId !== 'CYBER-DIGITECT'
                    );
                });
            } else if (
                this.AffinityPartnerFlags.partnercode &&
                this.AffinityPartnerFlags.partnercode === 'amazon10'
            ) {
                leftRailSteps.forEach((step, index, obj) => {
                    if (step.title === 'Product Selection') {
                        obj.splice(index, 1);
                    }
                });
                leftRailSteps.forEach((step, index) => {
                    if (step.name === 'Step ') {
                        step.name = step.name + (index + 1).toString();
                    }
                });
                this.progressBarService.steps = leftRailSteps;
                if (this.__ccs.questionsLoaded) {
                    this.__ccs.questionsLoaded = false;
                    this.goBack();
                    return;
                }
                this.products = this.products.filter(function (product) {
                    return product.productId === 'GL';
                });
                if (this.products.length > 0) {
                    this.products.forEach((product) => {
                        product.selected = true;
                        product.displayProduct = true;
                    });
                    this.continue();
                }
            }
        }
    }

    checkBopGroupSelection() {
        // check if BOP / GL selected
        let bopSelectionFlag = false;
        this.products.forEach((product) => {
            if (product.selected && product.productGroup === 'BOP') {
                bopSelectionFlag = true;
            }
        });
        return bopSelectionFlag;
    }

    selectProduct(productObj): void {
        // if (this.invalidPartnersField) {
        //     const productErrorfield = find(
        //         this.invalidPartnersField.invalidParams,
        //         ['errorFieldId', 'products']
        //     );
        //     if (productErrorfield) {
        //         productErrorfield.showError = false;
        //     }
        // }
        this.products.map((product) => {
            if (
                product.productGroup === productObj.productGroup &&
                product.productId !== productObj.productId &&
                productObj.productId !== 'UMBRELLA'
            ) {
                if (product.productId !== 'UMBRELLA') {
                    product.selected = false;
                }
            }
        });

        // Code should behave as usual for Cyber and MPL
        if (
            productObj.productGroup !== 'BOP' &&
            productObj.productId !== 'UMBRELLA'
        ) {
            // Toggle

            productObj.selected = !productObj.selected;
        } else if (productObj.productId === 'UMBRELLA') {
            // User is trying to deselect Umbrella
            if (productObj.selected) {
                productObj.selected = false;
            } else {
                // User is trying to select Umbrella

                // Check if BOP / GL selected
                if (this.checkBopGroupSelection()) {
                    productObj.selected = true;
                    this.showUmbrellaError = false;
                    this.activateUmbrella.next(false);
                } else {
                    // display error
                    this.showUmbrellaError = true;
                    this.activateUmbrella.next(true);
                }
            }
        } else if (productObj.productGroup === 'BOP') {
            // user trying deselect BOP group product

            if (productObj.selected) {
                this.products.forEach((product) => {
                    if (
                        product.productId === 'UMBRELLA' &&
                        productObj.selected
                    ) {
                        product.selected = false;
                        this.showUmbrellaError = true;
                        this.activateUmbrella.next(true);
                    }
                });
                productObj.selected = false;
            } else {
                // User is trying to select BOP group product
                productObj.selected = true;
                if (this.showUmbrellaError) {
                    this.showUmbrellaError = false;
                    this.activateUmbrella.next(false);
                }
            }
        }
        /**
         * Dispact updated products object to setProductAction
         */
        this.store.dispatch(new SetProductsAction(this.products));

        /**
         * Enable next button
         */
        this.showContinue =
            findIndex(this.products, { selected: true, displayProduct: true }) >
            -1;

        /**
         * Track Selection and Deselection of Products.
         *
         * The `Action` is made into Select/Deselect based on the click.
         *
         * Know more about Anatomy of Event in Google Analytics with the below link:
         * @link https://support.google.com/analytics/answer/1033068#Anatomy
         */
        GAEvent.CapturEvent(
            this._analytics,
            productObj.selected
                ? GAEvent.ACTION.SELECT
                : GAEvent.ACTION.DESELECT,
            GAEvent.CATEGORY.ProductSelection,
            productObj.name === 'Cyber ERM'
                ? 'Cyber Insurance'
                : productObj.name
        );
        return;
    }

    buildProductsRequest() {
        const productsRequest = new ProductsRequest();
        const quoteStep = this.quoteStep;
        productsRequest.digitalClassCode = quoteStep.business
            ? quoteStep.business.value.digitalClassCode
            : '';
        productsRequest.stateId = quoteStep.zipcode
            ? quoteStep.zipcode.value[0].StateProvince
            : '';

        if (this._partnerCustomization.appVersion === 1) {
            productsRequest.annualRevenue = quoteStep.revenue
                ? quoteStep.revenue.value
                : '';
        }

        productsRequest.isSciExcluded = this._partnerCustomization.isSciExcluded;

        productsRequest.wsgClassId = quoteStep.business
            ? quoteStep.business.value.wsgClassId
            : '';
        return productsRequest;
    }

    validUmbrellaSelection() {
        let umbrellaSelected = false;

        // check  if Umbrella is selected
        this.products.forEach((product) => {
            if (product.productId === 'UMBRELLA' && product.selected) {
                umbrellaSelected = true;
            }
        });

        // check if BOP / GL selected only if Umbrella is selected
        if (umbrellaSelected) {
            this.products.forEach((product) => {
                if (
                    product.selected &&
                    (product.productId === 'MICROBOP' ||
                        product.productId === 'MICROGL')
                ) {
                    this.convertMircoSelectionToSCI(product.productId);
                }
            });
        }
    }

    convertMircoSelectionToSCI(originalId) {
        const revisedProductId = originalId.split('MICRO')[1];
        this.products.forEach((product) => {
            if (product.productId === originalId) {
                product.selected = false;
            }
            if (product.productId === revisedProductId) {
                product.selected = true;
            }
        });
        // Dispatch products again as state has changed from Micro to SCI
        this.store.dispatch(new SetProductsAction(this.products));
    }

    continue(): void {
        this.validUmbrellaSelection();
        this.steps = this.progressBarService.getSteps();
        if (this.steps[0].name === 'Step 1') {
            this.steps[0].isTouched = true;
        }
        this.products.forEach((product) => {
            if (product.selected) {
                product.createNewQuote = false;
                product.isEligible = true;
            }
        });
        const questionsPayload = {};
        questionsPayload['classData'] = this.classData;
        questionsPayload['selectedProducts'] = this.products.filter(
            (productObj) => productObj.selected && productObj.displayProduct
        );
        const allSelectedProductsProductIds = questionsPayload[
            'selectedProducts'
        ].filter((selectedProduct) => {
            return selectedProduct;
        });

        const selectedName = [];
        questionsPayload['selectedProducts'].forEach((item) => {
            selectedName.push(item.name);
        });

        GAEvent.CapturEvent(
            this._analytics,
            GAEvent.ACTION.CHOSEN_PRODUCTS,
            GAEvent.CATEGORY.ProductSelection,
            selectedName.join(',')
        );
        questionsPayload[
            'allSelectedProductsProductIds'
        ] = convertArrayItemsToString(
            allSelectedProductsProductIds,
            'productId'
        );
        this.store.dispatch(new GetQuestionsAction(questionsPayload));
        if (this.eligibilityQuestions.length > 0) {
            const eligibilityQuestion = [];
            this.store.dispatch(new SetEligibilityAction(eligibilityQuestion));
        }
        if (this.__ccs.gotSciQuestions) {
            this.__ccs.gotSciQuestions = false;
            this.store.dispatch(new SetSciQuestionsAction([]));
        }
        if (this.__ccs.gotSciCyberQuestions) {
            this.__ccs.gotSciCyberQuestions = false;
            this.store.dispatch(new SetSciCyberQuestionsAction([]));
        }
        this._router.navigate(['/business-details']);
    }

    goBack(): void {
        this.store.dispatch(new SetProductsAction(this.products));
        this.showProducts.emit(false);
    }
}
const convertArrayItemsToString = (array, prop) => {
    let output = '';
    array.forEach((item) => {
        if (output) {
            output += ',' + item[prop];
        } else {
            output += item[prop];
        }
    });
    return output;
};
