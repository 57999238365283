import { find, isEmpty, isNil, isNull } from 'lodash-es';
// import { Router, UrlTree } from '@angular/router';
/**
 * Read eligibility questions from JSON
 */
import eligibilityQuestions from './../../../../assets/json/rating-questions-data.json';
import defaultCyberUWQForSentinelone from './../../../../assets/json/sentinelone-default-cyber-uwq.json';
import defaultCyberUWQ from './../../../../assets/json/default-cyber-uwq.json';

export const getProductTypeCode = (productId) => {
    switch (productId) {
        case 'MICROGL':
            return {
                policyTypeCode: '09',
                policySubTypeCode: '06',
            };

        case 'MICROBOP':
            return {
                policyTypeCode: '09',
                policySubTypeCode: '06',
            };

        case 'MICROCYBER':
            return {
                policyTypeCode: '09',
                policySubTypeCode: '08',
            };

        case 'MICROMPL':
            return {
                policyTypeCode: '09',
                policySubTypeCode: '09',
            };

        case 'WORKCOMP':
            return {
                policyTypeCode: '01',
                policySubTypeCode: '',
            };

        case 'CYBER-ERM':
            return {
                policyTypeCode: '08',
                policySubTypeCode: '07',
            };

        case 'CYBER-DIGITECT':
            return {
                policyTypeCode: '08',
                policySubTypeCode: '06',
            };

        case 'BOP':
            return {
                policyTypeCode: '89',
                policySubTypeCode: '10',
            };

        case 'GL':
            return {
                policyTypeCode: '89',
                policySubTypeCode: '10',
            };

        case 'UMBRELLA':
            return {
                policyTypeCode: '80',
                policySubTypeCode: '02',
            };

        default:
            return {
                policyTypeCode: '01',
                policySubTypeCode: '',
            };
    }
};

export const transformQuestions = (
    questions,
    sourceSystem,
    product,
    answeredQuestions,
    umbrellaProduct = null,
    currentRoute?,
    state?,
    partner?
) => {
    const triggerRoute =
        currentRoute !== '/business-details' &&
        currentRoute !== '/products' &&
        currentRoute !== '/retrieve-quote' &&
        currentRoute !== '/quote-list';
    const transformedQuestions = [];
    let groupFieldId;
    questions.data.forEach((productForm) => {
        if (umbrellaProduct && productForm.productId === 'UMBRELLA') {
            umbrellaProduct['quote_number'] = productForm.policyQuoteId;
            umbrellaProduct['policyTypeCode'] = productForm.policyTypeCode;
            umbrellaProduct['policySubTypeCode'] =
                productForm.policySubTypeCode;
        } else if (productForm.productId !== 'UMBRELLA') {
            if (product !== null && sourceSystem === 'SCI' && triggerRoute) {
                product.quote_number = questions.data[0].policyQuoteId;
                product.policyTypeCode = questions.data[0].policyTypeCode;
                product.policySubTypeCode = questions.data[0].policySubTypeCode;
            }
            productForm.forms.forEach((form, index) => {
                if (form.fieldSet.fields) {
                    form.fieldSet.fields.forEach((field, fieldIndex) => {
                        const transformedQuestionGroup = {};
                        transformedQuestionGroup['questions'] = [];
                        transformedQuestionGroup['partnerId'] = 0;
                        transformedQuestionGroup['order_seq_num'] = index + 1;
                        transformedQuestionGroup['type'] = 'Question Group';
                        // THIS WAS CHANGED FOR BUSINESS DETAILS
                        if (!triggerRoute) {
                            transformedQuestionGroup['group_name'] = field.name;
                            transformedQuestionGroup['sourceSystem'] = 'MICRO';
                        } else {
                            transformedQuestionGroup['group_name'] =
                                sourceSystem === 'SCI' ? form.id : field.name;
                            transformedQuestionGroup[
                                'sourceSystem'
                            ] = sourceSystem;
                        }
                        transformedQuestionGroup['id'] = groupFieldId =
                            field.id;

                        if (field.fieldTemplate.controlType !== 'group') {
                            let question = {};
                            question['rowId'] = field.id;
                            question['question_id'] = field.id;
                            if (field.fieldTemplate.controlType === 'radio') {
                                if (field.value == '1') {
                                    question['value'] = 'Yes';
                                } else if (field.value == '0') {
                                    question['value'] = 'No';
                                }
                            } else {
                                question['value'] = field.value;
                            }
                            question['fieldName'] = field.name;
                            question['order_seq_num'] = fieldIndex;
                            question['partnerId'] = 1;
                            question['groupName'] = null;
                            question['text'] = field.fieldTemplate.caption;
                            if (
                                field.fieldTemplate.agentCaption &&
                                !isNil(field.fieldTemplate.agentCaption) &&
                                !isEmpty(field.fieldTemplate.agentCaption) &&
                                partner &&
                                !isNil(partner) &&
                                !isEmpty(partner) &&
                                partner === 'CISA'
                            ) {
                                question['text'] =
                                    field.fieldTemplate.agentCaption;
                            }
                            if (
                                field.fieldTemplate.caption ===
                                'Company structure'
                            ) {
                                question['list_of_values'] = [
                                    {
                                        value: {
                                            SCI: 'INDSP',
                                            WSG: 'IN',
                                            WC: '01',
                                            CD:
                                                'UnincorporatedInformalBusiness',
                                        },
                                        label: 'Individual/Sole Proprietor',
                                    },
                                    {
                                        value: {
                                            SCI: 'PT',
                                            WSG: 'PT',
                                            WC: '02',
                                            CD:
                                                'UnincorporatedInformalBusiness',
                                        },
                                        label: 'Partnership',
                                    },
                                    {
                                        value: {
                                            SCI: 'CP',
                                            WSG: 'CP',
                                            WC: '03',
                                            CD: 'IncorporatedLegalEntity',
                                        },
                                        label: 'Corporation',
                                    },
                                    { value: 'LLC', label: 'LLC' },
                                    {
                                        value: {
                                            WSG: 'LLC',
                                            SCI: 'LC',
                                            WC: '10',
                                            CD: 'IncorporatedLegalEntity',
                                        },
                                        label: 'Limited Liability Company',
                                    },
                                    {
                                        value: {
                                            WSG: 'JV',
                                            SCI: 'JV',
                                            WC: '06',
                                            CD: 'IncorporatedLegalEntity',
                                        },
                                        label: 'Joint Venture',
                                    },
                                    {
                                        value: {
                                            WSG: 'Other',
                                            SCI: 'Other',
                                            WC: '99',
                                            CD: 'Other',
                                        },
                                        label: 'Other',
                                    },
                                    {
                                        value: {
                                            WSG: 'Public',
                                            SCI: 'GOVPUBPROP',
                                            WC: '14',
                                            CD: 'IncorporatedLegalEntity',
                                        },
                                        label:
                                            'Governmental Entity/Public Property',
                                    },
                                    {
                                        value: {
                                            WSG: 'LL',
                                            SCI: 'LY',
                                            WC: '13',
                                            CD: 'IncorporatedLegalEntity',
                                        },
                                        label: 'Limited Liability Partnership',
                                    },
                                    {
                                        value: {
                                            WSG: 'LP',
                                            SCI: 'LP',
                                            WC: '05',
                                            CD: 'IncorporatedLegalEntity',
                                        },
                                        label: 'Limited Partnership',
                                    },
                                    {
                                        value: {
                                            WSG: 'ASLURO',
                                            SCI: 'ALURO',
                                            WC: '04',
                                            CD: 'IncorporatedLegalEntity',
                                        },
                                        label:
                                            'Association, Labor Union, Religious Organization',
                                    },
                                    {
                                        value: {
                                            WSG: 'CO',
                                            SCI: 'CO',
                                            WC: '07',
                                            CD:
                                                'UnincorporatedInformalBusiness',
                                        },
                                        label: 'Common Ownership',
                                    },
                                    {
                                        value: {
                                            WSG: 'TR',
                                            SCI: 'EXECTRST',
                                            WC: '12',
                                            CD:
                                                'UnincorporatedInformalBusiness',
                                        },
                                        label: 'Executor or Trustee',
                                    },
                                    {
                                        value: {
                                            WSG: 'MS',
                                            SCI: 'MS',
                                            WC: '08',
                                            CD: 'Other',
                                        },
                                        label: 'Multiple Status',
                                    },
                                    {
                                        value: {
                                            WSG: 'TE',
                                            SCI: 'TRES',
                                            WC: '11',
                                            CD: 'IncorporatedLegalEntity',
                                        },
                                        label: 'Trust or Estate',
                                    },
                                ];
                            }
                            if (
                                field.fieldTemplate.caption ===
                                'Non-Profit Organization'
                            ) {
                                question['list_of_values'] = [
                                    {
                                        value: 'Yes',
                                        label: 'Yes',
                                    },
                                    {
                                        value: 'No',
                                        label: 'No',
                                    },
                                ];
                            }
                            if (field.id === '28') {
                                question['list_of_values'] = [
                                    {
                                        value: '0',
                                        label: 'Basic',
                                    },
                                    {
                                        value: '5',
                                        label: 'Premium',
                                    },
                                    {
                                        value: '10',
                                        label: 'PBC',
                                    },
                                ];
                            }
                            if (field.fieldTemplate.caption === 'Net Worth') {
                                question['list_of_values'] = [
                                    {
                                        value: '1',
                                        label: '$0 - $1,499,999',
                                    },
                                    {
                                        value: '2',
                                        label: '$1,500,000 - $7,499,999',
                                    },
                                    {
                                        value: '3',
                                        label: '$7,500,000 or more',
                                    },
                                ];
                            }
                            if (
                                field.fieldTemplate.caption === 'Gross Assets'
                            ) {
                                question['list_of_values'] = [
                                    {
                                        value: '1',
                                        label: '$0 - $25,000,000',
                                    },
                                    {
                                        value: '2',
                                        label: '$25,000,001 or more',
                                    },
                                ];
                            }
                            if (
                                field.fieldTemplate.caption ===
                                'Number of Protected Records'
                            ) {
                                question['list_of_values'] = [
                                    {
                                        value: '249',
                                        label: 'Under 250',
                                    },
                                    {
                                        value: '499',
                                        label: 'Under 500',
                                    },
                                    {
                                        value: '999',
                                        label: 'Under 1000',
                                    },
                                    {
                                        value: '2499',
                                        label: 'Under 2500',
                                    },
                                    {
                                        value: '4999',
                                        label: 'Under 5000',
                                    },
                                    {
                                        value: '9999',
                                        label: 'Under 10000',
                                    },
                                    {
                                        value: '24999',
                                        label: 'Under 25000',
                                    },
                                    {
                                        value: '49999',
                                        label: 'Under 50000',
                                    },
                                    {
                                        value: '99999',
                                        label: 'Under 100000',
                                    },
                                    {
                                        value: '249999',
                                        label: 'Under 250000',
                                    },
                                    {
                                        value: '499999',
                                        label: 'Under 500000',
                                    },
                                    {
                                        value: '999999',
                                        label: 'Under 1000000',
                                    },
                                    {
                                        value: '1000001',
                                        label: 'Over 1000000',
                                    },
                                ];
                            }
                            if (
                                field.fieldTemplate.caption === 'Ownership Type'
                            ) {
                                question['list_of_values'] = [
                                    {
                                        value: 'PubliclyTraded',
                                        label: 'Publicly Traded',
                                    },
                                    {
                                        value: 'PrivatelyHeld',
                                        label: 'Privately Held',
                                    },
                                    {
                                        value: 'NotForProfit',
                                        label: 'Not For Profit',
                                    },
                                    {
                                        value: 'PublicEntity',
                                        label: 'Public Entity',
                                    },
                                ];
                            }
                            if (
                                field.fieldTemplate.caption ===
                                'Description of Operations'
                            ) {
                                question['list_of_values'] = [
                                    {
                                        value: 'OM',
                                        label: 'Medical Office',
                                    },
                                    {
                                        value: 'OD',
                                        label: 'Dental Office',
                                    },
                                    {
                                        value: 'OS',
                                        label: 'Oral Surgeon',
                                    },
                                    {
                                        value: 'BSM',
                                        label: 'Billing Service - Medical',
                                    },
                                    {
                                        value: 'CONH',
                                        label: 'Consultant - Healthcare',
                                    },
                                    {
                                        value: 'HHA',
                                        label: 'Home Health Agency',
                                    },
                                    {
                                        value: 'HHHC',
                                        label: 'Hospice Home Health Care',
                                    },
                                    {
                                        value: 'TMSA',
                                        label:
                                            'Temporary Medical Staffing Agency',
                                    },
                                    {
                                        value: 'OT',
                                        label: 'Other',
                                    },
                                ];
                            }
                            if (field.id === '107') {
                                question['list_of_options'] = [
                                    {
                                        value: 'BIO',
                                        label:
                                            'Biotechnology Innovation Organization (BIO)',
                                        checked: false,
                                    },
                                    {
                                        value: 'MDMA',
                                        label:
                                            'Medical Device Manufacturers Association (MDMA)',
                                        checked: false,
                                    },
                                    {
                                        value: 'AMED',
                                        label:
                                            'Advanced Medical Technology Association (AdvaMed)',
                                        checked: false,
                                    },
                                    {
                                        value: 'BMES',
                                        label:
                                            'BioMedical Engineering Society (BMES)',
                                        checked: false,
                                    },
                                    {
                                        value: 'IMDA',
                                        label:
                                            'Independent Medical Specialty Dealers Association (IMDA)',
                                        checked: false,
                                    },
                                    {
                                        value: 'PHRMA',
                                        label:
                                            'Pharmaceutical Research and Manufacturers of America (PhRMA)',
                                        checked: false,
                                    },
                                ];
                            }
                            if (
                                field.fieldTemplate.caption ===
                                'Name of prior carrier'
                            ) {
                                question['list_of_values'] = [
                                    {
                                        name: 'New in Business',
                                        value: 'NIB',
                                        tags: [
                                            'AIG',
                                            'Amtrust',
                                            'Berkshire Hathaway/Guard',
                                            'C.N.A.',
                                            'Cincinnati',
                                            'Columbia',
                                            'Countrywide',
                                            'EMC',
                                            'Employers Ins Grp',
                                            'Foremost/Farmers',
                                            'Hanover',
                                            'Liberty Mutual',
                                            'Nationwide/Allied',
                                            'New in Business',
                                            'Old Republic',
                                            'Others',
                                            'QBE',
                                            'Republic Indemnity/Great American',
                                            'Selective',
                                            'Self-Insured',
                                            'State Auto',
                                            'The Hartford',
                                            'Travelers',
                                            'Westfield',
                                            'Zenith',
                                            'Zurich',
                                        ],
                                    },
                                    {
                                        name: 'AIG',
                                        value: 'AIG',
                                        tags: ['AIG'],
                                    },
                                    {
                                        name: 'Amtrust',
                                        value: 'AMT',
                                        tags: ['Amtrust'],
                                    },
                                    {
                                        name: 'Berkshire Hathaway/Guard',
                                        value: 'BHG',
                                        tags: ['Berkshire Hathaway/Guard'],
                                    },
                                    {
                                        name: 'C.N.A.',
                                        value: 'CAN',
                                        tags: ['C.N.A.'],
                                    },
                                    {
                                        name: 'Cincinnati',
                                        value: 'CIN',
                                        tags: ['Cincinnati'],
                                    },
                                    {
                                        name: 'Columbia',
                                        value: 'COL',
                                        tags: ['Columbia'],
                                    },
                                    {
                                        name: 'Countrywide',
                                        value: 'COU',
                                        tags: ['Countrywide'],
                                    },
                                    {
                                        name: 'EMC',
                                        value: 'EMC',
                                        tags: ['EMC'],
                                    },
                                    {
                                        name: 'Employers Ins Grp',
                                        value: 'EIG',
                                        tags: ['Employers Ins Grp'],
                                    },
                                    {
                                        name: 'Foremost/Farmers',
                                        value: 'FOF',
                                        tags: ['Foremost/Farmers'],
                                    },
                                    {
                                        name: 'Hanover',
                                        value: 'HAN',
                                        tags: ['Hanover'],
                                    },
                                    {
                                        name: 'Liberty Mutual',
                                        value: 'LIM',
                                        tags: ['Liberty Mutual'],
                                    },
                                    {
                                        name: 'Nationwide/Allied',
                                        value: 'NAA',
                                        tags: ['Nationwide/Allied'],
                                    },
                                    {
                                        name: 'Old Republic',
                                        value: 'OLD',
                                        tags: ['Old Republic'],
                                    },
                                    {
                                        name: 'QBE',
                                        value: 'QBE',
                                        tags: ['QBE'],
                                    },
                                    {
                                        name:
                                            'Republic Indemnity/Great American',
                                        value: 'RIG',
                                        tags: [
                                            'Republic Indemnity/Great American',
                                        ],
                                    },
                                    {
                                        name: 'Selective',
                                        value: 'SEL',
                                        tags: ['Selective'],
                                    },
                                    {
                                        name: 'Self-Insured',
                                        value: 'SEI',
                                        tags: ['Self-Insured'],
                                    },
                                    {
                                        name: 'State Auto',
                                        value: 'STA',
                                        tags: ['State Auto'],
                                    },
                                    {
                                        name: 'The Hartford',
                                        value: 'THH',
                                        tags: ['The Hartford'],
                                    },
                                    {
                                        name: 'Travelers',
                                        value: 'TRA',
                                        tags: ['Travelers'],
                                    },
                                    {
                                        name: 'Westfield',
                                        value: 'WES',
                                        tags: ['Westfield'],
                                    },
                                    {
                                        name: 'Zenith',
                                        value: 'ZEN',
                                        tags: ['Zenith'],
                                    },
                                    {
                                        name: 'Zurich',
                                        value: 'ZUR',
                                        tags: ['Zurich'],
                                    },
                                    {
                                        name: 'Others',
                                        value: 'OTH',
                                        tags: [
                                            'AIG',
                                            'Amtrust',
                                            'Berkshire Hathaway/Guard',
                                            'C.N.A.',
                                            'Cincinnati',
                                            'Columbia',
                                            'Countrywide',
                                            'EMC',
                                            'Employers Ins Grp',
                                            'Foremost/Farmers',
                                            'Hanover',
                                            'Liberty Mutual',
                                            'Nationwide/Allied',
                                            'New in Business',
                                            'Old Republic',
                                            'Others',
                                            'QBE',
                                            'Republic Indemnity/Great American',
                                            'Selective',
                                            'Self-Insured',
                                            'State Auto',
                                            'The Hartford',
                                            'Travelers',
                                            'Westfield',
                                            'Zenith',
                                            'Zurich',
                                        ],
                                    },
                                ];
                            }
                            if (field.fieldTemplate.caption.includes('*')) {
                                let start = field.fieldTemplate.caption.indexOf(
                                    '*'
                                );
                                let end = field.fieldTemplate.caption.lastIndexOf(
                                    '*'
                                );
                                let startOfPound = field.fieldTemplate.caption.indexOf(
                                    '#'
                                );
                                let endOfPound = field.fieldTemplate.caption.lastIndexOf(
                                    '#'
                                );
                                const str = field.fieldTemplate.caption.substring(
                                    start + 1,
                                    end
                                );
                                const str2 = field.fieldTemplate.caption.substring(
                                    0,
                                    end + 1
                                );
                                const str3 = field.fieldTemplate.caption.substring(
                                    startOfPound + 1,
                                    endOfPound
                                );
                                const listItems = str
                                    .split(',')
                                    .map((u) => u.trim());
                                const listItems2 = str3
                                    .split(',')
                                    .map((u) => u.trim());
                                question['list_of_values'] = listItems;
                                if (listItems2.length > 0 && str3 !== '') {
                                    question[
                                        'second_list_of_values'
                                    ] = listItems2;
                                }
                                question[
                                    'text'
                                ] = field.fieldTemplate.caption.substring(
                                    0,
                                    start
                                );
                                question[
                                    'endText'
                                ] = field.fieldTemplate.caption.replace(
                                    str2,
                                    ''
                                );
                                if (str3.length > 1 && str3 !== '') {
                                    question['endText'] = question[
                                        'endText'
                                    ].replace(str3, '');
                                    question['endText'] = question[
                                        'endText'
                                    ].replace(/#/g, '');
                                }
                            }
                            question['maxlength'] =
                                field.fieldTemplate.maxLength === 1
                                    ? 3
                                    : field.fieldTemplate.maxLength;
                            question['minlength'] =
                                field.fieldTemplate.minLength;
                            question['tooltip'] = field.fieldTemplate.tooltip;
                            question['parent_question_id'] =
                                field.fieldTemplate.parentQuestionId;
                            question['parent_trigger_value'] =
                                field.fieldTemplate.parentTriggerValue;
                            question['disqualifyingOperator'] =
                                field.fieldTemplate.disqualifyingOperator;
                            question['disqualifyingValue'] =
                                field.fieldTemplate.disqualifyingValue;
                            question['required'] = field.fieldTemplate.required;
                            if (sourceSystem === 'SCI' && triggerRoute) {
                                question['length'] = '100';
                            } else {
                                question['length'] =
                                    field.fieldTemplate.displayLength;
                            }
                            question['mask'] = field.fieldTemplate.formatMask;
                            question['refreshOnChange'] =
                                field.fieldTemplate.refreshOnChange;
                            question['type'] = !field.fieldTemplate.controlType
                                ? field.fieldTemplate.dataType
                                : controlTypesToDataTypes(
                                      field.fieldTemplate.controlType,
                                      field.fieldTemplate.formatMask
                                  );
                            if (field.id === '107') {
                                question['type'] = 'checkbox';
                            }
                            if (
                                !isEmpty(state) &&
                                !isNull(state) &&
                                (state === 'NY' ||
                                    (state !== 'NY' &&
                                        question['question_id'] !== '25' &&
                                        question['question_id'] !== '26' &&
                                        question['question_id'] !== '27'))
                            ) {
                                transformedQuestionGroup['questions'].push(
                                    question
                                );
                            } else if (isEmpty(state) || isNull(state)) {
                                transformedQuestionGroup['questions'].push(
                                    question
                                );
                            }
                        } else if (
                            field.fieldTemplate.controlType === 'group'
                        ) {
                            let groupId = field.id;
                            let groupAssociatedToFieldId = find(
                                form.fieldSet.groups,
                                (searchGroup) => {
                                    return searchGroup.id === groupId;
                                }
                            );
                            groupAssociatedToFieldId.rows.forEach(
                                (row, rowIndex) => {
                                    if (
                                        row.fieldSet.fields !== null &&
                                        row.fieldSet.fields.length > 0
                                    ) {
                                        row.fieldSet.fields.forEach(
                                            (field, fieldIndex) => {
                                                let question = {};
                                                question['rowId'] = row.id;
                                                question['question_id'] =
                                                    field.id;
                                                if (
                                                    field.fieldTemplate
                                                        .controlType === 'radio'
                                                ) {
                                                    if (field.value == '1') {
                                                        question['value'] =
                                                            'Yes';
                                                    } else if (
                                                        field.value == '0'
                                                    ) {
                                                        question['value'] =
                                                            'No';
                                                    }
                                                } else {
                                                    question['value'] =
                                                        field.value;
                                                }
                                                question['fieldName'] =
                                                    field.name;
                                                question[
                                                    'order_seq_num'
                                                ] = fieldIndex;
                                                question['partnerId'] = 1;
                                                question['groupName'] = null;
                                                question['text'] =
                                                    field.fieldTemplate.caption;
                                                if (
                                                    field.fieldTemplate
                                                        .agentCaption &&
                                                    !isNil(
                                                        field.fieldTemplate
                                                            .agentCaption
                                                    ) &&
                                                    !isEmpty(
                                                        field.fieldTemplate
                                                            .agentCaption
                                                    ) &&
                                                    partner &&
                                                    !isNil(partner) &&
                                                    !isEmpty(partner) &&
                                                    partner === 'CISA'
                                                ) {
                                                    question['text'] =
                                                        field.fieldTemplate.agentCaption;
                                                }
                                                if (
                                                    field.fieldTemplate
                                                        .caption ===
                                                    'Company structure'
                                                ) {
                                                    question[
                                                        'list_of_values'
                                                    ] = [
                                                        {
                                                            value: {
                                                                SCI: 'INDSP',
                                                                WSG: 'IN',
                                                                WC: '01',
                                                                CD:
                                                                    'UnincorporatedInformalBusiness',
                                                            },
                                                            label:
                                                                'Individual/Sole Proprietor',
                                                        },
                                                        {
                                                            value: {
                                                                SCI: 'PT',
                                                                WSG: 'PT',
                                                                WC: '02',
                                                                CD:
                                                                    'UnincorporatedInformalBusiness',
                                                            },
                                                            label:
                                                                'Partnership',
                                                        },
                                                        {
                                                            value: {
                                                                SCI: 'CP',
                                                                WSG: 'CP',
                                                                WC: '03',
                                                                CD:
                                                                    'IncorporatedLegalEntity',
                                                            },
                                                            label:
                                                                'Corporation',
                                                        },
                                                        {
                                                            value: {
                                                                WSG: 'LLC',
                                                                SCI: 'LC',
                                                                WC: '10',
                                                                CD:
                                                                    'IncorporatedLegalEntity',
                                                            },
                                                            label:
                                                                'Limited Liability Company',
                                                        },
                                                        {
                                                            value: {
                                                                WSG: 'JV',
                                                                SCI: 'JV',
                                                                WC: '06',
                                                                CD:
                                                                    'IncorporatedLegalEntity',
                                                            },
                                                            label:
                                                                'Joint Venture',
                                                        },
                                                        {
                                                            value: {
                                                                WSG: 'Other',
                                                                SCI: 'Other',
                                                                WC: '99',
                                                                CD: 'Other',
                                                            },
                                                            label: 'Other',
                                                        },
                                                        {
                                                            value: {
                                                                WSG: 'Public',
                                                                SCI:
                                                                    'GOVPUBPROP',
                                                                WC: '14',
                                                                CD:
                                                                    'IncorporatedLegalEntity',
                                                            },
                                                            label:
                                                                'Governmental Entity/Public Property',
                                                        },
                                                        {
                                                            value: {
                                                                WSG: 'LL',
                                                                SCI: 'LY',
                                                                WC: '13',
                                                                CD:
                                                                    'IncorporatedLegalEntity',
                                                            },
                                                            label:
                                                                'Limited Liability Partnership',
                                                        },
                                                        {
                                                            value: {
                                                                WSG: 'LP',
                                                                SCI: 'LP',
                                                                WC: '05',
                                                                CD:
                                                                    'IncorporatedLegalEntity',
                                                            },
                                                            label:
                                                                'Limited Partnership',
                                                        },
                                                        {
                                                            value: {
                                                                WSG: 'ASLURO',
                                                                SCI: 'ALURO',
                                                                WC: '04',
                                                                CD:
                                                                    'IncorporatedLegalEntity',
                                                            },
                                                            label:
                                                                'Association, Labor Union, Religious Organization',
                                                        },
                                                        {
                                                            value: {
                                                                WSG: 'CO',
                                                                SCI: 'CO',
                                                                WC: '07',
                                                                CD:
                                                                    'UnincorporatedInformalBusiness',
                                                            },
                                                            label:
                                                                'Common Ownership',
                                                        },
                                                        {
                                                            value: {
                                                                WSG: 'TR',
                                                                SCI: 'EXECTRST',
                                                                WC: '12',
                                                                CD:
                                                                    'UnincorporatedInformalBusiness',
                                                            },
                                                            label:
                                                                'Executor or Trustee',
                                                        },
                                                        {
                                                            value: {
                                                                WSG: 'MS',
                                                                SCI: 'MS',
                                                                WC: '08',
                                                                CD: 'Other',
                                                            },
                                                            label:
                                                                'Multiple Status',
                                                        },
                                                        {
                                                            value: {
                                                                WSG: 'TE',
                                                                SCI: 'TRES',
                                                                WC: '11',
                                                                CD:
                                                                    'IncorporatedLegalEntity',
                                                            },
                                                            label:
                                                                'Trust or Estate',
                                                        },
                                                    ];
                                                }
                                                if (
                                                    field.fieldTemplate
                                                        .caption ===
                                                    'Non-Profit Organization'
                                                ) {
                                                    question[
                                                        'list_of_values'
                                                    ] = [
                                                        {
                                                            value: 'Yes',
                                                            label: 'Yes',
                                                        },
                                                        {
                                                            value: 'No',
                                                            label: 'No',
                                                        },
                                                    ];
                                                }
                                                if (field.id === '28') {
                                                    question[
                                                        'list_of_values'
                                                    ] = [
                                                        {
                                                            value: '0',
                                                            label: 'Basic',
                                                        },
                                                        {
                                                            value: '5',
                                                            label: 'Premium',
                                                        },
                                                        {
                                                            value: '10',
                                                            label: 'PBC',
                                                        },
                                                    ];
                                                }

                                                if (
                                                    field.fieldTemplate
                                                        .caption === 'Net Worth'
                                                ) {
                                                    question[
                                                        'list_of_values'
                                                    ] = [
                                                        {
                                                            value: '1',
                                                            label:
                                                                '$0 - $1,499,999',
                                                        },
                                                        {
                                                            value: '2',
                                                            label:
                                                                '$1,500,000 - $7,499,999',
                                                        },
                                                        {
                                                            value: '3',
                                                            label:
                                                                '$7,500,000 or more',
                                                        },
                                                    ];
                                                }
                                                if (
                                                    field.fieldTemplate
                                                        .caption ===
                                                    'Gross Assets'
                                                ) {
                                                    question[
                                                        'list_of_values'
                                                    ] = [
                                                        {
                                                            value: '1',
                                                            label:
                                                                '$0 - $25,000,000',
                                                        },
                                                        {
                                                            value: '2',
                                                            label:
                                                                '$25,000,001 or more',
                                                        },
                                                    ];
                                                }
                                                if (
                                                    field.fieldTemplate
                                                        .caption ===
                                                    'Number of Protected Records'
                                                ) {
                                                    question[
                                                        'list_of_values'
                                                    ] = [
                                                        {
                                                            value: '249',
                                                            label: 'Under 250',
                                                        },
                                                        {
                                                            value: '499',
                                                            label: 'Under 500',
                                                        },
                                                        {
                                                            value: '999',
                                                            label: 'Under 1000',
                                                        },
                                                        {
                                                            value: '2499',
                                                            label: 'Under 2500',
                                                        },
                                                        {
                                                            value: '4999',
                                                            label: 'Under 5000',
                                                        },
                                                        {
                                                            value: '9999',
                                                            label:
                                                                'Under 10000',
                                                        },
                                                        {
                                                            value: '24999',
                                                            label:
                                                                'Under 25000',
                                                        },
                                                        {
                                                            value: '49999',
                                                            label:
                                                                'Under 50000',
                                                        },
                                                        {
                                                            value: '99999',
                                                            label:
                                                                'Under 100000',
                                                        },
                                                        {
                                                            value: '249999',
                                                            label:
                                                                'Under 250000',
                                                        },
                                                        {
                                                            value: '499999',
                                                            label:
                                                                'Under 500000',
                                                        },
                                                        {
                                                            value: '999999',
                                                            label:
                                                                'Under 1000000',
                                                        },
                                                        {
                                                            value: '1000001',
                                                            label:
                                                                'Over 1000000',
                                                        },
                                                    ];
                                                }
                                                if (
                                                    field.fieldTemplate
                                                        .caption ===
                                                    'Ownership Type'
                                                ) {
                                                    question[
                                                        'list_of_values'
                                                    ] = [
                                                        {
                                                            value:
                                                                'PubliclyTraded',
                                                            label:
                                                                'Publicly Traded',
                                                        },
                                                        {
                                                            value:
                                                                'PrivatelyHeld',
                                                            label:
                                                                'Privately Held',
                                                        },
                                                        {
                                                            value:
                                                                'NotForProfit',
                                                            label:
                                                                'Not For Profit',
                                                        },
                                                        {
                                                            value:
                                                                'PublicEntity',
                                                            label:
                                                                'Public Entity',
                                                        },
                                                    ];
                                                }
                                                if (
                                                    field.fieldTemplate
                                                        .caption ===
                                                    'Description of Operations'
                                                ) {
                                                    question[
                                                        'list_of_values'
                                                    ] = [
                                                        {
                                                            value: 'OM',
                                                            label:
                                                                'Medical Office',
                                                        },
                                                        {
                                                            value: 'OD',
                                                            label:
                                                                'Dental Office',
                                                        },
                                                        {
                                                            value: 'OS',
                                                            label:
                                                                'Oral Surgeon',
                                                        },
                                                        {
                                                            value: 'BSM',
                                                            label:
                                                                'Billing Service - Medical',
                                                        },
                                                        {
                                                            value: 'CONH',
                                                            label:
                                                                'Consultant - Healthcare',
                                                        },
                                                        {
                                                            value: 'HHA',
                                                            label:
                                                                'Home Health Agency',
                                                        },
                                                        {
                                                            value: 'HHHC',
                                                            label:
                                                                'Hospice Home Health Care',
                                                        },
                                                        {
                                                            value: 'TMSA',
                                                            label:
                                                                'Temporary Medical Staffing Agency',
                                                        },
                                                        {
                                                            value: 'OT',
                                                            label: 'Other',
                                                        },
                                                    ];
                                                }
                                                if (field.id === '107') {
                                                    question[
                                                        'list_of_options'
                                                    ] = [
                                                        {
                                                            value: 'BIO',
                                                            label:
                                                                'Biotechnology Innovation Organization (BIO)',
                                                            checked: false,
                                                        },
                                                        {
                                                            value: 'MDMA',
                                                            label:
                                                                'Medical Device Manufacturers Association (MDMA)',
                                                            checked: false,
                                                        },
                                                        {
                                                            value: 'AMED',
                                                            label:
                                                                'Advanced Medical Technology Association (AdvaMed)',
                                                            checked: false,
                                                        },
                                                        {
                                                            value: 'BMES',
                                                            label:
                                                                'BioMedical Engineering Society (BMES)',
                                                            checked: false,
                                                        },
                                                        {
                                                            value: 'IMDA',
                                                            label:
                                                                'Independent Medical Specialty Dealers Association (IMDA)',
                                                            checked: false,
                                                        },
                                                        {
                                                            value: 'PHRMA',
                                                            label:
                                                                'Pharmaceutical Research and Manufacturers of America (PhRMA)',
                                                            checked: false,
                                                        },
                                                    ];
                                                }
                                                if (
                                                    field.fieldTemplate
                                                        .caption ===
                                                    'Name of prior carrier'
                                                ) {
                                                    question[
                                                        'list_of_values'
                                                    ] = [
                                                        {
                                                            name:
                                                                'New in Business',
                                                            value: 'NIB',
                                                            tags: [
                                                                'AIG',
                                                                'Amtrust',
                                                                'Berkshire Hathaway/Guard',
                                                                'C.N.A.',
                                                                'Cincinnati',
                                                                'Columbia',
                                                                'Countrywide',
                                                                'EMC',
                                                                'Employers Ins Grp',
                                                                'Foremost/Farmers',
                                                                'Hanover',
                                                                'Liberty Mutual',
                                                                'Nationwide/Allied',
                                                                'New in Business',
                                                                'Old Republic',
                                                                'Others',
                                                                'QBE',
                                                                'Republic Indemnity/Great American',
                                                                'Selective',
                                                                'Self-Insured',
                                                                'State Auto',
                                                                'The Hartford',
                                                                'Travelers',
                                                                'Westfield',
                                                                'Zenith',
                                                                'Zurich',
                                                            ],
                                                        },
                                                        {
                                                            name: 'AIG',
                                                            value: 'AIG',
                                                            tags: ['AIG'],
                                                        },
                                                        {
                                                            name: 'Amtrust',
                                                            value: 'AMT',
                                                            tags: ['Amtrust'],
                                                        },
                                                        {
                                                            name:
                                                                'Berkshire Hathaway/Guard',
                                                            value: 'BHG',
                                                            tags: [
                                                                'Berkshire Hathaway/Guard',
                                                            ],
                                                        },
                                                        {
                                                            name: 'C.N.A.',
                                                            value: 'CAN',
                                                            tags: ['C.N.A.'],
                                                        },
                                                        {
                                                            name: 'Cincinnati',
                                                            value: 'CIN',
                                                            tags: [
                                                                'Cincinnati',
                                                            ],
                                                        },
                                                        {
                                                            name: 'Columbia',
                                                            value: 'COL',
                                                            tags: ['Columbia'],
                                                        },
                                                        {
                                                            name: 'Countrywide',
                                                            value: 'COU',
                                                            tags: [
                                                                'Countrywide',
                                                            ],
                                                        },
                                                        {
                                                            name: 'EMC',
                                                            value: 'EMC',
                                                            tags: ['EMC'],
                                                        },
                                                        {
                                                            name:
                                                                'Employers Ins Grp',
                                                            value: 'EIG',
                                                            tags: [
                                                                'Employers Ins Grp',
                                                            ],
                                                        },
                                                        {
                                                            name:
                                                                'Foremost/Farmers',
                                                            value: 'FOF',
                                                            tags: [
                                                                'Foremost/Farmers',
                                                            ],
                                                        },
                                                        {
                                                            name: 'Hanover',
                                                            value: 'HAN',
                                                            tags: ['Hanover'],
                                                        },
                                                        {
                                                            name:
                                                                'Liberty Mutual',
                                                            value: 'LIM',
                                                            tags: [
                                                                'Liberty Mutual',
                                                            ],
                                                        },
                                                        {
                                                            name:
                                                                'Nationwide/Allied',
                                                            value: 'NAA',
                                                            tags: [
                                                                'Nationwide/Allied',
                                                            ],
                                                        },
                                                        {
                                                            name:
                                                                'Old Republic',
                                                            value: 'OLD',
                                                            tags: [
                                                                'Old Republic',
                                                            ],
                                                        },
                                                        {
                                                            name: 'QBE',
                                                            value: 'QBE',
                                                            tags: ['QBE'],
                                                        },
                                                        {
                                                            name:
                                                                'Republic Indemnity/Great American',
                                                            value: 'RIG',
                                                            tags: [
                                                                'Republic Indemnity/Great American',
                                                            ],
                                                        },
                                                        {
                                                            name: 'Selective',
                                                            value: 'SEL',
                                                            tags: ['Selective'],
                                                        },
                                                        {
                                                            name:
                                                                'Self-Insured',
                                                            value: 'SEI',
                                                            tags: [
                                                                'Self-Insured',
                                                            ],
                                                        },
                                                        {
                                                            name: 'State Auto',
                                                            value: 'STA',
                                                            tags: [
                                                                'State Auto',
                                                            ],
                                                        },
                                                        {
                                                            name:
                                                                'The Hartford',
                                                            value: 'THH',
                                                            tags: [
                                                                'The Hartford',
                                                            ],
                                                        },
                                                        {
                                                            name: 'Travelers',
                                                            value: 'TRA',
                                                            tags: ['Travelers'],
                                                        },
                                                        {
                                                            name: 'Westfield',
                                                            value: 'WES',
                                                            tags: ['Westfield'],
                                                        },
                                                        {
                                                            name: 'Zenith',
                                                            value: 'ZEN',
                                                            tags: ['Zenith'],
                                                        },
                                                        {
                                                            name: 'Zurich',
                                                            value: 'ZUR',
                                                            tags: ['Zurich'],
                                                        },
                                                        {
                                                            name: 'Others',
                                                            value: 'OTH',
                                                            tags: [
                                                                'AIG',
                                                                'Amtrust',
                                                                'Berkshire Hathaway/Guard',
                                                                'C.N.A.',
                                                                'Cincinnati',
                                                                'Columbia',
                                                                'Countrywide',
                                                                'EMC',
                                                                'Employers Ins Grp',
                                                                'Foremost/Farmers',
                                                                'Hanover',
                                                                'Liberty Mutual',
                                                                'Nationwide/Allied',
                                                                'New in Business',
                                                                'Old Republic',
                                                                'Others',
                                                                'QBE',
                                                                'Republic Indemnity/Great American',
                                                                'Selective',
                                                                'Self-Insured',
                                                                'State Auto',
                                                                'The Hartford',
                                                                'Travelers',
                                                                'Westfield',
                                                                'Zenith',
                                                                'Zurich',
                                                            ],
                                                        },
                                                    ];
                                                }
                                                if (
                                                    field.fieldTemplate.caption.includes(
                                                        '*'
                                                    )
                                                ) {
                                                    let start = field.fieldTemplate.caption.indexOf(
                                                        '*'
                                                    );
                                                    let end = field.fieldTemplate.caption.lastIndexOf(
                                                        '*'
                                                    );
                                                    let startOfPound = field.fieldTemplate.caption.indexOf(
                                                        '#'
                                                    );
                                                    let endOfPound = field.fieldTemplate.caption.lastIndexOf(
                                                        '#'
                                                    );
                                                    const str = field.fieldTemplate.caption.substring(
                                                        start + 1,
                                                        end
                                                    );
                                                    const str2 = field.fieldTemplate.caption.substring(
                                                        0,
                                                        end + 1
                                                    );
                                                    const str3 = field.fieldTemplate.caption.substring(
                                                        startOfPound + 1,
                                                        endOfPound
                                                    );
                                                    const listItems = str
                                                        .split(',')
                                                        .map((u) => u.trim());
                                                    const listItems2 = str3
                                                        .split(',')
                                                        .map((u) => u.trim());
                                                    question[
                                                        'list_of_values'
                                                    ] = listItems;
                                                    if (
                                                        listItems2.length > 0 &&
                                                        str3 !== ''
                                                    ) {
                                                        question[
                                                            'second_list_of_values'
                                                        ] = listItems2;
                                                    }
                                                    question[
                                                        'text'
                                                    ] = field.fieldTemplate.caption.substring(
                                                        0,
                                                        start
                                                    );
                                                    question[
                                                        'endText'
                                                    ] = field.fieldTemplate.caption.replace(
                                                        str2,
                                                        ''
                                                    );
                                                    if (
                                                        str3.length > 1 &&
                                                        str3 !== ''
                                                    ) {
                                                        question[
                                                            'endText'
                                                        ] = question[
                                                            'endText'
                                                        ].replace(str3, '');
                                                        question[
                                                            'endText'
                                                        ] = question[
                                                            'endText'
                                                        ].replace(/#/g, '');
                                                    }
                                                }
                                                question['maxlength'] =
                                                    field.fieldTemplate
                                                        .maxLength === 1
                                                        ? 3
                                                        : field.fieldTemplate
                                                              .maxLength;
                                                question['minlength'] =
                                                    field.fieldTemplate.minLength;
                                                question['tooltip'] =
                                                    field.fieldTemplate.tooltip;
                                                question['parent_question_id'] =
                                                    field.fieldTemplate.parentQuestionId;
                                                question[
                                                    'parent_trigger_value'
                                                ] =
                                                    field.fieldTemplate.parentTriggerValue;
                                                question[
                                                    'disqualifyingOperator'
                                                ] =
                                                    field.fieldTemplate.disqualifyingOperator;
                                                question['disqualifyingValue'] =
                                                    field.fieldTemplate.disqualifyingValue;
                                                question['required'] =
                                                    field.fieldTemplate.required;
                                                if (
                                                    sourceSystem === 'SCI' &&
                                                    triggerRoute
                                                ) {
                                                    question['length'] = '100';
                                                } else {
                                                    question['length'] =
                                                        field.fieldTemplate.displayLength;
                                                }
                                                question[
                                                    'mask'
                                                ] = getFormatMask(
                                                    field.fieldTemplate
                                                        .formatMask
                                                );
                                                question['refreshOnChange'] =
                                                    field.fieldTemplate.refreshOnChange;
                                                question['type'] = !field
                                                    .fieldTemplate.controlType
                                                    ? field.fieldTemplate
                                                          .dataType
                                                    : controlTypesToDataTypes(
                                                          field.fieldTemplate
                                                              .controlType,
                                                          field.fieldTemplate
                                                              .formatMask
                                                      );
                                                if (field.id === '107') {
                                                    question['type'] =
                                                        'checkbox';
                                                }
                                                if (
                                                    question['type'] ===
                                                    'select'
                                                ) {
                                                    question[
                                                        'list_of_values'
                                                    ] = [
                                                        ...convertToDropDownValues(
                                                            field.fieldTemplate
                                                                .options
                                                        ),
                                                    ];
                                                }
                                                if (
                                                    !isEmpty(state) &&
                                                    !isNull(state) &&
                                                    (state === 'NY' ||
                                                        (state !== 'NY' &&
                                                            question[
                                                                'question_id'
                                                            ] !== '25' &&
                                                            question[
                                                                'question_id'
                                                            ] !== '26' &&
                                                            question[
                                                                'question_id'
                                                            ] !== '27'))
                                                ) {
                                                    transformedQuestionGroup[
                                                        'questions'
                                                    ].push(question);
                                                } else if (
                                                    isEmpty(state) ||
                                                    isNull(state)
                                                ) {
                                                    transformedQuestionGroup[
                                                        'questions'
                                                    ].push(question);
                                                }
                                            }
                                        );
                                    }
                                }
                            );
                        }
                        transformedQuestions.push(transformedQuestionGroup);
                        if (
                            isNil(partner) ||
                            isEmpty(partner) ||
                            (partner &&
                                !isNil(partner) &&
                                !isEmpty(partner) &&
                                partner !== 'RSA10')
                        ) {
                            transformedQuestionGroup['questions'].forEach(
                                (que, index, obj) => {
                                    if (
                                        que.question_id &&
                                        que.question_id === '28'
                                    ) {
                                        obj.splice(index, 1);
                                    }
                                }
                            );
                        }
                    });
                }
            });
        }
    });
    if (questions.data[1]) {
        umbrellaProduct.quote_number = questions.data[1].policyQuoteId;
    }

    if (
        product &&
        product.payload &&
        product.payload.selectedProducts &&
        (currentRoute === '/business-details' || currentRoute === '/products')
    ) {
        arrangeQuestionGroupProductWise(
            product.payload.selectedProducts,
            transformedQuestions
        );
    }
    return transformedQuestions;
};

export const SCIUWtransformQuestions = (uwquestions, productSelected) => {
    const transformedQuestions = [];
    let childWithoutParent = [];
    let childWithNoParentValue = [];
    if (
        uwquestions &&
        uwquestions.data &&
        uwquestions.data.questions &&
        uwquestions.data.questions.length > 0
    ) {
        const transformedQuestionGroup = {};
        transformedQuestionGroup['questions'] = [];
        transformedQuestionGroup['partnerId'] = 0;
        transformedQuestionGroup['order_seq_num'] = 1;
        transformedQuestionGroup['type'] = 'Question Group';
        transformedQuestionGroup['group_name'] = 'GLUnderwritingQuestions';
        transformedQuestionGroup['sourceSystem'] = 'SCI';
        transformedQuestionGroup['id'] = uwquestions.requestId;
        uwquestions.data.questions.forEach((field, fieldIndex) => {
            const question = {};
            question['rowId'] = uwquestions.requestId;
            question['question_id'] = field.ruleCode;
            question['type'] = field.type === 'radio' ? '1' : '0';
            if (field.type.toLowerCase() === 'text') {
                question['type'] = field.type;
            }
            question['fieldName'] = field.name;
            question['order_seq_num'] = fieldIndex;
            question['partnerId'] = 1;
            question['groupName'] = null;
            question['text'] = field.questionText;
            question['maxlength'] = 3;
            question['minlength'] = 1;
            question['tooltip'] = null;
            question['parent_question_id'] = null;
            question['parent_trigger_value'] = null;
            question['disqualifyingOperator'] = null;
            question['disqualifyingValue'] = null;
            question['required'] = true;
            question['length'] = '100';
            question['mask'] = 'none';
            question['refreshOnChange'] = false;
            question['captionCode'] = field.captionCode;
            transformedQuestionGroup['questions'].push(question);
        });
        transformedQuestions.push(transformedQuestionGroup);
    } else if (
        uwquestions.underwritingQuestionsResponse &&
        uwquestions.underwritingQuestionsResponse.underwritingQuestionsData &&
        uwquestions.underwritingQuestionsResponse.underwritingQuestionsData
            .underwritingQuestionsLookup &&
        uwquestions.underwritingQuestionsResponse.underwritingQuestionsData
            .underwritingQuestionsLookup.length > 0
    ) {
        const transformedQuestionGroup = {};
        transformedQuestionGroup['questions'] = [];
        transformedQuestionGroup['partnerId'] = 0;
        transformedQuestionGroup['order_seq_num'] = 1;
        transformedQuestionGroup['type'] = 'Question Group';
        transformedQuestionGroup['group_name'] = 'GLUnderwritingQuestions';
        transformedQuestionGroup['sourceSystem'] = 'SCI';
        transformedQuestionGroup['id'] = uwquestions.requestId;
        let classLevelUWQ = [];
        let buildingLevelUWQ = [];
        let uwqToBeSetAsChild = [
            'DG1098',
            'DG542_1',
            'DG656',
            'DG765',
            'DO1023',
            'DP10',
            'DG185',
            'DG544',
            'DG63',
            'DG655',
            'DG76',
            'RG171',
            'RG173',
            'DG1101',
            'DG1102',
            'DG1103',
            'DG1104',
        ];
        let uwqToBeSetAsParentExists = false;
        uwquestions.underwritingQuestionsResponse.underwritingQuestionsData.underwritingQuestionsLookup.forEach(
            (field, fieldIndex) => {
                if (
                    field.questionDescription &&
                    !isNil(field.questionDescription) &&
                    !isEmpty(field.questionDescription)
                ) {
                    if (
                        field.ruleCode &&
                        !isNil(field.ruleCode) &&
                        !isEmpty(field.ruleCode) &&
                        field.ruleCode === 'NA1142'
                    ) {
                        uwqToBeSetAsParentExists = true;
                    }
                    const question = {};
                    question['rowId'] = uwquestions.requestId;
                    question['question_id'] = field.ruleCode;
                    question['mask'] = 'none';

                    if (
                        field.answerType === 'YesNo,CaptionOptionSet' ||
                        field.answerType === 'Header,YesNo' ||
                        field.answerType === 'YesNo'
                    ) {
                        question['type'] = '1';
                    } else {
                        question['type'] = '0';
                    }

                    if (
                        field.answerType.toLowerCase() === 'text' ||
                        field.answerType.toLowerCase() === 'numberpercent' ||
                        field.answerType.toLowerCase() === 'numberpremium' ||
                        field.answerType.toLowerCase() === 'number' ||
                        field.answerType.toLowerCase() === 'label' ||
                        field.answerType.toLowerCase() === 'time'
                    ) {
                        question['type'] = 'text';
                        if (
                            field.answerType.toLowerCase() ===
                                'numberpercent' ||
                            field.answerType.toLowerCase() ===
                                'numberpremium' ||
                            field.answerType.toLowerCase() === 'number'
                        ) {
                            question['mask'] = 'number';
                        }
                    } else if (
                        field.answerType.toLowerCase() === 'optionlist' ||
                        field.answerType.toLowerCase() === 'captionoptionset' ||
                        field.answerType.toLowerCase() === 'dropdown'
                    ) {
                        question['type'] = 'select';
                        question['list_of_values'] = [];
                        if (
                            field.applicableValues &&
                            field.applicableValues.length &&
                            field.applicableValues.length > 0
                        ) {
                            field.applicableValues.forEach((script) => {
                                const valueObj = {};
                                valueObj['value'] = script.code;
                                valueObj['label'] = script.text;
                                question['list_of_values'].push(valueObj);
                            });
                        }
                    } else if (
                        field.answerType === 'MultiSelect' ||
                        field.answerType === 'Checkbox'
                    ) {
                        question['type'] = 'checkbox';
                        question['list_of_options'] = [];
                        if (
                            field.applicableValues &&
                            field.applicableValues.length &&
                            field.applicableValues.length > 0
                        ) {
                            field.applicableValues.forEach((script) => {
                                const valueObj = {};
                                // let lable = script.code.split('_');
                                valueObj['value'] = script.code;
                                //valueObj['label'] = lable[1];
                                valueObj['label'] = script.text;
                                valueObj['checked'] = false;
                                question['list_of_options'].push(valueObj);
                            });
                        }
                    }
                    question['fieldName'] = null;
                    question['order_seq_num'] = fieldIndex;
                    question['partnerId'] = 1;
                    question['groupName'] = null;
                    question['text'] = field.questionDescription;
                    question['questionLevel'] = field.questionLevel;
                    question['maxlength'] = 300;
                    question['minlength'] = 1;
                    question['tooltip'] = null;
                    question['parent_question_id'] =
                        !isNil(field.parentCode) && !isEmpty(field.parentCode)
                            ? field.parentCode
                            : null;
                    if (
                        !isNil(field.parentAnswer) &&
                        !isEmpty(field.parentAnswer)
                    ) {
                        if (field.parentAnswer === '1') {
                            question['parent_trigger_value'] = 'Yes';
                        } else if (field.parentAnswer === '0') {
                            question['parent_trigger_value'] = 'No';
                        } else {
                            question['parent_trigger_value'] =
                                field.parentAnswer;
                        }
                    } else {
                        question['parent_trigger_value'] = null;
                    }
                    question['disqualifyingOperator'] = null;
                    question['disqualifyingValue'] = null;
                    question['required'] = true;
                    question['length'] = '100';
                    question['refreshOnChange'] = false;
                    question['captionCode'] = null;
                    if (field.questionLevel === 'ClassCode') {
                        if (
                            question['type'] === 'checkbox' &&
                            question['list_of_options'] &&
                            question['list_of_options'].length === 0
                        ) {
                            return;
                        } else {
                            classLevelUWQ.push(question);
                        }
                    }
                    // else if (
                    //     productSelected === 'BOP' &&
                    //     field.questionLevel === 'Building'
                    // ) {
                    //     buildingLevelUWQ.push(question);
                    // }
                    transformedQuestionGroup['questions'] = classLevelUWQ;
                }
            }
        );
        // if (buildingLevelUWQ && buildingLevelUWQ.length > 0) {
        //     buildingLevelUWQ.forEach((uwq) => {
        //         transformedQuestionGroup['questions'].push(uwq);
        //     });
        // }
        if (classLevelUWQ && classLevelUWQ.length > 0) {
            transformedQuestions.push(transformedQuestionGroup);
        }
        if (
            transformedQuestions &&
            transformedQuestions[0] &&
            transformedQuestions[0]['questions'] &&
            transformedQuestions[0]['questions'].length &&
            transformedQuestions[0]['questions'].length > 0
        ) {
            transformedQuestions[0]['questions'].forEach((que) => {
                if (
                    que.parent_question_id &&
                    !isNil(que.parent_question_id) &&
                    !isEmpty(que.parent_question_id)
                ) {
                    if (
                        !find(transformedQuestions[0]['questions'], [
                            'question_id',
                            que['parent_question_id'],
                        ])
                    ) {
                        childWithoutParent.push(que.question_id);
                    }
                    if (
                        isNil(que.parent_trigger_value) ||
                        isEmpty(que.parent_trigger_value)
                    ) {
                        childWithNoParentValue.push(que.question_id);
                    }
                }
                if (
                    uwqToBeSetAsParentExists &&
                    uwqToBeSetAsChild.indexOf(que.question_id) > 0
                ) {
                    que['parent_question_id'] = 'NA1142';
                    que['parent_trigger_value'] = 'No';
                }
            });
        }
        if (childWithoutParent && childWithoutParent.length > 0) {
            transformedQuestions[0]['questions'] = transformedQuestions[0][
                'questions'
            ].filter((que) => {
                return (
                    childWithoutParent.indexOf(que.question_id) <= -1 &&
                    childWithoutParent.indexOf(que.parent_question_id) <= -1
                );
            });
        }
        if (childWithNoParentValue && childWithNoParentValue.length > 0) {
            transformedQuestions[0]['questions'] = transformedQuestions[0][
                'questions'
            ].filter((que) => {
                return childWithNoParentValue.indexOf(que.question_id) <= -1;
            });
        }
    }
    return transformedQuestions;
};

export const SCICyberUWtransformQuestions = (uwquestions, partner) => {
    const transformedQuestions = [];
    const childQuestions = [];
    const subQuestions = [];
    const subQuestionsCodes = [];
    let regex = /\d+/g;
    let questionsTobeDefaulted = [];
    if (uwquestions.length > 0) {
        const transformedQuestionGroup = {};
        transformedQuestionGroup['questions'] = [];
        transformedQuestionGroup['partnerId'] = 0;
        transformedQuestionGroup['order_seq_num'] = 2;
        transformedQuestionGroup['type'] = 'Question Group';
        transformedQuestionGroup['group_name'] = 'CyberUnderwritingQuestions';
        transformedQuestionGroup['sourceSystem'] = 'SCI';
        transformedQuestionGroup['id'] = null;
        uwquestions.forEach((field, fieldIndex) => {
            const question = {};
            let subQuestionToBeAdded = [];
            question['rowId'] = null;
            question['question_id'] = field.ruleCode;
            if (field.coverageCodes) {
                question['coverageCodes'] = field.coverageCodes;
            }
            if (field.type.toLowerCase() === 'radio') {
                question['type'] = '1';
                if (
                    field.questionText.match(regex) &&
                    field.ruleCode.includes('_') &&
                    field.ruleCode !== 'RD15_2'
                ) {
                    question['type'] = 'select';
                    question['list_of_values'] = [];
                    field.options.forEach((val) => {
                        const valueObj = {};
                        valueObj['value'] = val.value;
                        valueObj['label'] = val.caption;
                        question['list_of_values'].push(valueObj);
                    });
                }
                if (field.options.length > 2) {
                    question['type'] = 'select';
                    question['list_of_values'] = [];
                    field.options.forEach((val) => {
                        const valueObj = {};
                        valueObj['value'] = val.value;
                        valueObj['label'] = val.caption;
                        question['list_of_values'].push(valueObj);
                    });
                }
                if (field.type.toLowerCase() === 'radio') {
                    question['type'] = '1';
                    if (
                        field.questionText.match(regex) &&
                        field.ruleCode.includes('_') &&
                        field.ruleCode !== 'RD15_2'
                    ) {
                        question['type'] = 'select';
                        question['list_of_values'] = [];
                        field.options.forEach((val) => {
                            const valueObj = {};
                            valueObj['value'] = val.value;
                            valueObj['label'] = val.caption;
                            question['list_of_values'].push(valueObj);
                        });
                    }
                    if (field.options.length > 2) {
                        question['type'] = 'select';
                        question['list_of_values'] = [];
                        field.options.forEach((val) => {
                            const valueObj = {};
                            valueObj['value'] = val.value;
                            valueObj['label'] = val.caption;
                            question['list_of_values'].push(valueObj);
                        });
                    }
                } else if (field.type.toLowerCase() === 'text') {
                    question['type'] = 'text';
                    if (field.options.length > 1) {
                        question['type'] = 'select';
                        question['list_of_values'] = [];
                        field.options.forEach((val) => {
                            const valueObj = {};
                            valueObj['value'] = val.value;
                            valueObj['label'] = val.caption;
                            question['list_of_values'].push(valueObj);
                        });
                    }
                } else if (field.type.toLowerCase() === 'integer') {
                    question['type'] = 'select';
                    if (field.options.length > 1) {
                        question['list_of_values'] = [];
                        field.options.forEach((val) => {
                            const valueObj = {};
                            valueObj['value'] = val.value;
                            valueObj['label'] = val.caption;
                            question['list_of_values'].push(valueObj);
                        });
                    } else if (field.options.length === 1) {
                        question['type'] = 'text';
                    }
                }
                question['fieldName'] = null;
                question['order_seq_num'] = fieldIndex;
                question['partnerId'] = 1;
                question['groupName'] = null;
                question['text'] = field.questionText;
                question['agentText'] = field.agentText;
                question['maxlength'] = 500;
                question['minlength'] = 1;
                question['tooltip'] = null;
                question['parent_question_id'] = null;
                question['parent_trigger_value'] = null;
                question['disqualifyingOperator'] = null;
                question['disqualifyingValue'] = null;
                question['required'] = true;
                question['length'] = '100';
                question['mask'] = 'none';
                question['refreshOnChange'] = false;
                question['captionCode'] = null;
                if (
                    question['question_id'] === 'RD06' ||
                    question['question_id'] === 'RD07'
                ) {
                    let quesParagraphArray = question['text']
                        .replace('? ', '?^')
                        .split(/\s{2,}|\^/);
                    question['text'] = quesParagraphArray[0];
                    question['endText'] =
                        quesParagraphArray[quesParagraphArray.length - 1];
                    question['list_of_values'] = [];
                    quesParagraphArray.forEach((phrase) => {
                        if (
                            !phrase.includes('?') &&
                            !isEmpty(phrase) &&
                            !isNil(phrase)
                        ) {
                            question['list_of_values'].push(phrase);
                        }
                    });
                    if (
                        question['agentText'] &&
                        !isNil(question['agentText']) &&
                        !isEmpty(question['agentText']) &&
                        partner &&
                        !isNil(partner) &&
                        !isEmpty(partner) &&
                        partner === 'CISA'
                    ) {
                        let agentQuesParagraphArray = question['agentText']
                            .replace('? ', '?^')
                            .split(/\s{2,}|\^/);
                        question['agentText'] = agentQuesParagraphArray[0];
                        question['endText'] =
                            agentQuesParagraphArray[
                                agentQuesParagraphArray.length - 1
                            ];
                    }
                }
                if (field.actionsForAnswer) {
                    field.actionsForAnswer.forEach((obj) => {
                        if (obj.childQuestions.length > 0) {
                            obj.childQuestions.forEach((question) => {
                                const childQuestionObj = {};
                                childQuestionObj['parent_id'] = field.ruleCode;
                                childQuestionObj['child_id'] = question;
                                childQuestionObj['triggering_value'] =
                                    obj.answer;
                                childQuestionObj['parent_text'] =
                                    field.questionText;
                                if (
                                    childQuestions.length > 0 &&
                                    find(childQuestions, ['child_id', question])
                                ) {
                                    const existingQuestion = find(
                                        childQuestions,
                                        ['child_id', question]
                                    );
                                    const values = [];
                                    if (
                                        typeof existingQuestion[
                                            'triggering_value'
                                        ] === 'string'
                                    ) {
                                        values.push(
                                            existingQuestion['triggering_value']
                                        );
                                        values.push(
                                            childQuestionObj['triggering_value']
                                        );
                                        existingQuestion[
                                            'triggering_value'
                                        ] = values;
                                    } else if (
                                        existingQuestion['triggering_value']
                                            .length
                                    ) {
                                        existingQuestion[
                                            'triggering_value'
                                        ].push(
                                            childQuestionObj['triggering_value']
                                        );
                                    }
                                } else {
                                    childQuestions.push(childQuestionObj);
                                }
                            });
                        }
                    });
                }
                childQuestions.forEach((que) => {
                    if (
                        que.child_id === field.ruleCode &&
                        que.parent_text.match(regex) &&
                        que.child_id !== 'RD07_1' &&
                        que.child_id !== 'RD06_1'
                    ) {
                        subQuestionToBeAdded.push(que);
                    }
                });

                if (
                    (field.questionText.match(regex) &&
                        field.ruleCode.includes('_') &&
                        field.ruleCode !== 'RD15_2') ||
                    (subQuestionToBeAdded &&
                        subQuestionToBeAdded.length > 0 &&
                        subQuestionToBeAdded[0].child_id === field.ruleCode)
                ) {
                    let subQuestionObject = {};
                    let index: any;
                    index =
                        subQuestionToBeAdded.length > 0 &&
                        subQuestionToBeAdded[0].parent_text
                            ? subQuestionToBeAdded[0].parent_text.indexOf(
                                  subQuestionToBeAdded[0].parent_text.match(
                                      regex
                                  )
                              )
                            : field.questionText.indexOf(
                                  field.questionText.match(regex)
                              );
                    if (index < 0 && field.ruleCode === 'RD10_1') {
                        index =
                            subQuestionToBeAdded.length > 0 &&
                            subQuestionToBeAdded[0].parent_text
                                ? subQuestionToBeAdded[0].parent_text.indexOf(
                                      subQuestionToBeAdded[0].parent_text.match(
                                          regex
                                      )
                                  )
                                : field.questionText.indexOf(
                                      field.questionText.match(/\d/)
                                  );
                    }
                    let parentText =
                        subQuestionToBeAdded.length > 0 &&
                        subQuestionToBeAdded[0].parent_text
                            ? subQuestionToBeAdded[0].parent_text.substring(
                                  0,
                                  index
                              )
                            : field.questionText.substring(0, index);
                    let childtext =
                        subQuestionToBeAdded.length > 0 &&
                        subQuestionToBeAdded[0].parent_text
                            ? field.questionText
                            : field.questionText.substring(index);
                    question['text'] = parentText;
                    question['type'] = 'checkbox';
                    subQuestionsCodes.push(field.ruleCode);
                    question['question_id'] = 'multipleValueUWQ_' + fieldIndex;
                    subQuestionObject['text'] = childtext;
                    subQuestionObject['id'] = field.ruleCode;
                    subQuestionObject['parentText'] = parentText;
                    if (
                        question['list_of_values'] &&
                        question['list_of_values'].length > 1
                    ) {
                        subQuestionObject['options'] =
                            question['list_of_values'];
                        subQuestionObject['type'] = 'select';
                    } else if (
                        question['list_of_values'] &&
                        question['list_of_values'].length <= 1
                    ) {
                        subQuestionObject['type'] = 'text';
                        subQuestionObject['options'] = null;
                    }
                    subQuestions.push(subQuestionObject);
                    delete question['list_of_values'];
                }

                if (
                    !find(transformedQuestionGroup['questions'], [
                        'text',
                        question['text'],
                    ])
                ) {
                    transformedQuestionGroup['questions'].push(question);
                } else if (
                    find(transformedQuestionGroup['questions'], [
                        'text',
                        question['text'],
                    ]) &&
                    question['type'] !== 'checkbox'
                ) {
                    question['type'] = 'duplicate';
                    transformedQuestionGroup['questions'].push(question);
                }
            }
        });
        transformedQuestions.push(transformedQuestionGroup);
    }
    transformedQuestions[0].questions.forEach((question) => {
        childQuestions.forEach((childQuestion) => {
            if (childQuestion.child_id === question.question_id) {
                question.parent_question_id = childQuestion.parent_id;
                question.parent_trigger_value = childQuestion.triggering_value;
            }
        });
    });
    transformedQuestions[0].questions.forEach((question) => {
        question.subQuestionsCodes = [];
        question.subQuestions = [];
        let childOfSubquestion: any;
        subQuestions.forEach((childQuestion, fieldIndex) => {
            if (
                childQuestion.parentText === question.text ||
                (childOfSubquestion &&
                    childQuestion.id === childOfSubquestion.child_id)
            ) {
                const subQuestion = {};
                subQuestion['rowId'] = null;
                subQuestion['question_id'] = childQuestion.id;
                subQuestion['type'] = childQuestion.type;
                subQuestion['list_of_values'] = childQuestion.options;
                subQuestion['fieldName'] = null;
                subQuestion['order_seq_num'] = fieldIndex;
                subQuestion['partnerId'] = 1;
                subQuestion['groupName'] = null;
                subQuestion['text'] = childQuestion.text;
                subQuestion['maxlength'] = 3;
                subQuestion['minlength'] = 1;
                subQuestion['tooltip'] = null;
                subQuestion['parent_question_id'] = null;
                subQuestion['parent_trigger_value'] = null;
                subQuestion['disqualifyingOperator'] = null;
                subQuestion['disqualifyingValue'] = null;
                subQuestion['required'] = true;
                subQuestion['length'] = '100';
                subQuestion['mask'] = 'none';
                subQuestion['refreshOnChange'] = false;
                subQuestion['captionCode'] = null;
                question.subQuestionsCodes.push(childQuestion.id);
                question.subQuestions.push(subQuestion);
            }
        });
        if (
            question.agentText &&
            !isNil(question.agentText) &&
            !isEmpty(question.agentText) &&
            partner &&
            !isNil(partner) &&
            !isEmpty(partner) &&
            partner === 'CISA'
        ) {
            question['text'] = question.agentText;
        }
    });

    transformedQuestions[0].questions.forEach((question) => {
        if (question.subQuestions && question.subQuestions.length > 0) {
            question.subQuestions.forEach((que) => {
                childQuestions.forEach((childQuestion) => {
                    if (childQuestion.child_id === que.question_id) {
                        que.parent_question_id = childQuestion.parent_id;
                        que.parent_trigger_value =
                            childQuestion.triggering_value;
                    }
                });
            });
        }
    });
    if (
        partner &&
        !isNil(partner) &&
        !isEmpty(partner) &&
        partner === 'sentinelone'
    ) {
        questionsTobeDefaulted = defaultCyberUWQForSentinelone;
    } else {
        questionsTobeDefaulted = defaultCyberUWQ;
    }
    if (questionsTobeDefaulted && questionsTobeDefaulted.length > 0) {
        transformedQuestions[0].questions.forEach((question) => {
            questionsTobeDefaulted.forEach((que) => {
                if (
                    question.question_id &&
                    que.code &&
                    question.question_id === que.code &&
                    !que.displayToUser
                ) {
                    question.value = que.value;
                    que.includedInApiResponse = true;
                }
                if (question.subQuestions && question.subQuestions.length > 0) {
                    question.subQuestions.forEach((subQue, index, obj) => {
                        if (
                            subQue.question_id.includes('RD10') &&
                            partner &&
                            !isNil(partner) &&
                            !isEmpty(partner) &&
                            partner === 'sentinelone'
                        ) {
                            subQue.text = subQue.text.replace(/[0-9]/g, '');
                            subQue.text = subQue.text.replace(/[.]/g, '');
                        }
                        if (
                            subQue.question_id &&
                            que.code &&
                            subQue.question_id === que.code
                        ) {
                            que.includedInApiResponse = true;
                            subQue.value = que.value;
                            if (que.removeQuestion) {
                                obj.splice(index, 1);
                            }
                        }
                    });
                }
            });
        });
        transformedQuestions[0].questions = transformedQuestions[0].questions.filter(
            (que) => {
                return isNil(que.value) || isEmpty(que.value);
            }
        );

        transformedQuestions[0].questions = transformedQuestions[0].questions.filter(
            (que) => {
                return (
                    isNil(que.parent_question_id) ||
                    isEmpty(que.parent_question_id) ||
                    (que.parent_question_id &&
                        !isNil(que.parent_question_id) &&
                        !isEmpty(que.parent_question_id) &&
                        find(transformedQuestions[0].questions, [
                            'question_id',
                            que.parent_question_id,
                        ]))
                );
            }
        );
        transformedQuestions[0].questions.forEach((question, index, obj) => {
            if (question.subQuestions && question.subQuestions.length > 0) {
                question.subQuestions = question.subQuestions.filter((que) => {
                    return isNil(que.value) || isEmpty(que.value);
                });
            }
            questionsTobeDefaulted.forEach((que) => {
                if (
                    question.question_id &&
                    que.code &&
                    question.question_id === que.code
                ) {
                    question.value = que.value;
                    if (que.removeQuestion) {
                        obj.splice(index, 1);
                    }
                }
            });
        });
    }
    return transformedQuestions;
};

const convertToDropDownValues = (values) => {
    let listOfValues = [];
    values.forEach((value) => {
        let convertedValue = {};
        convertedValue['label'] = value.caption;
        convertedValue['value'] = value.value;
        listOfValues.push(convertedValue);
    });
    return listOfValues;
};

// converts the control type to dataType used in ratin universal component
// example: radio => yes no => 1
const controlTypesToDataTypes = (controlType, formatMask) => {
    let type = controlTypesToDataTypesDictionary[controlType];
    if (type) {
        return type;
    }
    if (controlType === 'text' && formatMask === 'currencyInt') {
        return '3';
    }
    return controlType;
};

const controlTypesToDataTypesDictionary = {
    radio: '1',
};

const getFormatMask = (mask) => {
    if (mask === 'currencyInt') {
        return 'currency';
    } else return mask;
};

/* SUX-2682
AC5: Before navigating the user to the home page, all the details entered by the user in the business details screen should be saved,
so that the user need not enter the information again while restarting a quote. */

const checkIfValueExists = (groupFieldId, fieldId, answeredQuestions) => {
    let answer = '';
    // loop through products
    if (answeredQuestions) {
        answeredQuestions.forEach((answeredGroup) => {
            if (groupFieldId === answeredGroup['id']) {
                answeredGroup.questions.forEach((answeredQuestion) => {
                    if (
                        answeredQuestion['question_id'] === fieldId &&
                        answeredQuestion['value'] != null
                    ) {
                        answer = answeredQuestion['value'];
                    }
                });
            }
        });
    }
    return answer;
};

const arrangeQuestionGroupProductWise = (
    selectedProduct,
    transformedQuestions
) => {
    let newquestionGroupindex = 4;
    selectedProduct.forEach((product) => {
        transformedQuestions.forEach((questionGroup, questionindex) => {
            if (
                product.productGroup === 'CYBER' &&
                questionGroup.group_name.includes('Cyber')
            ) {
                transformedQuestions.splice(questionindex, 1);
                transformedQuestions.splice(
                    newquestionGroupindex,
                    0,
                    questionGroup
                );
                newquestionGroupindex += 1;
            }
            if (questionGroup.group_name.includes(product.productGroup)) {
                transformedQuestions.splice(questionindex, 1);
                transformedQuestions.splice(
                    newquestionGroupindex,
                    0,
                    questionGroup
                );
                newquestionGroupindex += 1;
            }
        });
    });
    return transformedQuestions;
};
