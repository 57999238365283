import { Action } from '@ngrx/store';
import { BusinessClass } from '../../../model/p360-business-class';

export enum P360ClassActionTypes {
    setP360Classes = '[ set p360 classes ] Set p360 classes',
}

export class SetP360ClassAction implements Action {
    readonly type = P360ClassActionTypes.setP360Classes;

    constructor(public payload: BusinessClass[]) {}
}

export type P360ClassActionUnion = SetP360ClassAction;
