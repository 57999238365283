import { Component, OnInit, Input } from '@angular/core';
import { CommonCommunicationService } from '../../../../services/common-communication.service';

@Component({
    selector: 'app-csb-left-panel',
    templateUrl: './csb-left-panel.component.html',
    styleUrls: ['./csb-left-panel.component.scss'],
})
export class CsbLeftPanelComponent implements OnInit {
    @Input() header: string;

    @Input() content: string;

    @Input() footer: string;

    @Input() url: string;

    constructor(public _ccs: CommonCommunicationService) {}

    ngOnInit() {}
}
