import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassificationComponent } from './classification.component';
import { InputModule, TypeaheadModule } from '@crux/components';
import { BusinessTypeModule } from '../../../@csb/sales-common/components/classification/business-type.module';

@NgModule({
    imports: [CommonModule, BusinessTypeModule, InputModule, TypeaheadModule],
    declarations: [ClassificationComponent],
    exports: [ClassificationComponent],
})
export class ClassificationModule {}
