import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@crux/services';
import { environment } from '../../../../environments/environment';
import { select, Store } from '@ngrx/store';
import { AffinityPartnerFlags } from '../../../model/affinity-partner';
import { isEmpty, isNil } from 'lodash-es';

@Component({
    selector: 'app-home-banner',
    templateUrl: './home-banner.component.html',
    styleUrls: ['./home-banner.component.scss'],
})
export class HomeBannerComponent implements OnInit {
    blobURL = environment.blobStorageBaseURL;
    affinityPartnerFlags: AffinityPartnerFlags;
    @Input() bannerContent: any;

    constructor(
        private router: Router,
        private _analytics: AnalyticsService,
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>
    ) {
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.affinityPartnerFlags = AffinityPartnerFlags;
                        if (
                            this.affinityPartnerFlags.partnercode ===
                            'sentinelone'
                        ) {
                            var styleElem = document.head.appendChild(
                                document.createElement('style')
                            );
                            styleElem.innerHTML =
                                '.getQuote_color {background-color: transparent !important;}';
                        }
                    }
                }
            });
    }

    ngOnInit(): void {}

    navigateTo(action) {
        this._analytics.eventTrack.next({
            category: 'HomePage',
            action: 'BUTTON_CLICK',
            label: `${action.text}`,
        });
        this.router.navigate([action.url]);
    }
}
