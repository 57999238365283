import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsbWebsiteComponent } from './csb-website.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { 
    ButtonToggleModule, 
    ButtonModule,
    CruxCalendarModule,
    CruxDropdownModule,
    InputModule,
    CruxCheckboxModule,
    TooltipModule
} from '@crux/components';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AnalyticsModule } from '@crux/services';
import { NotificationsModule } from '../../components/notifications/notifications.module';
import { ErrorBoxModule } from './../../components/error-box/error-box.module';
import { RouterModule } from '@angular/router';
import { CSBCoreModule } from '../../csb-core/csb-core.module';
import { QuoteImageModule } from '../quote-image/quote-image.module';

@NgModule({
    declarations: [CsbWebsiteComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonToggleModule,
        ButtonModule,
        CSBCoreModule,
        QuoteImageModule,
        CruxDropdownModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCheckboxModule,
        CruxCalendarModule,
        InputModule,
        CruxCheckboxModule,
        TooltipModule,
        AnalyticsModule,
        NotificationsModule,
        ErrorBoxModule,
        RouterModule

    ],
    exports: [CsbWebsiteComponent],
    entryComponents: [CsbWebsiteComponent],
})
export class CsbWebsiteModule {}
