import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, timeout, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { MajescoPaymentRequest } from '../model/majesco-payment';
import { BillingRequest } from '../model/billing-request';
import { CruxHttpClientService } from '@crux/services';

@Injectable()
export class MajesoPaymentService {
    static CORRELATION_ID = 'CorrelationId';
    static TIMEOUT = 120000;
    static CSB_QUICKPAY = 'CSB-68b25bdc-3b78-4427-b566-d66142dcea80';

    private _billingRequest: BillingRequest;

    constructor(private http: CruxHttpClientService) {}

    public get billingRequest(): BillingRequest {
        return this._billingRequest;
    }

    doBillingDetermination(requestObject: BillingRequest) {
        this._billingRequest = requestObject;
        const url: string =
            environment.backend + environment.uri.billingdetermination;

        const headers = {};
        headers[
            MajesoPaymentService.CORRELATION_ID
        ] = new Date().getTime().toString();

        return this.http
            .post(url, {
                body: requestObject,
                headers: new HttpHeaders(headers),
            })
            .pipe(
                timeout(MajesoPaymentService.TIMEOUT),
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
    }

    doPayment(requestObject: MajescoPaymentRequest) {
        const url: string =
            environment.backend + environment.uri.majescopayemnt;
        requestObject.quickPayToken = MajesoPaymentService.CSB_QUICKPAY;

        // Currently proxy is sending the unique id
        const headers = {};
        headers[
            MajesoPaymentService.CORRELATION_ID
        ] = new Date().getTime().toString();

        return this.http
            .post(url, {
                body: requestObject,
                headers: new HttpHeaders(headers),
            })
            .pipe(
                timeout(MajesoPaymentService.TIMEOUT),
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
    }
}
