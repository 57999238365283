import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoCarouselComponent } from './video-carousel.component';
import { CarouselLinkComponent } from './carousel-link/carousel-link.component';
import { CarouselModule } from '@crux/components';
import { CarouselItemViewComponent } from './carousel-item-view/carousel-item-view.component';
import { VideoComponent } from './video/video.component';
import { CarouselNavigationComponent } from './carousel-navigation/carousel-navigation.component';

@NgModule({
    declarations: [
        VideoCarouselComponent,
        CarouselLinkComponent,
        CarouselItemViewComponent,
        VideoComponent,
        CarouselNavigationComponent,
    ],
    imports: [CommonModule, CarouselModule],
    exports: [VideoCarouselComponent, CarouselLinkComponent],
})
export class VideoCarouselModule {}
