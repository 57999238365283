import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-quote-image',
    templateUrl: './quote-image.component.html',
    styleUrls: ['./quote-image.component.scss'],
})
export class QuoteImageComponent implements OnInit {
    @Input() activeImage: string;

    @Input() inactiveImage: string;

    @Input() questionId: string;

    private _changeImage: boolean;
    public get changeImage(): boolean {
        return this._changeImage;
    }

    @Input()
    public set changeImage(value: boolean) {
        this._changeImage = value;
        if (value) {
            this.urlImage = this.activeImage;
        } else {
            this.urlImage = this.inactiveImage;
        }
    }

    urlImage: string;

    constructor() {}

    ngOnInit() {
        this.urlImage = this.inactiveImage;
    }
}
