import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonToggleModule, ButtonModule } from '@crux/components';
import { StepNavigatorComponent } from './step-navigator/step-navigator.component';
import { FormsModule } from '@angular/forms';
import { HostDirective } from './directive/host.directive';
import { HighlightDirective } from './directive/highlight.directive';

@NgModule({
    declarations: [HostDirective, StepNavigatorComponent, HighlightDirective],
    imports: [CommonModule, FormsModule, ButtonToggleModule, ButtonModule],
    exports: [HostDirective, HighlightDirective, StepNavigatorComponent],
    entryComponents: [],
})
export class CSBCoreModule {}
