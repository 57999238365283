import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrossSalesComponent } from './gross-sales.component';
import { InputModule } from '@crux/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [CommonModule, InputModule, FormsModule, ReactiveFormsModule],
    declarations: [GrossSalesComponent],
    exports: [GrossSalesComponent],
})
export class GrossSalesModule {}
