export class MaskGapii {
    constructor() {}

    gaEmailMask(email) {
        if (email && email.includes('@')) {
            const emailat = email.split('@');
            const regex = /[^a-zA-Z0-9]/;
            const emailbeforeat = emailat[0].replace(/[a-zA-Z0-9]/g, 'x');
            const emailafterat = emailat[1].split('.');
            const emailafteratdt = emailafterat[0].replace(/[a-zA-Z0-9]/g, 'y');
            const maxlen = emailat[1].length;
            const startlen = emailafterat[0].length;
            const emaillastpart = emailat[1].slice(startlen, maxlen);
            const maskemail =
                emailbeforeat + '@' + emailafteratdt + emaillastpart;
            return maskemail;
        }
    }

    gaNameMask(name) {
        if (name) {
            return name.replace(/[a-zA-Z0-9]/g, 'x');
        }
    }

    gaNumberMask(number) {
        if (number) {
            return number.replace(/[0-9]/g, 'x');
        }
    }

    gaAddressMask(address) {
        if (address && typeof address === 'string') {
            return address.replace(/[a-zA-Z0-9]/g, 'x');
        }
    }
}
