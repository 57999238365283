import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { QuestionsActionTypes } from '../../questions-store/actions';
import { catchError, map, mergeMap, tap, switchMap } from 'rxjs/operators';
import { CruxHttpClientService } from '@crux/services';
import { environment } from './../../../../environments/environment';
import { Question, Questions, SimpleQuestions } from './../../../model/questions';
import { CustomerInfo } from '../../../model/customer-info';

@Injectable()
export class QuestionsEffects {
    questions: Observable<Question[]>;
    simpleQuestionsObj: Observable<SimpleQuestions>;
    customerInfoData: Observable<CustomerInfo>;

    // As per Shabeer, this is anti-practice and we need to change this.
    // Abhijit needs to change it as he gets time.
    constructor(private _store: Store<{ 
        questions: Questions[];
        simpleQuestionsObj: SimpleQuestions;
        customerInfoData: CustomerInfo;
        setQuestions: Question[];
    }>) {
        this.questions = this._store.pipe(select('setQuestions'));
        // TODO: Accept this incoming change
        // this.questions = this._store.pipe(select('questions'));
        this.simpleQuestionsObj = this._store.pipe(
            select('simpleQuestionsObj')
        );
        this.customerInfoData = this._store.pipe(select('customerInfoData'));
    }

    readQuestionsFromStore$(): Observable<Question[]> {
        return this.questions;
    }

    readSimpleQuestionsObjFromStore$(): Observable<SimpleQuestions> {
        return this.simpleQuestionsObj;
    }

    readCustomerInfoDataFromStore$(): Observable<CustomerInfo> {
        return this.customerInfoData;
    }
}
