import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsService } from '@crux/services';
import { environment } from '../../../environments/environment';
import { PartnerCustomization } from '../../partner-customization/partner-customization.helper';

@Component({
    selector: 'app-referral-response',
    templateUrl: './referral-response.component.html',
    styleUrls: ['./referral-response.component.scss'],
})
export class referralResponseComponent implements OnInit {
    public blobURL = environment.blobStorageBaseURL;

    constructor(
        private _analytics: AnalyticsService,
        public dialogRef: MatDialogRef<referralResponseComponent>,
        public partnersCustomization: PartnerCustomization,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {}

    continue() {
        this.dialogRef.close();
        this.partnersCustomization.goToNextScreenUsingProgressBarRoutes(
            this.data.currentRoute,
            this.data.selectedProducts,
            this.data.productIndex
        );
    }

    onNoClick() {
        this._analytics.eventTrack.next({
            category: 'DATAPOPUP',
            action: 'DATAPOPUP_DETAILS',
            label: 'CLOSE MODAL COMPONENT',
        });
        this.dialogRef.close();
    }
}
