import { throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CruxHttpClientService } from '@crux/services';
import { environment } from '../../environments/environment';
import { EmailJourney } from '../../assets/json/start-email-journey-payload';
import { Store, select } from '@ngrx/store';
import { QuoteStep } from '../model/quote-step';
import {
    NoReferralProductsPipe,
    ProductSelectedPipe,
} from '../pipes/productSelected.pipe';
import { AffinityPartnerFlags } from '../model/affinity-partner';
import { isEmpty, isNil } from 'lodash';
import { CommonCommunicationService } from './common-communication.service';
import { CustomerInfo } from '../model/customer-info';
import { MatDialog } from '@angular/material/dialog';
import { CisareferModelComponent } from '../components/cisarefer-model/cisarefer-model.component';
import { DialogComponent } from '../components/dialog/dialog.component';

@Injectable()
export class SaveEmailService {
    emailJourney: EmailJourney = new EmailJourney();
    questions: any;
    products: any;
    quote: QuoteStep = new QuoteStep();
    affinityPartnerFlags: AffinityPartnerFlags;
    customerInfoData: CustomerInfo;
    quoteSubmissionInformation: any;

    constructor(
        private _http: CruxHttpClientService,
        public _ccs: CommonCommunicationService,
        private dialog: MatDialog,
        private _store: Store<{
            questions: any;
            products: any;
            quoteStep: QuoteStep;
            AffinityPartnerFlags: AffinityPartnerFlags;
            customerInfoData: CustomerInfo;
            producerCodesInfo: any;
        }>
    ) {
        this._store.pipe(select('questions')).subscribe((questions) => {
            this.questions = questions;
        });

        this._store.pipe(select('products')).subscribe((products) => {
            this.products = new ProductSelectedPipe().transform(products);
        });

        this._store
            .pipe(select('quoteStep'))
            .subscribe((quoteStep: QuoteStep) => {
                this.quote = quoteStep;
            });

        this._store
            .pipe(select('customerInfoData'))
            .subscribe((customerInfoData) => {
                this.customerInfoData = customerInfoData;
            });

        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.affinityPartnerFlags = AffinityPartnerFlags;
                    }
                }
            });

        this._store
            .pipe(select('producerCodesInfo'))
            .subscribe((producerCodesInfo) => {
                if (!isNil(producerCodesInfo) && !isEmpty(producerCodesInfo)) {
                    producerCodesInfo.forEach((partner) => {
                        if (
                            this.affinityPartnerFlags &&
                            this.affinityPartnerFlags.partnercode &&
                            partner.partnerId ===
                                this.affinityPartnerFlags.partnercode
                        ) {
                            if (partner.eligibleForQuoteSubmission) {
                                this.quoteSubmissionInformation =
                                    partner.quoteSubmissionInfo;
                            }
                        } else if (partner.partnerId === this._ccs.PartnerId) {
                            if (partner.eligibleForQuoteSubmission) {
                                this.quoteSubmissionInformation =
                                    partner.quoteSubmissionInfo;
                            }
                        }
                    });
                }
            });
    }
    // SUX-849
    startEmailJourneyUrl: string =
        environment.backend + environment.uri.saveQuote;

    sendEmailofSavedQuotes() {
        this.dialog.open(DialogComponent, {
            width: '665px',
            data: {
                tag: '',
                title: 'Email your quote',
                content: '',
                showEmail: true,
            },
        });
    }

    startEmailJourney(quotePayload: any) {
        if (quotePayload !== undefined) {
            return this._http
                .post(this.startEmailJourneyUrl, { body: quotePayload })
                .pipe(
                    catchError((error: Response) => {
                        return throwError(error);
                    })
                );
        }
    }

    sendReferralEmail(popupContent) {
        let phoneNumber: string;
        let firstName: string;
        let lastName: string;
        let email: string;
        if (this.customerInfoData) {
            if (
                this.customerInfoData.afirstName &&
                !isNil(this.customerInfoData.afirstName) &&
                !isEmpty(this.customerInfoData.afirstName)
            ) {
                firstName = this.customerInfoData.afirstName;
            } else {
                firstName = this.customerInfoData.firstName;
            }
            if (
                this.customerInfoData.alastName &&
                !isNil(this.customerInfoData.alastName) &&
                !isEmpty(this.customerInfoData.alastName)
            ) {
                lastName = this.customerInfoData.alastName;
            } else {
                lastName = this.customerInfoData.lastName;
            }
            if (
                this.customerInfoData.aemailAddress &&
                !isNil(this.customerInfoData.aemailAddress) &&
                !isEmpty(this.customerInfoData.aemailAddress)
            ) {
                email = this.customerInfoData.aemailAddress;
            } else {
                email = this.customerInfoData.emailAddress;
            }
            if (
                this.customerInfoData.aphoneNumber &&
                !isNil(this.customerInfoData.aphoneNumber) &&
                !isEmpty(this.customerInfoData.aphoneNumber)
            ) {
                phoneNumber = this.customerInfoData.aphoneNumber;
            } else {
                phoneNumber = this.customerInfoData.phoneNumber;
            }
        }
        this.dialog.open(CisareferModelComponent, {
            width: '765px',
            data: {
                title: popupContent.title,
                telNumber: phoneNumber,
                email: email,
                firstName: firstName,
                lastName: lastName,
                agentEmail: this.quoteSubmissionInformation.email,
            },
        });
    }
}
