import {
    sciCyberQuoteActionTypes,
    sciCyberQuoteActionUnion,
} from '../actions/sci-cyber-quote.action';

export let sciCyberQuoteInitialState: any;

export function sciCyberQuoteReducer(
    sciCyberQuoteapiData = sciCyberQuoteInitialState,
    action: sciCyberQuoteActionUnion
) {
    switch (action.type) {
        case sciCyberQuoteActionTypes.sciCyberQuoteData: {
            return action.payload;
        }
        default: {
            return sciCyberQuoteapiData;
        }
    }
}
