import {
    ModuleWithProviders,
    NgModule,
    Optional,
    SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DictionaryService } from './services/dictionary.service';

@NgModule({
    imports: [CommonModule],
})
export class SingletonModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: SingletonModule
    ) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only'
            );
        }
    }

    static forRoot(): ModuleWithProviders<SingletonModule> {
        return {
            ngModule: SingletonModule,
            providers: [DictionaryService],
        };
    }
}
