import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadQuoteComponent } from './download-quote.component';
import { FooterModule } from '../footer/footer.module';
import { CruxAccordionModule } from '@crux/components';
@NgModule({
    imports: [CommonModule, FooterModule, CruxAccordionModule],
    declarations: [DownloadQuoteComponent],
    exports: [DownloadQuoteComponent],
})
export class DownloadQuoteModule {}
