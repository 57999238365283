import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageContainerComponent } from './home-page-container.component';
import { StepOneRetrieveQuoteWrapperModule } from '../step-one-retrieve-quote-wrapper/step-one-retrieve-quote-wrapper.module';
import { ProductsModule } from '../products/products.module';
import { QuoteStepModule } from '../quote-step/quote-step.module';
import { CsbQuestionModule } from '../../../csb-quote/csb-question/csb-question.module';
import { ContentSectionModule } from '../content-section/content-section.module';
import { VideoCarouselModule } from '../video-carousel/video-carousel.module';
import { HomePageProductSelectionModule } from '../home-page-product-selection/home-page-product-selection.module';
import { HomepageBlogSectionModule } from '../homepage-blog-section/homepage-blog-section.module';
import { CsbBannerModule } from '../csb-banner/csb-banner.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { SquarespaceModelComponent } from '../../../components/squarespace-model/squarespace-model.component';
import { SquarespaceModelModule } from '../../../components/squarespace-model/squarespace-model.module';
import { CisaHomepageModule } from '../cisa-homepage/cisa-homepage.module';
import { HomeBannerModule } from '../home-banner/home-banner.module';
import { ProductsOfferedModule } from '../products-offered/products-offered.module';

@NgModule({
    imports: [
        CommonModule,
        StepOneRetrieveQuoteWrapperModule,
        ProductsModule,
        QuoteStepModule,
        CsbQuestionModule,
        ContentSectionModule,
        VideoCarouselModule,
        HomePageProductSelectionModule,
        HomepageBlogSectionModule,
        CsbBannerModule,
        MatExpansionModule,
        SquarespaceModelModule,
        CisaHomepageModule,
        HomeBannerModule,
        ProductsOfferedModule,
    ],
    declarations: [HomePageContainerComponent],
    exports: [HomePageContainerComponent],
    entryComponents: [SquarespaceModelComponent],
})
export class HomePageContainerModule {}
