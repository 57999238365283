import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsService } from '@crux/services';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
    constructor(
        private _analytics: AnalyticsService,
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {}

    onNoClick() {
        this._analytics.eventTrack.next({
            category: 'DATAPOPUP',
            action: 'DATAPOPUP_DETAILS',
            label: 'CLOSE MODAL COMPONENT',
        });
        this.dialogRef.close();
    }
}
