import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonCommunicationService } from '../../../../services/common-communication.service';
import { DictionaryService } from '../../../../services/dictionary.service';
import { Store, select } from '@ngrx/store';
import { Product } from '../../../../model/product';
import { forEach, filter } from 'lodash';
import { PartnerCustomization } from './../../../../partner-customization/partner-customization.helper';
import { ProductSCI } from '../../../../csb-core/product-sci';
import { environment } from '../../../../../environments/environment';
import { CruxHttpClientService } from '@crux/services';
import allProductsData from '../../../../../assets/json/all-products-list.json';

@Component({
    selector: 'app-products-tile',
    templateUrl: './products-tile.component.html',
    styleUrls: ['./products-tile.component.scss'],
})
export class ProductsTileComponent implements OnInit {
    @Input() public inputProducts = [];
    public featuredProducts = [];
    public recommendedProducts = [];
    public nonFeaturedProducts = [];
    public alternateProducts = [];
    allProducts: any;
    public unavailableProducts = [];
    public alternateProductsExist: boolean;
    private blobURL = environment.blobStorageBaseURL;
    @Output()
    public selectedProduct: EventEmitter<any> = new EventEmitter<any>();
    public ccs: CommonCommunicationService;
    public products: Product[];
    @Input() public showUmbrellaError: boolean;
    @Output()
    public emitUmbrellaError: EventEmitter<boolean> = new EventEmitter<
        boolean
    >();
    anyFeaturedProduct: boolean;
    public labelsDictionary: any;
    constructor(
        public pccs: CommonCommunicationService,
        public dictionaryService: DictionaryService,
        private http: CruxHttpClientService,
        private _store: Store<{ products: Product[] }>,
        private _partnerCustomization: PartnerCustomization
    ) {
        this.ccs = pccs;
        this._store.pipe(select('products')).subscribe((products) => {
            this.products = products;
        });
    }

    ngOnInit() {
        this.allProducts = allProductsData;
        this.unavailableProducts = this.allProducts.products.filter(
            (item1) =>
                !this.inputProducts.some((item2) => item2.name === item1.name)
        );
        this.labelsDictionary = this.dictionaryService.labelDict;
        this.inputProducts.forEach((product) => {
            this.recommendedProducts.push(product);
        });
        this.checkProductsList();
        forEach(this.products, (product, index: number) => {
            product.selectedpackage = null;
            if (product.featured_ind && !this.anyFeaturedProduct) {
                this.anyFeaturedProduct = true;
            }
        });
    }

    selectProduct(productObj): void {
        if (productObj.displayProduct === true) {
            this.selectedProduct.emit(productObj);
        }
    }

    closeMessage($event) {
        this.showUmbrellaError = false;
        this.emitUmbrellaError.emit(false);
    }

    filterProducts(product) {
        return ProductSCI.filterSCIProduct(product, this.products);
        /*const productId = product.productId;
        let displayProductIndicator = true;
        // Filter extra SCI products
        if (productId.startsWith('MICRO')) {
            const sciProduct = find(this.products, [
                'productId',
                productId.split('MICRO')[1],
            ]);
            if (sciProduct) {
                if (!sciProduct.selected) {
                    return displayProductIndicator;
                } else {
                    return false;
                }
            } else {
                return displayProductIndicator;
            }
        } else {
            this.products.forEach((productFromStore) => {
                if (!product.selected) {
                    if ('MICRO' + productId === productFromStore.productId) {
                        displayProductIndicator = false;
                    }
                } else {
                    displayProductIndicator = true;
                }
            });
        }
        return displayProductIndicator;*/
    }

    checkProductsList() {
        const alternateProductNames = ['BOP', 'GL'];
        this.alternateProductsExist = alternateProductNames.every(
            (requiredProduct) =>
                this.inputProducts.some(
                    (product) => product.productId === requiredProduct
                )
        );
        if (this.alternateProductsExist) {
            this.alternateProducts = this.inputProducts.filter((product) => {
                if (product.productId === 'GL') {
                    product.alternateOption = true;
                    return true;
                }
            });
          
            this.recommendedProducts = this.inputProducts.filter(product => product.productId !== "GL")
          }
          const digitechProductExists = this.inputProducts.some(product => product.productId === 'CYBER-DIGITECT');
          if (digitechProductExists) {
            this.unavailableProducts =  this.unavailableProducts.filter(product => product.productId !== "CYBER-ERM")
          }
    }
}
