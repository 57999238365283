export class CustomerInfo {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    afirstName: string;
    alastName: string;
    aemailAddress: string;
    aphoneNumber: string;
    mailingBusinessAddress: string;
    mailingStAddress?: string;
    mailingAddressLine2?: string;
    mailingAddressCity?: string;
    mailingAddressState?: string;
    mailingAddressZipCode?: string;
    customersFein?: any;
    customersUian?: any;
    customersTin?: any;
    confirm: any;
    accept: any;
    initials: any;
    thirdpartyaccept: any;
}

export class BusinessMailingAddress {
    mailingStAddress?: string;
    mailingAddressLine2?: string;
    mailingAddressCity?: string;
    mailingAddressState?: string;
    mailingAddressZipCode?: string;
    isSameBusinessAddress: boolean;
    mailingBusinessAddress: string;
}
