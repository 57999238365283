import { Injectable } from '@angular/core';
import { CruxHttpClientService } from '@crux/services';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { CommonCommunicationService } from './common-communication.service';
import { ICruxHttpClientOptions } from '@crux/services/src/app/modules/http/client/http-client.interface';
import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { isEmpty, isNil } from 'lodash';

@Injectable()
export class RetrieveQuoteService {
    retrieveQuoteApi = environment.backend + environment.uri.retrieveQuote;
    sciretrieveQuoteApi =
        environment.backend + environment.uri.sciretrievequote;
    sciSemciRetrieveQuoteApi =
        environment.backend + environment.uri.scisemciretrievequote;
    retrieveQuoteByEmailApi =
        environment.backend + environment.uri.retrieveQuoteByEmail;
    retrieveQuoteByProducerCodeApi =
        environment.backend + environment.uri.retrieveQuoteByProducerCode;
    wcretrieveQuoteApi = environment.backend + environment.uri.wcretrievequote;
    sciCyberRetrieveQuoteApi =
        environment.backend + environment.uri.scicyberretrievequote;
    retrieveQuoteByQuoteNumberApi =
        environment.backend + environment.uri.retrieveQuoteByQuote;

    retrieveQuoteByQuoteKeyApi =
        environment.backend + environment.uri.retrieveQuoteByQuoteKey;

    getSciCyberUWQApi = environment.backend + environment.uri.scicybergetuwq;
    getSciSemciUWQApi = environment.backend + environment.uri.scigetsemciuwq;
    constructor(
        private _http: CruxHttpClientService,
        private ccs: CommonCommunicationService
    ) {}

    getRetrieveQuoteData(
        quotenumber,
        zipcode,
        isSciQuote,
        isWcQuote,
        isSciCyberQuote,
        isSemciQuote,
        productCode,
        apiVersion
    ) {
        let apiUrl: any;
        let headers: any;
        let requestBody: any;
        if (isSciQuote && !isWcQuote && !isSciCyberQuote) {
            apiUrl = this.sciretrieveQuoteApi.replace(
                '#PARTNER#',
                this.ccs.PartnerId
            );
            if (
                this.ccs.backendEnvVariable &&
                !isNil(this.ccs.backendEnvVariable) &&
                !isEmpty(this.ccs.backendEnvVariable)
            ) {
                headers = new HttpHeaders({
                    QuoteNumber: quotenumber,
                    env: this.ccs.backendEnvVariable,
                });
            } else {
                headers = new HttpHeaders({ QuoteNumber: quotenumber });
            }
        } else if (isSciCyberQuote) {
            apiUrl = this.sciCyberRetrieveQuoteApi;
            if (
                this.ccs.backendEnvVariable &&
                !isNil(this.ccs.backendEnvVariable) &&
                !isEmpty(this.ccs.backendEnvVariable)
            ) {
                headers = new HttpHeaders({
                    QuoteNumber: quotenumber,
                    env: this.ccs.backendEnvVariable,
                });
            } else {
                headers = new HttpHeaders({ QuoteNumber: quotenumber });
            }
        } else if (isWcQuote) {
            apiUrl = this.wcretrieveQuoteApi;
            if (
                this.ccs.backendEnvVariable &&
                !isNil(this.ccs.backendEnvVariable) &&
                !isEmpty(this.ccs.backendEnvVariable)
            ) {
                headers = new HttpHeaders({
                    QuoteNumber: quotenumber,
                    env: this.ccs.backendEnvVariable,
                });
            } else {
                headers = new HttpHeaders({ QuoteNumber: quotenumber });
            }
        } else if (isSemciQuote) {
            apiUrl = this.sciSemciRetrieveQuoteApi;
            requestBody = {
                businessOwnerPolicyQuoteInfo: {
                    policyQuoteId: quotenumber,
                    companyProductcode: productCode,
                },
            };
        } else {
            apiUrl = this.retrieveQuoteApi;
            headers = new HttpHeaders({
                DigitalApiToken: quotenumber,
                apiversion: apiVersion,
            });
        }
        if (
            quotenumber !== null &&
            quotenumber !== undefined &&
            zipcode !== null &&
            zipcode !== undefined
        ) {
            if (isSemciQuote && !isNil(productCode) && !isEmpty(productCode)) {
                if (
                    this.ccs.backendEnvVariable &&
                    !isNil(this.ccs.backendEnvVariable) &&
                    !isEmpty(this.ccs.backendEnvVariable)
                ) {
                    const headers = {};
                    headers['env'] = this.ccs.backendEnvVariable;
                    return this._http
                        .post(apiUrl, {
                            body: requestBody,
                            headers: new HttpHeaders(headers),
                        })
                        .pipe(
                            catchError((error: Response) => {
                                return observableThrowError(error);
                            })
                        );
                } else {
                    return this._http.post(apiUrl, { body: requestBody }).pipe(
                        catchError((error: Response) => {
                            return observableThrowError(error);
                        })
                    );
                }
            } else {
                return this._http.get(apiUrl, {
                    headers,
                });
            }
        }
    }

    getRetrieveQuoteByEmail(email, zipcode) {
        if (
            email !== null &&
            email !== undefined &&
            zipcode !== null &&
            zipcode !== undefined
        ) {
            return this._http.get(
                this.retrieveQuoteByEmailApi.replace(
                    '$emailAddress$',
                    encodeURIComponent(email)
                )
            );
        }
    }

    getRetrieveQuoteByProducerCode(producerCode) {
        if (producerCode && !isNil(producerCode) && !isEmpty(producerCode)) {
            return this._http.get(
                this.retrieveQuoteByProducerCodeApi.replace(
                    '$producerCd$',
                    producerCode
                )
            );
        }
    }

    getSourceSystemByQuote(quote, zipcode) {
        if (
            quote !== null &&
            quote !== undefined &&
            zipcode !== null &&
            zipcode !== undefined
        ) {
            return this._http.get(
                this.retrieveQuoteByQuoteNumberApi.replace(
                    '$quoteNumber$',
                    quote
                )
            );
        }
    }

    getSourceSystemByQuoteKey(quoteKey: string) {
        if (quoteKey !== null && quoteKey !== undefined) {
            const options: ICruxHttpClientOptions = {
                headers: new HttpHeaders({
                    QuoteKey: quoteKey,
                }),
            };
            return this._http.get(this.retrieveQuoteByQuoteKeyApi, options);
        }
    }

    getSciCyberUWQ(request) {
        return this._http.post(this.getSciCyberUWQApi, { body: request }).pipe(
            catchError((error: Response) => {
                return observableThrowError(error);
            })
        );
    }

    getSciSemciUWQ(request) {
        return this._http.post(this.getSciSemciUWQApi, { body: request }).pipe(
            catchError((error: Response) => {
                return observableThrowError(error);
            })
        );
    }
}
