import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, timeout, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { AutoPaymentRequest } from '../model/auto-payment';
import { CruxHttpClientService } from '@crux/services';

@Injectable()
export class AutoPaymentService {
    static CORRELATION_ID = 'CorrelationId';
    static USER_TOKEN = 'userToken';
    static TIMEOUT = 120000;

    constructor(private http: CruxHttpClientService) {}

    autoPaymentEnroll(requestObject: AutoPaymentRequest) {
        const url: string =
            environment.backend + environment.uri.autoPaymentEnroll;
        const headers = {};
        headers[
            AutoPaymentService.CORRELATION_ID
        ] = new Date().getTime().toString();

        headers[AutoPaymentService.USER_TOKEN] = '';

        return this.http
            .post(url, {
                body: requestObject,
                headers: new HttpHeaders(headers),
            })
            .pipe(
                timeout(AutoPaymentService.TIMEOUT),
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
    }
}
