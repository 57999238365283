import { Action } from '@ngrx/store';

export enum LossDetailsActionTypes {
    lossHistoryData = '[losshistorydata] setlosshistorydata',
}

export class SetLossDetailsAction implements Action {
    readonly type = LossDetailsActionTypes.lossHistoryData;

    constructor(public payload: any) {}
}

export type LossDetailsActionUnion = SetLossDetailsAction;
