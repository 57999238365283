import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmpTileComponent } from './emp-tile.component';

import {
    SpinnerModule,
    CardModule,
    AutocompleteModule,
    InputModule,
    CruxAccordionModule,
    ButtonModule,
    FormBuilderModule,
    CruxDropdownModule,
    CruxCheckboxModule,
    TypeaheadModule,
} from '@crux/components';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { AnalyticsModule } from '@crux/services';

@NgModule({
    declarations: [EmpTileComponent],
    imports: [
        CommonModule,
        SpinnerModule,
        CardModule,
        AutocompleteModule,
        AnalyticsModule,
        InputModule,
        CruxAccordionModule,
        ButtonModule,
        FormBuilderModule,
        CruxDropdownModule,
        CruxCheckboxModule,
        TypeaheadModule,
        MatExpansionModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        ReactiveFormsModule,
    ],
    exports: [EmpTileComponent],
})
export class EmpTileModule {}
