import {
    Component,
    OnInit,
    HostListener,
    ChangeDetectorRef,
    Inject,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { DictionaryService } from './services/dictionary.service';
import { ApplicationMessagesService } from './services/application-messages.service';
import { CommonCommunicationService } from './services/common-communication.service';
import { p360APIService } from './services/p360API.service';
import {
    ApplicationInsightsService,
    GoogleTagManagerService,
    CruxHttpClientService,
} from '@crux/services';
import {
    ParamMap,
    Router,
    ActivatedRoute,
    NavigationEnd,
    RoutesRecognized,
    NavigationStart,
} from '@angular/router';
import { skip, filter, pairwise } from 'rxjs/operators';
import { environment } from './../environments/environment';
import { DOCUMENT } from '@angular/common';
import { ProductSCI } from './csb-core/product-sci';
import { ViewportScroller } from '@angular/common';
import { find, findIndex, isEmpty, isNil } from 'lodash';
import { Store, select } from '@ngrx/store';
import {
    SetAffinityPartnerInfoAction,
    SetProducerCodesAction,
    SetLoadingModalFactsAction,
} from './sales-store/partners-redirection-store/actions';
import { AffinityPartnerFlags } from './model/affinity-partner';
import { SetPartnerOffersAction } from './sales-store/partners-redirection-store/actions/partner-offers.actions';
import { Subscription } from 'rxjs';
import { SquarespaceModelComponent } from './components/squarespace-model/squarespace-model.component';
import { MatDialog } from '@angular/material/dialog';
import { SetP360TotalClassesAction } from './sales-store/appetite-store/actions';
import { Product } from './model/product';
import { SaveEmailService } from './services/save-email.service';
import { GetBlobContentService } from './services/get-blob-content.service';
import { MsalAuthService } from './services/msal-auth.service';
import { MatSidenav } from '@angular/material/sidenav';

export let browserRefresh = false;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'app';
    partnerIds: any[] = environment.partnerIds;
    currentURL: any = '/';
    previousURL: any;
    private blobURL = environment.blobStorageBaseURL;
    pcode = '';
    source: string;
    medium: string;
    atricle: boolean;
    AffinityPartnerFlags = new AffinityPartnerFlags();
    subscription: Subscription;
    producerData: any;
    welcomeModalData: any;
    modalPopupData: any;
    getHelpContent: any;
    products: Product[];
    productIndex: any;
    @ViewChild('sidenav') sidenav: MatSidenav;

    constructor(
        private scroller: ViewportScroller,
        public dictionaryService: DictionaryService,
        private http: CruxHttpClientService,
        public saveEmailService: SaveEmailService,
        private msalAuthService: MsalAuthService,
        public applicationMessagesService: ApplicationMessagesService,
        public commonCommunicationService: CommonCommunicationService,
        public getBlobDataService: GetBlobContentService,
        private p360APIService: p360APIService,
        private _gtm: GoogleTagManagerService,
        private _ai: ApplicationInsightsService,
        private _cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private _store: Store<{
            producerCodes: any;
            LoadingModalFacts: any;
            products: Product[];
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>,
        @Inject(DOCUMENT) document
    ) {
        this.subscription = router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                browserRefresh = !router.navigated;
                let affinitypartnercode = sessionStorage.getItem(
                    'affinitypartnercode'
                );
                let affinityutm_source = sessionStorage.getItem(
                    'affinityutm_source'
                );
                let affinityMedium = sessionStorage.getItem('affinityMedium');
                let urlEvent = event.url.includes('partnercode');
                let printEvent = event.url.includes('print-disclosure');
                if (
                    browserRefresh &&
                    affinitypartnercode &&
                    !isNil(affinitypartnercode) &&
                    !isEmpty(affinitypartnercode) &&
                    affinitypartnercode !== 'CISA' &&
                    affinityutm_source &&
                    !urlEvent &&
                    !printEvent
                ) {
                    if (affinitypartnercode === 'sentinelone') {
                        if (affinityMedium === 'email') {
                            this.router.navigate(['/get-a-quote'], {
                                queryParams: {
                                    partnercode: affinitypartnercode,
                                    utm_source: affinityutm_source,
                                    utm_medium: 'email',
                                    utm_campaign: 'sentinelone_email',
                                },
                            });
                        } else {
                            this.router.navigate(['/get-a-quote'], {
                                queryParams: {
                                    partnercode: affinitypartnercode,
                                    utm_source: affinityutm_source,
                                    utm_medium: 'affiliate',
                                },
                            });
                        }
                    } else {
                        this.router.navigate(['/'], {
                            queryParams: {
                                partnercode: affinitypartnercode,
                                utm_source: affinityutm_source,
                                utm_medium: 'affiliate',
                            },
                        });
                    }
                }
                if (
                    event.url === '/get-a-quote' ||
                    event.url === '/retrieve-customer'
                ) {
                    this.commonCommunicationService.getHelpWorkFlowStage =
                        'getQuote';
                } else if (event.url === '/payment-confirmation') {
                    this.commonCommunicationService.getHelpWorkFlowStage =
                        'Issued';
                    var styleHelpDrawer = document.head.appendChild(
                        document.createElement('style')
                    );
                    styleHelpDrawer.innerHTML =
                        '.help-drawer-container {height: 100% !important;}';
                }
            }
            if (event instanceof NavigationEnd) {
                document
                    .getElementsByTagName('mat-sidenav-content')[0]
                    .scrollTo(0, 0);
                if (
                    event.url &&
                    !isEmpty(event.url) &&
                    !isNil(event.url) &&
                    event.url.includes('Alignable')
                ) {
                    this.router.navigate(['/'], {
                        queryParams: {
                            partnercode: null,
                            utm_source: null,
                            utm_medium: null,
                        },
                    });
                }
                if (
                    event.url &&
                    !isEmpty(event.url) &&
                    !isNil(event.url) &&
                    (event.url.includes('/cisa') ||
                        event.url ===
                            '/?partnercode=CISA&utm_source=CISA&utm_medium=affiliate')
                ) {
                    this.commonCommunicationService.showcisa = true;
                    this.pcode = 'CISA';
                    this.source = 'CISA';
                    this.AffinityPartnerFlags.partnercode = 'CISA';
                    this.AffinityPartnerFlags.utm_source = 'CISA';
                    this.AffinityPartnerFlags.utm_medium = 'affiliate';
                    this.commonCommunicationService.partnerSource = 'CISA';
                    this._store.dispatch(
                        new SetAffinityPartnerInfoAction(
                            this.AffinityPartnerFlags
                        )
                    );
                    if (this.commonCommunicationService.showcisa) {
                        this.msalAuthService
                            .getRedirectResponse()
                            .subscribe((res) => {
                                if (res !== true) {
                                    this.msalAuthService.signIn();
                                }
                            });
                        this.msalAuthService.handleRedirectPromise();
                    }
                }
            }
        });
    }
    labelLoaded: boolean;

    async ngOnInit() {
        this.route.queryParamMap.pipe(skip(1)).subscribe((params: ParamMap) => {
            this.source = params.get('utm_source');
            this.medium = params.get('utm_medium');
            if (
                !isNil(params.get('partnercode')) &&
                !isEmpty(params.get('partnercode'))
            ) {
                this.pcode = params.get('partnercode');
                this.AffinityPartnerFlags.partnercode = this.pcode;
                this.AffinityPartnerFlags.utm_source = params.get('utm_source');
                this.AffinityPartnerFlags.utm_medium = params.get('utm_medium');
                this.commonCommunicationService.partnerSource = this.AffinityPartnerFlags.utm_source;
                if (this.pcode === 'cospring5') {
                    this.AffinityPartnerFlags.discount_percentage = 5;
                } else if (this.pcode === 'cospring10') {
                    this.AffinityPartnerFlags.discount_percentage = 10;
                } else if (this.pcode === 'squarespace10') {
                    this.AffinityPartnerFlags.discount_percentage = 10;
                } else if (this.pcode === 'amazon10') {
                    this.AffinityPartnerFlags.discount_percentage = 10;
                } else if (this.pcode === 'ISBA10') {
                    this.AffinityPartnerFlags.discount_percentage = 10;
                } else if (this.pcode === 'RSA10') {
                    this.AffinityPartnerFlags.discount_percentage = 10;
                }
                this._store.dispatch(
                    new SetAffinityPartnerInfoAction(this.AffinityPartnerFlags)
                );
                if (this.producerData && this.producerData.length > 0) {
                    this.producerData.forEach((data) => {
                        if (
                            this.pcode &&
                            !isNil(this.pcode) &&
                            !isEmpty(this.pcode)
                        ) {
                            if (
                                data.partnerId === this.pcode &&
                                data.enableWhiteLabeling
                            ) {
                                this.setGloabalColor(data);
                            } else {
                                this.setDefaultColors();
                            }
                            if (
                                data.partnerId === this.pcode &&
                                data.addBackendEnvVariable
                            ) {
                                this.commonCommunicationService.backendEnvVariable =
                                    data.envVariable;
                            }
                        } else {
                            if (
                                (isNil(this.pcode) || isEmpty(this.pcode)) &&
                                data.partnerId ===
                                    this.commonCommunicationService.PartnerId &&
                                data.enableWhiteLabeling
                            ) {
                                this.setGloabalColor(data);
                            } else {
                                this.setDefaultColors();
                            }
                            if (
                                data.partnerId === this.pcode &&
                                data.addBackendEnvVariable
                            ) {
                                this.commonCommunicationService.backendEnvVariable =
                                    data.envVariable;
                            }
                        }
                    });
                }
                this.http
                    .get(
                        this.blobURL +
                            '/data/welcome-modal-content.json?ts=' +
                            ts,
                        {
                            responseType: 'json',
                        }
                    )
                    .subscribe((res) => {
                        if (!isNil(res) && !isEmpty(res)) {
                            this.welcomeModalData = res;
                            this.welcomeModalData.forEach((data) => {
                                if (data.partnerCode === this.pcode) {
                                    if (!isNil(data) && !isEmpty(data)) {
                                        this.modalPopupData = data;
                                        this.dialog.open(
                                            SquarespaceModelComponent,
                                            {
                                                width: '665px',
                                                data: this.modalPopupData,
                                                disableClose: true,
                                            }
                                        );
                                    }
                                }
                            });
                        }
                    });
            }

            if (this.source != null) {
                // source = source.toLowerCase();
                if (this.source === 'prtnr001') {
                    this.commonCommunicationService.PartnerId = 1;
                } else if (
                    this.source.toLocaleLowerCase() === 'capitalone' ||
                    this.source.toLocaleLowerCase() === 'walmart' ||
                    this.source.toLocaleLowerCase() === 'squarespace' ||
                    this.source.toLocaleLowerCase() === 'biz2credit' ||
                    this.source.toLocaleLowerCase() === 'isba' ||
                    this.source.toLocaleLowerCase() === 'cisa' ||
                    this.source.toLocaleLowerCase() === 'rsa' ||
                    this.source.toLocaleLowerCase() === 'sentinelone'
                ) {
                    this.commonCommunicationService.PartnerId = 0;
                } else if (this.source.toLocaleLowerCase() === 'amazon') {
                    this.commonCommunicationService.PartnerId = 'briza';
                } else {
                    // Check to see if the utm_source is an Affinity Partner ID
                    // otherwise set to the default Partner ID
                    if (this.partnerIds.indexOf(this.source) >= 0) {
                        this.commonCommunicationService.PartnerId = this.source;
                    } else {
                        this.commonCommunicationService.PartnerId = 0;
                    }
                }

                if (this.source.toLocaleLowerCase() === 'walmart') {
                    this.commonCommunicationService.walmartPartner = true;
                }
                if (this.source.toLocaleLowerCase() === 'cisa') {
                    this.commonCommunicationService.showcisa = true;
                }
                if (this.source.toLocaleLowerCase() === 'rsa') {
                    this.commonCommunicationService.rsaPartner = true;
                }
                if (this.source.toLocaleLowerCase() === 'jove') {
                    this.commonCommunicationService.jovePartner = true;
                }

                if (
                    this.pcode &&
                    !isNil(this.pcode) &&
                    !isEmpty(this.pcode) &&
                    this.pcode !== '' &&
                    this.pcode !== 'CISA'
                ) {
                    if (this.pcode === 'sentinelone') {
                        if (
                            this.medium &&
                            !isNil(this.medium) &&
                            !isEmpty(this.medium) &&
                            this.medium === 'email'
                        ) {
                            this.commonCommunicationService.coverageAdvisorLink =
                                this.commonCommunicationService
                                    .coverageAdvisorLink +
                                '?partnercode=' +
                                this.pcode +
                                '&utm_source=' +
                                this.source +
                                '&utm_medium=email&utm_campaign=sentinelone_email';
                        } else {
                            this.commonCommunicationService.coverageAdvisorLink =
                                this.commonCommunicationService
                                    .coverageAdvisorLink +
                                '?partnercode=' +
                                this.pcode +
                                '&utm_source=' +
                                this.source +
                                '&utm_medium=affiliate';
                        }
                    } else {
                        this.commonCommunicationService.coverageAdvisorLink =
                            this.commonCommunicationService
                                .coverageAdvisorLink +
                            '?partnercode=' +
                            this.pcode +
                            '&utm_source=' +
                            this.source +
                            '&utm_medium=affiliate';
                    }
                    sessionStorage.setItem('affinitypartnercode', this.pcode);
                    sessionStorage.setItem('affinityutm_source', this.source);
                    sessionStorage.setItem('affinityMedium', this.medium);
                }
            }
            this.getBlobDataService.getPaymentDrawerContent();
            this.getBlobDataService.getLoadingModalFacts();
        });
        if (isNil(this.source) || isEmpty(this.source)) {
            this.commonCommunicationService.partnerSource = 'csb';
        }
        this.dictionaryService.load();
        this.applicationMessagesService.load();
        this.labelLoaded = true;
        const ts = new Date().getTime();

        const productSCI: ProductSCI = ProductSCI.instance();
        productSCI.initialize(this.http);

        this.http
            .get(this.blobURL + '/data/carousel-video.json?ts=' + ts, {
                responseType: 'json',
            })
            .subscribe((res) => {
                if (
                    res.partnersNotEligibleForCA &&
                    res.partnersNotEligibleForCA.length > 0 &&
                    res.partnersNotEligibleForCA.indexOf(this.source) > -1
                ) {
                    this.commonCommunicationService.showCoverageAdvisorLink = false;
                } else {
                    this.commonCommunicationService.showCoverageAdvisorLink =
                        res.showCoverageAdvisorLink;
                }
                if (this.pcode == '') {
                    this.commonCommunicationService.coverageAdvisorLink =
                        res.coverageAdvisorLink;
                } else {
                    this.commonCommunicationService.coverageAdvisorLink =
                        res.coverageAdvisorLink +
                        '?partnercode=' +
                        this.pcode +
                        '&utm_source=' +
                        this.source +
                        '&utm_medium=affiliate';
                }
            });

        this.http
            .get(this.blobURL + '/data/partner-offers.json?ts=' + ts, {
                responseType: 'json',
            })
            .subscribe((res) => {
                if (!isNil(res) && !isEmpty(res)) {
                    this._store.dispatch(new SetPartnerOffersAction(res));
                }
            });

        this.http
            .get(this.blobURL + '/data/producer-codes.json?ts=' + ts, {
                responseType: 'json',
            })
            .subscribe((res) => {
                if (!isNil(res) && !isEmpty(res)) {
                    this._store.dispatch(new SetProducerCodesAction(res));
                    this.producerData = res;
                    this.producerData.forEach((data) => {
                        if (
                            this.pcode &&
                            !isNil(this.pcode) &&
                            !isEmpty(this.pcode)
                        ) {
                            if (
                                data.partnerId === this.pcode &&
                                data.enableWhiteLabeling
                            ) {
                                this.setGloabalColor(data);
                            } else {
                                this.setDefaultColors();
                            }
                            if (
                                data.partnerId === this.pcode &&
                                data.addBackendEnvVariable
                            ) {
                                this.commonCommunicationService.backendEnvVariable =
                                    data.envVariable;
                            }
                        } else {
                            if (
                                (isNil(this.pcode) || isEmpty(this.pcode)) &&
                                data.partnerId ===
                                    this.commonCommunicationService.PartnerId &&
                                data.enableWhiteLabeling
                            ) {
                                this.setGloabalColor(data);
                            } else {
                                this.setDefaultColors();
                            }
                            if (
                                data.partnerId === this.pcode &&
                                data.addBackendEnvVariable
                            ) {
                                this.commonCommunicationService.backendEnvVariable =
                                    data.envVariable;
                            }
                        }
                    });
                }
            });

        this.http
            .get(this.blobURL + '/data/get-help.json?ts=' + ts, {
                responseType: 'json',
            })
            .subscribe((res) => {
                if (res && !isNil(res) && !isEmpty(res)) {
                    this.getHelpContent = res;
                    if (
                        this.pcode &&
                        !isNil(this.pcode) &&
                        !isEmpty(this.pcode)
                    ) {
                        if (
                            find(this.getHelpContent, ['partnerId', this.pcode])
                        ) {
                            this.getHelpContent = this.getHelpContent.filter(
                                (item) => {
                                    return item.partnerId === this.pcode;
                                }
                            );
                            this.getHelpContent = this.getHelpContent[0];
                            this.enbaleReportSectionsInGetHelp();
                        } else {
                            this.getHelpContent = this.getHelpContent.filter(
                                (item) => {
                                    return item.partnerId === 'default';
                                }
                            );
                            this.getHelpContent = this.getHelpContent[0];
                            this.enbaleReportSectionsInGetHelp();
                        }
                    } else {
                        this.getHelpContent = this.getHelpContent.filter(
                            (item) => {
                                return item.partnerId === 'default';
                            }
                        );
                        this.getHelpContent = this.getHelpContent[0];
                        this.enbaleReportSectionsInGetHelp();
                    }
                }
            });

        this.http
            .get(this.blobURL + '/data/error-screens-content.json?ts=' + ts, {
                responseType: 'json',
            })
            .subscribe((res) => {
                if (res && !isNil(res) && !isEmpty(res)) {
                    this.commonCommunicationService.errorScreenContent = res;
                }
            });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.previousURL = this.currentURL;
                if (this.previousURL.includes('/blog/')) {
                    this.atricle = true;
                } else {
                    this.atricle = false;
                }
                this.currentURL = event.url;
                this.onWindowScroll(event);
            });

        // Get entire p360 business class list
        this.p360APIService.keywordsearch('').subscribe(
            (res) => {
                this.commonCommunicationService.allP360ClassList =
                    res.predictions;
                if (!isNil(this.producerData) && !isEmpty(this.producerData)) {
                    this.producerData.forEach((partner) => {
                        if (
                            partner.narrowClassList &&
                            partner.partnerId === this.pcode &&
                            partner.classListCodes &&
                            partner.classListCodes.length > 0
                        ) {
                            let filteredClasses = [];
                            partner.classListCodes.forEach((code) => {
                                this.commonCommunicationService.allP360ClassList.forEach(
                                    (classItem) => {
                                        if (classItem.tts_code === code) {
                                            filteredClasses.push(classItem);
                                        }
                                    }
                                );
                            });
                            this.commonCommunicationService.allP360ClassList = filteredClasses;
                            this._store.dispatch(
                                new SetP360TotalClassesAction(
                                    this.commonCommunicationService.allP360ClassList
                                )
                            );
                        }
                    });
                }
            },
            (error: Response) => {}
        );
        this.getBlobDataService.getPaymentDrawerContent();
        this.getBlobDataService.getLoadingModalFacts();
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
        if (
            this.currentURL === '/' ||
            this.currentURL === '/nawbo' ||
            this.currentURL === '/paypal' ||
            this.currentURL === '/capitalone' ||
            this.currentURL === '/squarespace' ||
            this.currentURL === '/biz2credit' ||
            this.currentURL === '/amazon' ||
            this.currentURL === '/ISBA' ||
            this.currentURL === '/CISA' ||
            this.currentURL === '/RSA' ||
            this.currentURL === '/pbn' ||
            this.currentURL === '/TailorBrands' ||
            this.currentURL === '/jove' ||
            this.currentURL === '/sentinelone' ||
            this.currentURL === '/?utm_source=prtnr001' ||
            this.currentURL.includes('partnercode')
        ) {
            const contentElement = document.getElementById('content-wrapper');
            contentElement.classList.add('removeMaringTop');
            if (window.scrollY <= 510) {
                const element = document.getElementById('navbar');
                element.classList.remove('navbar');
                element.classList.add('nonsticky');
                this.commonCommunicationService.navfontcolor = 'white';
            } else {
                const element = document.getElementById('navbar');
                element.classList.remove('nonsticky');
                element.classList.add('navbar');
                this.commonCommunicationService.navfontcolor = 'black';
            }
        } else {
            this.commonCommunicationService.navfontcolor = 'black';
            const element = document.getElementById('navbar');
            element.classList.add('navbar');
            const contentElement = document.getElementById('content-wrapper');
            contentElement.classList.remove('removeMaringTop');
        }
    }

    buildCommunicationFromSession(ccs, ccsFromSession) {
        if (!ccsFromSession) {
            return;
        }
        for (let ccsKey in ccsFromSession) {
            if (
                typeof ccsFromSession[ccsKey] === 'string' ||
                typeof ccsFromSession[ccsKey] === 'number' ||
                typeof ccsFromSession[ccsKey] === 'boolean' ||
                ccsFromSession[ccsKey] == null
            ) {
                ccs[ccsKey] = ccsFromSession[ccsKey];
            } else if (typeof ccsFromSession[ccsKey] === 'object') {
                if (Array.isArray(ccsFromSession[ccsKey])) {
                    ccs[ccsKey] = [];
                    for (let i = 0; i < ccsFromSession[ccsKey].length; i++) {
                        ccs[ccsKey][i] = {};
                        this.buildCommunicationFromSession(
                            ccs[ccsKey][i],
                            ccsFromSession[ccsKey][i]
                        );
                    }
                } else {
                    ccs[ccsKey] = {};
                    this.buildCommunicationFromSession(
                        ccs[ccsKey],
                        ccsFromSession[ccsKey]
                    );
                }
            }
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    async saveDatatoSession($event: any) {
        await this._cdr.detectChanges();
        await this._cdr.markForCheck();
        let ccsString = JSON.stringify(this.commonCommunicationService);
        sessionStorage.setItem('sessionCCS', ccsString);
    }

    onActivate(event) {
        let scrollToTop = window.setInterval(() => {
            let pos = window.scrollY;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
                if (this.atricle) {
                    this.scroller.scrollToAnchor('targetArticle');
                }
            }
        }, 16);
        this.enbaleReportSectionsInGetHelp();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setGloabalColor(data) {
        if (!isNil(data) && !isEmpty(data)) {
            if (data.globalColor1 && data.globalColor1 != '') {
                this.commonCommunicationService.globalColor1 =
                    data.globalColor1;
            }
            if (data.globalColor2 && data.globalColor2 != '') {
                this.commonCommunicationService.globalColor2 =
                    data.globalColor2;
            }
            if (data.globalColorHeader && data.globalColorHeader != '') {
                this.commonCommunicationService.globalColorHeader =
                    data.globalColorHeader;
            }
            if (data.globalColorOutline && data.globalColorOutline != '') {
                this.commonCommunicationService.globalColorOutline =
                    data.globalColorOutline;
            }
            if (
                data.homeBannerTextColor &&
                !isNil(data.homeBannerTextColor) &&
                !isEmpty(data.homeBannerTextColor)
            ) {
                this.commonCommunicationService.homeBannerTextColor =
                    data.homeBannerTextColor;
            }
            if (
                data.rocketOutline &&
                !isNil(data.rocketOutline) &&
                !isEmpty(data.rocketOutline)
            ) {
                this.commonCommunicationService.rocketOutline =
                    data.rocketOutline;
            }

            //HomeBannerTextColor//
            var styleElemBc1 = document.head.appendChild(
                document.createElement('style')
            );
            styleElemBc1.innerHTML =
                '.bannerText_color, .getQuote_color, .retrieveQuote_color {color: ' +
                this.commonCommunicationService.homeBannerTextColor +
                ' !important;}';

            var styleBannerBtnElem = document.head.appendChild(
                document.createElement('style')
            );
            styleBannerBtnElem.innerHTML =
                '.getQuote_color, .retrieveQuote_color {border: 1px solid ' +
                this.commonCommunicationService.homeBannerTextColor +
                ' !important;}';
            //HomeBannerTextColor//

            //GlobalColorOutline//
            var styleEleOutline = document.head.appendChild(
                document.createElement('style')
            );
            styleEleOutline.innerHTML =
                '.coloroutline {color: ' +
                this.commonCommunicationService.globalColorOutline +
                ' !important;}';

            var styleEleOutlineb = document.head.appendChild(
                document.createElement('style')
            );
            styleEleOutlineb.innerHTML =
                '.coloroutlinebordertop {border-top: 4px solid ' +
                this.commonCommunicationService.globalColorOutline +
                ' !important;}';

            //GlobalColorOutline//

            //GlobalColor1//
            var styleElemg1 = document.head.appendChild(
                document.createElement('style')
            );
            styleElemg1.innerHTML =
                '.backgroundg1, .banner {background-color: ' +
                this.commonCommunicationService.globalColor1 +
                ' !important;}';
            //GlobalColor1//

            //GlobalColorHeaderButton//
            var styleElemghb = document.head.appendChild(
                document.createElement('style')
            );
            styleElemghb.innerHTML =
                '.background_gh, .selected-btn {background-color: ' +
                this.commonCommunicationService.globalColorHeader +
                ' !important;}';

            var styleElemghc = document.head.appendChild(
                document.createElement('style')
            );
            styleElemghc.innerHTML =
                '.color_gh {color: ' +
                this.commonCommunicationService.globalColorHeader +
                ' !important;}';

            var styleElemghbb = document.head.appendChild(
                document.createElement('style')
            );
            styleElemghbb.innerHTML =
                '.background_border_gh, .content-container__btn--save, .crux-payment__button .mat-primary, .paypal-button .paypal-button-color-gold, .paypal-button-row .paypal-button-color-gold .menu-button {background-color: ' +
                this.commonCommunicationService.globalColorHeader +
                ' !important; border: 1px solid ' +
                this.commonCommunicationService.globalColorHeader +
                ' !important;}';

            var styleElemDisableBtn = document.head.appendChild(
                document.createElement('style')
            );
            styleElemDisableBtn.innerHTML =
                '.background_border_gh[disabled], .content-container__btn--save[disabled] {background-color: #d2d3d4 !important; border: 1px solid #d2d3d4 !important;}';
            //GlobalColorHeaderButton//

            //GlobalColor2//
            var styleElembannerLabel = document.head.appendChild(
                document.createElement('style')
            );
            styleElembannerLabel.innerHTML =
                'p, a, h1, h3, label, span, ul li, .steps-container-error-h4, .steps-container-error-h2, .wait-message .header1, .wait-message .header2, .progress .percentage, .choose-text, .title-job-type, .mat-form-field-label, .mat-empty, .mat-form-field-empty, .group-text, .input-label-items, .mat-expansion-panel-header-title, .amount {color: ' +
                this.commonCommunicationService.globalColor2 +
                ' !important;}';

            var styleElembannerborder = document.head.appendChild(
                document.createElement('style')
            );
            styleElembannerborder.innerHTML =
                '.mat-checkbox-frame{border-color: ' +
                this.commonCommunicationService.globalColor2 +
                ' !important;}';

            var styleElemfooter = document.head.appendChild(
                document.createElement('style')
            );
            styleElemfooter.innerHTML =
                '.section-footer-body p, .section-footer-body a, .section-footer-body label {color: #ffffff !important;}';

            var styleElemtoggleBtn = document.head.appendChild(
                document.createElement('style')
            );
            styleElemtoggleBtn.innerHTML =
                '*.mat-button-toggle {border: 1px solid ' +
                this.commonCommunicationService.globalColor2 +
                ' !important;}';
            //GlobalColor2//

            //miscellaneous//
            var styleElembannerLabel = document.head.appendChild(
                document.createElement('style')
            );
            styleElembannerLabel.innerHTML =
                '.white_color {color: #FFFFFF !important;}';
            //miscellaneous//
        }
    }

    setDefaultColors() {
        var styleElemDefault = document.head.appendChild(
            document.createElement('style')
        );
        styleElemDefault.innerHTML =
            '.backgroundg1 {background-color: ' +
            this.commonCommunicationService.globalColor1 +
            ' !important;}';
    }

    enbaleReportSectionsInGetHelp() {
        if (
            this.commonCommunicationService.getHelpWorkFlowStage &&
            !isNil(this.commonCommunicationService.getHelpWorkFlowStage) &&
            !isEmpty(this.commonCommunicationService.getHelpWorkFlowStage) &&
            this.getHelpContent &&
            !isNil(this.getHelpContent) &&
            !isEmpty(this.getHelpContent)
        ) {
            this.getHelpContent.issuesToBeReported.forEach((issue) => {
                if (
                    issue &&
                    issue.quoteStagesTobeAvailable &&
                    issue.quoteStagesTobeAvailable.length > 0
                ) {
                    if (
                        issue.quoteStagesTobeAvailable.indexOf(
                            this.commonCommunicationService.getHelpWorkFlowStage
                        ) >= 0
                    ) {
                        issue.enableInQuoteFlow = true;
                    } else {
                        issue.enableInQuoteFlow = false;
                    }
                }
            });
        }
    }

    displayNavButtons(navAction) {
        if (
            navAction &&
            navAction.navigationType &&
            navAction.navigationType === 'save' &&
            this.commonCommunicationService.quoteCreated
        ) {
            return true;
        } else if (
            navAction &&
            navAction.navigationType &&
            navAction.navigationType === 'external' &&
            this.commonCommunicationService.policyIssued
        ) {
            return true;
        } else if (
            navAction &&
            navAction.navigationType &&
            navAction.navigationType === 'referral' &&
            this.commonCommunicationService.quoteCreated
        ) {
            return true;
        } else {
            return false;
        }
    }

    hideContactNum(item) {
        if (
            item &&
            item.type &&
            !isNil(item.type) &&
            !isEmpty(item.type) &&
            item.type === 'phone'
        ) {
            if (
                this.commonCommunicationService.showcisa &&
                !this.commonCommunicationService.quoteCreated
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    sendEmailstoCustomer(navAction) {
        if (
            navAction &&
            navAction.navigationType &&
            navAction.navigationType === 'save'
        ) {
            this.saveEmailService.sendEmailofSavedQuotes();
        } else if (
            navAction &&
            navAction.navigationType &&
            navAction.navigationType === 'referral'
        ) {
            this.sidenav.toggle();
            this.saveEmailService.sendReferralEmail(navAction.popupContent);
        } else if (
            navAction &&
            navAction.navigationType &&
            navAction.navigationType === 'external'
        ) {
            window.open(navAction.url, '_blank');
        }
    }
}
