import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThirdpartyDisclosuresComponent } from './thirdparty-disclosures.component';
import { CruxDropdownModule } from '@crux/components';

@NgModule({
    imports: [CommonModule, CruxDropdownModule],
    declarations: [ThirdpartyDisclosuresComponent],
    exports: [ThirdpartyDisclosuresComponent],
})
export class ThirdpartyDisclosuresModule {}
