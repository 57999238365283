import { MarketingTabsComponent } from './marketing-tabs.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsModule } from '@crux/services';
import { CruxAccordionModule } from '@crux/components';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    imports: [
        CommonModule,
        AnalyticsModule,
        CruxAccordionModule,
        MatTabsModule,
    ],
    declarations: [MarketingTabsComponent],
    exports: [MarketingTabsComponent],
})
export class MarketingTabsModule {}
