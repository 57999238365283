import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { Router } from '@angular/router';
@Injectable()
export class QuoteListGuard implements CanActivate {
    constructor(
        private _CCS: CommonCommunicationService,
        private _router: Router
    ) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        // Allow user to navigate through route only if quote by email flag is true
        if (this._CCS.quoteByEmail) {
            return true;
        }
        /**
         * Navigate to home when the above condition does not match
         */
        this._router.navigate(['']);
    }
}
