import {
    Component,
    OnInit,
    ElementRef,
    ViewChild,
    Inject,
    ChangeDetectorRef,
    OnDestroy,
    ViewRef,
} from '@angular/core';
import { AnalyticsService } from '@crux/services';
import { ZipcodeModel } from '../../../../model/zipcode';
import {
    FormControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {
    SetLocationAction,
    SetP360ClassAction,
} from '../../../../sales-store/appetite-store/actions';
import { CommonCommunicationService } from '../../../../services/common-communication.service';
import { LocationService } from '../../../../services/location.service';
import { Subject } from 'rxjs';
import { QuestionBase } from '../../../../csb-quote/csb-question/csb-question-base.component';
import { DictionaryService } from '../../../../services/dictionary.service';
import { ApplicationMessagesService } from '../../../../services/application-messages.service';
import { DOCUMENT } from '@angular/common';
import {
    filter,
    debounceTime,
    distinctUntilChanged,
    map,
} from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { GAEvent } from '../../../../csb-core/ga-event';
import { MaskGapii } from '../../../../issuance/helper/ga-mask-pii';
import { isEmpty, isNil } from 'lodash';

@Component({
    selector: 'app-csb-zipcode',
    templateUrl: './csb-zipcode.component.html',
    styleUrls: ['./csb-zipcode.component.scss'],
})
export class CsbZipcodeComponent extends QuestionBase
    implements OnInit, OnDestroy {
    ZipcodeForm: FormGroup;
    zipCodeFormControl: FormControl;
    city = new FormControl('');
    state = new FormControl('');
    zipcodeData: ZipcodeModel[] = [];
    initialZipData: ZipcodeModel[] = [];
    public zipCodeValue$ = new Subject<string>();
    showZipInfo = false;
    public error = false;
    public notMatcherror = false;
    public isValidZipCode = false;
    public selectedCity: any;
    public isCitySelected = false;
    public zipcode: any = null;
    cityList: any[] = [];
    question: string;
    errorMessages: any;
    cssClass: Array<string>;
    componentClass: Array<string>;
    citySelected: any;
    focusIn = false;
    alignTop = 0;
    alignBottom = 0;
    leftSpace = 0;
    errorText: string;
    isEmpty = false;
    public gaMask: MaskGapii;
    showDropDownError: boolean = false;

    @ViewChild('containerRef') public containerRef: ElementRef;

    @ViewChild('anchorRef') public anchorRef: ElementRef;

    constructor(
        public commonCommunicationService: CommonCommunicationService,
        private dictionaryService: DictionaryService,
        private _fb: FormBuilder,
        public locationService: LocationService,
        private _analytics: AnalyticsService,
        private applicationMessagesService: ApplicationMessagesService,
        private _router: Router,
        private cd: ChangeDetectorRef,
        private _store: Store<{
            zipcode: ZipcodeModel[];
        }>,
        @Inject(DOCUMENT) document
    ) {
        super();
        this.question = this.dictionaryService.labelDict.banner.quoteStep.zipCode;
    }

    ngOnInit() {
        if (this.containerRef && this.containerRef.nativeElement) {
            const element: HTMLDivElement = <HTMLDivElement>(
                this.containerRef.nativeElement
            );
            if (element) {
                element.addEventListener(
                    'mousedown',
                    this.containerHandlerIn.bind(this)
                );
                element.addEventListener(
                    'focusin',
                    this.containerHandlerIn.bind(this)
                );
                document.addEventListener(
                    'mousedown',
                    this.containerHandlerIn.bind(this)
                );
                document.addEventListener(
                    'focusin',
                    this.containerHandlerIn.bind(this)
                );
            }
        }

        this.anchorHandler();

        this.cssClass = this.showImage
            ? ['col-4', 'col-md-4', 'col-sm-6']
            : ['col-7', 'col-md-7', 'col-sm-6'];

        this.componentClass = this.showImage
            ? ['col-5', 'col-md-5', 'col-sm-12']
            : ['col-5', 'col-md-5', 'col-sm-6'];

        this.errorMessages = this.applicationMessagesService.messageDictionary.homePageQuestions;
        this.zipCodeFormControl = new FormControl(Validators.required);
        this.ZipcodeForm = this._fb.group({
            zipCodeFormControl: this.zipCodeFormControl,
        });
        this.zipCodeFormControl.valueChanges.subscribe((resp) => {
            this.getZipcodeData(resp);
        });
        this.readDataFromStore();
        this.gaMask = new MaskGapii();
    }

    containerHandlerIn(event) {
        const val = this.focusIn;

        if (
            document.getElementById('zipcodeContainer') &&
            document.getElementById('zipcodeContainer').contains(event.target)
        ) {
            if (!this.focusIn) {
                this.anchorHandler();
                this.focusIn = true;
            }
        } else if (!this.isCitySelected && this.cityList.length) {
            this.showDropDownError = true;
        } else if (this.isCitySelected) {
            this.focusIn = false;
        }

        if (val !== this.focusIn) {
            setTimeout(() => {
                if (this.cd && !(this.cd as ViewRef).destroyed) {
                    this.cd.detectChanges();
                }
            });
        }
    }

    anchorHandler() {
        if (this.anchorRef && this.anchorRef.nativeElement) {
            const boundary = this.anchorRef.nativeElement.getBoundingClientRect();
            // const boundary2 = this.containerRef.nativeElement.getBoundingClientRect();
            if (boundary) {
                this.leftSpace = boundary.left;
                // this.leftSpace = this.leftSpace < 0 ? 0 : this.leftSpace;
            }
        }
    }

    readDataFromStore() {
        this._store.pipe(select('zipcode')).subscribe((zipdata) => {
            this.zipcodeData = zipdata;
            if (zipdata && zipdata.length > 0) {
                this.initialZipData = zipdata.filter((addObj) => {
                    return addObj.selected;
                });
            }
            this.initialZipData = zipdata;
            if (this.zipcodeData.length === 1) {
                this.zipCodeFormControl.setValue(
                    this.zipcodeData[0].PostalCode
                );
                this.city.setValue(this.zipcodeData[0].City);
                this.state.setValue(this.zipcodeData[0].StateProvince);
                if (this.zipcodeData[0] && this.cityList.length === 0) {
                    const obj = {};
                    obj['display'] = this.zipcodeData[0].City;
                    obj['value'] = this.zipcodeData[0].City;
                    this.cityList.push(obj);
                    setTimeout(() => {
                        this.zipCodeValue$.next(this.zipcodeData[0].PostalCode);
                    }, 100);
                }
            } else if (this.zipcodeData.length > 1) {
                this.zipcodeData.forEach((zipresult) => {
                    if (zipresult.selected) {
                        this.city.setValue(zipresult.City);
                    }
                });
                this.state.setValue(this.zipcodeData[0].StateProvince);
                if (this.cityList.length === 0) {
                    this.isCitySelected = true;
                    this.zipcodeData.forEach((zipResponse) => {
                        const obj = {};
                        obj['display'] = zipResponse.City;
                        obj['value'] = zipResponse.City;
                        this.cityList.push(obj);
                    });
                    setTimeout(() => {
                        this.zipCodeValue$.next(this.zipcodeData[0].PostalCode);
                    }, 100);
                }
            }

            if (!this.isCitySelected) {
                this.displayCityState();
            }
        });
    }

    getZipcodeData(resp) {
        if (resp) {
            if (
                resp &&
                resp.data &&
                resp.data[0] &&
                resp.data[0].PostalCode !== 'objectKeyboardEvent' &&
                resp.data[0].PostalCode !== 'objectEvent'
            ) {
                if (this.zipcodeData.length === 0) {
                    if (resp.data[0].PostalCode.length === 5) {
                        this.notMatcherror = false;
                        this.error = false;
                        this.isValidZipCode = false;
                        this.zipCodeDetails(resp);
                    } else if (resp.data[0].PostalCode.length < 5) {
                        this.notMatcherror = false;
                        this.error = true;
                        this.isEmpty = resp.data[0].PostalCode.length === 0;
                        this.isValidZipCode = false;
                    }
                } else if (this.zipcodeData.length > 0) {
                    this.notMatcherror = false;
                    this.error = false;
                    this.isValidZipCode = false;
                    if (resp.data[0].PostalCode.length < 5) {
                        this.notMatcherror = false;
                        this.error = true;
                        this.isEmpty = resp.data[0].PostalCode.length === 0;
                        this.isValidZipCode = false;
                    } else {
                        this.zipCodeDetails(resp);
                    }
                }
                this.enableNext();
            }
        } else {
            if (!this.error && resp === null) {
                this.notMatcherror = false;
                this.error = true;
                this.isEmpty = true;
                this.isValidZipCode = false;
                this.city.setValue('');
                this.state.setValue('');
                this.cityList = [];
                if (this.zipcodeData.length === 1) {
                    this.zipCodeFormControl.setValue(
                        this.zipcodeData[0].PostalCode
                    );
                    this.city.setValue(this.zipcodeData[0].City);
                    this.state.setValue(this.zipcodeData[0].StateProvince);
                }
            }
            if (this.gaevent && (this.notMatcherror || this.error)) {
                GAEvent.CapturEvent(
                    this._analytics,
                    'POSTAL_CODE_LOOKUP_FAILURE',
                    this.gaevent.category,
                    'xxxxx'
                );
            }
            this.enableNext();
        }
    }

    zipCodeDetails(validateResult) {
        if (validateResult && validateResult.data && !validateResult.error) {
            if (
                this.initialZipData &&
                this.initialZipData.length > 0 &&
                this.initialZipData[0].PostalCode &&
                !isNil(this.initialZipData[0].PostalCode) &&
                !isEmpty(this.initialZipData[0].PostalCode) &&
                this.initialZipData[0].PostalCode !==
                    validateResult.data[0].PostalCode
            ) {
                this.isCitySelected = false;
            }
            this.error = false;
            this.notMatcherror = false;
            this.showZipInfo = false;
            this.cityList = [];
            if (validateResult.data.length > 0) {
                validateResult.data.forEach((zipResponse) => {
                    const obj = {};
                    obj['display'] = zipResponse.City;
                    obj['value'] = zipResponse.City;
                    this.cityList.push(obj);
                });
            }
            this.city.enable();
            if (validateResult.data.length === 1) {
                validateResult.data[0].selected = true;
                this.zipcodeData = validateResult.data;
                this.isCitySelected = true;
                this.city.disable();
                this.city.setValue(this.zipcodeData[0].City);
                this.state.setValue(this.zipcodeData[0].StateProvince);
                this.displayCityState();
            } else {
                if (this.isCitySelected) {
                    const zipData = validateResult.data.filter((results) => {
                        return results.City === this.zipcodeData[0].City;
                    });
                    this.isCitySelected = zipData.length > 0;
                    if (this.isCitySelected) {
                        this.citySelected = zipData[0];
                    } else {
                        this.zipcodeData = validateResult.data;
                    }
                } else {
                    this.zipcodeData = validateResult.data;
                }
                // this.cityList = validateResult.data;
                this.anchorHandler();
                this.focusIn = true;
                this.alignTop = this.cityList.length
                    ? this.cityList.length * 35 + 50
                    : 0;
                this.alignBottom = -this.alignTop;
                this.displayCityState();
            }

            if (this.zipcodeData.length === 0 && validateResult.error) {
                if (validateResult.data[0].Status === 'F') {
                    this.error = true;
                    this.isEmpty = false;
                    this.isValidZipCode = false;
                    this.showZipInfo = true;
                }
            } else {
                if (this.gaevent) {
                    GAEvent.CapturEvent(
                        this._analytics,
                        this.gaevent.action,
                        this.gaevent.category,
                        `${this.gaMask.gaAddressMask(
                            validateResult.data[0].City
                        )},
                        ${this.gaMask.gaAddressMask(
                            validateResult.data[0].StateProvince
                        )},
                        ${this.gaMask.gaAddressMask(
                            validateResult.data[0].PostalCode
                        )}`
                    );
                }
            }
        } else if (validateResult && validateResult.error) {
            if (
                validateResult.data[0].Status === 'F' &&
                validateResult.data[0].PostalCode.length < 1
            ) {
                this.error = true;
                this.isEmpty = false;
                this.notMatcherror = false;
                this.isValidZipCode = false;
                this.showZipInfo = true;
            } else if (
                validateResult.data[0].Status === 'F' &&
                validateResult.data[0].PostalCode.length > 4
            ) {
                this.notMatcherror = true;
                this.error = false;
                this.isValidZipCode = false;
                this.showZipInfo = true;
            }
            /**
             * ! Track Failure Postal Code Lookup.
             */
            if (this.gaevent && (this.notMatcherror || this.error)) {
                GAEvent.CapturEvent(
                    this._analytics,
                    'POSTAL_CODE_LOOKUP_FAILURE',
                    this.gaevent.category,
                    `${this.gaMask.gaAddressMask(
                        validateResult.data[0].PostalCode
                    )}`
                );
            }
        } else if (validateResult === null) {
            this.isValidZipCode = false;
            this.isCitySelected = false;
        }
        this.enableNext();
    }
    setMulticityData(citySelection) {
        let city: any;
        if (!isEmpty(citySelection) && !isNil(citySelection)) {
            city = this.zipcodeData.find((cityObject: any) => {
                return cityObject.City === citySelection[0].value;
            });
            this.zipcodeData.forEach((el) => {
                if (city.City === el.City) {
                    el.selected = true;
                } else {
                    el.selected = false;
                }
            });
        }
        this.focusIn = false;
        this.showDropDownError = false;
        if (citySelection.length > 0) {
            this.citySelected = citySelection[0];
            GAEvent.CapturEvent(
                this._analytics,
                GAEvent.ACTION.SELECT_CITY,
                this.gaevent.category,
                `${this.gaMask.gaAddressMask(this.citySelected.City)},
                ${this.gaMask.gaAddressMask(this.citySelected.StateProvince)},
                ${this.gaMask.gaAddressMask(this.citySelected.PostalCode)}`
            );
            this.isCitySelected = true;
            this.city.setValue(city.City);
            this.state.setValue(city.StateProvince);
        } else {
            this.isCitySelected = false;
        }
        this.enableNext();
    }
    displayCityState(): void {
        if (this.zipcodeData.length > 0) {
            if (this.zipcodeData[0].City && this.zipcodeData[0].StateProvince) {
                if (
                    this.initialZipData.length > 0 &&
                    this.initialZipData[0].PostalCode &&
                    this.initialZipData[0].City &&
                    (this.initialZipData[0].City !== this.zipcodeData[0].City ||
                        this.initialZipData[0].PostalCode !==
                            this.zipcodeData[0].PostalCode)
                ) {
                    this._store.dispatch(new SetP360ClassAction([]));
                }
                this._store.dispatch(new SetLocationAction(this.zipcodeData));
                this.isValidZipCode = true;
            } else {
                this.isValidZipCode = false;
                this.isCitySelected = false;
            }
        }
        this.enableNext();
    }
    closeInfoBlock(): void {
        this.showZipInfo = false;
    }

    enableNext() {
        if (this.isValidZipCode && this.isCitySelected) {
            this.valid(true, this.zipcodeData);
        } else {
            this.valid(false, this.zipcodeData);
        }
    }

    ngOnDestroy() {
        if (this.containerRef && this.containerRef.nativeElement) {
            const element: HTMLDivElement = <HTMLDivElement>(
                this.containerRef.nativeElement
            );
            if (element) {
                element.removeEventListener(
                    'mousedown',
                    this.containerHandlerIn
                );
                element.removeEventListener('focusin', this.containerHandlerIn);
                document.removeEventListener(
                    'mousedown',
                    this.containerHandlerIn
                );
                document.removeEventListener(
                    'focusin',
                    this.containerHandlerIn
                );
            }
        }
    }

    checkDirtyAndEmpty() {
        let flag = false;
        if (this.error && this.isEmpty) {
            this.errorText = this.errorMessages.zipcode.required;
            flag = true;
        } else if (this.error) {
            this.errorText = this.errorMessages.zipcode.invalid;
            flag = true;
        }
        if (flag) {
            this.city.setValue('');
            this.state.setValue('');
            this.cityList = [];
        }

        return flag;
    }
}
