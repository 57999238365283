import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchModalComponent } from './search-modal.component';
import { InputModule } from '@crux/components';
import { ClassificationModule } from './../classification/classification.module';

@NgModule({
    imports: [CommonModule, ClassificationModule, InputModule],
    declarations: [SearchModalComponent],
    exports: [SearchModalComponent],
})
export class SearchModalModule {}
