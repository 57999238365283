import { ActivatedRoute } from '@angular/router';
import { CommonCommunicationService } from './../services/common-communication.service';
import { Component, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { environment } from '../../environments/environment';
import { CruxHttpClientService } from '@crux/services';
import { isEmpty, isNil } from 'lodash-es';
import edeliveryInfo from '../../assets/json/e-delivery.json';
import cisaInfo from '../../assets/json/cisaInfo.json';

@Component({
    selector: 'app-print-disclosure',
    templateUrl: './print-disclosure.component.html',
    styleUrls: ['./print-disclosure.component.scss'],
})
export class PrintDisclosureComponent implements OnInit {
    public dateTime;
    public logo = '../../assets/logos/CHUBB_Logo_Black_RBG.png';
    public getInitials: string;
    edeliveryContent: any;
    blobURL = environment.blobStorageBaseURL;
    cisaDisclosureContent:any;
    showCisa = false;

    constructor(
        public ccs: CommonCommunicationService,
        public aR: ActivatedRoute,
        private http: CruxHttpClientService,
    ) {}

    ngOnInit() {
        const ts = new Date().getTime();
        this.http
            .get(this.blobURL + '/data/e-delivery.json?ts=' + ts, {
                responseType: 'json',
            })
            .subscribe((res) => {
                if (!isNil(res) && !isEmpty(res)) {
                    this.edeliveryContent = res;
                } else {
                    this.edeliveryContent = edeliveryInfo;
                }
            });

            if(this.checkCisa()){
                this.http
                .get(this.blobURL + '/data/cisaDisclosureContent.json?ts=' + ts, {
                    responseType: 'json',
                })
                .subscribe((results) => {
                    if (!isNil(results) && !isEmpty(results)) {
                        this.cisaDisclosureContent = results;
                    } else {
                        this.cisaDisclosureContent = cisaInfo;
                    }
                });
            }
        this.getInitials = this.aR.snapshot.params.initials;
        const currentDate = new Date();
        this.dateTime = format(currentDate, 'MMMM dd, yyyy');
    }

    checkCisa(): boolean {
        let affinitypartnercode = sessionStorage.getItem('affinitypartnercode');
        return affinitypartnercode && affinitypartnercode === 'CISA';
    }
}
