import { QuoteActionTypes, QuoteActionUnion } from '../actions/quote.action';

export const initialDisqualificationState: Boolean = false;
export function isDisqualifiedReducer(
    isDisqualified = initialDisqualificationState,
    action: QuoteActionUnion
) {
    switch (action.type) {
        case QuoteActionTypes.setIsDisqualified: {
            isDisqualified = action.payload;
            return isDisqualified;
        }
        default: {
            return isDisqualified;
        }
    }
}
