import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogArticleComponent } from './blog-article.component';
import { ContentSectionTileModule } from '../quote/home-page/content-section-tile/content-section-tile.module';
import { RouterModule } from '@angular/router';
@NgModule({
    declarations: [BlogArticleComponent],
    imports: [CommonModule, ContentSectionTileModule, RouterModule],
    exports: [BlogArticleComponent],
})
export class BlogModule {}
