import { Action } from '@ngrx/store';

import { Product } from './../../../model/product';

export enum ProductActionTypes {
    setProducts = '[ set products ] Set products',
    setProductsError = '[ error ] Set product error',
    setProductsStatus = '[ status ] Set product status',
    getQuestions = '[get questions ] Get Questions',
    getSciQuestions = '[get Sci questions ] Get Sci Questions',
    getSciCyberQuestions = '[get Sci Cyber questions ] Get Sci Cyber questions',
    getSciChildQuestions = '[get Sci Child questions ] Get Sci Child Questions',
    setTotalPremium = '[number] Total Premium',
    setPackageCount = '[number] Selected Package Count',
}

export class SetProductsAction implements Action {
    readonly type = ProductActionTypes.setProducts;

    constructor(public payload: Product[]) {}
}

export class SetProductsErrorAction implements Action {
    readonly type = ProductActionTypes.setProductsError;

    constructor(public payload: any) {}
}

export class SetProductsStatusAction implements Action {
    readonly type = ProductActionTypes.setProductsStatus;

    constructor(public payload: any) {}
}

export class GetQuestionsAction implements Action {
    readonly type = ProductActionTypes.getQuestions;

    constructor(public payload: any) {}
}

export class GetSciQuestionsAction implements Action {
    readonly type = ProductActionTypes.getSciQuestions;

    constructor(public payload: any) {}
}

export class GetSciCyberQuestionsAction implements Action {
    readonly type = ProductActionTypes.getSciCyberQuestions;

    constructor(public payload: any) {}
}
export class GetSciChildQuestionsAction implements Action {
    readonly type = ProductActionTypes.getSciChildQuestions;

    constructor(public payload: any) {}
}

export class SetTotalPremiumAction implements Action {
    readonly type = ProductActionTypes.setTotalPremium;

    constructor(public payload: number) {}
}
export class SetSelectedPackageCount implements Action {
    readonly type = ProductActionTypes.setPackageCount;

    constructor(public payload: number) {}
}

export type ProductActionUnion =
    | SetProductsAction
    | SetProductsErrorAction
    | SetProductsStatusAction
    | GetQuestionsAction
    | GetSciQuestionsAction
    | GetSciCyberQuestionsAction
    | GetSciChildQuestionsAction
    | SetTotalPremiumAction
    | SetSelectedPackageCount;
