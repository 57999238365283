import { ZipcodeModel } from '../../../model/zipcode';
import {
    AppetiteActionUnion,
    AppetiteActionTypes,
} from './../actions/appetite.action';

export let zipcodeInitialState: ZipcodeModel[] = [];

export function zipcodeReducer(
    zipcodeState = zipcodeInitialState,
    action: AppetiteActionUnion
) {
    switch (action.type) {
        // Zipcode Setter
        case AppetiteActionTypes.SetZipcodeData: {
            zipcodeState = action.payload;
            return zipcodeState;
        }

        case AppetiteActionTypes.ResetZipcodeData: {
            zipcodeState = action.payload;
            return zipcodeState;
        }

        default: {
            return zipcodeState;
        }
    }
}
