/**
 * Angular Imports
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/**
 * Application Component Imports
 */
import { HomePageContainerComponent } from './quote/home-page/home-page-container/home-page-container.component';
import { PaymentConfirmationGuard } from './post-issuance/payment-confirmation/payment-confirmation.guard';
import { BusinessDetailsGuard } from './quote/form/business-details/business-details.guard';
import { AdditionalInfoGuard } from './quote/form/additional-info/additional-info.guard';
import { CustomPackagesGuard } from './issuance/custom-packages/custom-packages.guard';
import { ReviewApplicationGuard } from './issuance/review-application/review-application.guard';
import { LossHistoryGuard } from './issuance/loss-history/loss-history.guard';
import { PaymentGuard } from './issuance/payment/payment.guard';
import { ProductsGuard } from './quote/home-page/products/products.guard';
import { PartnersRedirectionGuard } from './quote/form/partners-redirection/partners-redirection.guard';
import { QuoteListGuard } from './quote/form/quote-list/quote-list.guard';
import { EligibilityGuard } from './quote/form/eligibility/eligibility.guard';
import { DownloadQuoteComponent } from './components/download-quote/download-quote.component';
import { DownloadReceiptComponent } from './post-issuance/download-receipt/download-receipt.component';
import { WorkersCompJobTypeComponent } from './quote/form/workers-comp-job-type/workers-comp-job-type.component';
import { FraudDisclosuresComponent } from './issuance/fraud-disclosures/fraud-disclosures.component';
import { ThirdpartyDisclosuresComponent } from './issuance/thirdparty-disclosures/thirdparty-disclosures.component';
import { EDeliveryComponent } from './issuance/e-delivery/e-delivery.component';
import { cisaDisclosureComponent } from './issuance/cisa-disclosure/cisa-disclosure.component';
import { BlogArticleComponent } from './blog/blog-article.component';
import { CsbMarketingpagesComponent } from './static-pages/csb-marketingpages/csb-marketingpages.component';

const routes: Routes = [
    {
        path: 'legal',
        children: [
            {
                path: 'fraud-disclosures',
                component: FraudDisclosuresComponent,
            },
            {
                path: 'thirdparty-disclosures',
                component: ThirdpartyDisclosuresComponent,
            },
            {
                path: 'e-delivery-consent',
                component: EDeliveryComponent,
            },
            {
                path: 'cisa-disclosure-consent',
                component: cisaDisclosureComponent,
            },
        ],
    },
    {
        path: 'retrieve-customer',
        loadChildren: () =>
            import(
                './quote/form/retrieve-customer-list/retrieve-customer-list.module'
            ).then((m) => m.RetrieveCustomerListModule),
    },
    {
        path: 'business-profile',
        loadChildren: () =>
            import(
                './quote/form/business-profile/business-profile.module'
            ).then((m) => m.BusinessProfileModule),
    },
    {
        path: 'retrieve-quote',
        loadChildren: () =>
            import('./quote/form/retrieve-quote/retrieve-quote.module').then(
                (m) => m.RetrieveQuoteModule
            ),
    },
    {
        path: 'retrieve-quote/:partnerName',
        loadChildren: () =>
            import('./quote/form/retrieve-quote/retrieve-quote.module').then(
                (m) => m.RetrieveQuoteModule
            ),
    },
    {
        path: 'pre-approved/:quoteNumber/:zipCode',
        loadChildren: () =>
            import('./quote/form/retrieve-quote/retrieve-quote.module').then(
                (m) => m.RetrieveQuoteModule
            ),
    },
    {
        path: 'get-a-quote',
        loadChildren: () =>
            import('./quote/get-a-quote/get-a-quote.module').then(
                (m) => m.GetAQuoteModule
            ),
    },
    {
        path: 'who-we-insure',
        loadChildren: () =>
            import('./static-pages/who-we-insure/who-we-insure.module').then(
                (m) => m.WhoWeInsureModule
            ),
    },
    {
        path: 'quote-list',
        loadChildren: () =>
            import('./quote/form/quote-list/quote-list.module').then(
                (m) => m.QuoteListModule
            ),
        canActivate: [QuoteListGuard],
    },
    {
        path: 'payment-confirmation',
        loadChildren: () =>
            import(
                './post-issuance/payment-confirmation/payment-confirmation.module'
            ).then((m) => m.PaymentConfirmationModule),
        canActivate: [PaymentConfirmationGuard],
    },
    {
        path: 'business-details',
        loadChildren: () =>
            import(
                './quote/form/business-details/business-details.module'
            ).then((m) => m.BusinessDetailsModule),
        canActivate: [BusinessDetailsGuard],
    },
    {
        path: 'optional-coverages/:productIndex',
        loadChildren: () =>
            import(
                './quote/form/build-your-policy/optional-coverages/optional-coverages.module'
            ).then((m) => m.OptionalCoveragesModule),
        canActivate: [CustomPackagesGuard],
    },
    {
        path: 'sci-optional-coverages/:productIndex',
        loadChildren: () =>
            import(
                './quote/form/build-your-policy/sci-optional-coverages/sci-optional-coverages.module'
            ).then((m) => m.SciOptionalCoveragesModule),
    },
    {
        path: 'additional-info/:productIndex',
        loadChildren: () =>
            import('./quote/form/additional-info/additional-info.module').then(
                (m) => m.AdditionalInfoModule
            ),
        canActivate: [AdditionalInfoGuard],
    },
    {
        path: 'get-acquainted',
        loadChildren: () =>
            import('./quote/form/sci-eligibility/sci-eligibility.module').then(
                (m) => m.SciEligibilityModule
            ),
        canActivate: [AdditionalInfoGuard],
    },
    {
        path: 'custom-packages/:productIndex',
        loadChildren: () =>
            import('./issuance/custom-packages/custom-packages.module').then(
                (m) => m.CustomPackagesModule
            ),
        canActivate: [CustomPackagesGuard],
    },
    {
        path: 'review-application',
        loadChildren: () =>
            import(
                './issuance/review-application/review-application.module'
            ).then((m) => m.ReviewApplicationModule),
        canActivate: [ReviewApplicationGuard],
    },
    {
        path: 'loss-history/:productIndex',
        loadChildren: () =>
            import('./issuance/loss-history/loss-history.module').then(
                (m) => m.LossHistoryModule
            ),
    },
    {
        path: 'payment',
        loadChildren: () =>
            import('./issuance/payment/payment.module').then(
                (m) => m.PaymentModule
            ),
        canActivate: [PaymentGuard],
    },
    {
        path: 'partners-redirection',
        loadChildren: () =>
            import(
                './quote/form/partners-redirection/partners-redirection.module'
            ).then((m) => m.PartnersRedirectionModule),
        canActivate: [PartnersRedirectionGuard],
    },
    {
        path: 'eligibility-questions',
        loadChildren: () =>
            import('./quote/form/eligibility/eligibility.module').then(
                (m) => m.EligibilityModule
            ),
        canActivate: [EligibilityGuard],
    },
    {
        path: 'not-eligible',
        loadChildren: () =>
            import('./quote/home-page/no-product/no-product.module').then(
                (m) => m.NoProductModule
            ),
    },
    {
        path: 'products',
        loadChildren: () =>
            import('./quote/home-page/products/products.module').then(
                (m) => m.ProductsModule
            ),
        canActivate: [ProductsGuard],
    },
    {
        path: 'businesstype',
        loadChildren: () =>
            import('./quote/businesstype/businesstype.module').then(
                (m) => m.BusinesstypeModule
            ),
    },
    {
        path: 'build-your-own-package/:productIndex',
        loadChildren: () =>
            import(
                './issuance/build-your-own-package/build-your-own-package.module'
            ).then((m) => m.BuildYourOwnPackageModule),
        canActivate: [CustomPackagesGuard],
    },
    {
        path: 'policy-limits/:productIndex',
        loadChildren: () =>
            import('./issuance/policy-limits/policy-limits.module').then(
                (m) => m.PolicyLimitsModule
            ),
    },
    {
        path: 'employer-liability-limits/:productIndex',
        loadChildren: () =>
            import(
                './issuance/employer-liability-limits/employer-liability-limits.module'
            ).then((m) => m.EmployerLiabilityLimitsModule),
    },
    {
        path: 'cyber-policy-limits/:productIndex',
        loadChildren: () =>
            import(
                './issuance/cyber-policy-limits/cyber-policy-limits.module'
            ).then((m) => m.CyberPolicyLimitsModule),
    },
    {
        path: 'cyber-optional-coverages/:productIndex',
        loadChildren: () =>
            import(
                './issuance/cyber-optional-coverages/cyber-optional-coverages.module'
            ).then((m) => m.CyberOptionalCoveragesModule),
    },
    {
        path: 'cyber-policy-limits/:productIndex',
        loadChildren:
            'app/issuance/cyber-policy-limits/cyber-policy-limits.module#CyberPolicyLimitsModule',
    },
    {
        path: 'cyber-optional-coverages/:productIndex',
        loadChildren:
            'app/issuance/cyber-optional-coverages/cyber-optional-coverages.module#CyberOptionalCoveragesModule',
    },
    {
        path: 'cart',
        loadChildren: () =>
            import('./quote/form/products-cart/products-cart.module').then(
                (m) => m.ProductsCartModule
            ),
    },
    {
        path: 'make-payment-terms',
        loadChildren: () =>
            import(
                './static-pages/make-payment-terms/make-payment-terms.module'
            ).then((m) => m.MakePaymentTermsModule),
    },
    {
        path: 'generic-error',
        loadChildren: () =>
            import('./components/generic-error/generic-error.module').then(
                (m) => m.GenericErrorModule
            ),
    },
    {
        path: 'print-disclosure/:initials',
        loadChildren: () =>
            import('./print-disclosure/print-disclosure.module').then(
                (m) => m.PrintDisclosureModule
            ),
    },
    {
        path: 'home/:isShowAll',
        component: HomePageContainerComponent,
    },
    {
        path: '',
        component: HomePageContainerComponent,
    },
    {
        path: 'cisa',
        component: HomePageContainerComponent,
    },
    {
        path: 'blog/:blogContentName',
        component: BlogArticleComponent,
    },
    {
        path: 'quote-download/:quoteId',
        component: DownloadQuoteComponent,
    },
    {
        path: 'receipt-download/:quoteId',
        component: DownloadReceiptComponent,
    },
    {
        path: 'workers-comp-job-type/:productIndex',
        component: WorkersCompJobTypeComponent,
        canActivate: [CustomPackagesGuard],
    },
    {
        path: 'nawbo',
        redirectTo: '/?utm_source=nawbo',
        pathMatch: 'full',
    },
    {
        path: 'paypal',
        redirectTo: '/?utm_source=paypal',
        pathMatch: 'full',
    },
    {
        path: 'pbn',
        redirectTo: '/?utm_source=pbn',
        pathMatch: 'full',
    },
    {
        path: 'capitalone',
        redirectTo: '/?utm_source=capitalone&utm_medium=spring',
        pathMatch: 'full',
    },
    {
        path: 'RSA',
        redirectTo: '/?partnercode=RSA10&utm_source=RSA&utm_medium=affiliate',
        pathMatch: 'full',
    },
    {
        path: 'partner/:partner',
        component: HomePageContainerComponent,
    },
    {
        path: 'coverages/:pageName',
        component: CsbMarketingpagesComponent,
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        PaymentConfirmationGuard,
        BusinessDetailsGuard,
        AdditionalInfoGuard,
        CustomPackagesGuard,
        ReviewApplicationGuard,
        PaymentGuard,
        ProductsGuard,
        PartnersRedirectionGuard,
        QuoteListGuard,
    ],
})
export class AppRoutingModule {}
