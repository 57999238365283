import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CallUsNumberModel } from '../../model/callUsNumber';
import { CommonCommunicationService } from '../../services/common-communication.service';
@Component({
    selector: 'app-cisa-disclosure',
    templateUrl: './cisa-disclosure.component.html',
    styleUrls: ['./cisa-disclosure.component.scss'],
})
export class cisaDisclosureComponent implements OnInit {
    isWidgetView = false;
    public commonCommunicationService: CommonCommunicationService;
    callUsNumber: CallUsNumberModel;
    @Input() cisaDisclosureData: any;
    constructor(public router: Router, public ccs: CommonCommunicationService) {
        if (this.router.url.includes('legal/cisa-disclosure-consent')) {
            this.isWidgetView = true;
        }
    }

    ngOnInit() {
        this.callUsNumber = this.ccs.callUsNumber();
    }
    printDisclosure() {
        window.print();
    }
}
