import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EDeliveryComponent } from './e-delivery.component';

@NgModule({
    imports: [CommonModule],
    declarations: [EDeliveryComponent],
    exports: [EDeliveryComponent],
})
export class EDeliveryModule {}
