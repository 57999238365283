import { DictionaryService } from './../../../services/dictionary.service';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
//import { CommonCommunicationService } from './../../../services/common-communication.service';

@Component({
    selector: 'app-search-modal',
    templateUrl: './search-modal.component.html',
    styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements OnInit {
    public labelDictionary: any;
    //public ccs: CommonCommunicationService;
    @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        //private pCommonCommunicationService: CommonCommunicationService,
        private dictionaryService: DictionaryService
    ) {
        //this.ccs = pCommonCommunicationService;
    }

    ngOnInit() {}

    closeSearch() {
        this.closeModal.emit(true);
        //this.ccs.isSearchActivate = false;
    }
}
