import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadReceiptComponent } from './download-receipt.component';

@NgModule({
    imports: [CommonModule],
    declarations: [DownloadReceiptComponent],
    exports: [DownloadReceiptComponent],
})
export class DownloadReceiptModule {}
