import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-social-media',
    templateUrl: './social-media.component.html',
    styleUrls: ['./social-media.component.scss'],
})
export class SocialMediaComponent implements OnInit {
    socialMedia = [];
    blobURL = environment.blobStorageBaseURL;
    @Input() mediaData: any;

    constructor() {}

    ngOnInit() {
        this.socialMedia = this.mediaData;
    }
}
