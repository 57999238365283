import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintDisclosureComponent } from './print-disclosure.component';
import { PrintDisclosureRoutingModule } from './print-disclosure-routing.module';
import { EDeliveryModule } from '../issuance/e-delivery/e-delivery.module';
import { cisaDisclosureModule } from '../issuance/cisa-disclosure/cisa-disclosure.module';

@NgModule({
    imports: [CommonModule, PrintDisclosureRoutingModule, EDeliveryModule, cisaDisclosureModule],
    declarations: [PrintDisclosureComponent],
})
export class PrintDisclosureModule {}
