import { Component, OnInit, Input } from '@angular/core';
import { CarouselLinkData } from '../model/carousel-video';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { GAEvent } from '../../../../csb-core/ga-event';
import { AnalyticsService } from '@crux/services';

@Component({
    selector: 'app-carousel-link',
    templateUrl: './carousel-link.component.html',
    styleUrls: ['./carousel-link.component.scss'],
})
export class CarouselLinkComponent implements OnInit {
    @Input() title: string;

    @Input() linkData: Array<CarouselLinkData> = [];

    constructor(
        private _router: Router,
        private _analytics: AnalyticsService
    ) {}

    ngOnInit() {}

    handleLink(value, name) {
        GAEvent.CapturEvent(
            this._analytics,
            GAEvent.ACTION.LINK_CLICK,
            GAEvent.CATEGORY.Start_Cover_Grow,
            this.title + '-' + name
        );
        this._router.navigateByUrl(value);
    }
}
