import { Injectable } from '@angular/core';
import { CruxHttpClientService, ReadJsonService } from '@crux/services';

import labels from '../../assets/json/labels.json';
import SciUwqWordingsDigital from '../../assets/json/sci-UWQ-digital-wordings.json';
import { environment } from '../../environments/environment';
import { isEmpty, isNil } from 'lodash';

@Injectable()
export class DictionaryService {
    labelDict: any;
    UWQWordingsDigital: any;
    public blobURL = environment.blobStorageBaseURL;

    constructor(
        public cruxReadJson: ReadJsonService,
        private http: CruxHttpClientService
    ) {}

    load() {
        const ts = new Date().getTime();
        this.http
            .get(
                this.blobURL + '/data/sci-UWQ-digital-wordings.json?ts=' + ts,
                {
                    responseType: 'json',
                }
            )
            .subscribe((res) => {
                if (!isNil(res) && !isEmpty(res)) {
                    this.UWQWordingsDigital = res;
                } else {
                    this.UWQWordingsDigital = SciUwqWordingsDigital;
                }
            });
        this.labelDict = labels;
        //this.UWQWordingsDigital = SciUwqWordingsDigital;
    }
}
