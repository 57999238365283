import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from '@crux/services';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
@Component({
    selector: 'app-marketing-product-info',
    templateUrl: './marketing-product-info.component.html',
    styleUrls: ['./marketing-product-info.component.scss'],
})
export class MarketingProductInfoComponent implements OnInit {
    // section 1
    @Input() product: string;
    @Input() imagePath: any;
    @Input() title: string;
    @Input() info: string;
    @Input() description: string;

    // section 2
    @Input() protectTitle: string;
    @Input() productImage: any;
    @Input() productTitle: string;
    @Input() infoItems: string[];
    @Input() productDesc: string;

    @Input() notes: any;

    // Section 3
    @Input() protectArrItems: string[];
    imageBasePath: any;
    isBopPage = false;
    constructor(
        private _router: Router,
        private _analytics: AnalyticsService,
        private _activatedRoute: ActivatedRoute
    ) {
        this.imageBasePath = environment.blobStorageBaseURL;
    }

    ngOnInit() {
        this._activatedRoute.params.subscribe((param) => {
            if (param['pageName'] === 'business-owners-policy') {
                this.isBopPage = true;
            } else {
                this.isBopPage = false;
            }
        });
    }

    navigateRoutes(url) {
        this._analytics.eventTrack.next({
            category: 'NAVBAR',
            action: 'NAVBAR_LINK_CLICK',
            label: 'learn more',
        });
        this._router.navigate([url]);
    }
}
