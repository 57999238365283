import { CruxHttpClientService } from '@crux/services';
import { Injectable } from '@angular/core';
// import { Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { CustomerInfo } from './../model/customer-info';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class CustomerInfoService {
    // public info: CustomerInfo

    public url: string = '../../../mocks/data.json';

    constructor(private http: CruxHttpClientService) {}

    getFraudDisclosure(): Observable<CustomerInfo[]> {
        return this.http.get(this.url);
    }

    saveCustomerInfo(info: CustomerInfo): Observable<any> {
        return this.http.post(this.url, { body: info });
    }
}
