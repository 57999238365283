import {
    LossDetailsActionTypes,
    LossDetailsActionUnion,
} from '../actions/loss-history.action';

export let lossDetailsInitialState: any;

export function LossDetailsReducer(
    LossDetailsData = lossDetailsInitialState,
    action: LossDetailsActionUnion
) {
    switch (action.type) {
        case LossDetailsActionTypes.lossHistoryData: {
            return action.payload;
        }
        default: {
            return LossDetailsData;
        }
    }
}
