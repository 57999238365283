import { Action } from '@ngrx/store';

export enum RetrieveQuoteActionTypes {
    setRetrieveQuoteData = '[QuoteResponse] setRetrieveQuote',
    setRetrieveQuoteEmail = '[setRetrieveQuoteEmail] setRetrieveQuoteEmail',
}

export class SetRetrieveQuoteAction implements Action {
    readonly type = RetrieveQuoteActionTypes.setRetrieveQuoteData;

    constructor(public payload: any) {}
}

export class SetRetrieveQuoteEmailAction implements Action {
    readonly type = RetrieveQuoteActionTypes.setRetrieveQuoteEmail;
    constructor(public payload: any) {}
}
export type RetrieveQuoteActionUnion =
    | SetRetrieveQuoteAction
    | SetRetrieveQuoteEmailAction;
