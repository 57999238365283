import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferralMessageComponent } from './referral-message.component';
import { AnalyticsModule } from '@crux/services';

@NgModule({
    declarations: [ReferralMessageComponent],
    imports: [CommonModule, AnalyticsModule],
    exports: [ReferralMessageComponent],
})
export class ReferralMessageModule {}
