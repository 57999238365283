import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CruxHttpClientService } from '@crux/services';
@Injectable()
export class p360APIService {
    constructor(private http: CruxHttpClientService) {}

    keywordsearch(keyword) {
        let url: string = environment.backend + environment.uri.keywordSearch;
        let requestObject = {
            search: keyword,
            enable_default_predictions: true,
        };
        return this.http
            .post(url, {
                body: requestObject,
            })
            .pipe(
                catchError((error: Response) => {
                    return observableThrowError(error);
                })
            );
    }
}
