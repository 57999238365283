import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { filter, isEmpty, isNil, map } from 'lodash-es';
import {
    ProductSelectedPipe,
    NoReferralProductsPipe,
} from '../../pipes/productSelected.pipe';
import { CommonCommunicationService } from '../../services/common-communication.service';
import { AnalyticsService } from '@crux/services';
import { EmailJourney } from '../../../assets/json/start-email-journey-payload';
import { ApplicationMessagesService } from '../../services/application-messages.service';
import { SaveEmailService } from '../../services/save-email.service';
import { DictionaryService } from '../../services/dictionary.service';

/**
 * NGRX store imports
 */
import { QuestionsEffects } from './../../sales-store/questions-store/effects';
import { CustomerInfo } from '../../model/customer-info';
import { Store, select } from '@ngrx/store';
import { MaskGapii } from '../../issuance/helper/ga-mask-pii';
import { SetCustomerInfoDataAction } from '../../sales-store/questions-store/actions';
import { QuoteStep } from '../../model/quote-step';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { AffinityPartnerFlags } from '../../model/affinity-partner';

@Component({
    selector: 'app-email-form',
    templateUrl: './email-form.component.html',
    styleUrls: ['./email-form.component.scss'],
})
export class EmailFormComponent implements OnInit {
    public ccs: CommonCommunicationService;
    emailForm: FormGroup;
    email: FormControl;
    emailJourney: EmailJourney = new EmailJourney();
    errorMessages: any;
    labelDictionary: any;
    @Input() label = 'Email Address: ';
    @Input() prefilledEmail = false;
    @Input() showOkButton?: boolean;

    customerInfoData: CustomerInfo;
    questions: any;
    products: any;
    public emailIeNotification: string;
    public gaMask: MaskGapii;
    public quote: QuoteStep = new QuoteStep();
    AffinityPartnerFlags: AffinityPartnerFlags;

    constructor(
        public pCommonCommunicationService: CommonCommunicationService,
        public applicationmessagesService: ApplicationMessagesService,
        public saveEmailService: SaveEmailService,
        public dictionaryService: DictionaryService,
        private _analytcs: AnalyticsService,
        private _questionsEffects: QuestionsEffects,
        public dialog: MatDialog,
        private _store: Store<{
            questions: any;
            products: any;
            quoteStep: QuoteStep;
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>
    ) {
        this.ccs = pCommonCommunicationService;
        this._questionsEffects
            .readCustomerInfoDataFromStore$()
            .subscribe((customerInfoData) => {
                this.customerInfoData = customerInfoData;
            });
        this._store.pipe(select('questions')).subscribe((questions) => {
            this.questions = questions;
        });
        this._store.pipe(select('products')).subscribe((products) => {
            this.products = new ProductSelectedPipe().transform(products);
        });
        this._store
            .pipe(select('quoteStep'))
            .subscribe((quoteStep: QuoteStep) => {
                this.quote = quoteStep;
            });
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.AffinityPartnerFlags = AffinityPartnerFlags;
                    }
                }
            });
        this.gaMask = new MaskGapii();
    }

    ngOnInit() {
        this.ccs.isShowIdleEmailSuccess = false;
        this.labelDictionary = this.dictionaryService.labelDict;
        this.email = new FormControl('', [
            Validators.required,
            Validators.email,
        ]);
        this.emailForm = new FormGroup(
            {
                email: this.email,
            },
            { updateOn: 'submit' }
        );
        if (this.customerInfoData.emailAddress) {
            this.email.setValue(this.customerInfoData.emailAddress);
            // Save for Future Use
            // this.SaveEmail();
        } else if (this.ccs.savedQuoteEmail) {
            this.email.setValue(this.ccs.savedQuoteEmail);
            // Save For Future Use
            // this.SaveEmail();
        }
        this.errorMessages = this.applicationmessagesService.messageDictionary;
        this.emailIeNotification = this.labelDictionary.ieNotification.forEmail;
    }

    SaveEmail() {
        const products = this.getChosenProductsNameAndQuoteNumber(
            this.products
        );
        this._analytcs.eventTrack.next({
            category: this.prefilledEmail
                ? 'ProductsInCart'
                : 'PackageSelection',
            action: 'BUTTON_CLICK',
            label: this.prefilledEmail
                ? 'Emailed the Quote to : ' +
                  this.gaMask.gaEmailMask(this.email.value)
                : 'Emailed the Quote',
        });
        if (this.emailForm.valid) {
            this.ccs.savedQuoteEmail = this.email.value;
            // SUX-849-Start Email Journey
            let startEmailPayloadFormat: any = this.emailJourney
                .emailJourneyPayload;
            startEmailPayloadFormat = this.emailJourney.buildEmailJourneyQuoteRequest(
                startEmailPayloadFormat,
                this.questions,
                new NoReferralProductsPipe().transform(this.products),
                this.email.value.toLowerCase(),
                true,
                this.ccs.PartnerId,
                this.quote,
                this.AffinityPartnerFlags
            );
            this.ccs.emailJourneyTriggered = true;
            this.saveEmailService
                .startEmailJourney(startEmailPayloadFormat)
                .subscribe((data) => {
                    if (data) {
                        // tslint:disable-next-line: max-line-length
                        // The email was successfully sent, set the isShowIdleEmailSuccess flag to true so that the idle modal doesn't come up
                        this.products.forEach((product) => {
                            if (product.quote_number) {
                                product.emailJourneyStarted = true;
                            }
                        });
                        this.ccs.isShowIdleEmailSuccess = true;
                        // Save email address to store to pre-populate it on review screen.
                        this.customerInfoData.emailAddress = this.email.value;
                        if (this.ccs.isIdling) {
                            this.ccs.userSavedQuoteInIdleMessage = true;
                        }
                        this._store.dispatch(
                            new SetCustomerInfoDataAction(this.customerInfoData)
                        );
                        this.ccs.emailSubject.next(
                            this.customerInfoData.emailAddress
                        );
                        this._analytcs.eventTrack.next({
                            category: 'PackageSelection',
                            action: 'EMAIL_QUOTE_SUCCESS',
                            label: products,
                        });

                        this._analytcs.eventTrack.next({
                            category: 'PackageSelection',
                            action: 'EMAIL_QUOTE',
                            label: 'Emailed the Quote',
                        });
                    }
                }),
                // tslint:disable-next-line: no-unused-expression
                (error: Response) => {
                    this.products.forEach((product) => {
                        if (product.quote_number) {
                            product.emailJourneyStarted = false;
                        }
                    });
                    this.ccs.isShowIdleEmailSuccess = false;
                    this._analytcs.eventTrack.next({
                        category: 'PackageSelection',
                        action: 'EMAIL_QUOTE_FAILURE',
                        label: products,
                    });
                };
            // end changes for SUX -849
        } else {
            this.emailForm.get('email').updateValueAndValidity();
        }
    }

    // setEmail() {
    //     let questionGroups = this.ccs.quoteRequestData.ratingUWQuestions;
    //     for (let i = 0; i < questionGroups.length; i++) {
    //         if (questionGroups[i].group_name === 'Additional Information') {
    //             for (let j = 0; j < questionGroups[i].questions.length; j++) {
    //                 if (questionGroups[i].questions[j].question_id === '12') {
    //                     this.ccs.quoteRequestData.customerInfoData.emailAddress = questionGroups[
    //                         i
    //                     ].questions[j].value = this.email.value;
    //                 }
    //             }
    //         }
    //     }
    // }

    // Move to CCS
    getChosenProductsNameAndQuoteNumber(products): string {
        return map(filter(products, { selected: true }), (product) => {
            return product['productId'] + ' - ' + product['quote_number'];
        }).join(';');
    }

    onNoClick() {
        this._analytcs.eventTrack.next({
            category: 'DATAPOPUP',
            action: 'DATAPOPUP_DETAILS',
            label: 'CLOSE MODAL COMPONENT',
        });
        this.dialog.closeAll();
    }
}
