import { Action } from '@ngrx/store';

import {
    Questions,
    SimpleQuestions,
    Question,
} from './../../../model/questions';
import { EligibilityQuestionModel } from '../../../model/eligibility';

export enum QuestionsActionTypes {
    setQuestions = '[Set Questions] Set Questions to Store',
    resetQuestions = '[Set Questions] Reset Questions to Store',
    setQuestionsAnswer = '[Set Questions Answer] Set Questions Answer to Store',
    setQuestionsError = '[Set Questions Error] SetQuestions Error',
    setSciQuestions = '[Set Sci Questions] Set Sci Questions to Store',
    setSciQuestionsError = '[Set Sci Questions Error] SetSciQuestions Error',
    setSciCyberQuestions = '[Set Sci Cyber Questions] Set Sci Cyber Questions to Store',
    setSciCyberQuestionsError = '[Set Sci Cyber Questions Error] Set Sci Cyber Questions Error',
    setSciChildQuestions = '[Set Sci Child Questions] Set Sci Child Questions to Store',
    setSciChildQuestionsError = '[Set Sci Child Questions Error] getSciChildQuestions Error',
    setSimpleQuestionsObj = '[ Set Simple Questions ] Set Simple Questions Object in Store',
    setCustomerInfoData = '[ Set CustomerInfoData ] Set Customer Information in Store',
    istBizDetails = '[ boolean ] Is Business Detals Answered',
    isAdditionalInfo = '[boolean] Is Additional Info Answered',
    setEligibily = '[Questions[]] Set Eligibility Answers to store',
    resetEligibility = '[Questions[]] Reset Eligibility Answers to store',
    setBusinessAddress = '[BusinessMailingAddress] set Business Mailing Address to store',
}

export class SetQuestionsAction implements Action {
    readonly type = QuestionsActionTypes.setQuestions;

    constructor(public payload: Questions[]) {}
}

export class ResetQuestionsAction implements Action {
    readonly type = QuestionsActionTypes.resetQuestions;

    constructor(public payload: Questions[]) {}
}

export class SetQuestionsAnswerAction implements Action {
    readonly type = QuestionsActionTypes.setQuestionsAnswer;

    constructor(public payload: any) {}
}

export class SetQuestionsErrorAction implements Action {
    readonly type = QuestionsActionTypes.setQuestionsError;

    constructor(public payload: any) {}
}

export class SetSciQuestionsAction implements Action {
    readonly type = QuestionsActionTypes.setSciQuestions;

    constructor(public payload: any) {}
}

export class SetSciCyberQuestionsAction implements Action {
    readonly type = QuestionsActionTypes.setSciCyberQuestions;

    constructor(public payload: any) {}
}

export class SetSciQuestionsErrorAction implements Action {
    readonly type = QuestionsActionTypes.setSciQuestionsError;

    constructor(public payload: any) {}
}

export class SetSciCyberQuestionsErrorAction implements Action {
    readonly type = QuestionsActionTypes.setSciCyberQuestionsError;

    constructor(public payload: any) {}
}

export class SetSciChildQuestionsAction implements Action {
    readonly type = QuestionsActionTypes.setSciChildQuestions;

    constructor(public payload: any) {}
}

export class SetSciChildQuestionsErrorAction implements Action {
    readonly type = QuestionsActionTypes.setSciChildQuestionsError;

    constructor(public payload: any) {}
}
export class SetSimpleQuestionsObjAction implements Action {
    readonly type = QuestionsActionTypes.setSimpleQuestionsObj;

    constructor(public payload: SimpleQuestions) {}
}

export class SetCustomerInfoDataAction implements Action {
    readonly type = QuestionsActionTypes.setCustomerInfoData;

    constructor(public payload: any) {}
}
export class SetBizDetailsAction implements Action {
    readonly type = QuestionsActionTypes.istBizDetails;
}
export class SetAddInfoAction implements Action {
    readonly type = QuestionsActionTypes.isAdditionalInfo;
    constructor(public payload: number) {}
}
export class SetEligibilityAction implements Action {
    readonly type = QuestionsActionTypes.setEligibily;
    constructor(public payload: EligibilityQuestionModel[]) {}
}
export class ResetEligibilityAction implements Action {
    readonly type = QuestionsActionTypes.resetEligibility;
    constructor(public payload: EligibilityQuestionModel[]) {}
}

export class SetBusinessMailingAddressAction implements Action {
    readonly type = QuestionsActionTypes.setBusinessAddress;
    constructor(public payload: any) {}
}

export type QuestionsActionUnion =
    | SetQuestionsAction
    | SetQuestionsAnswerAction
    | SetQuestionsErrorAction
    | SetSciQuestionsAction
    | SetSciQuestionsErrorAction
    | SetSciCyberQuestionsAction
    | SetSciCyberQuestionsErrorAction
    | SetSciChildQuestionsAction
    | SetSciChildQuestionsErrorAction
    | SetSimpleQuestionsObjAction
    | SetCustomerInfoDataAction
    | SetBizDetailsAction
    | SetAddInfoAction
    | SetEligibilityAction
    | SetBusinessMailingAddressAction
    | ResetEligibilityAction
    | ResetQuestionsAction;
