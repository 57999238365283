import { throwError as observableThrowError } from 'rxjs';

import { catchError, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { filter, isEmpty, isNil, map } from 'lodash-es';
import { AnalyticsService, CruxHttpClientService } from '@crux/services';
import { environment } from '../../environments/environment';
import { CommonCommunicationService } from './common-communication.service';
import { Classification } from '../model/classification';
import { Store, select } from '@ngrx/store';
import { AffinityPartnerFlags } from '../model/affinity-partner';
import { HttpHeaders } from '@angular/common/http';

enum Partner {
    TeamFocus = 0,
    Amex,
}

@Injectable()
export class QuoteResponseService {
    quoteServiceUrl: string;
    selectedClass: Classification;
    affinityPartnerFlags = new AffinityPartnerFlags();
    partnerCode: any;
    constructor(
        private http: CruxHttpClientService,
        private _ccs: CommonCommunicationService,
        private _analytics: AnalyticsService,
        private _store: Store<{
            classification: Classification;
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>
    ) {
        this._store.pipe(select('classification')).subscribe((data) => {
            this.selectedClass = data;
        });
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    this.affinityPartnerFlags.partnercode =
                        AffinityPartnerFlags.partnercode;
                    if (
                        this.affinityPartnerFlags.partnercode &&
                        !isNil(this.affinityPartnerFlags.partnercode) &&
                        !isEmpty(this.affinityPartnerFlags.partnercode)
                    ) {
                        if (
                            this.affinityPartnerFlags.partnercode !== 'walmart'
                        ) {
                            if (
                                this.affinityPartnerFlags.partnercode ===
                                'cospring5'
                            ) {
                                this.partnerCode = 'capitalone5';
                            } else if (
                                this.affinityPartnerFlags.partnercode ===
                                'cospring10'
                            ) {
                                this.partnerCode = 'capitalone10';
                            } else {
                                this.partnerCode = this.affinityPartnerFlags.partnercode;
                            }
                        }
                    }
                }
            });
    }

    getSciQuoteResponse(quoteRequestBody): any {
        const url = environment.backend + environment.uri.scirate;
        if (quoteRequestBody != null || quoteRequestBody !== undefined) {
            return this.http.post(url, { body: quoteRequestBody }).pipe(
                timeout(120000), // 2 minutes
                catchError((error: Response) => {
                    const chosenProducts = map(
                        filter(this._ccs.quoteRequestData.products, {
                            selected: true,
                        }),
                        (product: any) => {
                            return product['text'];
                        }
                    ).join(';');

                    const chosenClassification = this.selectedClass.name;

                    this._analytics.eventTrack.next({
                        category: 'PackageSelection',
                        action: 'SCI_RATE_QUOTE_API_FAILURE',
                        label: chosenClassification + ' : ' + chosenProducts,
                    });
                    return observableThrowError(error);
                })
            );
        }
    }

    updateSciQuote(quoteRequestBody): any {
        let url = environment.backend + environment.uri.sciQuoteUpdate;
        url = url.replace('#PARTNER#', this._ccs.PartnerId);
        if (quoteRequestBody != null || quoteRequestBody !== undefined) {
            return this.http.post(url, { body: quoteRequestBody }).pipe(
                timeout(120000), // 2 minutes
                catchError((error: Response) => {
                    const chosenProducts = map(
                        filter(this._ccs.quoteRequestData.products, {
                            selected: true,
                        }),
                        (product: any) => {
                            return product['text'];
                        }
                    ).join(';');

                    const chosenClassification = this.selectedClass.name;

                    this._analytics.eventTrack.next({
                        category: 'PackageSelection',
                        action: 'SCI_UPADTE_RATE_QUOTE_API_FAILURE',
                        label: chosenClassification + ' : ' + chosenProducts,
                    });
                    return observableThrowError(error);
                })
            );
        }
    }

    updateSemciSciQuote(quoteRequestBody): any {
        let url = environment.backend + environment.uri.scisemciupdatequote;
        url = url.replace('#PARTNER#', this._ccs.PartnerId);
        if (quoteRequestBody != null || quoteRequestBody !== undefined) {
            if (
                this._ccs.backendEnvVariable &&
                !isNil(this._ccs.backendEnvVariable) &&
                !isEmpty(this._ccs.backendEnvVariable)
            ) {
                const headers = {};
                headers['env'] = this._ccs.backendEnvVariable;
                return this.http
                    .post(url, {
                        body: quoteRequestBody,
                        headers: new HttpHeaders(headers),
                    })
                    .pipe(
                        timeout(120000), // 2 minutes
                        catchError((error: Response) => {
                            const chosenProducts = map(
                                filter(this._ccs.quoteRequestData.products, {
                                    selected: true,
                                }),
                                (product: any) => {
                                    return product['text'];
                                }
                            ).join(';');

                            const chosenClassification = this.selectedClass
                                .name;

                            this._analytics.eventTrack.next({
                                category: 'PackageSelection',
                                action: 'SCI_UPADTE_RATE_QUOTE_API_FAILURE',
                                label:
                                    chosenClassification +
                                    ' : ' +
                                    chosenProducts,
                            });
                            return observableThrowError(error);
                        })
                    );
            } else {
                return this.http.post(url, { body: quoteRequestBody }).pipe(
                    timeout(120000), // 2 minutes
                    catchError((error: Response) => {
                        const chosenProducts = map(
                            filter(this._ccs.quoteRequestData.products, {
                                selected: true,
                            }),
                            (product: any) => {
                                return product['text'];
                            }
                        ).join(';');

                        const chosenClassification = this.selectedClass.name;

                        this._analytics.eventTrack.next({
                            category: 'PackageSelection',
                            action: 'SCI_UPADTE_RATE_QUOTE_API_FAILURE',
                            label:
                                chosenClassification + ' : ' + chosenProducts,
                        });
                        return observableThrowError(error);
                    })
                );
            }
        }
    }

    getQuoteResponse(
        quoteRequestBody,
        partnerId,
        policyStateCode,
        productText
    ): any {
        let url: string;
        if (!isNil(this.partnerCode) && !isEmpty(this.partnerCode)) {
            if (this.partnerCode === 'CISA') {
                this.partnerCode = 0;
            }
            url =
                environment.backend +
                environment.uri.quote.replace('#PARTNER#', this.partnerCode);
        } else {
            url =
                environment.backend +
                environment.uri.quote.replace('#PARTNER#', partnerId);
        }
        if (quoteRequestBody != null || quoteRequestBody !== undefined) {
            return this.http.post(url, { body: quoteRequestBody }).pipe(
                timeout(120000), // 2 minutes
                catchError((error: Response) => {
                    const chosenClassification = this.selectedClass.name;

                    this._analytics.eventTrack.next({
                        category: 'PackageSelection',
                        action: 'QUOTE_API_FAILURE',
                        label: chosenClassification + ' : ' + productText,
                    });
                    return observableThrowError(error);
                })
            );
        }
    }

    getWcQuoteResponse(quoteRequestBody, productText): any {
        const url = environment.backend + environment.uri.wcQuote;
        if (quoteRequestBody !== null || quoteRequestBody !== undefined) {
            if (
                this._ccs.backendEnvVariable &&
                !isNil(this._ccs.backendEnvVariable) &&
                !isEmpty(this._ccs.backendEnvVariable)
            ) {
                const headers = {};
                headers['env'] = this._ccs.backendEnvVariable;
                return this.http
                    .post(url, {
                        body: quoteRequestBody,
                        headers: new HttpHeaders(headers),
                    })
                    .pipe(
                        timeout(120000), // 2 minutes
                        catchError((error: Response) => {
                            const chosenClassification = this.selectedClass
                                .name;

                            this._analytics.eventTrack.next({
                                category: 'Workers Comp',
                                action: 'QUOTE_API_FAILURE',
                                label:
                                    chosenClassification + ' : ' + productText,
                            });
                            return observableThrowError(error);
                        })
                    );
            } else {
                return this.http.post(url, { body: quoteRequestBody }).pipe(
                    timeout(120000), // 2 minutes
                    catchError((error: Response) => {
                        const chosenClassification = this.selectedClass.name;

                        this._analytics.eventTrack.next({
                            category: 'Workers Comp',
                            action: 'QUOTE_API_FAILURE',
                            label: chosenClassification + ' : ' + productText,
                        });
                        return observableThrowError(error);
                    })
                );
            }
        }
    }
}
