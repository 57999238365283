import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Answer } from './model/answer';
import { QuestionBase } from '../csb-question/csb-question-base.component';
import { GAEvent } from '../../csb-core/ga-event';
import { AnalyticsService } from '@crux/services';

@Component({
    selector: 'app-csb-yes-no',
    templateUrl: './csb-yes-no.component.html',
    styleUrls: ['./csb-yes-no.component.scss'],
})
export class CsbYesNoComponent extends QuestionBase implements OnInit {
    @Input() name: string;

    @Input() selectButton: string;

    @Output() answer: EventEmitter<string> = new EventEmitter<string>();

    yes: string;

    no: string;

    cssClass: Array<string>;

    componentClass: Array<string>;

    constructor(private _analytics: AnalyticsService) {
        super();
    }

    ngOnInit() {
        this.yes = Answer.Yes;
        this.no = Answer.No;
        this.cssClass = this.showImage
            ? ['col-4', 'col-md-4', 'col-sm-6']
            : ['col-7', 'col-md-7', 'col-sm-6'];

        this.componentClass = this.showImage
            ? ['col-5', 'col-md-5', 'col-sm-12']
            : ['col-5', 'col-md-5', 'col-sm-6'];

        if (this.data) {
            this.selectButton = this.data.value;
        }
    }

    buttonHandler(event: MouseEvent) {
        this.answer.emit(this.selectButton);
        if (this.gaevent) {
            GAEvent.CapturEvent(
                this._analytics,
                this.gaevent.action,
                this.gaevent.category,
                this.question + ':' + this.selectButton
            );
        }
        this.valid(true, this.selectButton);
    }
}
